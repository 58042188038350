import React from 'react';
import CollapsibleCard from '../Shared/CollapsibleCard';
import { Table } from 'reactstrap';

function StoreDistribution({ data }) {
  const categories = Array.from({ length: 10 }, (_, i) => i + 1);
  const quarters = [{label: 'LRQ', value: 'LRQ'}, {label: 'Q - 1', value: 'Q1'}, {label: 'Q - 2', value: 'Q2'}, {label: 'Q - 3', value: 'Q3'}, {label: 'Q - 4', value: 'Q4'}];

  const getCategoryRange = (category) => {
    switch(category) {
      case 1: return "$100,000+";
      case 2: return "$80,000 - $99,999.99";
      case 3: return "$60,000 - $79,999.99";
      case 4: return "$40,000 - $59,999.99";
      case 5: return "$30,000 - $39,999.99";
      case 6: return "$20,000 - $29,999.99";
      case 7: return "$15,000 - $19,999.99";
      case 8: return "$10,000 - $14,999.99";
      case 9: return "$5,000 - $9,999.99";
      case 10: return "$0 - $4,999.99";
      default: return "";
    }
  };

  return (
    <CollapsibleCard title="Store Distribution">
      <div className="store-distribution">
        <div className="table-responsive">
          <Table hover className="">
            <thead>
              <tr>
                <th className="font-weight-bold font-open-sans-condensed">Category</th>
                {quarters.map(quarter => (
                  <th key={quarter?.value} className="font-weight-bold font-open-sans-condensed">{quarter?.label}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {categories.map(category => (
                <tr key={category}>
                  <td className="font-16 font-weight-bold font-universe">
                    {category} ({getCategoryRange(category)})
                  </td>
                  {quarters.map(quarter => (
                    <td key={`${category}-${quarter?.value}`} className="font-16 font-open-sans-condensed">
                      {data?.[quarter?.value]?.[category - 1] || 0}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </CollapsibleCard>
  );
}

export default StoreDistribution;