import React from 'react';
import PropTypes from 'prop-types';
import loader from '../../../assets/images/loader2.gif';
import loaderInv from '../../../assets/images/loader2inv.gif';
import './styles.css';

const LoadingWrapper = ({ children, isInline }) => (
    <div className={isInline ? 'inline-loading-container-wrapper' : 'loading-container-wrapper'}>
        <div className={isInline ? 'inline-loading-container' : 'loading-container'}>{children}</div>
    </div>
);

LoadingWrapper.propTypes = {
    children: PropTypes.object,
    isInline: PropTypes.bool,
};

const Loading = props => {
    let loaderImage = loader;
    if (props.isInline) {
        loaderImage = loaderInv;
    }
    if (props.error) {
        return (
            <LoadingWrapper isInline={props.isInline}>
                <p>Something went wrong!</p>
            </LoadingWrapper>
        );
    }
    if (props.timedOut) {
        return (
            <LoadingWrapper isInline={props.isInline}>
                <div className={props.isInline ? 'text-center' : 'text-center mt-5'}>
                    {props.message && (<p>{props.message}</p>)}
                    <img src={loaderImage} alt="logo" />
                </div>
            </LoadingWrapper>
        );
    }
    if (props.pastDelay) {
        return (
            <LoadingWrapper isInline={props.isInline}>
                <span>
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                </span>
            </LoadingWrapper>
        );
    }
    return null;
};

Loading.propTypes = {
    error: PropTypes.bool,
    message: PropTypes.string,
    timedOut: PropTypes.bool,
    pastDelay: PropTypes.bool,
    isInline: PropTypes.bool,
};

export default Loading;
