import {
    GET_PRODUCT_DETAIL_ACTION_TYPES,
    GET_PRODUCT_SUMMARY_ACTION_TYPES,
    ADD_PRODUCT_TO_CART_ACTION_TYPES,
    RESET_CHANGE_LOG,
    UPDATE_ENGRAVING,
    ADD_TO_FAVORITE_ACTION_TYPES,
    REMOVE_FROM_FAVORITE_ACTION_TYPES,
    UPDATE_FAVORITES,
    RESET_ADD_TO_CART,
    RESET_PRODUCT_DATA,
} from './constants';
import CartManager from '../../utils/CartManager';
import AuthManager from '../../utils/AuthManager';

const initialData = {
    isFetching: false,
    product: {
        is_favorite: false,
    },
    addProductResult: null,
    isEngravingUpdated: false,
};

export default function productReducer(state = initialData, { payload, ...action }) {
    switch (action.type) {
        case GET_PRODUCT_DETAIL_ACTION_TYPES.request:
            return {
                ...state,
                isFetching: true,
                isEngravingUpdated: false,
            };
        case GET_PRODUCT_DETAIL_ACTION_TYPES.success:
            return {
                ...state,
                isFetching: false,
                isEngravingUpdated: false,
                product: payload,
            };
        case GET_PRODUCT_DETAIL_ACTION_TYPES.failure:
            return {
                ...state,
                isFetching: false,
                isEngravingUpdated: false,
            };
        case RESET_CHANGE_LOG:
            return {
                ...state,
                product: {
                    ...state.product,
                    changes: [],
                },
            };
        case UPDATE_ENGRAVING:
            return {
                ...state,
                isEngravingUpdated: true,
                product: {
                    ...state.product,
                    engraving: payload,
                },
            };
        case GET_PRODUCT_SUMMARY_ACTION_TYPES.request:
            return {
                ...state,
                isFetching: true,
                isEngravingUpdated: false,
            };
        case GET_PRODUCT_SUMMARY_ACTION_TYPES.success:
            return {
                ...state,
                isFetching: false,
                isEngravingUpdated: false,
                productSummary: payload,
            };
        case GET_PRODUCT_SUMMARY_ACTION_TYPES.failure:
            return {
                ...state,
                isFetching: false,
                isEngravingUpdated: false,
                productSummary: null,
            };
        case ADD_PRODUCT_TO_CART_ACTION_TYPES.request:
            return {
                ...state,
                isFetching: true,
                isEngravingUpdated: false,
                addProductResult: {
                    result: null,
                    message: '',
                },
            };
        case ADD_PRODUCT_TO_CART_ACTION_TYPES.success:
            const cartId = AuthManager.get() ? payload.cartId : payload.guestCartId;
            CartManager.set(cartId);
            return {
                ...state,
                isFetching: false,
                isEngravingUpdated: false,
                addProductResult: {
                    result: payload.result,
                    message:
                        payload.result === true
                            ? 'Item was added to cart.'
                            : 'There was a problem adding item to the cart.',
                },
            };
        case ADD_PRODUCT_TO_CART_ACTION_TYPES.failure:
            return {
                ...state,
                isFetching: false,
                isEngravingUpdated: false,
                addProductResult: {
                    result: false,
                    message: 'There was a problem adding item to the cart.',
                },
            };
        case ADD_TO_FAVORITE_ACTION_TYPES.request:
        case REMOVE_FROM_FAVORITE_ACTION_TYPES.request:
            return {
                ...state,
                isFetching: true,
            };
        case ADD_TO_FAVORITE_ACTION_TYPES.success:
            AuthManager.setAnonymousUserIfNotExists(payload.user_id);
            return {
                ...state,
                isFetching: false,
            };
        case ADD_TO_FAVORITE_ACTION_TYPES.failure:
        case REMOVE_FROM_FAVORITE_ACTION_TYPES.success:
        case REMOVE_FROM_FAVORITE_ACTION_TYPES.failure:
            return {
                ...state,
                isFetching: false,
            };
        case RESET_ADD_TO_CART:
            return {
                ...state,
                addProductResult: null,
            };
        case UPDATE_FAVORITES:
            return {
                ...state,
                product: {
                    ...state.product,
                    is_favorite: payload,
                },
            };
        case RESET_PRODUCT_DATA:
            return {
                ...state,
                ...initialData,
            };
        default:
            return state;
    }
}
