import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import queryString from 'qs';
import CurrentPlans from './CurrentPlans';
import BillingHistory from './BillingHistory';
import Loading from '../Shared/Loader';
import CustomerDetails from './CustomerDetails';
import Payment from './PaymentMethods';
import './styles.css';

const Dashboard = props => {
    const { isFetching,
        currentPlans,
        getCurrentPlan,
        getBillingHistory,
        billingHistory,
        getPaymentMethods,
        paymentMethods,
        addPaymentMethod,
        deletePaymentMethod,
        makeDefaultMethod,
        getCustomerDetails,
        customerDetails,
    } = props;
    const { search } = useLocation();
    const query = queryString.parse(search.slice(1));

    useEffect(() => {
        getCurrentPlan({ token: query.token });
    }, [query.token]);

    useEffect(() => {
        getBillingHistory({ token: query.token });
    }, [query.token]);

    useEffect(() => {
        getPaymentMethods({ token: query.token });
    }, [query.token]);

    useEffect(() => {
        getCustomerDetails({ token: query.token });
    }, [query.token]);

    return (
        <Row className="font-universe mx-auto mt-2 portal-container d-flex justify-content-between">
            {currentPlans?.data &&
            <Col xs={12} className="mb-3">
                <CurrentPlans
                    list={currentPlans.data}
                />
            </Col>}
            {paymentMethods?.data &&
            <Col xs={12} lg={5} className="mb-3">
                <Payment
                    getPaymentMethods={getPaymentMethods}
                    methods={paymentMethods.data}
                    addPaymentMethod={addPaymentMethod}
                    makeDefaultMethod={makeDefaultMethod}
                    deletePaymentMethod={deletePaymentMethod}
                    token={query.token}
                />
            </Col>}
            {customerDetails &&
            <Col xs={12} lg={5} className="mb-3">
                <CustomerDetails
                    details={customerDetails}
                />
            </Col>}
            {billingHistory?.data &&
            <Col xs={12} className="mb-3">
                <BillingHistory
                    history={billingHistory.data}
                />
            </Col>}
            <Loading timedOut={isFetching} />
        </Row>
    );
};

export default Dashboard;
