import get from 'lodash/get';
import { createSelector } from 'reselect';

export const selectProduct = state => get(state, 'staticProduct');

export const makeSelectIsFetching = () =>
    createSelector([selectProduct], isFetching => get(isFetching, 'isFetching', false));

export const makeSelectProduct = () =>
    createSelector([selectProduct], staticProduct => get(staticProduct, 'staticProduct', {}));

export const makeSelectProductSize = () =>
    createSelector([selectProduct], productSize => get(productSize, 'productSize', {}));

export const makeSelectAddProductResult = () =>
    createSelector([selectProduct], addProductResult => get(addProductResult, 'addProductResult', {}));

export const makeSelectUpdatedPrice = () =>
    createSelector([selectProduct], updatedPrice => get(updatedPrice, 'updatedPrice', {}));
