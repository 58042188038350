import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import config from '../../config';

const JckLanding = () => (
    <Container className="container-wide">
        <Row>
            <div className="wide-header-jck">
                <h1 className="font-syncopate jck-header">DON'T MISS OUT ON<br />AN ADDITIONAL</h1>
                <h1 className="font-syncopate jck-header strong">$36,000* OF PROFITS</h1>
                <h2 className="font-syncopate jck-header-byline">VISIT US AT JCK BOOTH 15065</h2>
                <img className="cash" src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/landingPages/stack-of-cash-savings-when-you-sign-up-to-be-a-lashbrook-retailer-jeweler.png" />
            </div>
        </Row>

        <Row>
            <Container>
                <div className="testi-container">
                    <div className="testi-row">
                        <div className="testi-image" >
                            <img src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/landingPages/Shes-putting-a-ring-on-his-finger.png" />
                            <p className="labels">TESTIMONIALS</p>
                        </div>
                        <div className="testi-image" >
                            <img src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/landingPages/cobalt-chrome-with-black-diamond-mens-wedding-ring.png" />
                            <p className="labels">EDGE DATA</p>
                        </div>
                        <div className="testi-image" >
                            <img src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/landingPages/Lashbrook_Designs_Mens_Wedding_Ring_Jewelry_Display_55.png" />
                            <p className="labels">NEW SETS &amp; STARTER KITS</p>
                        </div>
                        <div className="testi-image" >
                            <img src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/landingPages/Lashbrook-damascus-and-gold-mens-wedding-band.png" />
                            <p className="labels">DEALS</p>
                        </div>
                    </div>
                </div>
            </Container>
        </Row >

        <Row>
            <Container className="container-wide">
                <Col xs={12} className="text-center mt-5 d-flex align-items-center flex-column dark-bg">
                    <h1 className="font-syncopate heading-font">TESTIMONIALS</h1>
                    <p className="font-universe byline">Don't just hear it from us, read about all the positive experiences others have had with Lashrook.</p>
                    <p className="font-universe byline">We know you won't be disappointed to work with us.</p>
                    <div className="white-block">
                        <h1 className="font-syncopate giant-font">"</h1>
                        <p className="font-universe byline higher">My staff loves selling Lashbrook wedding bands! Each staff member has an iPad and sits with the client to create a unique band for each client. During the ring building process, the interaction with the future groom is more fulfilling than during a traditional band sale. This leads to repeat customers and eliminates all buyer’s remorse. Plus, the margin is great!</p>
                        <p className="font-syncopate heading-font">CHRIS SARICH< br />NOAH GABRIEL &amp; CO. JEWELERS</p>
                    </div>
                </Col>
            </Container>
        </Row>


        <Row>
            <Container className="container-wide">
                <Col xs={12} className="text-center mt-5 d-flex align-items-center flex-column">
                    <h1 className="font-syncopate heading-font">THE EDGE DATA</h1>
                    <p className="font-universe byline">We believe in numbers and data. Read how with Lashbrook you can increase your profits.</p>
                    <div className="data-container">
                        <div className="data-row">
                            <div className="data-image" >
                                <img src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/landingPages/average-gross-profit-per-sale.png" />
                            </div>
                            <div className="data-image" >
                                <img src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/landingPages/average-mens-band-sales.png" />
                            </div>
                            <div className="data-image" >
                                <img src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/landingPages/profits-in-1000-stock.png" />
                            </div>
                        </div>
                    </div>
                </Col>
            </Container>
        </Row >

        <Row>
            <Container className="container-wide">
                <Col className="text-center d-flex align-items-center flex-column dark-bg">
                    <h1 className="font-syncopate heading-font">NEW SETS</h1>
                    <p className="font-universe byline">Get familiar with all our kit sets. Contact us for any questions.</p>

                    <div className="coll-container">
                        <div className="half-image-text">
                            <img width="400px" src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/landingPages/Lashbrook-display-mens-wedding-rings-77.png" /></div>
                        <div className="half-image-text">
                            <h1 className="font-syncopate set-font">STARTER KIT</h1>
                            <p className="font-universe set-p">
                                <ul>
                                    <li>Over 150 rings: Best Sellers, New Styles, and a variety of combinations</li>
                                    <li>Includes My Style selling system (not photographed)</li>
                                    <li>Includes iPad (not photographed)</li>
                                    <li>Sizing Set (not photographed)</li>
                                    <li>Cerakote Swatch Set (41 colors - not photographed)</li>
                                </ul></p></div>
                    </div>
                </Col>
                <Col className="text-center d-flex align-items-center flex-column dark-bg">
                    <div className="coll-container">
                        <div className="half-image-text">
                            <img width="400px" src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/landingPages/Lashbrook-displays-86.png" /></div>
                        <div className="half-image-text">
                            <h1 className="font-syncopate set-font">PREMIUM KIT</h1>
                            <p className="font-universe set-p">
                                <ul>
                                    <li>Over 25+ rings</li>
                                    <li>Includes Hardwood, Meteorite, Carbon Fiber, Shell, and Mosaic</li>
                                    <li>Great addition any other kits or current stock</li>
                                </ul></p></div>
                    </div>
                </Col>
                <Col className="text-center d-flex align-items-center flex-column dark-bg">
                    <div className="coll-container">
                        <div className="half-image-text">
                            <img width="400px" src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/landingPages/Lashbrook-designs-mens-rings-display-96.png" />
                        </div>
                        <div className="half-image-text">
                            <h1 className="font-syncopate set-font">KEY ACCOUNT KIT</h1>
                            <p className="font-universe set-p">
                                <ul>
                                    <li>Over 130+ rings</li>
                                    <li>Includes our most popular classic styles in 6 different metals</li>
                                    <li>Includes Tantalum</li>
                                    <li>Includes new patterns and diamond arrangements</li>
                                    <li>Cerakote Swatch Set (41 colors - not photographed)</li>
                                    <li>Includes updated My Style selling system (not photographed)</li>
                                    <li>Designed to expand breadth of styles</li>
                                </ul></p>
                        </div>
                    </div>
                </Col>
                <Col className="text-center d-flex align-items-center flex-column dark-bg">
                    <div className="coll-container">
                        <div className="half-image-text">
                            <img width="400px" src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/landingPages/Lashbrook-designs-mens-rings-display-111.png" />
                            {/* <div className="quarters">
                                <img src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/landingPages/Lashbrook-display-mens-wedding-rings-77.png" />
                            </div>
                            <div className="quarters">
                                <img src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/landingPages/Lashbrook-display-mens-wedding-rings-77.png" />
                            </div>
                            <div className="quarters">
                                <img src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/landingPages/Lashbrook-display-mens-wedding-rings-77.png" />
                            </div>
                            <div className="quarters">
                                <img src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/landingPages/Lashbrook-display-mens-wedding-rings-77.png" />
                            </div> */}
                            </div>
                        <div className="half-image-text">
                            <h1 className="font-syncopate set-font">EXPANSION KIT</h1>
                            <p className="font-universe set-p">
                                <ul>
                                    <li>Over 60+ rings</li>
                                    <li>Includes collection of precious and diamond samples</li>
                                    <li>Created for a store that already carries Lashbrook rings</li>
                                </ul>
                            </p>
                        </div>
                    </div>
                </Col>

            </Container>
        </Row>

        <Row>
            <Container className="container-wide">
                <Col className="text-center mt-5 d-flex align-items-center flex-column">
                    <h1 className="font-syncopate heading-font">DEALS</h1>
                    <p className="font-universe byline">Let us work with you and your needs. Contact us to learn more about our special JCK 2022 deals.</p>

                    <img width="500px" src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/landingPages/Lashbrook-mens-wedding-rings-brings-all-thing-to-your-door.png" />
                    <p className="font-universe byline">For more information, contact Lashbrook:</p>
                    <p className="font-universe byline"><a className="gray-links" href="tel:8882527388">(888)252-7388</a><br />131 East 13065 South<br />Draper, UT 84020</p>
                    <p className="font-universe byline"><a className="gray-links" href="mailto:info@lashbrookdesigns.com">info@lashbrookdesigns.com</a>
                    </p>
                </Col>
                <Col className="text-center d-flex align-items-center flex-column dkr-bground">
                    <h1 className="font-syncopate heading-font-lt">HOW TO CUSTOMIZE</h1>
                    <p className="font-universe byline-white">There are many options for customiizing your ring. Add an<br />
                    engraving, inlay, or sleeve to make your ring, truly yours.</p>
                    <button className="build-own">

                        <Link
                            to={config.buildYourOwnProductUrl}
                            className="build-own"
                        >
                            {` Build Your Own `}
                        </Link>

                    </button>
                </Col>
                <Col className="text-center d-flex align-items-center flex-column gr-bkground">
                    <img src="https://wpassets.lashbrookdesigns.com/wp-content/uploads/2016/03/Meteorite-Banner.png" />
                </Col>
            </Container>
        </Row>
    </Container >
);

export default JckLanding;
