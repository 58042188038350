/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import { IOption, ITab } from '../types/customizer';
import { updateGemstonesParam, updateGemstoneType, updateGrooveGroupParam, updateGroovesParam,
  updateGrooveStyleParam, updateMachinePatternParam, updateRingFinishParam } from './helper';

interface ISelectedInfo {
  url: string;
  text: string;
  engravingText?:string;
  selectedOption?: IOption;
};

const hideSwatches: Array<string> = ['category', 'type']

const optionToBeDisplayed: any = {
  'shape': ['Profile', 'Width', 'Shape'],
  'finish': ['First Finish', 'Second Finish'],
  'outsideFeature_1': ['Material', 'Pattern', 'Color', 'Inlay', 'Signet Engraving'],
  'outsideFeature_2': ['Material', 'Pattern', 'Color'],
  'engraving': ['Text', 'Pattern'],
  'arrangement': ['Stone Quantity'], // 'Carat Weight', 'Color Arrangement', 'Color', 'Color 2', 'Color 3' All is added. So no need to mention here
}

export const getSelectedInfo: any = (page: ITab) => {
  if(!page) return;

  let info = {
    url: '',
    text: ''
  };

  if(page.items?.length) {
    const eventKey = page.eventKey;
    const showList: Array<string> = optionToBeDisplayed[eventKey];

    page.items.reduce((acc: ISelectedInfo, item: ITab) => {
      if(!showList || showList.includes(item.title as string)) {
        const text = item.content?.selectedOption.short_name || item.content?.selectedOption.name || '';
        const swatch_url = item.content?.selectedOption.swatch_url || '';

        acc.url = acc.url || swatch_url;
        acc.text = acc.text ? `${acc.text} - ${text}` : `${text}`; // If already url there then no need to use updated one.
        acc.selectedOption = acc.selectedOption || item.content?.selectedOption; // If already text is there append to previous one(Eg, shape, profile)
        if(item.content?.engravingText) {
          acc.engravingText = item.content.engravingText;
        }
        if(item.content?.selectedOption.is_reverse_selected) {
          acc.text = 'Rev ' + acc.text;
        }
      }
      return acc;
    }, info);
  } else {
    info.url = hideSwatches.includes(page.eventKey) ? '' : page.content?.selectedOption?.swatch_url ||  '';
    info.text = page.content?.selectedOption?.name || '';
  }

  return info;
};

export const getSelectedOption = (categoryArray: any[]) => {
  if (categoryArray && categoryArray.length > 0) {
    for (let index = 0; index < categoryArray.length; index++) {
      if (categoryArray[index].type === 'option' && categoryArray[index].selected) {
        return categoryArray[index];
      } else if (categoryArray[index].type === 'category') {
        const selectedOption: any = getSelectedOption(categoryArray[index].items);
        if (selectedOption) {
          return selectedOption;
        }
      }
    }
  }
};

export const getFilterMetaData = (product: any, filter: string) => product?.filter_descriptions?.[filter];

export const getWarning = (product:any, filter: any) => {
  if (product.changes && product.changes.length > 0) {
    const warning = product.changes.find((change: any) => change.option_id === filter);
    if (warning) {
      return `${warning.old_value} is not available in this combination.`;
    }
  }
  return null;
};

export const FONT_MAP: any = {
  Arial: 'arial',
  'Lucida Calligraphy': 'lucida-calligraphy',
  'Lucia BT': 'lucia-bt tw-text-2xl tw-leading-5',
  'French Script': 'french-script tw-text-2xl tw-leading-5',
  'Vladimir Script': 'vladimir-script tw-text-2xl tw-leading-5',
  'Times New Roman': 'times-new-roman',
  'Tempus Sans': 'tempus-sans',
  'Old London': 'old-london tw-text-lg tw-leading-5',
  'Kells SD': 'kells-sd',
  '': '',
};

export const FONT_SIZE_MAP: any = {
  Arial: 'text-lg',
  'Lucida Calligraphy': 'tw-text-lg',
  'Lucia BT': 'tw-text-2xl tw-tracking-widest',
  'French Script': 'tw-text-2xl',
  'Times New Roman': 'tw-text-lg',
  'Vladimir Script': 'tw-text-2xl tw-tracking-widest',
  'Tempus Sans': 'tw-text-lg',
  'Old London': 'tw-text-2xl',
  'Kells SD': 'tw-text-2xl',
  '': '',
};

export const getFontOptions = (options: IOption[]) => {
  const converted = options.map(option => {
    const className = FONT_MAP[option.name];
    return {
      ...option,
      additionalClassName: className,
    };
  });
  return converted;
};


export const getUpdatedParams = (toBeUpdated: { [key: string]: any }, params: { pathParams: any, queryParams: any }) => {
  const updateKeys = Object.keys(toBeUpdated);

  if (['profile', 'ring_materials', 'width', 'styles', 'color_arrangements', 'variants',
    'stone_quantities'].includes(updateKeys[0])) {
    params.pathParams = { ...params.pathParams, ...toBeUpdated };
  } else if (['ring_0', 'ring_1', 'ring_2'].includes(updateKeys[0])) {
    const newRingFinishParam = updateRingFinishParam(
      params.queryParams.ring_finish,
      { attribute: updateKeys[0] },
      toBeUpdated[updateKeys[0]]
    );
    params.queryParams = { ...params.queryParams, ring_finish: newRingFinishParam };
  } else if(updateKeys[0] === 'signet_inlay') {
    const data = params.queryParams.inlays.split('_');
    data[0] = toBeUpdated.signet_inlay;
    params.queryParams = { ...params.queryParams, inlays: data.join('_') };
  } else if(updateKeys[0] === 'signet_inlayfinish') {
    const data = params.queryParams.inlays.split('_');
    data[1] = toBeUpdated.signet_inlayfinish;
    params.queryParams = { ...params.queryParams, inlays: data.join('_') };
  } else if (updateKeys[0].startsWith('inlay')) {
    const keyParts = updateKeys[0].split(':');
    const newGroovesParam = updateGroovesParam(
      params.queryParams.grooves,
      {
        attribute: keyParts[0],
        grooveIndex: keyParts[0] === 'inlay_2' || keyParts[0] === 'inlay_finish_2' ? 1 : 0,
        grooveGroup: keyParts[2],
      },
      toBeUpdated[updateKeys[0]]
    );
    params.queryParams = { ...params.queryParams, grooves: newGroovesParam };
  } else if (updateKeys[0].startsWith('machine_pattern')) {
    const newMachinePatternParam = updateMachinePatternParam(
      params.queryParams.machine_pattern,
      { attribute: updateKeys[0] },
      toBeUpdated[updateKeys[0]]
    );
    params.queryParams = { ...params.queryParams, machine_pattern: newMachinePatternParam };
  } else if (updateKeys[0].startsWith('gemstone_details')) {
    const attribute = updateKeys[0];
    const value = toBeUpdated[attribute];
    const typeParams = params.queryParams.dia;
    const updatedTypes = updateGemstoneType(typeParams, attribute, value);
    params.queryParams = { ...params.queryParams, dia: updatedTypes };
  } else if (updateKeys[0].startsWith('gemstone')) {
    const newGemstonesParam = updateGemstonesParam(
      params.queryParams.gemstones,
      { attribute: updateKeys[0] },
      toBeUpdated[updateKeys[0]]
    );
    params.queryParams = { ...params.queryParams, gemstones: newGemstonesParam };
  } else if (['groove_group', 'mga', 'groove_group_2', 'mga_2'].includes(updateKeys[0])) {
    const isSecondary = ['groove_group_2', 'mga_2'].includes(updateKeys[0]);
    const newGrooveGroupParam = updateGrooveGroupParam(
      params.queryParams.groove_group,
      { attribute: updateKeys[0] },
      toBeUpdated[updateKeys[0]],
      isSecondary
    );
    params.queryParams = { ...params.queryParams, groove_group: newGrooveGroupParam };
  } else if (['groove_style', 'groove_style_2'].includes(updateKeys[0])) {
    const newGrooveStyleParam = updateGrooveStyleParam(
      params.queryParams.groove_style,
      { attribute: updateKeys[0] },
      toBeUpdated[updateKeys[0]]
    );
    params.queryParams = { ...params.queryParams, groove_style: newGrooveStyleParam };
  } else if (updateKeys[0].startsWith('all_cerakote')) {
    const newCerakoteParam = toBeUpdated[updateKeys[0]];
    params.queryParams = { ...params.queryParams, all_cerakote: newCerakoteParam };
  } else if (updateKeys[0].startsWith('feature_group')) {
    const featureGroups = params.queryParams.feature_group.split(',');
    const primary = featureGroups[0];
    const secondary = featureGroups.length > 0 && featureGroups[1];
    let updated;

    // clean up query params for primary features
    if (updateKeys[0] === 'feature_group' && primary === 'G') {
      const { grooves, groove_group, groove_style, ...clearGrooves } = params.queryParams;
      params.queryParams = { ...clearGrooves };
    } else if (updateKeys[0] === 'feature_group' && primary === 'D') {
      const { gemstones, ...clearGemstone } = params.queryParams;
      params.queryParams = { ...clearGemstone };
    } else if (updateKeys[0] === 'feature_group' && primary === 'M') {
      const { machine_pattern, ...clearPattern } = params.queryParams;
      params.queryParams = { ...clearPattern };
    } else if (updateKeys[0] === 'feature_group' && primary === 'A') {
      const { all_cerakote, ...clearPattern } = params.queryParams;
      params.queryParams = { ...clearPattern };
    }

    // clean up query params for secondary features
    if ((updateKeys[0] === 'feature_group_2' || secondary) && secondary === 'M') {
      const { machine_pattern, ...clearPattern } = params.queryParams;
      params.queryParams = { ...clearPattern };
    } else if ((updateKeys[0] === 'feature_group_2' || secondary) && secondary === 'G') {
      const { grooves, groove_group, groove_style, ...clearGrooves } = params.queryParams;
      if (primary === 'G') {
        const primaryGrooves =
          (params.queryParams.grooves && params.queryParams.grooves.split(',')[0]) || [];
        const primaryGrooveGroup =
          (params.queryParams.groove_group && params.queryParams.groove_group.split(',')[0]) || [];
        params.queryParams = { ...clearGrooves, grooves: primaryGrooves, groove_group: primaryGrooveGroup };
      } else {
        params.queryParams = { ...clearGrooves };
      }
    } else if (updateKeys[0] === 'feature_group_2' && secondary === 'D') {
      const { gemstones, ...clearGemstones } = params.queryParams;
      if (primary === 'D') {
        const primaryGemstones =
          (params.queryParams.gemstones && params.queryParams.gemstones.split(',')[0]) || [];
        params.queryParams = { ...clearGemstones, gemstones: primaryGemstones };
      } else {
        params.queryParams = { ...clearGemstones };
      }
    } else if ((updateKeys[0] === 'feature_group_2' || secondary) && secondary === 'D') {
      const { gemstones, ...clearGemstone } = params.queryParams;
      params.queryParams = { ...clearGemstone };
    }

    // update feature group when secondary feature updated
    if (updateKeys[0] === 'feature_group_2') {
      const oldSecondary = featureGroups.pop();
      featureGroups.push(toBeUpdated.feature_group_2);
      updated = featureGroups.join();
    }

    // update feature group when primary feature updated
    if (updateKeys[0] === 'feature_group') {
      updated = toBeUpdated.feature_group;
    }

    params.queryParams = { ...params.queryParams, feature_group: updated };
  } else if (updateKeys[0] === 'engraving_none') {
    delete params.queryParams.engraving_new;
  } else if (updateKeys[0] === 'engraving_pattern') { // If pattern is selected, construct engraving pattern value
    const material = params.queryParams.sleeve === 'NONE' ? params.pathParams.ring_materials : params.queryParams.sleeve;
    const value = `D_${toBeUpdated[updateKeys[0]]}_${material}`;
    params.queryParams = { ...params.queryParams, engraving_new: value };
  } else {
    params.queryParams = { ...params.queryParams, ...toBeUpdated };
  }
  return params;
};
