/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef } from 'react';
import './Accordion.css';

function Accordion(props) {
    const [setActive, setActiveState] = useState('');
    const [setHeight, setHeightState] = useState('0px');
    const [setRotate, setRotateState] = useState('accordion-icon');
    const {
        containerClass = '',
        dropdownIcon = false,
        dropDownOpen = '-',
        iconPosition = 'left',
        addBefore,
        addAfter,
        IsFontInherit,
        contentClass,
        isJXSContent,
    } = props;

    const content = useRef(null);

    function toggleAccordion() {
        setActiveState(setActive === '' ? 'active' : '');
        setHeightState(setActive === 'active' ? '0px' : `${content.current.scrollHeight}px`);
        setRotateState(setActive === 'active' ? 'accordion-icon' : 'accordion-icon rotate');
    }

    return (
        <div className={`accordion-section ${containerClass}`}>
            {iconPosition === 'left' && (
                <div className={`accordion ${setActive}`} onClick={toggleAccordion}>
                    {!dropdownIcon ? (
                        <i
                            className={`fa fa-chevron-right va-sub title-text-color arrow-golden ${setRotate}`}
                            width={10}
                            fill="#777"
                            aria-hidden="true"
                        />
                    ) : (
                        <div>{setActive === 'active' ? dropDownOpen : dropdownIcon}</div>
                    )}
                    <p className={`accordion-title ${contentClass} text-capitalize mb-0 ${!IsFontInherit ? 'font-universe' : ''}`}>{props.title}</p>
                </div>
            )}
            {iconPosition === 'right' && (
                <div className={`accordion ${setActive} icon-right`} onClick={toggleAccordion}>
                    <p className={`accordion-title ${contentClass} text-capitalize mb-0 ${!IsFontInherit ? 'font-universe' : ''}`}>{props.title}</p>
                    {!dropdownIcon ? (
                        <i
                            className={`fa fa-chevron-right va-sub title-text-color arrow-golden ${setRotate}`}
                            width={10}
                            fill="#777"
                            aria-hidden="true"
                        />
                    ) : (
                        <div>{setActive === 'active' ? dropDownOpen : dropdownIcon}</div>
                    )}
                </div>
            )}
            <div
                ref={content}
                style={{ maxHeight: `${setHeight}` }}
                className={`accordion-content mb-0 d-lg-flex ${!IsFontInherit ? 'font-universe' : ''}`}
            >
                {!isJXSContent ? (
                    <div>
                        {addBefore}
                        <div className="accordion-text" dangerouslySetInnerHTML={{ __html: props.content }} />
                        <p className="contact color-grey">
                            <a href={props.href} target="_blank">
                                {props.contact}
                            </a>
                            {addAfter}
                        </p>
                    </div>
                ) : <>
                    {props.content}
                </>}
                {props.asset && <div>{props.asset}</div>}
            </div>
        </div>
    );
}

export default Accordion;
