import get from 'lodash/get';
import { createSelector } from 'reselect';

export const selectApp = state => get(state, 'global');

export const makeSelectIsFetching = () =>
    createSelector([selectApp], app => get(app, 'isFetching', {}));

export const makeSelectCurrentCurrency = () =>
    createSelector([selectApp], app => get(app, 'currentCurrencyCode', {}));

