import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import Loader from '../Shared/Loader';

import './styles.css';

const OrderStatus = ({ isFetching, order, getAccountInfo, accountInfo, getOrder, orderData }) => {
    const { orderId } = useParams();

    useEffect(() => {
        getAccountInfo();
    }, []);

    useEffect(() => {
        if (typeof order.order_id === 'undefined') {
            getOrder(orderId);
        }
    }, []);

    if (typeof accountInfo.requires_parent_approval === 'undefined') return <Loader timedOut={isFetching} />;

    return (
        <section className="centerAlign font-universe margin-top-half">
            <i className="fa fa-check-circle-o fa-3x" aria-hidden="true" />
            <h2 className="mb-0">Thank you for placing your order with Lashbrook Designs</h2>
            {accountInfo.requires_parent_approval && (
                <p className="fs-14rem">A message has been sent for approval of this order.</p>
            )}
            <p className="font-universe font-weight-bolder mt-2 mb-0">
                Your order number is:{' '}
                {accountInfo.is_parent_account ? (
                    <span>{order.order_id || orderData.order_id || orderId}</span>
                ) : (
                    <Link to={`/shop/customer/account/me/order/view/${order.id || orderId}`}>
                        {order.order_id || orderData.order_id || orderId}
                    </Link>
                )}
            </p>
            <p className="font-weight-bolder mb-2">
                We'll email you an order confirmation with details and tracking info.
            </p>
            <Button>
                <Link to="/shop/categories">Continue Shopping</Link>
            </Button>
            <Loader timedOut={isFetching} />
        </section>
    );
};

OrderStatus.propTypes = {
    isFetching: PropTypes.bool,
    order: PropTypes.object,
};
export default OrderStatus;
