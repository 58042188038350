import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';

const TermsConditions = () => (
    <Container>
        <Helmet>
            <link rel="canonical" href="https://www.lashbrookdesigns.com/spiff-terms" />
            <title>Lashbrook Sales Partner Promotion Terms and Conditions: Spiff Incentive Program</title>
            <meta name="description" content="Lashbrook Designs terms and conditions for promotional partners." />
            <meta name="keywords" content="terms of service, spiff program, lashbrook, lashbrook designs, wedding rings, custom rings" />
        </Helmet>
        <Row>
            <Col xs={12} className="text-center mt-5 d-flex align-items-center flex-column">
                <img
                    src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/wp-content/themes/rinzler/images/icon-awards.png"
                    alt="size-icon"
                />
                <p className="mb-4">LASHBROOK SALES PARTNER PROMOTION</p>
                <p className="mb-4">TERMS &amp; CONDITIONS</p>
                <hr className="content-hr" color="#dcc67d" />
            </Col>
            <Col xs={12} className="font-universe fs-24 color-grey">
                <p className="smallFont">This Lashbrook Participant Promotion (the <strong>“Promotion”</strong>) is run by Lashbrook. The Promotion is effective from December 31, 2022 until terminated Lashbrook (the <strong>“Promotion Term”</strong>), and is open to any salesperson (a <strong>“Participant”</strong>) who is an employee of an authorized retailer of Lashbrook products (a <strong>“Retailer”</strong>). NOTE LATER THAT LASHBROOK CAN TERMINATE AT ANY TIME</p>
                <h4 className="smallFont">The Promotion is subject to the following terms and conditions:</h4>
                <p className="smallFont">By using this website, you warrant on behalf of yourself, your users, and other parties you represent that you will not:</p>
                <p className="smallFont text-underline">1. Definitions.</p>
                    <ol className="lists smallFont">
                        <p>1.1. <strong>“Customer”</strong> means any individual who purchases Qualifying Products.</p>
                        <p>1.2. <strong>“Qualifying Product”</strong> means any Lashbrook product sold by the Retailer of which Participant is an employee; provided, however, that stock Lashbrook pieces sold from the case are not Qualifying Products.</p>
                        <p>1.3. <strong>“Qualifying Sale”</strong> means a sale of Qualifying Products at a Retailer’s brick-and-mortar retail location to a Customer that is facilitated by the Participant. For avoidance of doubt, online sales are not Qualifying Sales.</p>
                        <p>1.4. <strong>“Retail Price”</strong> means the suggested retail price established by Lashbrook for each Qualifying Product.</p>
                        <p>1.5. <strong>“SPIFF”</strong> shall mean a one-time payment by Lashbrook to Participant, which will be earned upon completion of a Qualifying Sale pursuant to these Terms and Conditions.</p>
                    </ol>
                <p className="smallFont text-underline">2. Responsibilities.</p>
                    <ol className="lists smallFont">
                        <p>2.1.	Participant shall deliver a completed IRS Form W-9 to Lashbrook and/or any affiliate or other third party designated by Lashbrook.</p>
                        <p>2.2.	Participant shall promote and sell Qualifying Products to Customers.</p>
                        <p>2.3.	Participant shall complete such monthly tasks as reasonably directed by Lashbrook, including but not limited to participating in Lashbrook University or other training programs, and posting on or interacting with social media.</p>
                    </ol>
                <p className="smallFont text-underline">3. Incentive Payments.</p>
                    <ol className="lists smallFont">
                        <p>3.1. The Promotion applies only to Qualifying Sales during the Promotion Term. For all such Qualifying Sales, Lashbrook will pay Participant a cash payment (the <strong>“Incentive”</strong>) equal to a percentage (the <strong>“Incentive Rate”</strong> of the Retail Price of the applicable Qualifying Product(s). The current Incentive Rate, which may be amended by Lashbrook at any time in its sole discretion, will be available within the Lashbrook University Spiff Incentive Orientation or ask your sales representative for rates.</p>
                        <p>3.2. To receive an Incentive for a Qualifying Sale, Participant must identify himself or herself as having facilitated the Qualifying Sale at the time each applicable Qualifying Product is ordered from Lashbrook, and providing any other information Lashbrook may require. Lashbrook will not pay Incentives for any Qualifying Sales for which Participant does not comply with the requirements of this <u>Section 3.2.</u></p>
                        <p>3.3. Lashbrook will pay all Incentives in U.S. Dollars no later than the end of the month following the month in which the applicable Qualifying Sale occurred.</p>
                        <p>3.4.	INCENTIVES ARE AVAILABLE SOLELY TO PARTICIPANTS ACTING IN THEIR CAPACITY AS SALES REPRESENTATIVES. For avoidance of doubt, any sale of Qualifying Products facilitated by a party other than Participant is not a Qualifying Sale, and Participant shall not be entitled to receive an Incentive with respect to the same. Furthermore, Participant may not collect an Incentive for any sale of Qualifying Products in which Participant’s involvement was other than that of a sales representative (e.g., as a sales manager or owner of a Retailer).</p>
                        <p>3.5.	If Lashbrook determines, in its Sole Discretion, that Participant claimed and received an Incentive in violation of these terms and condition, Participant shall repay such Incentive to Lashbrook. Without prejudice to any other right or remedy it has or may have, Lashbrook may, without notice to Participant, set off or recoup any future Incentives due to Participant against any Incentives for which Lashbrook determines Participant is required to repay pursuant to this <u>Section 3.5.</u></p>
                    </ol>
                <p className="smallFont text-underline">4. Representations of Participant.</p>
                <p className="smallFont">Participant represents and warrants the following to Lashbrook:</p>
                    <ol className="lists smallFont">
                        <p>4.1.	Participant is not a party to any contract or other agreement with a third party that would interfere with or hinder Participant’s ability to fulfill Participant’s obligations under these Terms and Conditions.</p>
                        <p>4.2.	Participant will not share SPIFFs received through the Promotion with any third party, or accept SPIFFs received through the Promotion from any third party.</p>
                    </ol>
                <p className="smallFont text-underline">5. Relationship of the Parties.</p>
                    <ol className="lists smallFont">
                        <p>The relationship between Participant and Lashbrook is that of independent contractors, and this Agreement shall not be construed to create any association, partnership, joint venture, employee, or agency relationship between Participant and Lashbrook for any purpose. Participant has no authority (and shall not hold itself out as having authority) to bind Lashbrook and Participant shall not make any agreements or representations on Lashbrook’s behalf without Lashbrook’s prior written consent.</p>
                    </ol>
                <p className="smallFont text-underline">6.	Modification; Termination.</p>
                <p className="smallFont">Lashbrook reserves the right to, at any time in its sole discretion:</p>
                    <ol className="lists smallFont">
                        <p>(a)	Expand or restrict the scope of Lashbrook products that are Qualifying Products eligible for SPIFFs under the Promotion;</p>
                        <p>(b)	Change or modify these Terms and Conditions at any time; and/or </p>
                        <p>(c)	Cancel the Promotion entirely or with respect to certain Retailers or Participants.</p>
                    </ol>
                <p className="smallFont">Without limiting the foregoing, Lashbrook may exclude Participant from the Promotion if Lashbrook discovers or believes, in its sole discretion, that Participant has violated these Terms and Conditions.</p>
                <p className="smallFont text-underline">7. Miscellaneous. </p>
                    <ol className="lists smallFont">
                        <p>All decisions and determinations made by Lashbrook with respect to the Promotion are final. The Promotion and all matters arising out of or relating to the Promotion are governed by, and construed in accordance with, the laws of the State of Utah. Any legal suit, action, or proceeding arising out of or relating to this Agreement must be instituted in the federal courts of the United States of America or the courts of the State of Utah, and Participant irrevocably submits to the exclusive jurisdiction of such courts in any such suit, action, or proceeding. Participant shall not assign, transfer, delegate, or subcontract any of its rights or obligations under these Terms and Conditions, and any purported assignment or delegation shall be null and void. If any term or provision of these Terms and Conditions is invalid, illegal, or unenforceable in any jurisdiction, such invalidity, illegality, or unenforceability shall not affect any other term or provision of these Terms and Conditions or invalidate or render unenforceable such term or provision in any other jurisdiction.</p>
                        <p>By accepting the terms and conditions you are agreeing to allow us to text you updates and information. To opt out, Reply STOP to any message you receive to stop receiving text messages.</p>
                    </ol>
                    {/* <p><a href="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/2022/spiff/Promotion-terms-and-conditions-2022-12-28.pdf" target="_blank">Download Here</a></p> */}
           </Col>
            <Col className="text-center mt-5 d-flex align-items-center flex-column font-universe fs-24 color-grey">
                <span className="divider" />
                <p className="hr-svg">
                    <svg xmlns="http://www.w3.org/2000/svg" width="1091.5" height="8" viewBox="0 0 1091.5 8">
                        <rect
                            id="Rectangle_871"
                            data-name="Rectangle 871"
                            width="8"
                            height="1091.5"
                            rx="3"
                            transform="translate(1091.5) rotate(90)"
                            fill="#d8c37c"
                        />
                    </svg>
                </p>
                <p className="">For more information, contact Lashbrook:</p>
                <p className="">
                    131 East 13065 South<br />Draper, UT 84020<br />
                    <a className="gold-links" href="tel:8882527388">
                        (888)252-7388
                        </a>
                </p>
                <p className="">
                    <a className="gold-links" href="mailto:info@lashbrookdesigns.com">
                        info@lashbrookdesigns.com
                        </a>
                </p>
            </Col>
        </Row>
    </Container>
);

export default TermsConditions;