import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';

const TermsConditions = () => (
    <Container>
    <Helmet>
        <link rel="canonical" href="https://www.lashbrookdesigns.com/lashbrook-terms-conditions" />
        <title>Lashbrook Designs Terms and Conditions, including Cookie and Privacy Policy</title>
        <meta name="description" content="Lashbrook Designs terms and conditions for site usage, media usage, how we treat privacy, and what cookies we use." />
        <meta name="keywords" content="cookie policy, terms of service, privacy policy, lashbrook, lashbrook designs, wedding rings, custom rings" />
    </Helmet>
        <Row>
            <Col xs={12} className="text-center mt-5 d-flex align-items-center flex-column">
                <img
                    src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/wp-content/themes/rinzler/images/icon-awards.png"
                    alt="size-icon"
                />
                <h1 className="font-syncopate text-uppercase mt-3 mb-3">Terms &amp; Conditions</h1>
                <hr className="content-hr" color="#dcc67d" />
            </Col>
            <Col xs={12} className="font-universe fs-24 color-grey">
                <h2 className=" text-underline mb-4">Terms of Service</h2>
                <p className="smallFont">These Terms of Service govern your use of the website located at 
                <Link to="/" className="gold-links">
                        {' '}
                        https://www.lashbrookdesigns.com 
                    </Link> and any related services provided by Lashbrook.</p>
                <p className="smallFont">By accessing <Link to="/" className="gold-links">{' '}https://www.lashbrookdesigns.com</Link>, you agree to abide by these Terms of Service and to comply with all applicable laws and regulations. If you do not agree with these Terms of Service, you are prohibited from using or accessing this website or using any other services provided by Lashbrook.</p>
                <p className="smallFont">We, Lashbrook, reserve the right to review and amend any of these Terms of Service at our sole discretion. Upon doing so, we will update this page. Any changes to these Terms of Service will take effect immediately from the date of publication.</p>
                <h4 className="mb-4">Limitations of Use</h4>
                <p className="smallFont">By using this website, you warrant on behalf of yourself, your users, and other parties you represent that you will not:
                    <ol className="lists">
                        <li>modify, copy, prepare derivative works of, decompile, or reverse engineer any materials and software contained on this website;</li>
                        <li>remove any copyright, watermark, or other proprietary notations from any materials and software on this website;</li>
                        <li>transfer the materials to another person;</li>
                        <li>mirror the materials on any other server;</li>
                        <li>hotlink any materials on any other website or digital production without express written permission;</li>
                        <li>knowingly or negligently use this website or any of its associated services in a way that abuses or disrupts our networks or any other service Lashbrook provides;</li>
                        <li>use this website or its associated services to transmit or publish any harassing, indecent, obscene, fraudulent, or unlawful material;</li>
                        <li>use this website or its associated services in violation of any applicable laws or regulations;</li>
                        <li>use this website in conjunction with sending unauthorized advertising or spam;</li>
                        <li>harvest, collect, or gather user data without the user’s consent; or</li>
                        <li>use this website or its associated services in such a way that may infringe the privacy, intellectual property rights, or other rights of third parties.</li>
                    </ol>
                    </p>
            </Col>
            <Col xs={12} className="font-universe fs-24 color-grey">
            <span className="divider" />
                    <p className="hr-svg">
                        <svg xmlns="http://www.w3.org/2000/svg" width="1091.5" height="8" viewBox="0 0 1091.5 8">
                            <rect
                                id="Rectangle_871"
                                data-name="Rectangle 871"
                                width="8"
                                height="1091.5"
                                rx="3"
                                transform="translate(1091.5) rotate(90)"
                                fill="#d8c37c"
                            />
                        </svg>
                    </p>
                <h2 className=" text-underline mb-4">Intellectual Property Notices</h2>
                <h4 className="mb-4">Intellectual Property</h4>
                <p className="smallFont">The intellectual property in the materials contained in this website are owned by or licensed to Lashbrook and are protected by applicable copyright and trademark law. We grant our users permission to download one copy of the materials for personal, non-commercial transitory use.</p>
                <p className="smallFont">This constitutes the grant of a license, not a transfer of title. This license shall automatically terminate if you violate any of these restrictions or the Terms of Service, and may be terminated by Lashbrook at any time.</p>
                <h4 className="mb-4">Copyright</h4>
                <p className="smallFont">All content included on this site, such as jewelry designs, text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software, is the property of Diamonds Direct, L.C. DBA Lashbrook Jewelry Designs (LASHBROOK), unless otherwise noted and is protected by United States and international copyright laws. The compilation of all content on this site is the exclusive property of LASHBROOK and is protected by U.S. and international copyright laws. All software used on this site is the property of LASHBROOK and is protected by United States and international copyright laws. All jewelry designs shown on this site are protected by U.S. and international copyright laws.</p>
                <h4 className="mb-4">The following is a non-exhaustive list of LASHBROOK copyright registrations for select jewelry designs:</h4>
               <p className="smallFont">VA0002266462 (Black Zirconium and Meteorite – Z8D15)</p>
               <p className="smallFont">VA0002266479 (Cobalt Chrome, Cerakote and Hardwood – HWSLEEVECC7D)</p>
               <p className="smallFont">VA0002272306 (Cobalt Chrome, Meteorite and Cerakote – CC7.5F14)</p>
               <p className="smallFont">VA0002266471 (Damascus Steel and Cerakote – D8D)</p>
               <p className="smallFont">VA0002266476 (Damascus Steel and Silver D7D110C/SS)</p>
               <p className="smallFont">VA0002272305 (Titanium 10F)</p>
               <p className="smallFont">VA0001925985 (Fable Spring Catalog 2013)</p>
               <h4 className="mb-4">Patents</h4>
                <p className="smallFont">Various products shown and otherwise displayed on this website and the operation of portions of this website are covered by one or more U.S. or international patents or patents pending. The following lists certain product or technology-specific patents, as well as a general list of all patents owned or licensed(*) by LASHBROOK DESIGNS. Please note that many of LASHBROOK DESIGNS’s products may be covered by more than one patent, and thus in some cases by patents from multiple different sections listed below (e.g., a reconfigurable wall employing embedded monitors). (If you have any questions about whether a product or technology you are considering is covered by LASHBROOK Intellectual Property, we invite you to contact us directly, and we will coordinate with LASHBROOK counsel to provide you with a complimentary review of the product or technology in question.)</p>
                <p className="smallFont">The following products are covered by one or more patents or patent applications in the United States of America and elsewhere. This website is provided to satisfy the virtual marking provisions of various jurisdictions including Section 16 of the America Invents Act and Title 35, U.S. Code Section 287. Additional patents may be issued and additional patent applications may be pending in the U.S. and elsewhere.</p>
               <h4 className="mb-4">Ring Boxes and Ring Packaging</h4>
               <h4 className="text-italic mb-4">For ring boxes, and other ring packaging, the following patents (including any foreign counterparts) may apply:</h4>
                <p className="smallFont">D946,896</p>
               <h4 className="mb-4">Software</h4>
               <h4 className="text-italic mb-4">For LASHBROOK software, the following patents, or pending patent applications (including any foreign counterparts) may apply:</h4>
                <p className="smallFont">US 20210374820 (Jewelry Customization Interface and System)</p>
                <p className="smallFont">US 20220012078 (Jewelry Customization Interface and System)</p>
               <h4 className="mb-4">Trademarks</h4>
                <p className="smallFont">Any word, name, symbol, device, or combination thereof on this site that is used to identify and distinguish LASHBROOK goods or services constitutes a trademark or service mark (generally “trademark”), or trade dress of LASHBROOK. LASHBROOK’s trademarks and trade dress, whether used on this site or not, may not be used with any product or service that is not LASHBROOK’s without LASHBROOK’s express written permission. Under no circumstance may LASHBROOK’s trademarks, or trade dress be used in any manner that is likely to cause confusion among customers, or in any manner that disparages or discredits LASHBROOK. All other trademarks not owned by LASHBROOK that appear on this site are the property of their respective owners, who may or may not be affiliated with, connected to, or sponsored by LASHBROOK.</p>
            </Col>
            <Col className="text-center mt-5 d-flex align-items-center flex-column font-universe fs-24 color-grey">
                    <h4 className="">The following is a non-exhaustive list of<br /> LASHBROOK trademarks:</h4>
                    <p className="">Lashbrook<br />Lashbrook Designs</p>
                    <p className="gold-links">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="111.271"
                            height="19.421"
                            viewBox="0 0 111.271 19.421"
                        >
                            <g id="Group_3238" data-name="Group 3238" transform="translate(-24.575 -29.558)">
                                <g id="Group_3236" data-name="Group 3236" transform="translate(24.575 29.558)">
                                    <path
                                        id="Path_3068"
                                        data-name="Path 3068"
                                        d="M37.149,42l.695.153L36.435,46.9H24.575v-.484a1.632,1.632,0,0,0,1.458-.64,3.985,3.985,0,0,0,.434-2.15V33.083a3.237,3.237,0,0,0-.405-1.886,1.766,1.766,0,0,0-1.486-.534V30.18H30.32v.483a1.63,1.63,0,0,0-1.429.612,3.584,3.584,0,0,0-.434,2.019V44.606a1.635,1.635,0,0,0,.391,1.266,2.622,2.622,0,0,0,1.5.341,16.425,16.425,0,0,0,3.314-.245A3.479,3.479,0,0,0,35.8,44.937,9.1,9.1,0,0,0,37.149,42Z"
                                        transform="translate(-24.575 -30.058)"
                                        fill="#dbba66"
                                    />
                                    <path
                                        id="Path_3069"
                                        data-name="Path 3069"
                                        d="M82.62,56.769V56.36q1.213,0,1.213-.844a1.63,1.63,0,0,0-.108-.543l-1.31-3.916H77.887l-.962,2.832c-.241.715-3.012,2.879-3.012,2.879V56.36a2.149,2.149,0,0,0,1.215-.518,4.508,4.508,0,0,0,.879-1.7l3.9-11.634h1.574l4.1,12.152a3.112,3.112,0,0,0,.752,1.386,2.015,2.015,0,0,0,1.258.314v.408Zm-4.472-6.577h3.977l-2-5.976Z"
                                        transform="translate(-64.203 -39.96)"
                                        fill="#dbba66"
                                    />
                                    <path
                                        id="Path_3070"
                                        data-name="Path 3070"
                                        d="M206.791,54.758v-4.1h-6.581v4.1a3.138,3.138,0,0,0,.355,1.747,1.476,1.476,0,0,0,1.28.494v.408h-5.1V57a1.418,1.418,0,0,0,1.263-.5,3.275,3.275,0,0,0,.337-1.739v-8.8A3.24,3.24,0,0,0,198,44.188a1.453,1.453,0,0,0-1.257-.475V43.3h5.1v.409a1.451,1.451,0,0,0-1.28.511,3.167,3.167,0,0,0-.355,1.737v4.016h6.581V45.961a3.335,3.335,0,0,0-.331-1.773,1.419,1.419,0,0,0-1.244-.475V43.3h5.05v.409a1.428,1.428,0,0,0-1.25.494,3.2,3.2,0,0,0-.349,1.757v8.8a3.173,3.173,0,0,0,.349,1.751,1.436,1.436,0,0,0,1.25.487v.408h-5.05V57a1.4,1.4,0,0,0,1.256-.488A3.433,3.433,0,0,0,206.791,54.758Z"
                                        transform="translate(-162.862 -40.599)"
                                        fill="#dbba66"
                                    />
                                    <path
                                        id="Path_3071"
                                        data-name="Path 3071"
                                        d="M276.944,57.407h-6.5V57a1.436,1.436,0,0,0,1.25-.487,3.173,3.173,0,0,0,.349-1.751v-8.8a3.345,3.345,0,0,0-.331-1.769,1.44,1.44,0,0,0-1.268-.481V43.3h5.321a6.068,6.068,0,0,1,3.6.943,2.928,2.928,0,0,1,1.312,2.492q0,2.271-2.837,3.2v.06a4.743,4.743,0,0,1,2.627,1.426,3.626,3.626,0,0,1,1.016,2.533,2.993,2.993,0,0,1-1.245,2.509A5.313,5.313,0,0,1,276.944,57.407Zm-3.037-13.562v5.976h1.458a3.543,3.543,0,0,0,2.435-.8,2.738,2.738,0,0,0,.9-2.159,2.832,2.832,0,0,0-.886-2.224,3.622,3.622,0,0,0-2.5-.793Zm1.337,6.517h-1.337V54.5a3.984,3.984,0,0,0,.3,1.87,1.24,1.24,0,0,0,1.157.5h1.169a2.633,2.633,0,0,0,2.006-.823,3.081,3.081,0,0,0,.777-2.206,3.151,3.151,0,0,0-1.114-2.525A4.406,4.406,0,0,0,275.244,50.362Z"
                                        transform="translate(-222.054 -40.599)"
                                        fill="#dbba66"
                                    />
                                    <path
                                        id="Path_3072"
                                        data-name="Path 3072"
                                        d="M333.481,50.879h-1.142v3.879a3.138,3.138,0,0,0,.355,1.747A1.445,1.445,0,0,0,333.95,57v.408h-5.074V57a1.422,1.422,0,0,0,1.268-.494,3.317,3.317,0,0,0,.331-1.745V45.915a3.267,3.267,0,0,0-.325-1.727,1.448,1.448,0,0,0-1.275-.475V43.3h5.783a5.265,5.265,0,0,1,3.421,1.04A3.268,3.268,0,0,1,339.361,47q0,2.6-2.909,3.462a5.709,5.709,0,0,1,1.248,1.124,18.687,18.687,0,0,1,1.6,2.377,12.256,12.256,0,0,0,1.73,2.417,2.138,2.138,0,0,0,1.294.623v.408h-2.885a27.515,27.515,0,0,1-2.152-3.143,11.9,11.9,0,0,0-2.11-2.783A2.663,2.663,0,0,0,333.481,50.879Zm.337-7.034h-1.479v6.444h1.732a3.277,3.277,0,0,0,2.435-.89,3.2,3.2,0,0,0,.884-2.357,2.969,2.969,0,0,0-.98-2.315A3.723,3.723,0,0,0,333.818,43.845Z"
                                        transform="translate(-268.986 -40.599)"
                                        fill="#dbba66"
                                    />
                                    <path
                                        id="Path_3073"
                                        data-name="Path 3073"
                                        d="M509.019,57.407V57a1.418,1.418,0,0,0,1.263-.5,3.28,3.28,0,0,0,.337-1.739v-8.8a3.353,3.353,0,0,0-.33-1.763,1.433,1.433,0,0,0-1.269-.487V43.3h5.05v.409a1.382,1.382,0,0,0-1.233.505,3.256,3.256,0,0,0-.342,1.743v4.256l4.607-4.531a2.007,2.007,0,0,0,.734-1.179q0-.614-.893-.795V43.3h5.326v.409h-.7a3.256,3.256,0,0,0-1.461.427,5.942,5.942,0,0,0-1.509,1.124L514.215,49.6l5.83,5.907a7.035,7.035,0,0,0,1.334,1.164A3.148,3.148,0,0,0,522.69,57v.408h-3.356l-6.84-6.913v4.264a3.328,3.328,0,0,0,.33,1.741,1.385,1.385,0,0,0,1.245.5v.408Z"
                                        transform="translate(-413.675 -40.599)"
                                        fill="#dbba66"
                                    />
                                    <g id="Group_3235" data-name="Group 3235" transform="translate(71.747 0)">
                                        <path
                                            id="Path_3074"
                                            data-name="Path 3074"
                                            d="M435.021,53.341a9.3,9.3,0,0,1,.167,1.119,5.571,5.571,0,0,0-.392.442,7.777,7.777,0,0,0-1.5,4.99,8.023,8.023,0,0,0,1.478,5.032,4.659,4.659,0,0,0,3.876,1.92,4.729,4.729,0,0,0,3.931-1.962,7.793,7.793,0,0,0,1.508-4.893,8.039,8.039,0,0,0-1.478-5.057,4.624,4.624,0,0,0-3.827-1.907c-.173,0-.342.008-.507.022q-.081-.276-.181-.544c.21-.014.423-.023.641-.023a7.348,7.348,0,0,1,5.347,2.144,7.064,7.064,0,0,1,2.186,5.245,7.223,7.223,0,0,1-2.2,5.341,7.722,7.722,0,0,1-10.756,0,7.243,7.243,0,0,1-2.192-5.341,7.045,7.045,0,0,1,2.18-5.275A7.406,7.406,0,0,1,435.021,53.341Z"
                                            transform="translate(-422.855 -47.969)"
                                            fill="#dbba66"
                                        />
                                        <path
                                            id="Path_3075"
                                            data-name="Path 3075"
                                            d="M391.305,31.672a7.5,7.5,0,0,1,5.438-2.114,7.348,7.348,0,0,1,5.347,2.144,7.063,7.063,0,0,1,2.186,5.244,7.223,7.223,0,0,1-2.2,5.341,7.629,7.629,0,0,1-1.7,1.278,9.1,9.1,0,0,1-.168-1.151,5.832,5.832,0,0,0,.386-.454,7.794,7.794,0,0,0,1.508-4.893,8.036,8.036,0,0,0-1.478-5.057,4.624,4.624,0,0,0-3.827-1.907A4.843,4.843,0,0,0,392.8,31.98a7.775,7.775,0,0,0-1.5,4.99A8.024,8.024,0,0,0,392.783,42a4.66,4.66,0,0,0,3.876,1.92c.166,0,.328-.009.487-.022.055.184.117.364.186.542-.213.016-.428.026-.648.026a7.315,7.315,0,0,1-5.365-2.18,7.241,7.241,0,0,1-2.192-5.341A7.046,7.046,0,0,1,391.305,31.672Z"
                                            transform="translate(-389.125 -29.558)"
                                            fill="#dbba66"
                                        />
                                    </g>
                                    <path
                                        id="Path_3076"
                                        data-name="Path 3076"
                                        d="M151.73,50.339a12.2,12.2,0,0,0-3.416-2.017,10.168,10.168,0,0,1-2.765-1.626,2,2,0,0,1-.568-1.606,1.932,1.932,0,0,1,.809-1.516,3.232,3.232,0,0,1,1.988-.564c.926,0,2.5-.151,4.137,3.221l.431-.09-.89-3.508-.057-.011h-.429a.961.961,0,0,1-.143.317.333.333,0,0,1-.247.071,3.32,3.32,0,0,1-.78-.165,6.347,6.347,0,0,0-2-.365,4.432,4.432,0,0,0-3,.989,3.544,3.544,0,0,0-1.173,3.062,3.631,3.631,0,0,0,.413,1.4,3.83,3.83,0,0,0,1.013,1.143,15.207,15.207,0,0,0,2.57,1.334,12.227,12.227,0,0,1,2.83,1.724,2.421,2.421,0,0,1,.811,1.628,2.56,2.56,0,0,1-.825,2.022,2.648,2.648,0,0,1-2.037.556c-2.95-.15-4.138-2.495-4.717-3.175l-.426.089.882,3.508h.451s.315-.7,1.414-.317a7.624,7.624,0,0,0,2.462.558,4.756,4.756,0,0,0,3.232-1.053,3.444,3.444,0,0,0,1.214-2.729A3.9,3.9,0,0,0,151.73,50.339Z"
                                        transform="translate(-119.906 -39.937)"
                                        fill="#dbba66"
                                    />
                                </g>
                                <g id="Group_3237" data-name="Group 3237" transform="translate(133.855 32.256)">
                                    <path
                                        id="Path_3077"
                                        data-name="Path 3077"
                                        d="M581.817,44.264a1,1,0,1,1-.292-.7A.959.959,0,0,1,581.817,44.264Zm-.141,0a.858.858,0,1,0-.251.6A.822.822,0,0,0,581.676,44.264Zm-.385.579H581.1l-.222-.493h-.319v.493h-.171V43.685h.49a.394.394,0,0,1,.3.1.318.318,0,0,1,.1.226.325.325,0,0,1-.054.177.293.293,0,0,1-.169.12Zm-.73-.629h.3a.234.234,0,0,0,.175-.059.2.2,0,0,0,.058-.145.159.159,0,0,0-.061-.135.247.247,0,0,0-.153-.046h-.319Z"
                                        transform="translate(-579.826 -43.268)"
                                        fill="#dbba66"
                                    />
                                </g>
                            </g>
                        </svg>
                    </p>
            </Col>
            <Col xs={12} className="font-universe fs-24 color-grey">
            <h4 className="mb-4">User-Generated Content</h4>
                <p className="smallFont">You retain your intellectual property ownership rights over content you submit to us for publication on our website. We will never claim ownership of your content, but we do require a license from you in order to use it.</p>
                <p className="smallFont">When you use our website or its associated services to post, upload, share, or otherwise transmit content covered by intellectual property rights, you grant to us a non-exclusive, royalty-free, transferable, sub-licensable, worldwide license to use, distribute, modify, run, copy, publicly display, translate, or otherwise create derivative works of your content in a manner that is consistent with your privacy preferences and our Privacy Policy.</p>
                <p className="smallFont">The license you grant us can be terminated at any time by deleting your content. However, to the extent that we (or our partners) have used your content in connection with commercial or sponsored content, the license will continue until the relevant commercial or post has been discontinued by us.</p>
            <h4 className="mb-4">Liability</h4>
                <p className="smallFont">Our website and the materials on our website are provided on an 'as is' basis. To the extent permitted by law, Lashbrook makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property, or other violation of rights.</p>
                <p className="smallFont">In no event shall Lashbrook or its suppliers be liable for any consequential loss suffered or incurred by you or any third party arising from the use or inability to use this website or the materials on this website, even if Lashbrook or an authorized representative has been notified, orally or in writing, of the possibility of such damage.</p>
                <p className="smallFont">In the context of this agreement, “consequential loss” includes any consequential loss, indirect loss, real or anticipated loss of profit, loss of benefit, loss of revenue, loss of business, loss of goodwill, loss of opportunity, loss of savings, loss of reputation, loss of use and/or loss or corruption of data, whether under statute, contract, equity, tort (including negligence), indemnity, or otherwise.</p>
                <p className="smallFont">Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.</p>
                <h4 className="mb-4">Accuracy of Materials</h4>
                <p className="smallFont">The materials appearing on our website are not comprehensive and are for general information purposes only. Lashbrook does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on this website, or otherwise relating to such materials or on any resources linked to this website.</p>
                <h4 className="mb-4">Links</h4>
                <p className="smallFont">Lashbrook has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement, approval, or control by Lashbrook of the site. Use of any such linked site is at your own risk and we strongly advise you make your own investigations with respect to the suitability of those sites.</p>
                <h4 className="mb-4">Rights to Terminate</h4>
                <p className="smallFont">We may suspend or terminate your right to use our website and terminate these Terms of Service immediately upon written notice to you for any breach of these Terms of Service.</p>
                <h4 className="mb-4">Severance</h4>
                <p className="smallFont">Any term of these Terms of Service which is wholly or partially void or unenforceable is severed to the extent that it is void or unenforceable. The validity of the remainder of these Terms of Service is not affected.</p>
                <h4 className="mb-4">Governing Law</h4>
                <p className="smallFont">These Terms of Service are governed by and construed in accordance with the laws of United States. You irrevocably submit to the exclusive jurisdiction of the courts in that State or location.</p>
            </Col>
            <Col xs={12} className="font-universe fs-24 color-grey">
            <span className="divider" />
                    <p className="hr-svg">
                        <svg xmlns="http://www.w3.org/2000/svg" width="1091.5" height="8" viewBox="0 0 1091.5 8">
                            <rect
                                id="Rectangle_871"
                                data-name="Rectangle 871"
                                width="8"
                                height="1091.5"
                                rx="3"
                                transform="translate(1091.5) rotate(90)"
                                fill="#d8c37c"
                            />
                        </svg>
                    </p>
                <h2 className=" text-underline mb-4">Privacy Policy</h2>
                <p className="smallFont">Your privacy is important to us. It is Lashbrook's policy to respect your privacy and comply with any applicable law and regulation regarding any personal information we may collect about you, including across our website, https://www.lashbrookdesigns.com, and other sites we own and operate. Personal information is any information about you which can be used to identify you. This includes information about you as a person (such as name, address, and date of birth), your devices, payment details, and even information about how you use a website or online service.</p>
                <p className="smallFont">In the event our site contains links to third-party sites and services, please be aware that those sites and services have their own privacy policies. After following a link to any third-party content, you should read their posted privacy policy information about how they collect and use personal information. This Privacy Policy does not apply to any of your activities after you leave our site.</p>
                <p className="smallFont">This policy is effective as of 12 May 2022.</p>
                <p className="smallFont">Last updated: 7 July 2022</p>
                <h4 className="mb-4">Information We Collect</h4>
                <p className="smallFont">Information we collect falls into one of two categories: “voluntarily provided” information and “automatically collected” information.</p>
                <p className="smallFont">“Voluntarily provided” information refers to any information you knowingly and actively provide us when using or participating in any of our services and promotions.</p>
                <p className="smallFont">“Automatically collected” information refers to any information automatically sent by your devices in the course of accessing our products and services.</p>
                <h4 className="mb-4">Log Data</h4>
                <p className="smallFont">When you visit our website, our servers may automatically log the standard data provided by your web browser. It may include your device’s Internet Protocol (IP) address, your browser type and version, the pages you visit, the time and date of your visit, the time spent on each page, and other details about your visit.</p>
                <p className="smallFont">Additionally, if you encounter certain errors while using the site, we may automatically collect data about the error and the circumstances surrounding its occurrence. This data may include technical details about your device, what you were trying to do when the error happened, and other technical information relating to the problem. You may or may not receive notice of such errors, even in the moment they occur, that they have occurred, or what the nature of the error is.</p>
                <p className="smallFont">Please be aware that while this information may not be personally identifying by itself, it may be possible to combine it with other data to personally identify individual persons.</p>
                <p className="smallFont">Personal Information</p>
                <p className="smallFont">We may ask for personal information — for example, when you submit content to us or when you contact us — which may include one or more of the following:
                    <ol className="lists">
                        <li>Name</li>
                        <li>Email</li>
                        <li>Phone/Mobile Number</li>
                        <li>Home/Mailing Address</li>
                    </ol>
                </p>
                <h4 className="mb-4">User-Generated Content</h4>
                <p className="smallFont">We consider “user-generated content” to be materials (text, image and/or video content) voluntarily supplied to us by our users for the purpose of publication on our website or re-publishing on our social media channels. All user-generated content is associated with the account or email address used to submit the materials.</p>
                <p className="smallFont">Please be aware that any content you submit for the purpose of publication will be public after posting (and subsequent review or vetting process). Once published, it may be accessible to third parties not covered under this privacy policy.</p>
                <h4 className="mb-4">Legitimate Reasons for Processing Your Personal Information</h4>
                <p className="smallFont">We only collect and use your personal information when we have a legitimate reason for doing so. In which instance, we only collect personal information that is reasonably necessary to provide our services to you.</p>
                <h4 className="mb-4">Collection and Use of Information</h4>
                <p className="smallFont">We may collect personal information from you when you do any of the following on our website:
                    <ul className="lists">
                        <li>Enter any of our competitions, contests, sweepstakes, and surveys</li>
                        <li>Sign up to receive updates from us via email or social media channels</li>
                        <li>Use a mobile device or web browser to access our content</li>
                        <li>Contact us via email, social media, or on any similar technologies</li>
                        <li>When you mention us on social media</li>
                    </ul>
                </p>
                <p className="smallFont">We may collect, hold, use, and disclose information for the following purposes, and personal information will not be further processed in a manner that is incompatible with these purposes:
                    <ul className="lists">
                        <li>to provide you with our platform's core features and services</li>
                        <li>to deliver products and/or services to you</li>
                        <li>to contact and communicate with you</li>
                        <li>for analytics, market research, and business development, including to operate and improve our website, associated applications, and associated social media platforms</li>
                        <li>for advertising and marketing, including to send you promotional information about our products and services and information about third parties that we consider may be of interest to you</li>
                        <li>for internal record keeping and administrative purposes</li>
                        <li>to run competitions, sweepstakes, and/or offer additional benefits to you</li>
                    </ul>
                </p>
                <p className="smallFont">We may combine voluntarily provided and automatically collected personal information with general information or research data we receive from other trusted sources. For example, Our marketing and market research activities may uncover data and insights, which we may combine with information about how visitors use our site to improve our site and your experience on it.</p>
                <h4 className="mb-4">Security of Your Personal Information</h4>
                <p className="smallFont">When we collect and process personal information, and while we retain this information, we will protect it within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use, or modification.</p>
                <p className="smallFont">Although we will do our best to protect the personal information you provide to us, we advise that no method of electronic transmission or storage is 100% secure, and no one can guarantee absolute data security.</p>
                <p className="smallFont">You are responsible for selecting any password and its overall security strength, ensuring the security of your own information within the bounds of our services. For example, ensuring you do not make your personal information publicly available via our platform.</p>
                <h4 className="mb-4">How Long We Keep Your Personal Information</h4>
                <p className="smallFont">We keep your personal information only for as long as we need to. This time period may depend on what we are using your information for, in accordance with this privacy policy. For example, if you have provided us with personal information such as an email address when contacting us about a specific enquiry, we may retain this information for the duration of your enquiry remaining open as well as for our own records so we may effectively address similar enquiries in future. If your personal information is no longer required for this purpose, we will delete it or make it anonymous by removing all details that identify you.</p>
                <p className="smallFont">However, if necessary, we may retain your personal information for our compliance with a legal, accounting, or reporting obligation or for archiving purposes in the public interest, scientific, or historical research purposes or statistical purposes.</p>
                <h4 className="mb-4">Children’s Privacy</h4>
                <p className="smallFont">We do not aim any of our products or services directly at children under the age of 13, and we do not knowingly collect personal information about children under 13.</p>
                <h4 className="mb-4">Disclosure of Personal Information to Third Parties</h4>
                <p className="smallFont">We may disclose personal information to:
                    <ul className="lists">
                    <li>a parent, subsidiary, or affiliate of our company</li>
                    <li>third-party service providers for the purpose of enabling them to provide their services, including (without limitation) IT service providers, data storage, hosting and server providers, analytics, error loggers, debt collectors, maintenance or problem-solving providers, marketing providers, professional advisors, and payment systems operators</li>
                    <li>our employees, contractors, and/or related entities</li>
                    <li>our existing or potential agents or business partners</li>
                    <li>sponsors or promoters of any competition, sweepstakes, or promotion we run</li>
                    <li>credit reporting agencies, courts, tribunals, and regulatory authorities, in the event you fail to pay for goods or services we have provided to you</li>
                    <li>courts, tribunals, regulatory authorities, and law enforcement officers, as required by law, in connection with any actual or prospective legal proceedings, or in order to establish, exercise, or defend our legal rights</li>
                    <li>third parties, including agents or sub-contractors, who assist us in providing information, products, services, or direct marketing to you</li>
                    <li>third parties to collect and process data</li>
                    <li>an entity that buys, or to which we transfer all or substantially all of our assets and business</li>
                    </ul>
                    </p>
                <p className="smallFont">Third parties we currently use include:
                    <ul className="lists">
                    <li>Google Analytics</li>
                    <li>Hubspot</li>
                    <li>Rocket</li>
                    </ul>
                    </p>
                <h4 className="mb-4">Your Rights and Controlling Your Personal Information</h4>
                <p className="smallFont"><b>Your choice:</b> By providing personal information to us, you understand we will collect, hold, use, and disclose your personal information in accordance with this privacy policy. You do not have to provide personal information to us, however, if you do not, it may affect your use of our website or the products and/or services offered on or through it.</p>
                <p className="smallFont"><b>Information from third parties: </b> If we receive personal information about you from a third party, we will protect it as set out in this privacy policy. If you are a third party providing personal information about somebody else, you represent and warrant that you have such person’s consent to provide the personal information to us.</p>
                <p className="smallFont"><b>Marketing permission: </b>If you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by contacting us using the details below.</p>
                <p className="smallFont"><b>Access: </b>You may request details of the personal information that we hold about you.</p>
                <p className="smallFont"><b>Correction: </b>If you believe that any information we hold about you is inaccurate, out of date, incomplete, irrelevant, or misleading, please contact us using the details provided in this privacy policy. We will take reasonable steps to correct any information found to be inaccurate, incomplete, misleading, or out of date.</p>
                <p className="smallFont"><b>Non-discrimination: </b>We will not discriminate against you for exercising any of your rights over your personal information. Unless your personal information is required to provide you with a particular service or offer (for example providing user support), we will not deny you goods or services and/or charge you different prices or rates for goods or services, including through granting discounts or other benefits, or imposing penalties, or provide you with a different level or quality of goods or services.</p>
                <p className="smallFont"><b>Notification of data breaches: </b>We will comply with laws applicable to us in respect of any data breach.</p>
                <p className="smallFont"><b>Complaints: </b>If you believe that we have breached a relevant data protection law and wish to make a complaint, please contact us using the details below and provide us with full details of the alleged breach. We will promptly investigate your complaint and respond to you, in writing, setting out the outcome of our investigation and the steps we will take to deal with your complaint. You also have the right to contact a regulatory body or data protection authority in relation to your complaint.</p>
                <p className="smallFont"><b>Unsubscribe: </b>To unsubscribe from our email database or opt-out of communications (including marketing communications), please contact us using the details provided in this privacy policy, or opt-out using the opt-out facilities provided in the communication. We may need to request specific information from you to help us confirm your identity.</p>
                <h4 className="mb-4">Use of Cookies</h4>
                <p className="smallFont">We use “cookies” to collect information about you and your activity across our site. A cookie is a small piece of data that our website stores on your computer, and accesses each time you visit, so we can understand how you use our site. This helps us serve you content based on preferences you have specified. Please refer to our Cookie Policy for more information.</p>
                <h4 className="mb-4">Business Transfers</h4>
                <p className="smallFont">If we or our assets are acquired, or in the unlikely event that we go out of business or enter bankruptcy, we would include data, including your personal information, among the assets transferred to any parties who acquire us. You acknowledge that such transfers may occur, and that any parties who acquire us may, to the extent permitted by applicable law, continue to use your personal information according to this policy, which they will be required to assume as it is the basis for any ownership or use rights we have over such information.</p>
                <h4 className="mb-4">Limits of Our Policy</h4>
                <p className="smallFont">Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and policies of those sites, and cannot accept responsibility or liability for their respective privacy practices.</p>
                <h4 className="mb-4">Changes to This Policy</h4>
                <p className="smallFont">At our discretion, we may change our privacy policy to reflect updates to our business processes, current acceptable practices, or legislative or regulatory changes. If we decide to change this privacy policy, we will post the changes here at the same link by which you are accessing this privacy policy.</p>
                <p className="smallFont">If required by law, we will get your permission or give you the opportunity to opt in to or opt out of, as applicable, any new uses of your personal information.</p>
                <h4 className="mb-4">Additional Disclosures for California Compliance (US)</h4>
                <p className="smallFont">Under California Civil Code Section 1798.83, if you live in California and your business relationship with us is mainly for personal, family, or household purposes, you may ask us about the information we release to other organizations for their marketing purposes.</p>
                <p className="smallFont">To make such a request, please contact us using the details provided in this privacy policy with “Request for California privacy information” in the subject line. You may make this type of request once every calendar year. We will email you a list of categories of personal information we revealed to other organizations for their marketing purposes in the last calendar year, along with their names and addresses. Not all personal information shared in this way is covered by Section 1798.83 of the California Civil Code.</p>
                <h4 className="mb-4">Do Not Track</h4>
                <p className="smallFont">Some browsers have a “Do Not Track” feature that lets you tell websites that you do not want to have your online activities tracked. At this time, we do not respond to browser “Do Not Track” signals.</p>
                <p className="smallFont">We adhere to the standards outlined in this privacy policy, ensuring we collect and process personal information lawfully, fairly, transparently, and with legitimate, legal reasons for doing so.</p>
                <h4 className="mb-4">Cookies and Pixels</h4>
                <p className="smallFont">At all times, you may decline cookies from our site if your browser permits. Most browsers allow you to activate settings on your browser to refuse the setting of all or some cookies. Accordingly, your ability to limit cookies is based only on your browser’s capabilities. Please refer to the Cookies section of this privacy policy for more information.</p>
                <h4 className="mb-4">CCPA-permitted financial incentives</h4>
                <p className="smallFont">In accordance with your right to non-discrimination, we may offer you certain financial incentives permitted by the CCPA that can result in different prices, rates, or quality levels for the goods or services we provide.</p>
                <p className="smallFont">Any CCPA-permitted financial incentive we offer will reasonably relate to the value of your personal information, and we will provide written terms that describe clearly the nature of such an offer. Participation in a financial incentive program requires your prior opt-in consent, which you may revoke at any time.</p>
                <h4 className="mb-4">California Notice of Collection</h4>
                <p className="smallFont">In the past 12 months, we have collected the following categories of personal information enumerated in the California Consumer Privacy Act:
                    <ul className="lists">
                    <li>Identifiers, such as name, email address, phone number account name, IP address, and an ID or number assigned to your account.</li>
                    <li>Customer records, such as billing and shipping address, and credit or debit card data.</li>
                    <li>Audio or visual data, such as photos or videos you share with us or post on the service.</li></ul>
                For more information on information we collect, including the sources we receive information from, review the “Information We Collect” section. We collect and use these categories of personal information for the business purposes described in the “Collection and Use of Information” section, including to provide and manage our Service.
                </p>
                <h4 className="mb-4">Right to Know and Delete</h4>
                <p className="smallFont">If you are a California resident, you have rights to delete your personal information we collected and know certain information about our data practices in the preceding 12 months. In particular, you have the right to request the following from us:
                    <ul className="lists">
                        <li>The categories of personal information we have collected about you;</li>
                        <li>The categories of sources from which the personal information was collected;</li>
                        <li>The categories of personal information about you we disclosed for a business purpose or sold;</li>
                        <li>The categories of third parties to whom the personal information was disclosed for a business purpose or sold;</li>
                        <li>The business or commercial purpose for collecting or selling the personal information; and</li>
                        <li>The specific pieces of personal information we have collected about you.</li></ul>
                    To exercise any of these rights, please contact us using the details provided in this privacy policy.
                    </p>
                <h4 className="mb-4">Shine the Light</h4>
                <p className="smallFont">If you are a California resident, in addition to the rights discussed above, you have the right to request information from us regarding the manner in which we share certain personal information as defined by California’s “Shine the Light” with third parties and affiliates for their own direct marketing purposes.</p>
                <p className="smallFont">To receive this information, send us a request using the contact details provided in this privacy policy. Requests must include “California Privacy Rights Request” in the first line of the description and include your name, street address, city, state, and ZIP code.</p>
            </Col>
            <Col xs={12} className="font-universe fs-24 color-grey">
            <span className="divider" />
                    <p className="hr-svg">
                        <svg xmlns="http://www.w3.org/2000/svg" width="1091.5" height="8" viewBox="0 0 1091.5 8">
                            <rect
                                id="Rectangle_871"
                                data-name="Rectangle 871"
                                width="8"
                                height="1091.5"
                                rx="3"
                                transform="translate(1091.5) rotate(90)"
                                fill="#d8c37c"
                            />
                        </svg>
                    </p>
                <h2 className=" text-underline mb-4">Cookie Policy</h2>
                <p className="smallFont">We use cookies to help improve your experience of our website at https://www.lashbrookdesigns.com. This cookie policy is part of Lashbrook's privacy policy. It covers the use of cookies between your device and our site.</p>
                <p className="smallFont">We also provide basic information on third-party services we may use, who may also use cookies as part of their service. This policy does not cover their cookies.</p>
                <p className="smallFont">If you don’t wish to accept cookies from us, you should instruct your browser to refuse cookies from https://www.lashbrookdesigns.com. In such a case, we may be unable to provide you with some of your desired content and services.</p>
                <h4 className="mb-4">What is a Cookie?</h4>
                <p className="smallFont">A cookie is a small piece of data that a website stores on your device when you visit. It typically contains information about the website itself, a unique identifier that allows the site to recognize your web browser when you return, additional data that serves the cookie’s purpose, and the lifespan of the cookie itself.</p>
                <p className="smallFont">Cookies are used to enable certain features (e.g. logging in), track site usage (e.g. analytics), store your user settings (e.g. time zone, notification preferences), and to personalize your content (e.g. advertising, language).</p>
                <p className="smallFont">Cookies set by the website you are visiting are usually referred to as first-party cookies. They typically only track your activity on that particular site.</p>
                <p className="smallFont">Cookies set by other sites and companies (i.e. third parties) are called third-party cookies They can be used to track you on other websites that use the same third-party service.</p>
                <h4 className="mb-4">Types of cookies and how we use them</h4>
                <h4 className="text-italic mb-4">Essential cookies</h4>
                <p className="smallFont">Essential cookies are crucial to your experience of a website, enabling core features like user logins, account management, shopping carts, and payment processing. We use essential cookies to enable certain functions on our website.</p>
                <h4 className="text-italic mb-4">Performance Cookies</h4>
                <p className="smallFont">Performance cookies track how you use a website during your visit. Typically, this information is anonymous and aggregated, with information tracked across all site users. They help companies understand visitor usage patterns, identify and diagnose problems or errors their users may encounter, and make better strategic decisions in improving their audience’s overall website experience. These cookies may be set by the website you’re visiting (first-party) or by third-party services. They do not collect personal information about you. We use performance cookies on our site. </p>
                <h4 className="text-italic mb-4">Functionality Cookies</h4>
                <p className="smallFont">Functionality cookies are used to collect information about your device and any settings you may configure on the website you’re visiting (like language and time zone settings). With this information, websites can provide you with customized, enhanced, or optimized content and services. These cookies may be set by the website you’re visiting (first-party) or by third-party services. We use functionality cookies for selected features on our site.</p>
                <h4 className="text-italic mb-4">Targetting/Advertising Cookies</h4>
                <p className="smallFont">Targeting/advertising cookies help determine what promotional content is most relevant and appropriate to you and your interests. Websites may use them to deliver targeted advertising or limit the number of times you see an advertisement. This helps companies improve the effectiveness of their campaigns and the quality of content presented to you. These cookies may be set by the website you’re visiting (first-party) or by third-party services. Targeting/advertising cookies set by third-parties may be used to track you on other websites that use the same third-party service. We do not use this type of cookie on our site. </p>
            </Col>
            <Col className="text-center mt-5 d-flex align-items-center flex-column font-universe fs-24 color-grey">
                    <span className="divider" />
                    <p className="hr-svg">
                        <svg xmlns="http://www.w3.org/2000/svg" width="1091.5" height="8" viewBox="0 0 1091.5 8">
                            <rect
                                id="Rectangle_871"
                                data-name="Rectangle 871"
                                width="8"
                                height="1091.5"
                                rx="3"
                                transform="translate(1091.5) rotate(90)"
                                fill="#d8c37c"
                            />
                        </svg>
                    </p>
                    <p className="">For more information, contact Lashbrook:</p>
                    <p className="">
                        131 East 13065 South<br />Draper, UT 84020<br />
                        <a className="gold-links" href="tel:8882527388">
                            (888)252-7388
                        </a>
                    </p>
                    <p className="">
                        <a className="gold-links" href="mailto:info@lashbrookdesigns.com">
                            info@lashbrookdesigns.com
                        </a>
                    </p>
            </Col>
        </Row>
    </Container>
);

export default TermsConditions;