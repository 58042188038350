import AuthManager from './AuthManager';
import config from '../config';
import cookies from './cookies';

export default class CartManager {
    static COOKIE_NAME = 'Lashbrook-Cart';
    static get() {
        return cookies.get(this.COOKIE_NAME) || '';
    }

    static set(cartId) {
        if (cartId !== null && cartId !== undefined) {
            return cookies.set(this.COOKIE_NAME, cartId && typeof cartId !== 'undefined' ? cartId : '');
        }
        return '';
    }

    static remove() {
        return cookies.remove(this.COOKIE_NAME);
    }

    static getCartLink() {
        const cartId = CartManager.get();
        return `/shop/cart/${cartId || 'emptyCart'}`;
    }

    static isCartEnabled() {
        // if guest checkout is enabled, or the user is logged in.
        return config.guestCheckout || AuthManager.get();
    }

    static isStoreSearchEnabled() {
        // if guest checkout is enabled, or the user is NOT logged in.
        return config.guestCheckout || !AuthManager.get();
    }
}
