import React, { useState } from 'react';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const PageLimit = ({ pageSize, setPageSize, translatedSize }) => {
    const [dropdownOpen, setOpen] = useState(false);

    const toggle = () => setOpen(!dropdownOpen);

    const handlePageSize = size => {
        const updatedPageSize = size.target.textContent;
        setPageSize(translatedSize[updatedPageSize]);
    };

    return (
        <ButtonDropdown isOpen={dropdownOpen} toggle={toggle} className="outlined-button">
            <DropdownToggle caret size="sm">
                {translatedSize[pageSize]}
            </DropdownToggle>
            <DropdownMenu onClick={handlePageSize}>
                <DropdownItem active={pageSize === translatedSize['10']}>10</DropdownItem>
                <DropdownItem active={pageSize === translatedSize['25']}>25</DropdownItem>
            </DropdownMenu>
        </ButtonDropdown>
    );
};

export default PageLimit;
