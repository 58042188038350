/*
 * Updates the ring finish param with the specified attribute part (ring_0, ring_1, ring_2 are valid params)
 * Outputs the param as FINISH1,FINISH2,FINISH3 preserving any existing values.
 */
export const updateRingFinishParam = (ringFinishParam, { attribute }, value) => {
    const ringFinishes = (ringFinishParam && ringFinishParam.split(',')) || [];
    if (attribute === 'ring_0') ringFinishes[0] = value;
    if (attribute === 'ring_1') ringFinishes[1] = value;
    if (attribute === 'ring_2') ringFinishes[2] = value;
    const values = ringFinishes.reduce((str, finish) => `${str}${finish},`, '');
    return values.substr(0, values.length - 1);
};

/*
 * Updates the ring grooves param with the specified attribute part (inlay, inlay_finish) are valid params)
 * Outputs the param as GROOVE1SKU_INLAYSKU_FINISH,GROOVE2SKU_INLAYSKU_FINISH preserving any existing values.
 */
export const updateGroovesParam = (groovesParam, { attribute, grooveIndex, grooveGroup }, value) => {
    const grooves = (groovesParam && groovesParam.split(',')) || [];
    const grooveParts = (grooves[grooveIndex] && grooves[grooveIndex].split('_')) || [];
    grooveParts[0] = grooveGroup;
    if (attribute === 'inlay' || attribute === 'inlay_2') grooveParts[1] = value;
    if (attribute === 'inlay_finish' || attribute === 'inlay_finish_2') grooveParts[2] = value;
    const grooveValues = grooveParts.reduce((str, part) => `${str}${part}_`, '');
    grooves[grooveIndex] = grooveValues.substr(0, grooveValues.length - 1);

    const values = grooves.reduce((str, groove) => `${str}${groove},`, '');
    return values.substr(0, values.length - 1);
};

/*
 * Updates the machine pattern param with the specified attribute (machine_pattern, machine_pattern_color and machine_pattern_finish are valid params)
 * Outputs the param as PATTERNSKU_C_COLOR or PATTERNSKU_F_FINISH, preserving any existing values.
 */
export const updateMachinePatternParam = (machinePatternParam, { attribute }, value) => {
    const machinePatternParts = (machinePatternParam && machinePatternParam.split('_')) || [];
    if (attribute === 'machine_pattern') machinePatternParts[0] = value;
    if (attribute === 'machine_pattern_option') {
        machinePatternParts[1] = value;
        if (machinePatternParts[2]) delete machinePatternParts[2];
        if (machinePatternParts[3]) delete machinePatternParts[3];
    }

    const machinePatternValues = machinePatternParts.reduce((str, part) => `${str}${part}_`, '');
    return machinePatternValues.substr(0, machinePatternValues.length - 1);
};

/*
 * Updates the groove_group param with the specified attribute (groove_group, mga are valid params)
 * Outputs the param as GrooveGroupSKU_MGA-CERA-{color} preserving any existing values.
 */
export const updateGrooveGroupParam = (grooveGroupParams, { attribute }, value, isSecondary) => {
    const grooveGroup = (grooveGroupParams && grooveGroupParams.split(',')) || [];
    const grooveGroupParts = isSecondary ? grooveGroup[1].split('_') : grooveGroup[0].split('_');
    if (attribute === 'groove_group' || attribute === 'groove_group_2') {
        grooveGroupParts[0] = value;
    }

    if (attribute === 'mga' || attribute === 'mga_2') {
        // if is MGA value specified
        if (value && value.substr(0, 3) === 'MGA') {
            // if the existing groove group parts already end with an MGA
            if (grooveGroupParts[grooveGroupParts.length - 1].substr(0, 3) === 'MGA') {
                if (value && value !== 'MGA-NONE') {
                    // if not set to 'NONE', update the MGA value
                    grooveGroupParts[grooveGroupParts.length - 1] = value;
                } else {
                    // otherwise, remove the MGA portion from the param
                    grooveGroupParts.splice(-1, 1);
                }
                // otherwise, add MGA value (non-NONE value) to groove group
            } else if (value !== 'MGA-NONE') {
                grooveGroupParts.push(value);
            }
            // otherwise, is another groove specification. Add the portion to the set of values
        } else {
            grooveGroupParts.push(value);
        }
    }
    const grooveGroupValues = grooveGroupParts.reduce((str, part) => `${str}${part}_`, '');
    return isSecondary ? `${grooveGroup[0]},${grooveGroupValues.substr(0, grooveGroupValues.length - 1)}` : `${grooveGroupValues.substr(0, grooveGroupValues.length - 1)},${grooveGroup[1]}`;
};
export const updateGrooveStyleParam = (grooveStyleParam, { attribute }, value) => {
    const grooveStyleParts = (grooveStyleParam && grooveStyleParam.split(',')) || [];
    if (attribute === 'groove_style') grooveStyleParts[0] = value;
    if (attribute === 'groove_style_2') grooveStyleParts[1] = value;

    const grooveStyleValues = grooveStyleParts.reduce((str, part) => `${str}${part},`, '');
    return grooveStyleValues.substr(0, grooveStyleValues.length - 1);
};
export const updateGemstonesParam = (gemstonesParam, { attribute }, value) => {
    const gemstonesSplit = (gemstonesParam && gemstonesParam.split(',')) || [];
    const primaryGemstones = (gemstonesSplit[0] && gemstonesSplit[0].split('_')) || [];
    const secondaryGemstones = (gemstonesSplit[1] && gemstonesSplit[1].split('_')) || [];

    if (attribute === 'gemstone_arrangement') primaryGemstones[0] = value;
    if (attribute === 'gemstone_cut') primaryGemstones[1] = value;
    if (attribute === 'gemstone_material') primaryGemstones[2] = value;
    if (attribute === 'gemstone_size') primaryGemstones[3] = value;
    if (attribute === 'gemstone_setting') primaryGemstones[4] = value;
    if (attribute === 'gemstone_arrangement_2') secondaryGemstones[0] = value;
    if (attribute === 'gemstone_cut_2') secondaryGemstones[1] = value;
    if (attribute === 'gemstone_material_2') secondaryGemstones[2] = value;
    if (attribute === 'gemstone_size_2') secondaryGemstones[3] = value;
    if (attribute === 'gemstone_setting_2') secondaryGemstones[4] = value;

    const updatedPrimary = primaryGemstones.reduce((str, part) => `${str}${part}_`, '');
    const updatedSecondary = secondaryGemstones.reduce((str, part) => `${str}${part}_`, '');
    return `${updatedPrimary.substr(0, updatedPrimary.length - 1)},${updatedSecondary.substr(0, updatedSecondary.length - 1)}`;
};

export const updateGemstoneType = (typeParams, attribute, value) => {
    const typeParts = (typeParams && typeParams.split(',')) || [];
    if (attribute === 'gemstone_details_1') typeParts[0] = value;
    if (attribute === 'gemstone_details_2') typeParts[1] = value;
    if (attribute === 'gemstone_details_3') typeParts[2] = value;

    const gemstoneType = typeParts.reduce((str, part) => `${str}${part},`, '');
    return gemstoneType.substr(0, gemstoneType.length - 1);
};
