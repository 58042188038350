import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory, Link } from 'react-router-dom';
import { Table, Container, Row, Col, Card, CardTitle, CardText, CardBody, Button } from 'reactstrap';
import Loader from '../Shared/Loader';
import If from '../Shared/If';
import AuthManager from '../../utils/AuthManager';

const ViewOrder = ({ isFetching, getOneOrder, orderData, getAccountInfo, accountInfo, updateOrder }) => {
    const { orderId } = useParams();
    const history = useHistory();

    // to fetch order related details of one order

    useEffect(() => {
        async function fetchData() {
            await getOneOrder(orderId);
        }
        fetchData()
            .then(() => getAccountInfo())
            .catch(error => console.log(error.response));
    }, []);

    const handleUpdateOrder = approval => {
        const updateData = {
            hook_type: 'payment',
            is_approved: approval,
            status: approval ? 'approved' : 'rejected',
        };
        getButtonDisabled();

        updateOrder(updateData, orderId).then(() => history.push('/shop/customer/account/me/order/history'));
    };
    const getButtonDisabled = () => {
        const orderButtons = document.getElementsByClassName('orderButtons');
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < orderButtons.length; i++) {
            orderButtons[i].disabled = true;
        }
    };

    const renderAddresses = () =>
        orderData &&
        orderData.addresses &&
        orderData.addresses.length > 0 &&
        orderData.addresses.map(address => (
            <Col lg={12} key={address.id} className="mt-2">
                <Card>
                    <CardBody className="text-capitalize custom-card">
                        <CardTitle className="custom-title font-14">{`${address.address_type} Address`}</CardTitle>
                        <CardText className="mb-0 font-universe font-weight-bolder">{`${address.full_name}`}</CardText>
                        <CardText className="mb-0 font-universe font-weight-bolder">{address.street_address1}</CardText>
                        <CardText className="mb-0 font-universe font-weight-bolder">{address.street_address2}</CardText>
                        <CardText className="mb-0 font-universe font-weight-bolder">{address.city}</CardText>
                        <CardText className="mb-0 font-universe font-weight-bolder">{address.state}</CardText>
                        <CardText className="mb-0 font-universe font-weight-bolder">{address.zip}</CardText>
                        <CardText className="mb-0 font-universe font-weight-bolder">{address.country_code}</CardText>
                        <CardText className="mb-0 font-universe font-weight-bolder">
                            T :<a href={`tel:${address.phone_number}`}> {address.phone_number}</a>
                        </CardText>
                    </CardBody>
                </Card>
            </Col>
        ));

    if (!orderData || Object.keys(orderData).length === 0) {
        return (
            <div className="font-universe fs-20 text-center py-5">
                <div>
                    Unable to get order details. Please login with the authorised account or go back and try again.
                </div>
                <Link to="/shop/customer/account/me/order/history" color="primary" className="font-universe link-text">
                    Back to My Orders
                </Link>
            </div>
        );
    }

    return (
        <Container className="view-order-container d-flex flex-column">
            <p className="header-info font-16">Order Information</p>
            <If
                condition={
                    accountInfo?.store?.is_parent_store &&
                    !orderData.is_approved &&
                    orderData.status === 'pending approval'
                }
            >
                <Row xs={12} className="d-flex justify-content-center order-buttons">
                    <Col xs={12} md={6} lg={4} className="d-flex justify-content-between">
                        <Button onClick={() => handleUpdateOrder(true)} className="orderButtons">
                            Approve Order
                        </Button>
                        <Button onClick={() => handleUpdateOrder(false)} className="orderButtons">
                            Reject Order
                        </Button>
                    </Col>
                </Row>
            </If>
            <Row xs={12} md={12} lg={12} className="mb-2 d-flex justify-content-between">
                <Col xs={12} md={12} lg={5}>
                    {renderAddresses()}
                </Col>
                {orderData.items &&
                    orderData.items.length > 0 && (
                    <Col xs={12} md={12} lg={5} className="mt-2">
                        <Card className="secondary_tiles">
                            <CardBody className="text-capitalize custom-card">
                                <CardTitle className="custom-title font-14">Shipping Method</CardTitle>
                                <CardText className="mb-0 font-universe font-weight-bolder">
                                    {`${orderData && orderData.shipping_method_name}`}
                                </CardText>
                                <CardTitle className="custom-title font-14">Payment Method</CardTitle>
                                <CardText className="mb-0 font-universe font-weight-bolder">
                                    {`${orderData && orderData.checkout_method}`}
                                </CardText>
                                <If condition={AuthManager.isParent()}>
                                    <CardTitle className="custom-title font-14">Store Name</CardTitle>
                                    <CardText className="mb-0 font-universe font-weight-bolder">
                                        {`${orderData?.account?.store?.store_name}`}
                                    </CardText>
                                    <CardTitle className="custom-title font-14">User Name</CardTitle>
                                    <CardText className="mb-0 font-universe font-weight-bolder">
                                        {`${orderData?.account?.username}`}
                                    </CardText>
                                </If>
                            </CardBody>
                        </Card>
                    </Col>
                )}
            </Row>
            <Row className="flex-column mt-2">
                {orderData.items &&
                    orderData.items.length > 0 && (
                    <Col xs={12} md={12} lg={12} className="mx-auto d-flex flex-column">
                        <h5 className="text-header">Items Ordered</h5>
                        <Table className="font-universe font-weight-bolder table-responsive view-order">
                            <thead>
                                {orderData &&
                                        orderData.items &&
                                        orderData.items.length > 0 && (
                                        <tr>
                                        <th>Product Name</th>
                                        <th>SKU</th>
                                        <th>Qty</th>
                                        <th>Price</th>
                                        <th>Subtotal</th>
                                    </tr>
                                )}
                            </thead>
                            <tbody>
                                {orderData.items &&
                                        orderData.items.length > 0 &&
                                        orderData.items.map(data => (
                                            <tr>
                                                <td className="name-width">
                                                    <p>{data.name}</p>
                                                    <div className="d-flex">
                                                        <div className="bolder-font">Magic Number : </div>
                                                        <div>{data.magic_number}</div>
                                                    </div>
                                                    {data.properties &&
                                                        data.properties.length > 0 &&
                                                        data.properties.map(subData => (
                                                            <dl className="d-flex">
                                                                <dt className="bolder-font">{subData.name} : </dt>
                                                                <dd>{subData.value}</dd>
                                                            </dl>
                                                        ))}
                                                </td>

                                                <td className="sku">{data.sku}</td>
                                                <td className="qty">{data.quantity}</td>
                                                <td className="unit_price">{data.unit_price}</td>
                                                <td className="total_amount">{data.total_amount}</td>
                                            </tr>
                                        ))}
                            </tbody>
                            {orderData.items &&
                                    orderData.items.length > 0 && (
                                    <tfoot className="d-none-m">
                                    <tr>
                                            <th colSpan="4">Subtotal:</th>
                                            <td>{orderData.total_amount}</td>
                                        </tr>
                                    <tr>
                                            <th colSpan="4">Shipping & Handling:</th>
                                            <td>{orderData.shipping_cost}</td>
                                        </tr>
                                    <tr>
                                            <th colSpan="4">Grand Total:</th>
                                            <td>{orderData.grand_total}</td>
                                        </tr>
                                </tfoot>
                            )}
                        </Table>
                    </Col>
                )}
            </Row>
            <Row className="flex-column mobile-total">
                <Col xs={12} lg={12} className="d-flex flex-column">
                    <p className="font-universe font-weight-bolder base-column d-flex">
                        <span>Subtotal:</span> {`${orderData.total_amount}`}
                    </p>
                    <p className="font-universe font-weight-bolder base-column d-flex">
                        <span>Shipping & Handling:</span> {`${orderData.shipping_cost}`}
                    </p>
                    <p className="font-universe font-weight-bolder base-column d-flex">
                        <span>Grand Total:</span>
                        {orderData.grand_total}
                    </p>
                </Col>
            </Row>
            {orderData.items &&
                orderData.items.length > 0 && (
                <Link
                    to="/shop/customer/account/me/order/history"
                    color="primary"
                    className="font-universe link-text"
                >
                        Back to My Orders
                </Link>
            )}
            <Loader timedOut={isFetching} />
        </Container>
    );
};
ViewOrder.propTypes = {
    isFetching: PropTypes.bool,
    orderData: PropTypes.object,
    getOneOrder: PropTypes.func,
    getAccountInfo: PropTypes.func,
    accountInfo: PropTypes.object,
    updateOrder: PropTypes.func,
};

export default ViewOrder;
