import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table, Container, Row, Col } from 'reactstrap';
import Loader from '../Shared/Loader';
import OrderPagination from './OrderPagination';
import AuthManager from '../../utils/AuthManager';

const OrderHistory = ({ isFetching, getOrders, orderData }) => {
    useEffect(() => {
        getOrders();
    }, []);

    if (!orderData) return null;

    const isParent = AuthManager.getAccountType();
    const pendingOrders = orderData.length > 0 && orderData.filter(order => isParent ? order.status === 'pending approval' : order);
    return (
        <Container className="order-history-container d-flex flex-column justify-content-center">
            <Row className="flex-column">
                <Col xs={12} md={12} className="mx-auto d-flex flex-column">
                    <h5 className="text-center">{isParent ? 'Pending Approval' : 'Review Orders'}</h5>
                    {pendingOrders && pendingOrders.length > 0 ? (
                        <Table hover className="font-universe font-weight-bolder table-responsive orders-table">
                            <thead>
                                <tr>
                                    <th>Order#</th>
                                    <th>Store Name</th>
                                    <th>Date</th>
                                    <th>Ship To</th>
                                    <th>Order Total</th>
                                    {!isParent && <th>Status</th>}
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <OrderPagination
                                    pageSize={10}
                                    pageCount={pendingOrders.length > 10 ? pendingOrders.length / 10 : 0}
                                    currentPage={0}
                                    data={pendingOrders}
                                    isParent={isParent}
                                />
                            </tbody>
                        </Table>
                    ) : (
                        <div className={`${isFetching && 'd-none'} text-center font-universe fs-20`}>
                            No orders placed yet
                        </div>
                    )}
                </Col>
            </Row>
            <Loader timedOut={isFetching} />
        </Container>
    );
};
OrderHistory.propTypes = {
    isFetching: PropTypes.bool,
    getOrders: PropTypes.func,
    orderData: PropTypes.object,
};

export default OrderHistory;
