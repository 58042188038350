import { Cookies } from 'react-cookie';

const cookiesInst = new Cookies();
const cookies = {
    get: (name) => cookiesInst.get(name),
    set: (name, value) => {
        const options = window.location.protocol === 'https:' ? { path: '/', sameSite: 'none', secure: true } : { path: '/' };
        cookiesInst.set(name, value, options);
    },
    remove: (name) => cookiesInst.remove(name, { path: '/' }),
};

export default cookies;
