import get from 'lodash/get';
import { createSelector } from 'reselect';

export const selectProduct = state => get(state, 'product');

export const makeSelectIsFetching = () =>
    createSelector([selectProduct], isFetching => get(isFetching, 'isFetching', false));

export const makeSelectIsEngravingUpdated = () =>
    createSelector([selectProduct], product => get(product, 'isEngravingUpdated', false));

export const makeSelectProduct = () =>
    createSelector([selectProduct], product => get(product, 'product', {}));

export const makeSelectAddProductResult = () =>
    createSelector([selectProduct], addProductResult => get(addProductResult, 'addProductResult', {}));

export const makeSelectProductSummary = () =>
    createSelector([selectProduct], productSummary => get(productSummary, 'productSummary', {}));
