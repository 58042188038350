import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Card, Row, CardTitle, CardText, CardBody, Col, Button } from 'reactstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import Loader from '../Shared/Loader';
import CartManager from '../../utils/CartManager';
import Payment from './Payment';

const Shipping = ({
    isFetching,
    getShippingDetail,
    getAllAddresses,
    shippingMethods,
    addresses,
    handleShipping,
    shippingMethod,
    getSubscriptionSummary,
    createOrder,
    previous,
    next,
    paymentIntent,
    getPaymentMethods,
    savePaymentMethod,
    removePaymentMethod,
    getAccountInfo,
    accountInfo,
    createSubscription,
    orderDetails,
    paymentMethods,
    directOrder,
    setShippingMethod,
    selectedShipping,
}) => {
    const [shipping_id, setShippingId] = useState('1');
    const [billing_address, setBillingAddressId] = useState();
    const [shipping_address, setShippingAddressId] = useState();

    useEffect(() => {
        getAllAddresses();
    }, []);

    useEffect(() => {
        setShippingMethod(shipping_id);
    }, []);

    useEffect(() => {
        if (typeof addresses.data !== 'undefined') {
            addresses.data.map(address => {
                if (address.address_type === 'billing') {
                    setBillingAddressId(address.id);
                }
                if (address.address_type === 'shipping') {
                    setShippingAddressId(address.id);
                }
                if (address.address_type === 'both') {
                    setShippingAddressId(address.id);
                    setBillingAddressId(address.id);
                }
            });
        }
    }, [addresses]);

    useEffect(() => {
        getShippingDetail();
    }, []);

    const handleShippingChange = (reference, cost, service) => {
        const is_subscription = !directOrder;
        setShippingId(reference);
        setShippingMethod(reference);
        handleShipping(formatter.format(cost / 100), service);
        createOrder({ shipping_address, billing_address, shipping_id: reference, is_subscription })
            .then(({ payload }) => getSubscriptionSummary(payload.id));
    };

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const renderAddresses = () =>
        addresses &&
        addresses.data &&
        addresses.data.map(address => {
            if (
                address.address_type === 'shipping' ||
                address.address_type === 'both' ||
                (addresses.data.length === 1 && address.address_type === 'billing')
            ) {
                return (
                    <Col xs={12} lg={6} key={address.id} className="mt-2">
                        <Card>
                            <CardBody className="text-capitalize">
                                <CardTitle>
                                    {`${
                                        address.address_type === 'both'
                                            ? 'Billing & Shipping Address'
                                            : `${address.address_type} Address`
                                    }`}
                                </CardTitle>
                                <CardTitle>{`${address.first_name} ${address.last_name}`}</CardTitle>
                                <CardText className="mb-0">{address.street_address1}</CardText>
                                <CardText className="mb-0">{address.street_address2}</CardText>
                                <CardText className="mb-0">{address.city}</CardText>
                                <CardText className="mb-0">{address.state}</CardText>
                                <CardText className="mb-0">{address.zip}</CardText>
                                <CardText className="mb-0">
                                    <a href={`tel:${address.phone_number}`}>{address.phone_number}</a>
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                );
            }
        });
    const renderShippingMethods = () =>
        // eslint-disable-next-line camelcase
        shippingMethods.shipping_options.map(shipping_options => (
            <Col key={shipping_options.reference} className="mt-2">
                <input
                    key={shipping_options.reference}
                    type="radio"
                    name={shipping_options.service}
                    value={shipping_options.cost}
                    checked={shippingMethod === shipping_options.service}
                    className="mr-2"
                    onChange={() => {
                        handleShippingChange(shipping_options.reference, shipping_options.cost, shipping_options.service);
                    }}
                />
                <span>{formatter.format(shipping_options.cost / 100)}</span> <span>{shipping_options.service}</span>
            </Col>
        ));

    return (
        <div className="customer-cart-summary-container p-3">
            <Row className="justify-content-between font-universe">
                {addresses && addresses.data && addresses.data.length > 0 ? (
                    renderAddresses()
                ) : (
                    <Col
                        xs={12}
                        className={`${isFetching && 'd-none'} text-center font-universe py-5 fs-20`}
                    >
                        No addresses added yet
                    </Col>
                )}
                <Col xs={12} lg={6} className="mt-2">
                    <div className="shipping-methods fs-18 font-weight-bold">Select shipping method:  </div>
                    {shippingMethods &&
                    shippingMethods.shipping_options &&
                    shippingMethods.shipping_options.length > 0 &&
                    renderShippingMethods()}
                </Col>
            </Row>
            <hr />
            {!directOrder &&
            <Payment
                paymentIntent={paymentIntent}
                getPaymentMethods={getPaymentMethods}
                savePaymentMethod={savePaymentMethod}
                removePaymentMethod={removePaymentMethod}
                paymentMethods={paymentMethods}
                getAccountInfo={getAccountInfo}
                accountInfo={accountInfo}
                orderDetails={orderDetails}
                createSubscription={createSubscription}
                selectedShipping={selectedShipping}
                addresses={addresses}
                createOrder={createOrder}
            />}

            { directOrder &&
            <Row className="custom-place-order">
                <Col xs={12} className={`d-flex justify-content-between ${directOrder && 'flex-column align-items-center'}`}>
                    <Button className="mr-3 submit-shipping" onClick={next}>
                        Continue
                    </Button>
                    <Button className="back-button text-capitalize" onClick={previous}>
                        <i className="fa fa-chevron-left mr-1" aria-hidden="true" />
                        Back
                    </Button>
                </Col>
            </Row> }

            <Loader timedOut={isFetching} />
        </div>
    );
};

Shipping.propTypes = {
    isFetching: PropTypes.bool,
    getShippingDetail: PropTypes.func,
    getAllAddresses: PropTypes.func,
    shippingMethods: PropTypes.object,
    addresses: PropTypes.object,
    createOrder: PropTypes.func,
};

export default Shipping;
