import React from 'react';
import { Table } from 'reactstrap';
import { toast } from 'react-toastify';
import TablePagination from '../Open Transactions/TablePagination';
import getDetailsPDF from '../Open Transactions/getPdf';
import { salesOrderStatus, PAGE_LIMIT, translatedSizeOrders } from '../../../containers/CustomerContainer/constants';

const OrdersTable = props => {
    const { data, isFetching, page, pageSize, setPage, setPageSize, pageCount, type, getTransactionInfo, recordType } = props;
    const isCredit = type === 'credits';

    if (!data) return null;

    const renderTable = () => data.map((record) => (
        <tr key={record.tranNumber}>
            <td>{record.webStoreOrderId}</td>
            <td>{record.tranNumber}</td>
            <td>{record.poNumber}</td>
            <td>{record.customer}</td>
            <td>{record.date}</td>
            <td>{`$${record.totalAmount}`}</td>
            <td>{salesOrderStatus[record.status] || '-'}</td>
            <td className="pdf-link" onClick={() => handlePdfDownload(record.tranNumber)}>Download Details</td>
        </tr>
    ));

    const renderCredits = () => data.map((record) => (
        <tr key={record.tranNumber}>
            <td>{record.tranNumber}</td>
            <td>{record.relatedInvoice}</td>
            <td>{record.customer}</td>
            <td>{record.date}</td>
            <td>{`$${Math.abs(record.totalAmount)}`}</td>
            <td className="pdf-link" onClick={() => handlePdfDownload(record.tranNumber)}>Download Details</td>
        </tr>
    ));

    const handlePdfDownload = id => {
        getTransactionInfo({ recordType, transactionid: id })
            .then(({ payload: { data: pdfData } }) => {
                const { PDFBinary } = pdfData[0];
                getDetailsPDF(PDFBinary, id);
            })
            .catch(() => {
                toast.error('Something went wrong, please try again', { className: 'toast-error' });
            });
    };

    return (
        <>
            {data ? (
                <Table hover className="font-universe transactions-table">
                    <thead>
                        {isCredit ?
                            <tr>
                                <th>Credit #</th>
                                <th>Inv #</th>
                                <th>Name</th>
                                <th>Date</th>
                                <th>Total Amount</th>
                                <th></th>
                            </tr> :
                            <tr>
                                <th>Web ID #</th>
                                <th>SO #</th>
                                <th>PO #</th>
                                <th>Name</th>
                                <th>Date</th>
                                <th>Total Amount</th>
                                <th>Status</th>
                                <th></th>
                            </tr>}
                    </thead>
                    <tbody>
                        {!isCredit && renderTable()}
                        {isCredit && renderCredits()}
                    </tbody>
                    <TablePagination
                        pageSize={pageSize}
                        pageCount={pageCount}
                        currentPage={page}
                        setPage={setPage}
                        setPageSize={setPageSize}
                        translatedSize={translatedSizeOrders}
                    />
                </Table>
            ) : (
                <div className={`${isFetching && 'd-none'} text-center font-universe fs-20`}>
                    No data
                </div>
            )}
        </>
    );
};

export default OrdersTable;
