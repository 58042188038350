import { apiActionTypes } from '../../middleware';
const base = '@@Containers/CartContainer/';

export const GET_CART_DETAIL_ACTION_TYPES = apiActionTypes(`${base}GET_CART_DETAIL`);
export const UPDATE_PRODUCT_DETAIL_ACTION_TYPES = apiActionTypes(`${base}UPDATE_PRODUCT_DETAIL`);
export const REMOVE_PRODUCT_ACTION_TYPES = apiActionTypes(`${base}REMOVE_PRODUCT`);
export const REMOVE_ADDON_ACTION_TYPES = apiActionTypes(`${base}REMOVE_ADDON_PRODUCT`);
export const UPDATE_CART_ACTION_TYPES = apiActionTypes(`${base}UPDATE_CART`);
export const CLEAR_CART_ACTION_TYPES = apiActionTypes(`${base}CLEAR_CART`);
export const CREATE_ORDER_ACTION_TYPES = apiActionTypes(`${base}CREATE_ORDER`);
export const GET_SHIPPING_DETAILS_ACTION_TYPES = apiActionTypes(`${base}GET_SHIPPING_DETAILS`);
export const GET_PARTNERS_ACTION_TYPES = apiActionTypes(`${base}GET_PARTNERS`);
export const RESET_PRODUCT_DATA = `${base}RESET_PRODUCT_DATA`;


export const ACTION = {
    INC: 'inc',
    DEC: 'dec',
};
