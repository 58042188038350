import React, { Component } from 'react';
import { Badge } from 'reactstrap';
import VersionManager from '../../../utils/VersionManager';
import If from '../If';
import config from '../../../config';
import settings from '../../../config/settings';
import './styles.css';

class EnvironmentInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isVisible: (config.showEnvironmentInfo !== false && config.showEnvironmentInfo !== 'false'),
            allowHover: false,
        };
    }

    componentDidMount() {
        document.addEventListener('keydown', this.keydownHandler.bind(this));
        setTimeout(() => this.setState({ allowHover: true }), 5000); // show-initial animation is 5s
    }
    componentWillUnmount() {
        document.removeEventListener('keydown', this.keydownHandler.bind(this));
    }

    keydownHandler(e) {
        const oneKey = 49;
        if (e.keyCode === oneKey && e.shiftKey && e.ctrlKey) {
            const { isVisible } = this.state;
            this.setState({ isVisible: !isVisible });
        }
    }

    toggleAppBehavior() {
        settings.toggleAppBehavior();
        window.location.reload();
    }

    render() {
        const { isVisible, allowHover } = this.state;
        return (
            <If condition={isVisible}>
                <div className="meta" >
                    <div className={`environment-info${allowHover ? ' allow-hover' : ''}`} style={{ backgroundColor: config.color }}>
                        <div className="environment">{config.name}&nbsp;&nbsp;<Badge color="primary" style={{ cursor: 'pointer' }} onClick={this.toggleAppBehavior}>{config.appDefaultBehavior}</Badge></div>
                        <div className="version">Build version {config.appVersion} (Commit {VersionManager.getCommit()})</div>
                        <div className="env-info">
                            <div className="env">API: {config.apiBaseUrl}</div>
                            <div className="env">Assets: {config.assetsBaseUrl}</div>
                        </div>
                    </div>
                </div>
            </If>
        );
    }
}

export default EnvironmentInfo;
