import { CALL_API, METHODS } from '../../middleware';
import URLs from '../../utils/url';
import CartManager from '../../utils/CartManager';

import {
    GET_CART_DETAIL_ACTION_TYPES,
    RESET_PRODUCT_DATA,
    UPDATE_PRODUCT_DETAIL_ACTION_TYPES,
    REMOVE_PRODUCT_ACTION_TYPES,
    REMOVE_ADDON_ACTION_TYPES,
    UPDATE_CART_ACTION_TYPES,
    CREATE_ORDER_ACTION_TYPES,
    GET_SHIPPING_DETAILS_ACTION_TYPES,
    CLEAR_CART_ACTION_TYPES,
    GET_PARTNERS_ACTION_TYPES,
} from './constants';
import PricingTypeManager from '../../utils/PricingTypeManager';

const getOrder = cartId => ({
    [CALL_API]: {
        requestConfig: {
            useAuth: true,
            baseUrl: URLs.apiBaseUrl,
            url: URLs.order,
            method: METHODS.POST,
            headers: { 'guest-cart': cartId },
        },
        types: CREATE_ORDER_ACTION_TYPES,
    },
});

const getShippingDetail = () => ({
    [CALL_API]: {
        requestConfig: {
            useAuth: true,
            baseUrl: URLs.apiBaseUrl,
            url: URLs.shipping,
            method: METHODS.POST,
        },
        types: GET_SHIPPING_DETAILS_ACTION_TYPES,
    },
});
const getCartDetail = cartId => {
    const ptpe = PricingTypeManager.get();
    const priceType = ptpe || 'Retail';
    return ({
        [CALL_API]: {
            requestConfig: {
                useAuth: true,
                baseUrl: URLs.apiBaseUrl,
                url: URLs.cart,
                method: METHODS.GET,
                headers: { 'Lashbrook-PTPE': priceType, 'Lashbrook-Cart': cartId },
            },
            types: GET_CART_DETAIL_ACTION_TYPES,
        },
    });
};

const updateProductDetail = (data, cartId) => ({
    [CALL_API]: {
        requestConfig: {
            useAuth: true,
            baseUrl: URLs.apiBaseUrl,
            url: `${URLs.cart}/product/${data.id}`,
            method: METHODS.PUT,
            headers: { 'Lashbrook-Cart': cartId },
            data,
        },
        types: UPDATE_PRODUCT_DETAIL_ACTION_TYPES,
    },
});

const removeProduct = (productId, cartId) =>
// const cartId = CartManager.get();

    ({
        [CALL_API]: {
            requestConfig: {
                useAuth: true,
                baseUrl: URLs.apiBaseUrl,
                url: `${URLs.cart}/product/${productId}`,
                method: METHODS.DELETE,
                headers: { 'Lashbrook-Cart': cartId },
            },
            types: REMOVE_PRODUCT_ACTION_TYPES,
        },
    });
const removeAddOnProduct = (productId, addOnProductId, cartId) =>
// const cartId = CartManager.get();

    ({
        [CALL_API]: {
            requestConfig: {
                useAuth: true,
                baseUrl: URLs.apiBaseUrl,
                url: `${URLs.cart}/product/${productId}/${addOnProductId}`,
                method: METHODS.DELETE,
                headers: { 'Lashbrook-Cart': cartId },
            },
            types: REMOVE_ADDON_ACTION_TYPES,
        },
    });
const updateCart = (data, cartId) =>
// const cartId = CartManager.get();

    ({
        [CALL_API]: {
            requestConfig: {
                useAuth: true,
                baseUrl: URLs.apiBaseUrl,
                url: URLs.cart,
                method: METHODS.PATCH,
                headers: { 'Lashbrook-Cart': cartId },
                data,
            },
            types: UPDATE_CART_ACTION_TYPES,
        },
    });
const clearCart = cartId => {
// const cartId = CartManager.get();
    CartManager.remove();

    return ({
        [CALL_API]: {
            requestConfig: {
                useAuth: true,
                baseUrl: URLs.apiBaseUrl,
                url: URLs.cart,
                method: METHODS.DELETE,
                headers: { 'Lashbrook-Cart': cartId },
            },
            types: CLEAR_CART_ACTION_TYPES,
        },
    });
};

const getPartners = () => ({
    [CALL_API]: {
        requestConfig: {
            useAuth: true,
            baseUrl: URLs.apiBaseUrl,
            url: URLs.partners,
            method: METHODS.GET,
        },
        types: GET_PARTNERS_ACTION_TYPES,
    },
});

const resetProductData = () => ({
    type: RESET_PRODUCT_DATA,
});

export {
    getOrder,
    getShippingDetail,
    getCartDetail,
    updateProductDetail,
    removeProduct,
    removeAddOnProduct,
    updateCart,
    clearCart,
    getPartners,
    resetProductData,
};
