import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Table } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import Loader from '../Shared/Loader';
import CollapsibleCard from '../Shared/CollapsibleCard';
import LabelValuePair from '../Shared/LabelValuePair/LabelValuePair';
import { EMPTY_DATE, YES, NO } from '../../utils/Constants';
import { formatCurrency, formatNumber } from '../../utils/currencyFormatter';
import { getMonth } from '../../utils/getMonth';
import Autocomplete from '../Shared/Autocomplete/Autocomplete';
import { dateFormat } from '../../containers/CustomerContainer/constants';
import NotesList from './NotesList';

const StoreSuccess = ({
                          isFetching,
                          getTransactionalData,
                          getCustomerData,
                          getAccountInfo,
                          accountInfo,
                          resetTransactionalCustomerData,
                          transactionalData,
                          customerData,
                          isFetchingTransactionalData,
                          isFetchingCustomerData,
                          getStoreList,
                          storeList,
                          isFetchingStoreList,
                          isFetchingTaskContactNotes,
                          taskContactNotes,
                          getTaskContactNotes,
                          resetTaskContactNotes,
                      }) => {
    const [filter, setFilter] = useState('Yearly');
    const [selectedValue, setSelectedValue] = useState('');

    const handleChange = (selectedOption) => {
        setSelectedValue(selectedOption);
    };

    useEffect(() => {
        getAccountInfo();
        return () => resetTransactionalCustomerData();
    }, []);

    useEffect(() => {
        if (accountInfo?.store?.netsuite_id) {
            getTransactionalData({ ns_custId:selectedValue?.internalid || accountInfo?.store?.netsuite_id });
            getCustomerData({ customerId: selectedValue?.internalid || accountInfo?.store?.netsuite_id });
            getTaskContactNotes({ CustomerId: selectedValue?.internalid || accountInfo?.store?.netsuite_id });
        }
        return () => resetTaskContactNotes();
    }, [accountInfo?.store?.netsuite_id, selectedValue]);

    useEffect(() => {
        if (accountInfo?.show_store_toggle) {
            getStoreList();
        }
    }, [accountInfo?.show_store_toggle]);

    const salesAnalysisData = useMemo(() => {
        if (!transactionalData) return [];
        let dataArray = [];
        if (filter === 'Yearly') {
            let yearlyData;
            let lastYear;
            if (parseInt(transactionalData?.AvgTicketby_year?.[transactionalData?.AvgTicketby_year?.length - 1]?.transhippeddate) === new Date().getFullYear()) {
                yearlyData = transactionalData?.AvgTicketby_year?.slice(0, transactionalData?.AvgTicketby_year?.length - 1) || [];
                lastYear = transactionalData?.AvgTicketby_year?.[transactionalData?.AvgTicketby_year?.length - 1];
            } else {
                yearlyData = transactionalData?.AvgTicketby_year || [];
            }
            dataArray = [...(yearlyData), ...(transactionalData?.AvgTicketby_lastyearYTD?.[0] ? [{
                ...transactionalData?.AvgTicketby_lastyearYTD?.[0],
                transhippeddate: `${transactionalData?.AvgTicketby_lastyearYTD?.[0]?.transhippeddate} YTD`,
                hide_calculation: true,
            }] : []), ...(lastYear ? [lastYear] : [])];
        } else {
            dataArray = transactionalData?.AvgTicketby_month?.slice(0, 13).sort((a, b) => a.transhippeddate > b.transhippeddate ? 1 : -1);
        }
        return dataArray?.map((item, index) => {
            const previousYear = dataArray?.[index - 1];
            const currentNetAmount = parseFloat(item?.netamount) || 0;
            const previousNetAmount = parseFloat(previousYear?.netamount) || 0;
            const growth = previousYear ? (previousNetAmount === 0
                ? (currentNetAmount > 0 ? Infinity : 0)
                : ((currentNetAmount - previousNetAmount) / previousNetAmount) * 100) : 0;
            const currentUnits = parseInt(item?.quantity) || 0;
            const previousUnits = parseInt(previousYear?.quantity) || 0;
            const growthUnits = previousYear ? (previousUnits === 0
                ? (currentUnits > 0 ? Infinity : 0)
                : ((currentUnits - previousUnits) / previousUnits) * 100) : 0;
            const avgTicketRetails = item.quantity ? item.netamount / item.quantity : 0;
            const estNetProfit = item?.netamount * 2;
            return {
                yearMonth: filter === 'Yearly' ? item.transhippeddate : getMonth(item.transhippeddate),
                totalVolume: formatCurrency(item?.netamount),
                growth: item?.hide_calculation ? '-' : `${formatNumber(growth?.toFixed(2))}%`,
                growthClass: item?.hide_calculation ? '' : growth > 0 ? 'text-success' : 'text-danger',
                unitsSold: formatNumber(item?.quantity),
                growthUnits: item?.hide_calculation ? '-' : `${formatNumber(growthUnits?.toFixed(2))}%`,
                growthUnitsClass: item?.hide_calculation ? '' : growthUnits > 0 ? 'text-success' : 'text-danger',
                avgTicketRetails: formatCurrency(avgTicketRetails),
                estNetProfit: formatCurrency(estNetProfit),
            };
        });
    }, [transactionalData, filter]);

    const handleFilterChange = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setFilter(e.target.value);
    };

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const isLoading = useMemo(() => isFetching || isFetchingTransactionalData || isFetchingCustomerData || isFetchingStoreList || isFetchingTaskContactNotes, [isFetching, isFetchingTransactionalData, isFetchingCustomerData, isFetchingStoreList, isFetchingTaskContactNotes]);

    if (accountInfo && accountInfo?.show_store_success_page === false) {
        return <Redirect to={'/shop/customer/account'} />;
    }

    return (
        <Container className="mx-xl-1 store-success mb-4">
            <div className="d-flex justify-content-between">
                <h5>Store Success</h5>
                {accountInfo?.show_store_toggle &&
                    <div className="d-flex align-items-center">
                        <p className="mr-2 font-open-sans-condensed p-0 mb-0"><strong>Store #: </strong>{selectedValue?.["Account Number"] || accountInfo?.store?.store_number}</p>
                        <Autocomplete
                            placeholder="Search Store..."
                            options={storeList}
                            selectedValue={selectedValue?.internalid}
                            valueAttribute="internalid"
                            className="min-w-15-rem"
                            labelAttribute="altname"
                            onChange={handleChange}
                            labelSecondAttribute={"Account Number"}
                        />
                    </div>}
            </div>
            <CollapsibleCard
                title="Sales Analysis"
                filter={(
                    <select className="mr-2 border-0 select p-0" value={filter} onChange={handleFilterChange}
                            onClick={stopPropagation}>
                        <option value="Yearly">Yearly</option>
                        <option value="Monthly">Monthly</option>
                    </select>
                )}
            >
                <Table hover className="font-universe font-weight-bolder table-responsive orders-table mt-4">
                    <thead>
                    <tr>
                        <th>{filter === 'Yearly' ? 'Year' : 'Month'}</th>
                        <th className="px-0">Total Volume</th>
                        <th className="text-nowrap">%Growth($)</th>
                        <th>Units Sold</th>
                        <th className="text-nowrap">%Growth(#)</th>
                        <th>Avg. Ticket Wholesale</th>
                        <th className="px-0">Est. Net Profit</th>
                    </tr>
                    </thead>
                    <tbody className="table-body">
                    {salesAnalysisData?.map((item, index) => (
                        <tr key={item.yearMonth + index}>
                            <td>{item.yearMonth}</td>
                            <td className="px-0">{item.totalVolume}</td>
                            <td className={item?.growthClass}>{item.growth}</td>
                            <td>{item.unitsSold}</td>
                            <td className={item?.growthUnitsClass}>{item.growthUnits}</td>
                            <td>{item.avgTicketRetails}</td>
                            <td className="px-0">{item.estNetProfit}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                {!isLoading && salesAnalysisData?.length === 0 &&
                    <div className="text-center font-open-sans-condensed">No data available</div>}
            </CollapsibleCard>
            <CollapsibleCard title="Growth Opportunities">
                <div className="row mt-5">
                    <div className="col-4 border-right">
                        <LabelValuePair label="Marketing" labelClassName="font-weight-bold" />
                        <LabelValuePair label="Product Loaded to Website"
                                        value={customerData?.ProductLoadedtoWeb ? customerData?.ProductLoadedtoWeb : EMPTY_DATE}
                                        valueClassName={!customerData?.ProductLoadedtoWeb && 'text-danger'} />
                        <LabelValuePair label="Current Branding on Website"
                                        value={customerData?.CurrentBrandingonWeb ? customerData?.CurrentBrandingonWeb : EMPTY_DATE}
                                        valueClassName={!customerData?.CurrentBrandingonWeb && 'text-danger'} />
                        <LabelValuePair label="Duratrans/ In-Store Promo"
                                        value={customerData?.InStorePromo ? customerData?.InStorePromo : EMPTY_DATE}
                                        valueClassName={!customerData?.InStorePromo && 'text-danger'} />
                        <LabelValuePair label="Builder on Website"
                                        value={customerData?.BuildOnWeb ? customerData?.BuildOnWeb : EMPTY_DATE}
                                        valueClassName={!customerData?.BuildOnWeb && 'text-danger'} />
                        <LabelValuePair label="Shared Lashbrook on Social"
                                        value={customerData?.SharedLBonSocial ? YES : NO}
                                        valueClassName={!customerData?.SharedLBonSocial && 'text-danger'} />
                        <LabelValuePair label="Has Social Media Acct."
                                        value={customerData?.Hassocialmediaccount ? YES : NO}
                                        valueClassName={!customerData?.Hassocialmediaccount && 'text-danger'} />
                        <div className="ml-3">
                            <LabelValuePair label="Website" isLink
                                            value={customerData?.Website ? customerData?.Website : '-'}
                                            valueClassName={!customerData?.Website && 'text-danger'} />
                            <LabelValuePair label="Instagram" isLink
                                            value={customerData?.InstagramAccount ? customerData?.InstagramAccount : '-'}
                                            valueClassName={!customerData?.InstagramAccount && 'text-danger'} />
                            <LabelValuePair label="TikTok" isLink
                                            value={customerData?.TiktokAccount ? customerData?.TiktokAccount : '-'}
                                            valueClassName={!customerData?.TiktokAccount && 'text-danger'} />
                            <LabelValuePair label="Facebook" isLink
                                            value={customerData?.FacebookAccount ? customerData?.FacebookAccount : '-'}
                                            valueClassName={!customerData?.FacebookAccount && 'text-danger'} />
                        </div>
                    </div>
                    <div className="col-2 border-right">
                        <LabelValuePair label="Product" labelClassName="font-weight-bold" />
                        {customerData?.KitCollection?.length > 0 ? customerData?.KitCollection?.split(",")?.map((item, index) => (
                            <LabelValuePair label={item} value={YES} key={index} />
                        )) : <LabelValuePair label="Kit Collection" value={NO} valueClassName="text-danger" />}
                    </div>
                    <div className="col-3 border-right">
                        <LabelValuePair label="Training" labelClassName="font-weight-bold" />
                        <LabelValuePair label="Lashbrook University"
                                        value={customerData?.LashbrookUniversity ? YES : NO}
                                        valueClassName={!customerData?.LashbrookUniversity && 'text-danger'} />
                        <LabelValuePair label="Store Trainings (in last 12 mo.)"
                                        value={customerData?.TrainingDate ? customerData?.TrainingDate : EMPTY_DATE}
                                        valueClassName={!customerData?.TrainingDate && 'text-danger'} />
                        <LabelValuePair label="Recent Onboarding"
                                        value={customerData?.OnBoardingDate ? customerData?.OnBoardingDate : EMPTY_DATE}
                                        valueClassName={!customerData?.OnBoardingDate && 'text-danger'} />
                    </div>
                    <div className="col-3">
                        <LabelValuePair label="Inventory" labelClassName="font-weight-bold" />
                        <LabelValuePair label="Current Displays" value={customerData?.DisplayColors ? YES : NO}
                                        valueClassName={!customerData?.DisplayColors && 'text-danger'} />
                        <LabelValuePair
                            label="Optimal Setup In Case"
                            value={customerData?.Optimalsetupinstore ? YES : NO}
                            valueClassName={!customerData?.Optimalsetupinstore && 'text-danger'}
                            imageURL={customerData?.Optimalsetupinstore ? customerData?.Optimalsetupinstore : null}
                        />
                        <LabelValuePair
                            label="Optimal Placement in Store"
                            value={customerData?.OptimalPlaceinstore ? YES : NO}
                            valueClassName={!customerData?.OptimalPlaceinstore && 'text-danger'}
                            imageURL={customerData?.OptimalPlaceinstore ? customerData?.OptimalPlaceinstore : null}
                        />
                        <LabelValuePair label="QR Code Tags on ALL Inventory"
                                        value={customerData?.QRTagonallInventory ? YES : NO}
                                        valueClassName={!customerData?.QRTagonallInventory && 'text-danger'} />
                    </div>
                </div>
            </CollapsibleCard>
            <CollapsibleCard
                title="Tasks"
                className="scroll-table"
            >
                {taskContactNotes?.Cx_Tasks?.length > 0 ?
                    <Table hover className="font-universe font-weight-bolder table-responsive orders-table mt-4">
                        <thead>
                        <tr>
                            <th>Status</th>
                            <th className="text-nowrap">Due Date</th>
                            <th>Assigned To</th>
                            <th colSpan={2}>Task</th>
                            <th>Created</th>
                        </tr>
                        </thead>
                        <tbody className="table-body">
                        {taskContactNotes?.Cx_Tasks?.map((item, index) => (
                            <tr key={index}>
                                <td>{item.status || '-'}</td>
                                <td>{item.duedate ? new Date(item.duedate).toLocaleString('en-US', dateFormat) : '-'}</td>
                                <td>{item.assigned || '-'}</td>
                                <td colSpan={2}><strong>{item.title}</strong>: {item.message}</td>
                                <td>{item.createddate ? `${new Date(item.createddate).toLocaleString('en-US', dateFormat)} by ${item.owner}` : '-'}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table> :
                    <div className="text-center font-open-sans-condensed fs-24">No tasks available</div>}
            </CollapsibleCard>
            <CollapsibleCard
                title="Contacts"
                className="scroll-table"
            >
                {taskContactNotes?.Cx_Contacts?.length > 0 ?
                    <Table hover className="font-universe font-weight-bolder table-responsive orders-table mt-4">
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Role</th>
                            <th className="text-nowrap">Email</th>
                            <th className="text-nowrap">Phone #</th>
                        </tr>
                        </thead>
                        <tbody className="table-body">
                        {taskContactNotes?.Cx_Contacts?.map((item, index) => (
                            <tr key={`${item.email}_${index}`}>
                                <td>{item.entityid || '-'}</td>
                                <td>{item.contactrole || '-'}</td>
                                <td>{item.email || '-'}</td>
                                <td>{item.phone || '-'}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table> :
                    <div className="text-center font-open-sans-condensed fs-24">No contacts available</div>}
            </CollapsibleCard>
            {accountInfo?.show_store_toggle &&
                <NotesList notes={taskContactNotes?.Cx_Usernotes || []} />}
            <Loader timedOut={isLoading} />
        </Container>
    );
};

StoreSuccess.propTypes = {
    isFetching: PropTypes.bool,
    getAccountInfo: PropTypes.func,
    getTransactionalData: PropTypes.func,
    getCustomerData: PropTypes.func,
    resetTransactionalCustomerData: PropTypes.func,
    accountInfo: PropTypes.object,
};

export default StoreSuccess;
