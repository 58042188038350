import React from 'react';
import { Link } from 'react-router-dom';
import './styles.css';
import URLs from '../../../utils/url';
import AuthManager from '../../../utils/AuthManager';
import If from '../If';

const uniqueMaterials = [
    { label: 'Camo', link: '/shop/unique-materials/camo' },
    { label: 'Carbon Fiber', link: '/shop/unique-materials/carbon-fiber' },
    { label: 'Cerakote', link: '/shop/unique-materials/cerakote' },
    { label: 'Cobalt Chrome', link: '/shop/unique-materials/cobalt-chrome' },
    { label: 'Damascus', link: '/shop/unique-materials/damascus' },
    { label: 'Diamonds & Gemstones', link: '/shop/content/diamonds-gemstones' },
    { label: 'Dinosaur Bone', link: '/shop/unique-materials/dinosaur-bone' },
    { label: 'Hardwood', link: '/shop/unique-materials/hardwood' },
    { label: 'Meteorite', link: '/shop/unique-materials/meteorite' },
    { label: 'Mosaics', link: '/shop/unique-materials/mosaics' },
    { label: 'Precious Metal', link: '/shop/unique-materials/precious-metal' },
    { label: 'Shell', link: '/shop/unique-materials/shell' },
    { label: 'Superconductor', link: '/shop/unique-materials/superconductor' },
    { label: 'Tantalum', link: '/shop/unique-materials/Tantalum' },
    { label: 'Titanium', link: '/shop/unique-materials/titanium' },
    { label: 'Titanium Damascus', link: '/shop/unique-materials/titanium-damascus' },
    { label: 'Zirconium', link: '/shop/unique-materials/zirconium' },
];

const Footer = () => (
    <footer className="no-print">
        <nav className="category">
            <div className="menu-main-nav-container">
                <ul>
                    {uniqueMaterials.map((material, i) => (
                        <li key={i} className="menu-item menu-item-type-custom menu-item-object-custom">
                            <Link aria-label={material.label} to={material.link}>{material.label}</Link>
                        </li>
                    ))}
                </ul>
            </div>
        </nav>
        <nav className="utility">
            <ul>
                <div className="menu-main-nav-container">
                    <li
                        id="menu-item-32"
                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-32"
                    >
                        <Link aria-label="Collections" to="/shop/categories">Collections</Link>
                    </li>
                    <li
                        id="menu-item-214"
                        className="menu-item menu-item-214"
                    >
                        <Link aria-label="Learn" to="/learn">Learn</Link>
                    </li>
                    <If condition={!(AuthManager.get() && AuthManager.getUsername())}>
                        <li
                            id="menu-item-196"
                            className="menu-item menu-item-196"
                        >
                            <Link aria-label="Locations" to="/shop/content/where-to-buy/">Stores</Link>
                        </li>
                    </If>
                    <li
                        id="menu-item-27"
                        className="menu-item menu-item-has-children menu-item-27"
                    >
                        <Link aria-label="About" to="/shop/content/company/">Company</Link>
                    </li>
                    <li
                        id="menu-item-27"
                        className="menu-item menu-item-has-children menu-item-27"
                    >
                        <Link aria-label="Terms" to="/lashbrook-terms-conditions">Terms &amp; Conditions</Link>
                    </li>
                </div>
            </ul>
        </nav>
        <div className="social-icons">
            <a href="https://www.facebook.com/lashbrookrings/" rel="noreferrer noopener" aria-label="Find us on Facebook" target="_blank">
                <i className="fa fa-facebook" />
            </a>
            <a href="https://twitter.com/lashbrookrings" rel="noreferrer noopener" aria-label="Find us on Twitter" target="_blank">
                <i className="fa fa-twitter" />
            </a>
            <a href="https://www.pinterest.com/lashbrookrings/" rel="noreferrer noopener" aria-label="Find us on Pinterest" target="_blank">
                <i className="fa fa-pinterest" />
            </a>
            <a href="https://www.instagram.com/lashbrookdesigns/" rel="noreferrer noopener" aria-label="Find us on Instagram" target="_blank">
                <i className="fa fa-instagram" />
            </a>
        </div>
        <div className="mt-3">
            <p className="font-universe text-center footer-text">
                <sup>&#42;</sup>MSRP is defined as the current manufacture&#39;s retail price (that&#39;s the price
                you&#39;ll see live on our website). Prices fluctuate over time, especially with precious metals.
                Shipping and Handling charges apply.
            </p>
        </div>
    </footer>
);

export default Footer;
