import React from 'react';
import { ICategoryDesc } from '../../types/customizer';
import Rating from '../Rating';
import leftArrow from '../../../../assets/images/left-chevrolet.svg';
import If from '../../../../components/Shared/If';
import { isEmpty } from '../../utils/basic';

interface IMaterialInfo extends ICategoryDesc {
onBack?: () => void
}

const MaterialInfo = (props: IMaterialInfo) => {
    const openLearnMore = () => {
        const link = props.link ? props.link.replace('/pages', '') : '';
        window.open(`/shop/unique-materials${link}`, '_blank');
    };

    return (
        <div className="tw-text-blue-900">
            <If condition={!!props.onBack}>
                <div className="tw-inline-flex tw-items-center tw-cursor-pointer" role="presentation" onClick={props.onBack}>
                    <img src={leftArrow} alt="left_arrow" className="tw-mr-1" />
                    <button className="tw-bg-transparent tw-border-none hover:tw-bg-transparent hover:tw-border-none p-0 tw-leading-none focus:tw-outline-0 focus:tw-shadow-none focus:tw-border-none tw-normal-case tw-text-blue-900 hover:tw-text-blue-900 tw-font-[inherit]">Back</button>
                </div>
            </If>
            <div className="tw-max-h-[550px] tw-overflow-auto">
                <div className="tw-px-6 tw-py-4">
                    <div className="tw-text-lg tw-mb-4 primary-font-family">{props.name}</div>
                    <p className="tw-text-gray-700 tw-text-sm tw-leading-6">
                        {props.short_desc}
                    </p>
                    <button className="tw-bg-transparent hover:tw-bg-transparent p-0 tw-border-0 hover:tw-border-0 tw-border-b hover:tw-border-b tw-border-solid tw-border-blue-900 tw-text-sm tw-text-blue-900 hover:tw-text-blue-900 hover:tw-border-blue-900 tw-normal-case focus:tw-border-blue-900 focus:tw-text-blue-900 focus:tw-outline-0 focus:tw-shadow-none focus:tw-border-0 focus:tw-border-b focus:text-blue-900 tw-font-[inherit]" onClick={openLearnMore}>
                        Learn more &rarr;
                    </button>
                </div>
                <div className="tw-px-6 tw-pt-4 tw-pb-2">
                    <div className="tw-flex tw-items-center tw-justify-end">
                        <label className="tw-text-xs mb-0">Weight:</label>
                        {isEmpty(props.weight) ? <div className="tw-font-bold tw-text-sm tw-ml-1">N/A</div> : <Rating count={Number(props.weight)} />}
                    </div>
                    <div className="tw-flex tw-items-center tw-justify-end">
                        <label className="tw-text-xs mb-0">Scratch Resistance:</label>
                        {isEmpty(props.scratch_resistance) ?
                            <div className="tw-font-bold tw-text-sm tw-ml-1">N/A</div> :
                            <Rating count={Number(props.scratch_resistance)} />}
                    </div>
                </div>
                <div className="tw-flex tw-justify-center tw-items-end tw-min-h-[150px] tw-max-h-[240px] tw-pr-3">
                    <img src={props.image_url || 'https://assets.staging.lashbrookdesigns.com/rings/profiles/D/widths/7/ring_materials/14KW/images/1?ring_finish=POLISH&finish_type=F&weight=P&size=10&feature_group=N&image_size=250'} alt="Mountain" />
                </div>
            </div>
        </div>
    );
};

export default MaterialInfo;
