/* eslint-disable react/sort-comp */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { connect } from 'react-redux';
import ProductContainer from './containers/ProductContainer';
import RingCustomizerContainer from './containers/RingCustomizerContainer';
import StaticProductContainer from './containers/StaticProductContainer';
import CartContainer from './containers/CartContainer';
import RingCategoryListContainer from './containers/RingCategoryListContainer';
import SearchContainer from './containers/SearchContainer';
import CategoryContainer from './containers/CategoryContainer';
import NotFound from './containers/NotFound';
import { history } from './store';
import GuestRoute from './GuestRoute';
import config from './config';
import OrderStatusContainer from './containers/OrderStatus/OrderStatusContainer';
import CustomerContainer from './containers/CustomerContainer/CustomerContainer';
import ManageSubscription from './containers/ManageSubscription/ManageSubscription';
import Scripts from './scripts';
import WomensProductContainer from './containers/WomensProductContainer';
import CartRoute from './CartRoute';
import LandingPageContainer from './containers/LandingPageContainer';
import ContentContainer from './containers/ContentContainer';
import UniqueMaterialsContainer from './containers/UniqueMaterialsContainer';
import UniqueHandmadeContainer from './containers/UniqueHandmadeContainer';
import Our from './components/Content/Our';
import Spiff from './components/Content/Spiff';
import SpiffTerms from './components/Content/SpiffTerms';
import SameDay from './components/Content/SameDay';
import ContactUs from './components/Content/ContactUs';
import FinanceOption from './components/Content/FinanceOption';
import FinancingTerms from './components/Content/FinancingTerms';
import Learn from './components/Content/Learn';
import Customization from './components/Content/Customization';
import UniqueHandmade from './containers/UniqueHandmadeContainer/UniqueHandmade';
import RingAnatomy from './components/Content/RingAnatomy';
import Home from './components/Content/Home';
import RingSizing from './components/Content/RingSizing';
import ListPage from './components/Unique Materials';
import Engravings from './components/Content/Engravings';
import JckLanding from './components/Content/JckLanding';
import TermsConditions from './components/Content/TermsNConditions';
import HelzbergSizing from './components/Content/HelzbergSizing';
import AGS from './components/Content/AGS';
import { getCurrencyConversion } from './containers/AppContainer/actions';
import ProductDetail from './containers/ProductDetail';
import './assets/css/multiselect-dropdown-override.css';
import ManlyBandsLawsuit from './components/Content/ManlyBandsLawsuit';
import Fashion from './components/Content/Fashion';

class App extends Component {
    async componentWillMount() {
        await Scripts.registerScripts();
    }

    componentWillUnmount() {
        Scripts.unregisterScripts();
    }

    componentDidMount() {
        this.props.getCurrencyConversion();
    }

    render() {
        return (
            <ConnectedRouter history={history}>
                <Switch>
                    {/* Below are wordpress static site as react page */}
                    {config.allowCategories && <GuestRoute path="/" exact component={Home} />}
                    <GuestRoute exact component={RingAnatomy} path="/ring-ana" />
                    <GuestRoute exact component={RingAnatomy} path="/ring-anatomy" />
                    <GuestRoute exact component={ListPage} path="/unique-material" />
                    <GuestRoute exact component={Engravings} path="/engravings" />
                    <GuestRoute exact component={Learn} path="/learn" />
                    <GuestRoute exact component={FinanceOption} path="/finance-option" />
                    <GuestRoute exact component={FinancingTerms} path="/financing-terms-conditions" />
                    <GuestRoute exact component={ContactUs} path="/contact-us" />
                    <GuestRoute exact component={RingSizing} path="/accurate-ring-sizing" />
                    <GuestRoute exact component={JckLanding} path="/Jck" />
                    <GuestRoute exact component={Customization} path="/cus" />
                    <GuestRoute exact component={UniqueHandmade} path="/unique-handmade-rings" />
                    <GuestRoute exact component={SameDay} path="/same-day" />
                    <GuestRoute exact component={Our} path="/our" />
                    <GuestRoute exact component={Spiff} path="/spiff" />
                    <GuestRoute exact component={SpiffTerms} path="/spiff-terms" />
                    <GuestRoute exact component={TermsConditions} path="/lashbrook-terms-conditions" />
                    <GuestRoute
                        exact
                        component={ManlyBandsLawsuit}
                        displayHeader={false}
                        displayFooter={false}
                        path="/manlybandslawsuit"
                    />
                    <GuestRoute
                        exact
                        component={HelzbergSizing}
                        displayHeader={false}
                        displayFooter={false}
                        path="/helzbergsizing"
                    />
                    <GuestRoute
                        exact
                        component={AGS}
                        displayHeader={false}
                        displayFooter={false}
                        path="/ags"
                    />
                    {/* ENDS */}
                    {config.allowCategories && <GuestRoute exact path="/shop" component={RingCategoryListContainer} />}
                    {config.allowCategories &&
                        <GuestRoute
                            exact
                            path="/shop/product/rings/profiles/:profile/widths/:width/ring_materials/:ring_material"
                            type={1}
                            component={ProductDetail}
                        />}
                    {config.allowCategories &&
                        <GuestRoute
                            exact
                            path="/shop/product/women/rings/styles/:styles/ring_materials/:ring_materials/color_arrangements/:color_arrangements/stone_quantities/:stone_quantities/variants/:variants"
                            type={2}
                            component={ProductDetail}
                        />}
                    {config.allowCategories &&
                        <GuestRoute
                            exact
                            path="/shop/product/rings/:ring_type/ring_materials/:ring_materials"
                            type={3}
                            component={ProductDetail}
                        />}
                    {config.allowCategories &&
                        <GuestRoute
                            exact
                            path="/shop/product/static-product/profiles/:profile/widths/:width/ring_materials/:ring_material"
                            type={4}
                            component={ProductDetail}
                        />}
                    {config.allowCategories &&
                        <GuestRoute
                            path="/shop/static-product/profiles/:profile/widths/:width/ring_materials/:ring_material"
                            exact
                            type={4}
                            component={ProductDetail}
                        />
                    }
                    {config.allowCategories &&
                        <GuestRoute
                            exact
                            path="/shop/rings/profiles/:profile/widths/:width/ring_materials/:ring_materials"
                            type={1}
                            component={RingCustomizerContainer}
                            displayHeader={false}
                            displayFooter={false}
                        />}
                    {config.allowCategories &&
                        <GuestRoute
                            exact
                            path="/shop/women/rings/styles/:styles/ring_materials/:ring_materials/color_arrangements/:color_arrangements/stone_quantities/:stone_quantities/variants/:variants"
                            type={2}
                            component={RingCustomizerContainer}
                            displayHeader={false}
                            displayFooter={false}
                        />}
                    {config.allowCategories &&
                        <GuestRoute
                            exact
                            path="/shop/rings/:ring_type/ring_materials/:ring_materials"
                            type={3}
                            component={RingCustomizerContainer}
                            displayHeader={false}
                            displayFooter={false}
                        />}


                    {/* For shaneco and other Iframe embedded apps use old version of customizer */}
                    {!config.allowCategories && <GuestRoute exact component={ProductContainer} path="/shop/rings/profiles/:profile/widths/:width/ring_materials/:ring_material" />}
                    {!config.allowCategories && <GuestRoute exact component={WomensProductContainer} path="/shop/women/rings/styles/:styles/ring_materials/:ring_materials/color_arrangements/:color_arrangements/stone_quantities/:stone_quantities/variants/:variants" />}
                    {!config.allowCategories && <Redirect to={config.buildYourOwnProductUrl} />}
                    <GuestRoute path="/shop/emptyCart" exact component={CartContainer} />
                    <CartRoute path="/shop/cart/:cartId" exact component={CartContainer} />
                    <GuestRoute path="/shop/manage-financing" component={ManageSubscription} />
                    {/* <GuestRoute
                        path="/shop/static-product/profiles/:profile/widths/:width/ring_materials/:ring_material"
                        exact
                        component={StaticProductContainer}
                    /> */}

                    <GuestRoute path="/shop/orderConfirmation/:orderId" exact component={OrderStatusContainer} />
                    <GuestRoute path="/shop/customer" component={CustomerContainer} />
                    <GuestRoute path="/shop/customize-wedding-rings" component={LandingPageContainer} />
                    <GuestRoute path="/shop/content/fashion-coming-soon"  component={Fashion}/>
                    <GuestRoute path="/shop/content" component={ContentContainer} />
                    <GuestRoute path="/shop/unique-materials" component={UniqueMaterialsContainer} />
                    {config.allowCategories && (
                        <GuestRoute path="/shop/categories" exact component={RingCategoryListContainer} />
                    )}
                    {config.allowCategories && (
                        <GuestRoute path="/shop/categories/:category" exact component={CategoryContainer} />
                    )}
                    {config.allowSearch && <GuestRoute path="/shop/search" exact component={SearchContainer} />}
                    <GuestRoute
                        path="/shop/unique-handmade-rings"
                        exact
                        displayFooter={window.innerWidth > 768}
                        component={UniqueHandmadeContainer}
                    />
                    <GuestRoute exact path="*" component={NotFound} />
                </Switch>
            </ConnectedRouter>
        );
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    getCurrencyConversion,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
