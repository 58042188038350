import React, { useEffect } from 'react';
import { Container, Row, Button, Form, FormGroup, Label, Input, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import Loader from '../Shared/Loader';

import './styles.css';
import AuthManager from '../../utils/AuthManager';

const ConfirmPassword = ({ handleConfirmPassword, isFetching }) => {
    const { errors, handleSubmit, control, getValues } = useForm({});
    useEffect(() => {
        if (AuthManager.get()) {
            AuthManager.remove();
        }
    }, []);

    const submit = async data => {
        handleConfirmPassword({ data });
    };

    return (
        <Container className="confirm-password-container d-flex flex-column justify-content-center">
            <Row className="flex-column">
                <Col xs={12} md={6} lg={5} className="mx-auto d-flex flex-column align-items-center">
                    <h5 className="text-center">Reset your password</h5>
                    <Form className="mt-4 d-flex flex-column confirm-password-form" onSubmit={handleSubmit(submit)}>
                        <FormGroup>
                            <Label className="font-universe font-weight-bolder" for="password">
                                Password
                            </Label>
                            <Controller
                                name="password"
                                label="Password"
                                control={control}
                                rules={{
                                    required: 'You must specify a password',
                                }}
                                as={<Input name="password" type="password" id="password" />}
                            />
                            {errors.password && <p className="font-universe warning">{errors.password.message}</p>}
                        </FormGroup>
                        <FormGroup>
                            <Label className="font-universe font-weight-bolder" for="confirm-password">
                                Confirm Password
                            </Label>

                            <Controller
                                name="password_repeat"
                                label="Confirm Password"
                                control={control}
                                rules={{
                                    required: 'You must specify a password',
                                    validate: value => {
                                        if (value === getValues().password) {
                                            return true;
                                        } else {
                                            return 'The passwords do not match';
                                        }
                                    },
                                }}
                                as={
                                    <Input
                                        name="password_repeat"
                                        type="password"
                                        id="confirm-password"
                                    />
                                }
                            />
                            {errors.password_repeat && (
                                <p className="font-universe warning"> {errors.password_repeat.message}</p>
                            )}
                        </FormGroup>
                        <Button>Reset</Button>
                        <Loader timedOut={isFetching} />
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

ConfirmPassword.propTypes = {
    handleConfirmPassword: PropTypes.func,
    isFetching: PropTypes.bool,
};
export default ConfirmPassword;
