import React, { useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';

const ContactUs = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = '//js.hsforms.net/forms/shell.js';
        document.body.appendChild(script);

        script.addEventListener('load', () => {
            if (window.hbspt) {
                window.hbspt.forms.create({
                    region: 'na1',
                    portalId: '8816040',
                    formId: '95d1867b-f60d-4157-8ef9-98ae1e12b058',
                    target: '#hubspotForm',
                });
            }
        });
    }, []);

    return (
        <Container className="mt-5">
            <Row>
                <Col xs={12} className="text-center font-syncopate mb-4">
                    <h1 className="blue-font text-uppercase fs-3rem">Contact Us</h1>
                </Col>
                <Col
                    xs={12}
                    className="color-dark-grey font-universe d-flex justify-content-between text-center text-md-left flex-column flex-md-row fs-20 mt-5"
                >
                    <div>
                        <p className="font-syncopate mb-0">OUR ADDRESS</p>
                        <p>
                            131 East 13065 South Draper, <br /> UT 84020
                        </p>
                    </div>
                    <div>
                        <div className="mb-4">
                            <p className="font-syncopate mb-0">CUSTOMER CARE</p>
                            <p className="mb-0">Toll-Free: 888-252-7388</p>
                            <p>Local: 801-352-7388</p>
                        </div>
                        <div>
                            <p className="font-syncopate mb-0">HOURS</p>
                            <p>Monday – Friday 8am – 5pm MST</p>
                        </div>
                    </div>
                    <div>
                        <p className="font-syncopate mb-0">EMAIL ADDRESS</p>
                        <p>website@lashbrookdesigns.com</p>
                    </div>
                </Col>
                <Col xs={8} className="mx-auto my-5">
                    <hr className="content-hr-full" color="#dcc67d" />
                </Col>
                <Col xs={12} className="text-center font-syncopate mb-4">
                    <h2 className="blue-font text-uppercase fs-3rem">Send us a message</h2>
                    <div id="hubspotForm" className="w-75 mx-auto my-5 py-5 " />
                </Col>
            </Row>
        </Container>
    );
};
export default ContactUs;
