import React, { useEffect } from 'react';
import { Row, Col, Container } from 'reactstrap';
import Helmet from 'react-helmet';

const HelzbergSizing = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = '//js.hsforms.net/forms/embed/v2.js';
        document.body.appendChild(script);
        script.addEventListener('load', () => {
            if (window.hbspt) {
                window.hbspt.forms.create({
                    portalId: '8816040',
                    formId: 'e9f14003-6c2b-4744-9864-9f36dab8fbd2',
                    target: '#HelzbergForm',
                });
            }
        });
    }, []);
    return (
        <Container className="hb-container-wide">
            <Helmet>
                <link rel="canonical" href="https://www.lashbrookdesigns.com/helzbergsizing" />
                <title>Helzberg Sizing Confirmation Page</title>
                <meta name="description" content="Helzberg | Confirm your custom ring size with Lashbrook" />
                <meta name="keywords" content="jewelry, rings, wedding bands, engagement rings" />
            </Helmet>
            <Row>
                <Col xs={12} className="wide-header-helzberg text-center d-flex align-items-center flex-column">
                    <img className="helzberg-logo" src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/landingPages/helzberg-logo.png" />
                    <h1 className="font-syncopate hb-heading-font">CONFIRM</h1>
                    <h1 className="font-syncopate  text-uppercase mt-3 mb-3">Your Size</h1>
                    <img className="star-rule" src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/landingPages/hr-with-lights.png" />
                </Col>
            </Row>
            <Row className="form-section">
                <Col xs={12} className="text-center align-items-center form-section">
                    <p id="HelzbergForm" className="w-75 mx-auto" />
                </Col>
            </Row>
            <Row>
                <Col className="text-center mt-5 d-flex align-items-center flex-column">
                    <h1 className="font-syncopate text-uppercase hb-heading-font">What to do with the sizers?</h1>
                    <p className="font-universe hb-byline">The sizers are yours to keep or give to a friend. You are also welcome to mail them back to Lashbrook or recycle them - they are 100% aluminum.</p>
                    <p className="font-universe hb-byline">Still having trouble sizing? Reach out to us: <a className="hb-links" href="https://www.helzberg.com/contact-us.html" target="_blank" rel="noopener noreferrer">helzberg.com/contact-us</a></p>
                    <img className="star-rule" src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/landingPages/hr-with-lights.png" />
                    <img className="lashbrook-logo" src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/landingPages/Powered-by-Lashbrook-Logo.png" />
                </Col>
            </Row>
        </Container>
    );
};

export default HelzbergSizing;
