import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import queryString from 'qs';
import PageLimit from './PageLimit';

class TablePagination extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: props.currentPage,
            width: window.innerWidth,
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            this.checkPage();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions = () => {
        this.setState({ width: window.innerWidth });
    };

    handleClick = (e, index) => {
        e.preventDefault();
        this.setState({
            currentPage: index,
        });
        this.props.setPage(index);
    };
    formatDate(string) {
        return new Date(string).toLocaleDateString();
    }

    checkPage = () => {
        const { history, currentPage } = this.props;
        const { search } = history.location;
        const query = queryString.parse(search.slice(1));
        if (currentPage !== (query.page - 1)) {
            this.setState({ currentPage: (query.page - 1) });
        }
    }
    renderPageNumbers = () => {
        const { pageCount = 1 } = this.props;
        const { currentPage, width } = this.state;
        const pageNumbers = [];
        const isMobile = width < 768;

        let pageLimit = isMobile ? 5 : 10; // number of page links in pagination
        let start = 0; // starting page
        let end = pageLimit; // ending page

        if (pageCount <= pageLimit) {
            pageLimit = pageCount;
        }

        // increment start page when current page is greater than 5
        if (currentPage - 5 >= 0) {
            start = currentPage - 4;
        }

        // if reaching end of pagination stop increment
        if (start + pageLimit >= pageCount) {
            start = pageCount - pageLimit;
        }

        // increment end page when current + 5 exceeds page limit
        if (currentPage + 5 >= pageLimit) {
            end = currentPage + 6;
            pageLimit = end;
            if (pageCount <= pageLimit) {
                pageLimit = pageCount;
            }
        }

        [...Array(pageLimit)].map((page, i) => {
            if (i >= start && i < end + 1) {
                pageNumbers.push(
                    <PaginationItem active={i === currentPage} key={i}>
                        <PaginationLink onClick={e => this.handleClick(e, i)} href="#">
                            {i + 1}
                        </PaginationLink>
                    </PaginationItem>
                );
            }
        });

        return pageNumbers;
    };

    render() {
        const { pageCount = 1, pageSize, setPageSize, translatedSize } = this.props;
        const { currentPage } = this.state;

        return (
            <React.Fragment>
                <div className="d-flex align-items-center justify-content-between mt-2">
                    <Pagination className="portal-pagination my-0">
                        <PaginationItem disabled={currentPage < 1}>
                            <PaginationLink first href="#" onClick={e => this.handleClick(e, 0)}>
                                <i className="fa fa-angle-double-left" aria-hidden="true" />
                            </PaginationLink>
                        </PaginationItem>
                        <PaginationItem disabled={currentPage < 1}>
                            <PaginationLink onClick={e => this.handleClick(e, currentPage - 1)} previous href="#">
                                <i className="fa fa-angle-left" aria-hidden="true" />
                            </PaginationLink>
                        </PaginationItem>
                        {this.renderPageNumbers()}
                        <PaginationItem disabled={currentPage >= pageCount - 1}>
                            <PaginationLink onClick={e => this.handleClick(e, currentPage + 1)} next href="#">
                                <i className="fa fa-angle-right" aria-hidden="true" />
                            </PaginationLink>
                        </PaginationItem>
                        <PaginationItem disabled={currentPage >= pageCount - 1}>
                            <PaginationLink last href="#" onClick={e => this.handleClick(e, pageCount - 1)}>
                                <i className="fa fa-angle-double-right" aria-hidden="true" />
                            </PaginationLink>
                        </PaginationItem>
                    </Pagination>
                    <PageLimit pageSize={pageSize} setPageSize={setPageSize} translatedSize={translatedSize} />
                </div>
            </React.Fragment>
        );
    }
}

TablePagination.propTypes = {
    currentPage: PropTypes.number,
    setPage: PropTypes.func,
};

export default withRouter(TablePagination);
