import React from 'react';
import {  getSelectedOption } from './commonRingUtils';
import { getV1FinishItems } from './finishItems';
import { getOutsideFeatureItems } from './outsideFeatureItems';
import { getSecondaryOutsideFeatureItems } from './secondaryOutsideFeatureItems';
import { getShapeItems } from './shapeItems';
import SwatchFilter from '../components/SwatchFilter';
import { ITab, ITabs } from '../types/customizer';
import SizeFilter from '../components/SizeFilter';
import Summary from '../components/Summary';
import Data from '../components/Data';
import { getEngravingItems } from './engravingItems';
import BoxFilter from '../components/BoxFilter';
import { getCategorizedOptions } from './basic';

export const getV1PageData = (product: any, isDataTabAccess = false) => {
    product.categories = getCategorizedOptions('band');
    const items: ITabs = [
        {
            title: 'Category',
            eventKey: 'category',
            component: BoxFilter,
            isEnabled: true,
            content: {
                hasSwatch: true,
                fieldType: 'category',
                options: product.categories,
                selectedOption: getSelectedOption(product.categories),
            },
        },
        {
            title: 'Material',
            label: 'Ring Material',
            eventKey: 'material',
            component: SwatchFilter,
            isEnabled: product.ring_material?.length > 0,
            content: {
                // showDefaultTitle: false,
                // displayShowMoreOption: false,
                // meta: getFilterMetaData(product, 'ring_material'),
                // warning: getWarning(product, 'ring_material'),
                hasSwatch: true,
                fieldType: 'ring_materials',
                options: product.ring_material,
                selectedOption: getSelectedOption(product.ring_material),
            },
        },
        {
            title: 'Shape',
            eventKey: 'shape',
            items: getShapeItems(product),
            tabStyle: 'box',
        },
        {
            title: 'Finish',
            eventKey: 'finish',
            items: getV1FinishItems(product),
            tabStyle: 'box',
        },
        {
            title: 'Outside Feature',
            eventKey: 'outsideFeature_1',
            ...getOutsideFeatureItems(product),
            tabStyle: 'box',
        },
        {
            title: () => <>2<sup>nd</sup> Outside Feature</>,
            eventKey: 'outsideFeature_2',
            ...getSecondaryOutsideFeatureItems(product),
            tabStyle: 'box',
        },
        {
            title: 'Inside Feature',
            label: 'Inside Sleeve',
            eventKey: 'insideFeature',
            component: SwatchFilter,
            isEnabled: product.sleeve?.length > 0,
            content: {
                // showDefaultTitle: false,
                // displayShowMoreOption: false,
                // meta: getFilterMetaData(product, 'sleeve'),
                // warning: getWarning(product, 'sleeve'),
                hasSwatch: true,
                fieldType: 'sleeve',
                options: product.sleeve,
                selectedOption: getSelectedOption(product.sleeve),
            },
        },
        {
            title: 'Engraving',
            eventKey: 'engraving',
            // disclaimer: "\"The customer's engraving as they have typed it, or nothing if not added\""
            tabStyle: 'box',
            ...getEngravingItems(product, product.engraving_new)
        },
        {
            title: 'Size',
            label: 'Ring Size',
            eventKey: 'ringSize',
            component: SizeFilter,
            isEnabled: product.size?.length > 0,
            content: {
                // showDefaultTitle: true,
                // displayShowMoreOption: false,
                // meta: getFilterMetaData(product, 'size'),
                // warning: getWarning(product, 'size'),
                hasSwatch: false,
                fieldType: 'size',
                options: product.size || [],
                selectedOption: getSelectedOption(product.size),
            },
        },
        {
            title: 'Summary',
            eventKey: 'summary',
            component: Summary,
            isEnabled: true
        },
        {
            title: 'Data',
            eventKey: 'data',
            component: Data,
            isEnabled: isDataTabAccess,
        },
    ];

    const filtered = items.filter((tab: ITab) => tab.isEnabled || tab.items?.length);
    return filtered;
};
