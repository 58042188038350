import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form, FormGroup, Label, Container, Col, FormText } from 'reactstrap';
import { useForm } from 'react-hook-form';
import Loader from '../Shared/Loader';

const EditAddress = ({
    address,
    getOneAddress,
    updateAddress,
    deleteAddress,
    getCountries,
    getStates,
    countries,
    states,
    isFetching,
    resetSelectedAddress,
    resetStates,
}) => {
    const { id } = useParams();
    const history = useHistory();
    const { register, handleSubmit, getValues, triggerValidation, errors } = useForm();
    const [billing, setBilling] = useState(true);
    const [shipping, setShipping] = useState(true);
    let currentCountry;

    useEffect(() => {
        async function fetchData() {
            const response = await getOneAddress(id);
            currentCountry = response.payload.data.country_code;
        }
        async function fetchCountries() {
            await getCountries();
        }

        fetchData()
            .then(() =>
                fetchCountries()
                    .then(() => {
                        getStates(currentCountry);
                    })
                    .catch(() => {
                        toast.error('Unable to get States', { className: 'toast-error' });
                    })
            )
            .catch(() => toast.error('Unable to get Countries', { className: 'toast-error' }));

        return () => {
            resetStates();
            resetSelectedAddress();
        };
    }, []);

    const handleAddressChange = event => {
        if (event.target.value === 'billing') {
            setBilling(!billing);
        }

        if (event.target.value === 'shipping') {
            setShipping(!shipping);
        }
    };

    const loadStates = async () => {
        const selectedCountry = getValues().country_code;
        try {
            await getStates(selectedCountry);
        } catch (e) {
            toast.error('Unable to get States', { className: 'toast-error' });
        }
    };

    const handleDelete = async () => {
        try {
            await deleteAddress(id);
            toast.success('Address Deleted', { className: 'toast-success' });
            history.push('/shop/customer/address');
        } catch (e) {
            toast.error('Unable to delete Address', { className: 'toast-error' });
        }
    };

    const handleSave = async data => {
        const address_type =
            data.address_type.includes('billing') && data.address_type.includes('shipping')
                ? 'both'
                : data.address_type[0];
        const editedData = {
            ...data,
            address_type,
        };
        triggerValidation({ name: 'address_type' });
        try {
            await updateAddress({ data: editedData, id });
            history.push('/shop/customer/address');
            toast.success('Address Updated', { className: 'toast-success' });
        } catch (e) {
            toast.error('Unable to update Address', { className: 'toast-error' });
        }
    };

    if (!address || !address.data || !countries || !countries.data || !states || !states.data) return null;

    const { data } = address;
    return (
        <Form className="font-universe" onSubmit={handleSubmit(handleSave)}>
            {address &&
                address.data &&
                countries &&
                countries.data &&
                states &&
                states.data && (
                <Container className="d-flex flex-wrap justify-content-between">
                    <Col lg={5}>
                        <FormGroup>
                            <h3>Contact Information</h3>
                            <Label className="required" for="first_name">
                                    First Name
                            </Label>
                            <input
                                type="text"
                                name="first_name"
                                id="first_name"
                                placeholder="First Name"
                                defaultValue={data.first_name}
                                ref={register}
                                required
                            />
                            <Label className="required" for="last_name">
                                    Last Name
                            </Label>
                            <input
                                type="text"
                                name="last_name"
                                id="last_name"
                                placeholder="Last Name"
                                defaultValue={data.last_name}
                                ref={register}
                                required
                            />
                            <Label for="company">Company</Label>
                            <input
                                type="text"
                                name="company"
                                id="company"
                                placeholder="Company"
                                defaultValue={data.company}
                                ref={register}
                            />
                            <Label className="required" for="phone_number">
                                    Phone Number
                            </Label>
                            <input
                                type="text"
                                name="phone_number"
                                id="phone_number"
                                placeholder="Phone Number"
                                defaultValue={data.phone_number}
                                ref={register}
                                pattern="[0-9.]+"
                                required
                            />
                        </FormGroup>
                    </Col>
                    <Col lg={5}>
                        <FormGroup>
                            <h3>Address</h3>
                            <Label className="required" for="street_address1">
                                    Street Address
                            </Label>
                            <input
                                type="text"
                                name="street_address1"
                                id="street_address1"
                                defaultValue={data.street_address1}
                                ref={register}
                                required
                            />
                            <input
                                type="text"
                                name="street_address2"
                                id="street_address2"
                                defaultValue={data.street_address2 || ''}
                                ref={register}
                            />
                            <Label className="required" for="city">
                                    City
                            </Label>
                            <input
                                type="text"
                                name="city"
                                id="city"
                                placeholder="City"
                                defaultValue={data.city}
                                ref={register}
                                required
                            />
                            <Label className="required" for="zip">
                                    Zip/Postal Code
                            </Label>
                            <input
                                type="text"
                                name="zip"
                                id="zip"
                                placeholder="Zip/Postal Code"
                                defaultValue={data.zip}
                                ref={register}
                                required
                                pattern="^[a-zA-Z0-9]{1,10}$"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label className="required" for="country_code">
                                    Country:{' '}
                            </Label>
                            <select
                                name="country_code"
                                id="country_code"
                                placeholder="Country"
                                ref={register}
                                onChange={loadStates}
                                defaultValue={data.country_code}
                                required
                            >
                                {countries.data.map(country => (
                                    <option key={country.name} value={country.code}>
                                        {country.name}
                                    </option>
                                ))}
                            </select>
                        </FormGroup>
                        <FormGroup>
                            <Label className="required" for="state">
                                    State:{' '}
                            </Label>
                            <select
                                name="state"
                                id="state"
                                placeholder="State"
                                ref={register}
                                defaultValue={data.state}
                                required
                            >
                                {states.data.map(state => (
                                    <option key={state.name} value={state.name}>
                                        {state.name}
                                    </option>
                                ))}
                            </select>
                        </FormGroup>
                        <FormGroup>
                            <Label className="required mt-3" for="address_type">
                                    Address Type:{' '}
                            </Label>
                            <FormText color="muted">
                                    This will replace any existing shipping or billing address.
                            </FormText>
                            <Label className="mt-3 mr-1" for="address_type_billing">
                                    Billing Address
                            </Label>
                            <input
                                type="checkbox"
                                name="address_type"
                                id="address_type_billing"
                                defaultChecked={
                                    address.data.address_type === 'billing' || address.data.address_type === 'both'
                                }
                                ref={register({ required: true })}
                                value="billing"
                                onChange={handleAddressChange}
                            />
                            <Label className="mt-3 ml-3 mr-1" for="address_type_shipping">
                                    Shipping Address
                            </Label>
                            <input
                                type="checkbox"
                                name="address_type"
                                id="address_type_shipping"
                                defaultChecked={
                                    address.data.address_type === 'shipping' || address.data.address_type === 'both'
                                }
                                ref={register({ required: true })}
                                value="shipping"
                                onChange={handleAddressChange}
                            />
                        </FormGroup>
                        <div className="submitError">
                            {errors && errors.address_type && 'Please select an address'}
                        </div>
                    </Col>
                    <Col lg={6}>
                        <FormGroup className="d-flex align-items-center">
                            <Button type="submit" className="mr-3">
                                    Save
                            </Button>
                            <Button className="mr-3" onClick={handleDelete}>
                                    Delete Address
                            </Button>
                            <Link to="/shop/customer/address">Go Back</Link>
                        </FormGroup>
                    </Col>
                </Container>
            )}
            <Loader timedOut={isFetching} />
        </Form>
    );
};

EditAddress.propTypes = {
    address: PropTypes.object,
    getOneAddress: PropTypes.func,
    updateAddress: PropTypes.func,
    deleteAddress: PropTypes.func,
    getCountries: PropTypes.func,
    getStates: PropTypes.func,
    countries: PropTypes.object,
    states: PropTypes.object,
    isFetching: PropTypes.bool,
    resetSelectedAddress: PropTypes.func,
    resetStates: PropTypes.func,
};
export default EditAddress;
