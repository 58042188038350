import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardText, CardTitle, Col, Container, Row } from 'reactstrap';
import Loader from '../Shared/Loader';

const Account = ({ isFetching, getAllAddresses, addresses, getAccountInfo, accountInfo }) => {
    useEffect(() => {
        getAccountInfo();
        getAllAddresses();
    }, []);

    const renderAddresses = () =>
        addresses.data.filter((address, i) => i < 2).map(address => (
            <Col lg={6} key={address.id} className="mt-2 font-universe">
                <Card>
                    <CardBody className="text-capitalize">
                        <CardTitle>{`${address.first_name} ${address.last_name}`}</CardTitle>
                        <CardText className="mb-0">{address.street_address1}</CardText>
                        <CardText className="mb-0">{address.street_address2}</CardText>
                        <CardText className="mb-0">{address.city}</CardText>
                        <CardText className="mb-0">{address.state}</CardText>
                        <CardText className="mb-0">{address.zip}</CardText>
                        <CardText className="mb-0">
                            <a href={`tel:${address.phone_number}`}>{address.phone_number}</a>
                        </CardText>
                    </CardBody>
                </Card>
            </Col>
        ));

    if (!accountInfo) return null;

    return (
        <Container className="mx-xl-1">
            <h5>Account Information</h5>
            {accountInfo && (
                <Row className="my-4 dashboard-account">
                    <Col xs={12}>
                        <h6>Contact Information</h6>
                    </Col>
                    {accountInfo &&
                        accountInfo.first_name &&
                        accountInfo.last_name &&
                        accountInfo.email && (
                        <Col className="font-universe fs-20" xs={12}>
                            <div>
                                {`${accountInfo.store?.store_name} - ${accountInfo.first_name} ${
                                    accountInfo.last_name
                                }`}
                            </div>
                            <div>{accountInfo.email}</div>
                            <Link className="d-inline-block fs-16" to="/shop/customer/account/me/edit">
                                    Edit
                            </Link>
                        </Col>
                    )}
                </Row>
            )}

            {addresses &&
                addresses.data && (
                <Row className="my-4">
                    <Col xs={12}>
                        <h6>Address Book</h6>
                    </Col>
                    {addresses && addresses.data && addresses.data.length > 0 ? (
                        renderAddresses()
                    ) : (
                        <Col xs={12} className={`${isFetching && 'd-none'} font-universe fs-20`}>
                            <div>No addresses added yet</div>
                        </Col>
                    )}
                    {addresses &&
                            addresses.data &&
                            addresses.data.length > 1 && (
                            <Link
                            className="d-inline-block fs-16 font-universe mx-auto mt-2"
                            to="/shop/customer/address"
                        >
                                    View More
                        </Link>
                    )}
                </Row>
            )}

            <Loader timedOut={isFetching} />
        </Container>
    );
};
Account.propTypes = {
    getAllAddresses: PropTypes.func,
    isFetching: PropTypes.bool,
    addresses: PropTypes.object,
    getAccountInfo: PropTypes.func,
    accountInfo: PropTypes.object,
};

export default Account;
