import { CALL_API, METHODS } from '../../middleware';
import URLs from '../../utils/url';
import {
    EMAIL_PORTAL,
    GET_CURRENT_PLAN_DETAILS,
    GET_BILLING_HISTORY,
    GET_PAYMENT_METHODS,
    ADD_PAYMENT_METHODS,
    DELETE_PAYMENT_METHOD,
    MAKE_DEFAULT_PAYMENT_METHOD,
    GET_CUSTOMER_DETAILS,
} from './constants';

const emailPortal = ({ email }) => {
    return {
        [CALL_API]: {
            requestConfig: {
                baseUrl: URLs.apiBaseUrl,
                useAuth: true,
                url: URLs.customerPortal,
                method: METHODS.POST,
                data: { email },
            },
            types: EMAIL_PORTAL,
        },
    };
};

const getCurrentPlan = ({ token }) => {
    return {
        [CALL_API]: {
            requestConfig: {
                baseUrl: URLs.apiBaseUrl,
                useAuth: true,
                url: `${URLs.currentPlan}?token=${token}`,
                method: METHODS.GET,
            },
            types: GET_CURRENT_PLAN_DETAILS,
        },
    };
};

const getBillingHistory = ({ token }) => {
    return {
        [CALL_API]: {
            requestConfig: {
                baseUrl: URLs.apiBaseUrl,
                useAuth: true,
                url: `${URLs.billingHistory}?token=${token}`,
                method: METHODS.GET,
            },
            types: GET_BILLING_HISTORY,
        },
    };
};

const getPaymentMethods = ({ token }) => {
    return {
        [CALL_API]: {
            requestConfig: {
                baseUrl: URLs.apiBaseUrl,
                useAuth: true,
                url: `${URLs.paymentMethodPortal}?token=${token}`,
                method: METHODS.GET,
            },
            types: GET_PAYMENT_METHODS,
        },
    };
};

const addPaymentMethod = ({ token, id, isDefault }) => {
    return {
        [CALL_API]: {
            requestConfig: {
                baseUrl: URLs.apiBaseUrl,
                useAuth: true,
                url: `${URLs.paymentMethodPortal}?token=${token}&default=${isDefault}`,
                method: METHODS.POST,
                data: { ...id },
            },
            types: ADD_PAYMENT_METHODS,
        },
    };
};

const deletePaymentMethod = ({ token, id }) => {
    return {
        [CALL_API]: {
            requestConfig: {
                baseUrl: URLs.apiBaseUrl,
                useAuth: true,
                url: `${URLs.paymentMethodPortal}?token=${token}&id=${id}`,
                method: METHODS.DELETE,
            },
            types: DELETE_PAYMENT_METHOD,
        },
    };
};

const makeDefaultMethod = ({ token, id }) => {
    return {
        [CALL_API]: {
            requestConfig: {
                baseUrl: URLs.apiBaseUrl,
                useAuth: true,
                url: `${URLs.paymentMethodPortal}?token=${token}&id=${id}&default=1`,
                method: METHODS.POST,
            },
            types: MAKE_DEFAULT_PAYMENT_METHOD,
        },
    };
};

const getCustomerDetails = ({ token }) => {
    return {
        [CALL_API]: {
            requestConfig: {
                baseUrl: URLs.apiBaseUrl,
                useAuth: true,
                url: `${URLs.customerDetails}?token=${token}`,
                method: METHODS.GET,
            },
            types: GET_CUSTOMER_DETAILS,
        },
    };
};


export {
    emailPortal,
    getCurrentPlan,
    getBillingHistory,
    getPaymentMethods,
    addPaymentMethod,
    deletePaymentMethod,
    makeDefaultMethod,
    getCustomerDetails,
};
