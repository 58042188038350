import cookies from '../utils/cookies';
import AuthManager from './AuthManager';
import LocalStorage from './LocalStorage';
export default class PricingTypeManager {
    static COOKIE_NAME = 'ptpe';
    static PRICE_TOGGLE_OVERRIDE = 'priceToggleOverride';

    // gets the current price type setting based on price toggle allowance flags.
    static get() {
        const priceType = cookies.get(this.COOKIE_NAME) || 'Retail';

        const allowPriceToggleWhenAuthorized = AuthManager.allowPriceToggle();
        const alwaysAllowPriceToggle = this.allowPriceToggleOverride();

        // determine if changing the price type is permitted.
        const finalPriceType = allowPriceToggleWhenAuthorized || alwaysAllowPriceToggle ? priceType : 'Retail';
        return finalPriceType;
    }

    // returns a value indicating whether or not the price toggle override is set.
    // if this is not set, relies on user account settings in AuthManager to determine whether
    // pricing may be toggled.
    static allowPriceToggleOverride() {
        return !!LocalStorage.get(this.PRICE_TOGGLE_OVERRIDE);
    }

    // sets the pricing type. Optionally specifies if this should disregard authorization settings.
    static set(pricingType, allowWithoutAuth) {
        if (allowWithoutAuth) LocalStorage.add(this.PRICE_TOGGLE_OVERRIDE, true);
        else LocalStorage.remove(this.PRICE_TOGGLE_OVERRIDE);
        return cookies.set(this.COOKIE_NAME, pricingType);
    }

    static remove() {
        LocalStorage.remove(this.PRICE_TOGGLE_OVERRIDE);
        cookies.remove(this.COOKIE_NAME_NOAUTH);
        return cookies.remove(this.COOKIE_NAME);
    }
}
