import cookies from '../utils/cookies';

export default class CurrencyManager {
    static COOKIE_NAME = 'currency-code';
    static get() {
        return cookies.get(this.COOKIE_NAME);
    }

    static set(currencyCode) {
        return cookies.set(this.COOKIE_NAME, currencyCode);
    }

    static remove() {
        return cookies.remove(this.COOKIE_NAME);
    }
}
