import React from 'react';
import CollapsibleCard from '../Shared/CollapsibleCard';
import { Table } from 'reactstrap';
import { formatCurrency, formatNumber } from '../../utils/currencyFormatter';

const timePeriods = [
    'last30Days',
    'last60Days',
    'last90Days',
    'last6Months',
    'last12Months',
    'last24Months'
];

const displayNames = {
    last30Days: 'Last 30 days',
    last60Days: 'Last 60 days',
    last90Days: 'Last 90 days',
    last6Months: 'Last 6 months',
    last12Months: 'Last 12 months',
    last24Months: 'Last 24 months'
};

function StockSalesSummary({ data = [] }) {
    return (
        <CollapsibleCard title="Stock Sales Summary">
            <div className="stock-sales-summary">
                <div className="table-responsive">
                    <Table hover>
                        <thead>
                            <tr>
                                <th className="font-weight-bold font-open-sans-condensed">Rep</th>
                                {timePeriods.map(period => (
                                    <th key={period} className="font-weight-bold font-open-sans-condensed">
                                        {displayNames[period]}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {data.length > 0 ? (
                                data.map((rep, index) => (
                                    <tr key={index}>
                                        <td className="font-16 font-weight-bold font-universe">{rep?.rep}</td>
                                        {timePeriods.map(period => (
                                            <td key={`${index}-${period}`} className="font-16 font-open-sans-condensed text-right">
                                                {formatCurrency(rep?.[period]?.amount || 0)}
                                                <br />
                                                <small>({formatNumber(rep?.[period]?.count || 0)} sales)</small>
                                            </td>
                                        ))}
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={timePeriods.length + 1} className="text-center font-open-sans-condensed">
                                        No Data
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
            </div>
        </CollapsibleCard>
    );
}

export default StockSalesSummary;