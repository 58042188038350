import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import FontAwesome from 'react-fontawesome';
import { connect } from 'react-redux';
import If from '../If';
import URLs from '../../../utils/url';
import AuthManager from '../../../utils/AuthManager';
import PricingTypeManager from '../../../utils/PricingTypeManager';
import CartManager from '../../../utils/CartManager';
import PriceDropdown from './PriceDropdown';
import Collapsible from './Collapsible';
import { learnItems, company } from './constants';
import logoBlue from '../../../assets/images/LB_Logoblue.png';
import './styles.css';
import CurrencyDropDown from './CurrencyDropDown';
import { setCurrentCurrency } from '../../../containers/AppContainer/actions';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSubHeader: false,
            isTop: true,
            width: window.innerWidth,
            searchQuery: '',
            mobileMenu: false,
            mobileSearch: false,
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        document.addEventListener('scroll', this.updateTop);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
        document.removeEventListener('scroll', this.updateTop);
    }

    updateDimensions = () => {
        this.setState({ width: window.innerWidth });
    };

    handleSearchChange = e => this.setState({ searchQuery: e.target.value });

    handleSearch = e => {
        e.preventDefault();
        this.setState({ mobileMenu: false });
        this.props.history.push(`/shop/search?q=${this.state.searchQuery}`);
    };

    updateTop = () => {
        const isTop = window.scrollY < 50;
        if (isTop !== this.state.isTop) {
            this.setState({ isTop });
        }
    };

    updatePriceType = (selectedPriceType = 'Retail') => {
        PricingTypeManager.set(selectedPriceType);
        window.location.reload();
        /*
        if (this.props.history.location.pathname.startsWith('/shop/women')) {
            this.props.getWomensProductDetail(this.props.womensProduct.ring_options_url);
        } else if (this.props.history.location.pathname.startsWith('/shop/rings')) {
            this.props.getProductDetail(this.props.product.ring_options_url);
        } else if (this.props.history.location.pathname.startsWith('/shop/static')) {
            this.props.getStaticProductDetail(this.props.staticProduct.ring_options_url, selectedPriceType);
        } else if (this.props.history.location.pathname.startsWith('/shop/categories')) {
            window.location.reload();
        } else if (this.props.history.location.pathname.startsWith('/shop/product')) {
            this.props.getRingCustomizerDetail(this.props.ringCustomizerProduct.ring_options_url);
        }
        */
    };

    renderLearn = () => (
        <ul
            className="sub-menu menu-depth-1 learn-menu sub-menu-item"
            style={{ zIndex: 10 }}
            onClick={this.state.width <= 1150 ? e => this.toggleMobileMenu('', e) : null}
            role="presentation"
        >
            <a href="/learn" className="menu-link main-menu-link collections-menu-link mobile-only-link mb-0">
                Learn Center
            </a>
            {learnItems.map((item, id) => {
                const subMenu = item.subMenu && (
                    <ul className="sub-menu menu-even sub-sub-menu menu-depth-2 unique-material-menu">
                        {item.subMenu.map((subItem, idx) => (
                            <li
                                key={idx}
                                className="sub-menu-item sub-sub-menu-item menu-item-even menu-item-depth-2 menu-item menu-item-type-post_type menu-item-object-page"
                            >
                                <Link to={subItem.link} className="menu-link sub-menu-link xxxx">
                                    {subItem.material}
                                </Link>
                            </li>
                        ))}
                    </ul>
                );
                return item.subMenu ? (
                    <Collapsible
                        key={id}
                        styles="sub-menu-item  menu-item-odd menu-item-depth-1 menu-item menu-item-type-post_type menu-item-object-page"
                        alwaysShow
                        isMobile={this.state.width <= 1150}
                        tab={
                            this.state.width <= 1150 ? (
                                <span className="menu-link sub-menu-link">{item.tab}</span>
                            ) : (
                                <Link to={item.link} className="menu-link sub-menu-link">
                                    {item.tab}
                                </Link>
                            )
                        }
                        isDropdown
                    >
                        {subMenu}
                    </Collapsible>
                ) : (
                    <li
                        key={id}
                        className="sub-menu-item  menu-item-odd menu-item-depth-1 menu-item menu-item-type-post_type menu-item-object-page"
                    >
                        <Link to={item.link} className="menu-link sub-menu-link">
                            {item.tab}
                        </Link>
                    </li>
                );
            })}
        </ul>
    );

    renderCollections = () => (
        <ul
            className="sub-menu menu-depth-1"
            style={{ zIndex: 10 }}
            onClick={this.state.width <= 1150 ? e => this.toggleMobileMenu('', e) : null}
        >
            <li className="sub-menu-item menu-item-depth-1 menu-item">
                <Link to="/shop/categories/all-rings" className="menu-link collections-menu-link">
                    Men's
                </Link>
                <ul className="sub-menu menu-depth-2 collections-sub-menu" style={{ zIndex: 10 }}>
                    {this.props.categoryList.filter(category => category.section === 'mens').map((category, i) => (
                        <li key={i} className="sub-menu-item menu-item">
                            <Link to={`/shop/categories/${category.category_sku}`} className="menu-link">
                                {category.name}
                            </Link>
                        </li>
                    ))}
                </ul>
            </li>
            <li className="sub-menu-item menu-item-depth-1 menu-item">
                <Link to="/shop/categories/womens" className="menu-link collections-menu-link collections-menu-link-2">
                    Women's
                </Link>
                <ul className="sub-menu menu-depth-2 collections-sub-menu" style={{ zIndex: 10 }}>
                    {this.props.categoryList
                        .filter(category => category.section === 'womens' && category.name !== "Women's")
                        .map((category, i) => (
                            <li key={i} className="sub-menu-item menu-item">
                                <Link to={`/shop/categories/${category.category_sku}`} className="menu-link">
                                    {category.name}
                                </Link>
                            </li>
                        ))}
                </ul>
            </li>
        </ul>
    );

    renderCompany = () => (
        <ul
            className="sub-menu menu-depth-1 sub-menu-item company-menu"
            style={{ zIndex: 10 }}
            onClick={this.state.width <= 1150 ? e => this.toggleMobileMenu('', e) : null}
        >
            <Link to="/shop/content/company/" className="menu-link collections-menu-link mobile-only-link mb-0">
                Company
            </Link>
            {company.map((item, i) => (
                <li className="sub-menu-item menu-item-depth-1 menu-item" key={i}>
                    <Link to={item.link} className="menu-link menu-link-company">
                        {item.tab}
                    </Link>
                </li>
            ))}
            <li className="sub-menu-item menu-item-depth-1 menu-item" key="careers">
                <a
                    target="_blank"
                    href="https://recruiting.paylocity.com/recruiting/jobs/All/c00f88f8-433a-4d37-b159-805946e4ac27/DIAMONDS-DIRECT-LC"
                    className="menu-link menu-link-company"
                >
                    Careers
                </a>
            </li>
        </ul>
    );

    toggleMobileMenu = (state, e) => {
        if (typeof state === 'boolean') {
            this.setState({ mobileMenu: state });
        } else if (e.target.tagName === 'A') {
            this.setState({ mobileMenu: false });
        }
    };

    toggleMobileSearch = () => {
        if (!this.state.mobileSearch) {
            document.addEventListener('click', this.clickOutsideSearch, false);
        } else {
            document.removeEventListener('click', this.clickOutsideSearch, false);
        }

        this.setState(prevState => ({
            mobileSearch: !prevState.mobileSearch,
        }));
    };

    clickOutsideSearch = e => {
        if (this.search.contains(e.target)) {
            return;
        }
        this.toggleMobileSearch();
    };

    getAddedProductLink = () => {
        const url = CartManager.getCartLink();
        this.props.history.push(url);
    };

    // calculate height for overlay based on number of categories
    getCategoryOverlayHeight = () => {
        const menCategories = Math.ceil(
            this.props.categoryList.filter(category => category.section === 'mens').length / 6
        );
        const womenCategories = Math.ceil(
            this.props.categoryList.filter(category => category.section === 'womens').length / 6
        );
        const totalRows = menCategories + womenCategories;
        return totalRows > 3 ? 110 + (totalRows - 3) * 30 : 110;
    };

    render() {
        const { mobileMenu, mobileSearch, width } = this.state;
        const { buildYourOwnProductUrl, history: { location }, currentCurrencyCode } = this.props;
        const allowPriceToggle = AuthManager.allowPriceToggle();
        const selectedPriceType = PricingTypeManager.get();
        const dynamicOverlay = this.getCategoryOverlayHeight();

        return (
            <header
                className={
                    this.state.isTop && location.pathname !== '/' ? 'header no-print' : 'header dark-header no-print'
                }
                style={this.state.isTop && location.pathname === '/' ? { background: 'transparent' } : {}}
            >
                <div className="wrapper justify-content-between d-flex">
                    <a href="/" className="header-logo">
                        <If condition={this.state.isTop && location.pathname !== '/'}>
                            <img
                                className="blue"
                                alt="Lashbrook Logo"
                                src={logoBlue}
                            />
                        </If>
                        <If condition={!this.state.isTop || location.pathname === '/'}>
                            <img
                                className="gold"
                                alt="Lashbrook Logo"
                                src="https://wpassets.lashbrookdesigns.com/wp-content/uploads/2019/09/17025312/LB_Logogold.png"
                            />
                        </If>
                    </a>
                    <nav className={mobileMenu ? 'd-block no-print' : 'no-print'}>
                        <div className="overlay-close" onClick={() => this.toggleMobileMenu(false)} />
                        <ul className="menu-link-list">
                            <Collapsible
                                styles="main-menu-item menu-item-depth-0 menu-item has-sub-menu"
                                tab={
                                    width <= 1150 ? (
                                        <span className="menu-link">Collections</span>
                                    ) : (
                                        <Link to="/shop/categories" className="menu-link">
                                            Collections
                                        </Link>
                                    )
                                }
                                isDropdown
                                isMobile={width <= 1150}
                                dynamicHeight={dynamicOverlay}
                            >
                                {this.renderCollections()}
                            </Collapsible>
                            <Collapsible
                                styles="main-menu-item menu-item-depth-0 menu-item has-sub-menu"
                                tab={
                                    width <= 1150 ? (
                                        <span className="menu-link">Learn</span>
                                    ) : (
                                        <Link to="/learn" className="menu-link main-menu-link">
                                            Learn
                                        </Link>
                                    )
                                }
                                isDropdown
                                isMobile={width <= 1150}
                            >
                                {this.renderLearn()}
                            </Collapsible>
                            <If condition={!(AuthManager.get() && AuthManager.getUsername())}>
                                <li className="main-menu-item menu-item-depth-0 menu-item">
                                    <a href="/shop/content/where-to-buy/" className="menu-link">
                                        Stores
                                    </a>
                                </li>
                            </If>
                            <Collapsible
                                id="nav-menu-item-27"
                                styles="main-menu-item menu-item-depth-0 menu-item has-sub-menu"
                                tab={
                                    width <= 1150 ? (
                                        <span className="menu-link">Company</span>
                                    ) : (
                                        <Link to="/shop/content/company/" className="menu-link">
                                            Company
                                        </Link>
                                    )
                                }
                                isDropdown
                                isMobile={width <= 1150}
                            >
                                {this.renderCompany()}
                            </Collapsible>
                            <li className="d-block d-sm-none">
                                <Link to="/shop/customer/account">My Account</Link>
                            </li>
                        </ul>
                        <div className="build-my-ring-wrapper">
                            <Link
                                to={buildYourOwnProductUrl}
                                target="_blank"
                                className="btn btn-sm btn-primary btn-customize btn-header"
                            >
                                Build Your Own
                            </Link>
                        </div>
                        <If condition={AuthManager.get() && AuthManager.getUsername() && width <= 1150}>
                            <div className="font-universe text-white text-center">
                                {`Hello, ${AuthManager.getUsername()}`}
                            </div>
                        </If>
                        <If
                            condition={
                                selectedPriceType &&
                                allowPriceToggle &&
                                (this.props.history.location.pathname.startsWith('/product') ||
                                    this.props.history.location.pathname.startsWith('/rings') ||
                                    this.props.history.location.pathname.startsWith('/categories/') ||
                                    this.props.history.location.pathname.startsWith('/static-product') ||
                                    this.props.history.location.pathname.startsWith('/women'))
                            }
                        >
                            <div className="price-dropdown-wrapper visible-mobile">
                                <PriceDropdown
                                    updatePriceType={this.updatePriceType}
                                    isTop={this.state.isTop}
                                    selectedPriceType={selectedPriceType}
                                />
                            </div>
                        </If>
                    </nav>
                    <div className="social-account">
                        <div className="tablet-build-my-ring mx-2">
                            <Link
                                to={buildYourOwnProductUrl}
                                className="btn btn-sm btn-primary btn-customize btn-header"
                            >
                                Build Your Own
                            </Link>
                        </div>
                        <div className="price-dropdown-wrapper mx-2">
                            <CurrencyDropDown
                                updateCurrency={this.props.setCurrentCurrency}
                                isTop={this.state.isTop}
                                selected={currentCurrencyCode}
                            />
                        </div>
                        <If
                            condition={
                                selectedPriceType &&
                                allowPriceToggle &&
                                (this.props.history.location.pathname.startsWith('/shop/product') ||
                                    this.props.history.location.pathname.startsWith('/shop/rings') ||
                                    this.props.history.location.pathname.startsWith('/shop/categories/') ||
                                    this.props.history.location.pathname.startsWith('/shop/static-product') ||
                                    this.props.history.location.pathname.startsWith('/shop/women'))
                            }
                        >
                            <div className="price-dropdown-wrapper mx-2 hide-mobile">
                                <PriceDropdown
                                    updatePriceType={this.updatePriceType}
                                    isTop={this.state.isTop}
                                    selectedPriceType={selectedPriceType}
                                />
                            </div>
                        </If>
                        <If condition={AuthManager.get() && AuthManager.getUsername()}>
                            <div
                                className={`${
                                    !this.state.isTop ? 'text-white' : 'user-label-dark'
                                } hide-mobile font-universe`}
                            >
                                {`Hello, ${AuthManager.getUsername()}`}
                            </div>
                        </If>
                        <i
                            role="presentation"
                            className="fa fa-search mobile-search mx-2"
                            onClick={this.toggleMobileSearch}
                        />
                        <Link to="/shop/customer/account" className="my-account mx-2" aria-label="Account">
                            <FontAwesome name="user" />
                        </Link>
                        <Link to="/shop/categories/favorite" className="favorites mx-2" aria-label="Favorites">
                            <FontAwesome name="heart" />
                        </Link>
                        <FontAwesome name="shopping-cart" className="mx-2" onClick={() => this.getAddedProductLink()} />
                        <i
                            role="presentation"
                            className="fa fa-bars mobileToggle mx-2"
                            onClick={() => this.toggleMobileMenu(true)}
                        />
                        {/* Desktop Search box */}

                        <div
                            className={mobileSearch ? 'header-search' : 'header-search toggle-mobile'}
                            ref={search => {
                                this.search = search;
                            }}
                        >
                            <form id="search_mini_form2">
                                <div className="field search">
                                    <div className="control">
                                        <input
                                            id="search2"
                                            name="q"
                                            value={this.state.searchQuery}
                                            placeholder="Search..."
                                            className="input-text"
                                            onChange={this.handleSearchChange}
                                            maxLength="128"
                                            type="text"
                                        />
                                        <div id="search_autocomplete" className="search-autocomplete" />
                                    </div>
                                </div>
                                <button type="submit" onClick={this.handleSearch} title="Search">
                                    <i className="fa fa-search mx-2" />
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

Header.propTypes = {
    history: PropTypes.object,
    getProductDetail: PropTypes.func.isRequired,
    getRingCustomizerDetail: PropTypes.func.isRequired,
    getWomensProductDetail: PropTypes.func.isRequired,
    getStaticProductDetail: PropTypes.func.isRequired,
    categoryList: PropTypes.array,
    product: PropTypes.object,
    ringCustomizerProduct: PropTypes.object,
    accountInfo: PropTypes.object,
    currentCurrencyCode: PropTypes.string,
    setCurrentCurrency: PropTypes.func,
    buildYourOwnProductUrl: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
    currentCurrencyCode: state.global.currentCurrencyCode,
    accountInfo: state.customer.accountInfo,
});

const mapDispatchToProps = {
    setCurrentCurrency,
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
