import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Container, Row, Form, FormGroup, FormText, Input, Col, Button } from 'reactstrap';
import Loader from '../Shared/Loader';
import './styles.css';

const ForgotPassword = ({ handlePassword, isFetching }) => {
    const [username, setUsername] = useState('');

    const handleSubmit = async e => {
        e.preventDefault();
        handlePassword({ username });
    };

    return (
        <Container className="forgot-password-container d-flex flex-column justify-content-center">
            <Row className="flex-column">
                <Col xs={12} md={6} lg={5} className="mx-auto d-flex flex-column align-items-center">
                    <h5 className="text-center">Forgot Password</h5>
                    <Form className="mt-2 d-flex flex-column forgot-password-form" onSubmit={handleSubmit}>
                        <FormGroup>
                            <FormText className="font-universe fs-18">
                                Please enter your username to receive a password reset link.
                            </FormText>
                            <Input
                                className="mt-3"
                                type="text"
                                name="username"
                                id="username"
                                placeholder="Username"
                                onChange={e => setUsername(e.target.value)}
                                value={username}
                                required
                            />
                        </FormGroup>
                        <Button>Submit</Button>
                        <Link to="/shop/customer/account" color="primary" className="font-universe link-text">
                            <i className="fa fa-long-arrow-left" aria-hidden="true" />
                            Back
                        </Link>
                        <Loader timedOut={isFetching} />
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

ForgotPassword.propTypes = {
    handlePassword: PropTypes.func,
    isFetching: PropTypes.bool,
};
export default ForgotPassword;
