import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import CurrencyManager from '../../utils/CurrencyManager';
import Header from '../../components/Shared/Header';
import Footer from '../../components/Shared/Footer';
import EnvironmentInfo from '../../components/Shared/EnvironmentInfo';
import If from '../../components/Shared/If';
import { getProductDetail } from '../ProductContainer/actions';
import { getProductDetail as getStaticProductDetail } from '../StaticProductContainer/actions';
import { getProductDetail as getWomensProductDetail } from '../WomensProductContainer/actions';
import { getProductDetail as getRingCustomizerDetail } from '../RingCustomizerContainer/action';
import { syncFavorites } from '../CategoryContainer/actions';
import * as actions from './actions';
import { getCategoryList } from '../RingCategoryListContainer/actions';
import { makeSelectIsFetching, makeSelectCategoryList } from '../RingCategoryListContainer/selectors';
import { makeSelectProduct } from '../ProductContainer/selectors';
import { makeSelectProduct as makeSelectStaticProduct } from '../StaticProductContainer/selectors';
import { makeSelectProduct as makeSelectWomensProduct } from '../WomensProductContainer/selectors';
import { makeSelectProduct as makeSelectRingCustomizerProduct } from '../RingCustomizerContainer/selectors';
import { makeSelectCurrentCurrency } from './selectors';
import AuthManager from '../../utils/AuthManager';
import config from '../../config';

class AppContainer extends Component {
    async componentDidMount() {
        CurrencyManager.remove();
        // await this.props.getCurrentCurrency();
        /**
         *
         * The getCurrentCurrency code call has been commented as we are focusing only on the USD and not on UK any more
         * In case, if we want to go for multiple currency handling, we can uncomment this call.
         *
         */
        CurrencyManager.set(this.props.currentCurrencyCode);
        this.props.getCategoryList();
        const auth = AuthManager.get();
        const userId = AuthManager.getAnonymousUserId();
        // if we have just authenticated and the user has an anonymous identity,
        // synchronize the favorites and remove the anonymous user id.
        if (auth && userId) {
            this.props.syncFavorites({ user_id: userId });
            AuthManager.removeAnonymousUserId();
        }
    }

    render() {
        const {
            children,
            product,
            staticProduct,
            womensProduct,
            ringCustomizerProduct,
            getProductDetail,
            getStaticProductDetail,
            getWomensProductDetail,
            getRingCustomizerDetail,
            categoryList,
            history,
            displayHeader = true,
            displayFooter = true,
        } = this.props;

        return (
            <div className="stable-header">
                <EnvironmentInfo />
                <If condition={config.displayHeader && displayHeader}>
                    <Header
                        getProductDetail={getProductDetail}
                        getStaticProductDetail={getStaticProductDetail}
                        getWomensProductDetail={getWomensProductDetail}
                        getRingCustomizerDetail={getRingCustomizerDetail}
                        categoryList={categoryList}
                        history={history}
                        product={product}
                        staticProduct={staticProduct}
                        womensProduct={womensProduct}
                        ringCustomizerProduct={ringCustomizerProduct}
                        buildYourOwnProductUrl={config.buildYourOwnProductUrl}
                    />
                </If>
                <main className={`${config.displayHeader && displayHeader && 'header-offset'} ${config.mainContainerClass}`}>
                    {React.Children.map(children, child => child)}
                </main>
                <If condition={config.displayHeader && displayHeader && displayFooter}>
                    <Footer categoryList={categoryList} />
                </If>
            </div>
        );
    }
}
AppContainer.propTypes = {
    children: PropTypes.object,
    currentCurrencyCode: PropTypes.string,
    // getCurrentCurrency: PropTypes.func.isRequired,
    getProductDetail: PropTypes.func.isRequired,
    getStaticProductDetail: PropTypes.func.isRequired,
    syncFavorites: PropTypes.func.isRequired,
    product: PropTypes.object,
    staticProduct: PropTypes.object,
    ringCustomizerProduct: PropTypes.object,
    getCategoryList: PropTypes.func.isRequired,
    categoryList: PropTypes.array,
    history: PropTypes.object,
};

const mapStateToProps = createSelector(
    [makeSelectIsFetching(), makeSelectCategoryList(), makeSelectCurrentCurrency(), makeSelectProduct(), makeSelectStaticProduct(), makeSelectWomensProduct(), makeSelectRingCustomizerProduct()],
    (isFetching, categoryList, currentCurrencyCode, product, staticProduct, womensProduct, ringCustomizerProduct) => ({
        isFetching,
        categoryList,
        currentCurrencyCode,
        product,
        staticProduct,
        womensProduct,
        ringCustomizerProduct,
    })
);

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getProductDetail,
            getStaticProductDetail,
            getWomensProductDetail,
            getCategoryList,
            syncFavorites,
            getRingCustomizerDetail,
            ...actions,
        },
        dispatch
    );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppContainer));
