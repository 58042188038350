import { CALL_API, METHODS } from '../../middleware';
import URLs from '../../utils/url';
import {
    RESET_CHECKOUT_DATA,
    CREATE_ORDER_ACTION_TYPES,
    GET_SHIPPING_DETAILS_ACTION_TYPES,
    GET_COUNTRIES,
    GET_STATES,
    CREATE_CUSTOMER_ACTION_TYPES,
    GET_ALL_ADDRESSES,
    GET_SUBSCRIPTION_SUMMARY,
    GET_ORDER_SUMMARY,
    GET_ORDER_DETAIL_ACTION_TYPES,
    GET_PAYMENT_INTENT,
    GET_PAYMENT_METHODS,
    REMOVE_PAYMENT_METHOD,
    SAVE_PAYMENT_METHOD,
    GET_ACCOUNT_INFO_ACTION_TYPES,
    CREATE_SUBSCRIPTION,
    CREATE_DIRECT_ORDER,
    SET_SHIPPING_METHOD,
} from './constants';
import CartManager from '../../utils/CartManager';
import AuthManager from '../../utils/AuthManager';

const getOrder = () => {
    const cartId = CartManager.get();
    return {
        [CALL_API]: {
            requestConfig: {
                useAuth: true,
                baseUrl: URLs.apiBaseUrl,
                url: URLs.order,
                method: METHODS.POST,
                headers: { 'guest-cart': cartId },
            },
            types: GET_ORDER_DETAIL_ACTION_TYPES,
        },
    };
};

const getShippingDetail = () => ({
    [CALL_API]: {
        requestConfig: {
            useAuth: true,
            baseUrl: URLs.apiBaseUrl,
            url: URLs.shipping,
            method: METHODS.POST,
        },
        types: GET_SHIPPING_DETAILS_ACTION_TYPES,
    },
});

const getCountries = () => ({
    [CALL_API]: {
        requestConfig: {
            baseUrl: URLs.apiBaseUrl,
            useAuth: true,
            url: URLs.countries,
            method: METHODS.GET,
        },
        types: GET_COUNTRIES,
    },
});

const getStates = country => ({
    [CALL_API]: {
        requestConfig: {
            baseUrl: URLs.apiBaseUrl,
            useAuth: true,
            url: `${URLs.countries}/${country}/states`,
            method: METHODS.GET,
        },
        types: GET_STATES,
    },
});

const createCustomer = data => ({
    [CALL_API]: {
        requestConfig: {
            baseUrl: URLs.apiBaseUrl,
            useAuth: true,
            url: URLs.customer,
            method: METHODS.POST,
            data,
        },
        types: CREATE_CUSTOMER_ACTION_TYPES,
    },
});

const getAllAddresses = () => ({
    [CALL_API]: {
        requestConfig: {
            baseUrl: URLs.apiBaseUrl,
            useAuth: true,
            url: URLs.shipToAddress,
            method: METHODS.GET,
        },
        types: GET_ALL_ADDRESSES,
    },
});

const createOrder = ({ shipping_address, billing_address, shipping_id, is_subscription }) => {
    const cartId = CartManager.get();
    return {
        [CALL_API]: {
            requestConfig: {
                baseUrl: URLs.apiBaseUrl,
                useAuth: true,
                url: URLs.createOrder,
                method: METHODS.POST,
                data: { shipping_address, billing_address, shipping_id, is_subscription },
                headers: { 'guest-cart': cartId },
            },
            types: CREATE_ORDER_ACTION_TYPES,
        },
    };
};

const getSubscriptionSummary = orderId => ({
    [CALL_API]: {
        requestConfig: {
            baseUrl: URLs.apiBaseUrl,
            useAuth: true,
            url: `${URLs.subscriptionSummary}/${orderId}`,
            method: METHODS.GET,
        },
        types: GET_SUBSCRIPTION_SUMMARY,
    },
});

const getOrderSummary = orderId => ({
    [CALL_API]: {
        requestConfig: {
            baseUrl: URLs.apiBaseUrl,
            useAuth: true,
            url: `${URLs.order}/${orderId}`,
            method: METHODS.GET,
        },
        types: GET_ORDER_SUMMARY,
    },
});

const setUpPaymentIntent = () => ({
    [CALL_API]: {
        requestConfig: {
            baseUrl: URLs.apiBaseUrl,
            useAuth: true,
            url: URLs.paymentIntent,
            method: METHODS.GET,
        },
        types: GET_PAYMENT_INTENT,
    },
});

const createSubscription = (order_id, payment_method_id) => ({
    [CALL_API]: {
        requestConfig: {
            baseUrl: URLs.apiBaseUrl,
            useAuth: true,
            url: URLs.createSubscription,
            method: METHODS.POST,
            data: { order_id, payment_method_id },
        },
        types: CREATE_SUBSCRIPTION,
    },
});

const createDirectOrder = (order_id, payment_method_id) => ({
    [CALL_API]: {
        requestConfig: {
            baseUrl: URLs.apiBaseUrl,
            useAuth: true,
            url: URLs.directOrder,
            method: METHODS.POST,
            data: { order_id, payment_method_id },
        },
        types: CREATE_DIRECT_ORDER,
    },
});

const savePaymentMethod = id => ({
    [CALL_API]: {
        requestConfig: {
            baseUrl: URLs.apiBaseUrl,
            useAuth: true,
            url: `${URLs.paymentMethod}?default=1`,
            method: METHODS.POST,
            data: { id },
        },
        types: SAVE_PAYMENT_METHOD,
    },
});

const getPaymentMethods = () => ({
    [CALL_API]: {
        requestConfig: {
            baseUrl: URLs.apiBaseUrl,
            useAuth: true,
            url: URLs.paymentMethod,
            method: METHODS.GET,
        },
        types: GET_PAYMENT_METHODS,
    },
});

const removePaymentMethod = id => ({
    [CALL_API]: {
        requestConfig: {
            baseUrl: URLs.apiBaseUrl,
            useAuth: true,
            url: URLs.paymentMethod,
            method: METHODS.DELETE,
            data: { id },
        },
        types: REMOVE_PAYMENT_METHOD,
    },
});

const getAccountInfo = () => ({
    [CALL_API]: {
        requestConfig: {
            useAuth: true,
            baseUrl: URLs.apiBaseUrl,
            url: URLs.accountInfo,
            method: METHODS.GET,
            params: {},
        },
        types: GET_ACCOUNT_INFO_ACTION_TYPES,
    },
});

const setShippingMethod = selectedShipping => {
    return {
        payload: selectedShipping,
        type: SET_SHIPPING_METHOD,
    };
};

const resetCheckoutData = () => {
    AuthManager.remove();
    return {
        type: RESET_CHECKOUT_DATA,
    };
};

export {
    getOrder,
    getShippingDetail,
    getCountries,
    getStates,
    createCustomer,
    createOrder,
    getAllAddresses,
    getSubscriptionSummary,
    getOrderSummary,
    setUpPaymentIntent,
    createSubscription,
    createDirectOrder,
    savePaymentMethod,
    getPaymentMethods,
    removePaymentMethod,
    getAccountInfo,
    setShippingMethod,
    resetCheckoutData,
};
