import React, { Component } from 'react';

import './styles.css';

class Collapsible extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        };
    }

    toggleMenu = (e, show) => {
        e.stopPropagation();
        const newState = typeof show === 'undefined' ? !this.state.isOpen : show;
        this.setState({ isOpen: newState });
    };

    render() {
        const { children, isDropdown, isMobile, styles, tab, alwaysShow = false, dynamicHeight } = this.props;
        const { isOpen } = this.state;
        return (
            <li
                onMouseEnter={(isDropdown && !isMobile && !alwaysShow) ? (e) => this.toggleMenu(e, true) : null}
                onMouseLeave={(isDropdown && !isMobile && !alwaysShow) ? (e) => this.toggleMenu(e, false) : null}
                onClick={(isMobile && isDropdown) ? (e) => this.toggleMenu(e) : null}
                className={styles}
            >
                {tab}
                {isDropdown && isMobile && <i className={isOpen ? 'fa fa-angle-up' : 'fa fa-angle-down'} aria-hidden="true" />}
                {(isOpen || (alwaysShow && !isMobile)) && children}
                {!isMobile ?
                    <div className={(isOpen && !alwaysShow) ? 'appear' : ''} style={{ minHeight: isOpen ? dynamicHeight : 'auto' }} />
                    : <div className={(isOpen && !alwaysShow) ? 'appear' : ''} />
                }
            </li>
        );
    }
}

export default Collapsible;
