import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import AppContainer from './containers/AppContainer';
import CartManager from './utils/CartManager';

const CartRoute = ({ component: Component, displayHeader = true, ...rest }) => (
    <Route
        render={props =>
            CartManager.get() && CartManager.get() !== 'emptyCart' ? (
                <div>
                    <AppContainer displayHeader={displayHeader}>
                        <div>
                            <Component {...props} />
                        </div>
                    </AppContainer>
                </div>
            ) : (
                <Redirect to={{ pathname: '/shop/emptyCart', state: { from: props.location } }} />
            )
        }
        {...rest}
    />
);

CartRoute.propTypes = {
    component: PropTypes.func.isRequired,
    rest: PropTypes.object,
    location: PropTypes.object,
};

export default CartRoute;
