import {
    GET_PRODUCT_DETAIL_ACTION_TYPES,
    ADD_PRODUCT_TO_CART_ACTION_TYPES,
    RESET_ADD_TO_CART,
    ADD_TO_FAVORITE_ACTION_TYPES,
    REMOVE_FROM_FAVORITE_ACTION_TYPES,
    UPDATE_FAVORITES,
    RESET_CHANGE_LOG,
    GET_RING_SIZE_ACTION_TYPES,
    UPDATE_PRICE,
} from './constants';
import CartManager from '../../utils/CartManager';
import AuthManager from '../../utils/AuthManager';

const initialData = {
    isFetching: false,
    addProductResult: null,
    updatedPrice: '',
};

export default function staticProductReducer(state = initialData, { payload, ...action }) {
    switch (action.type) {
        case GET_PRODUCT_DETAIL_ACTION_TYPES.request:
            return {
                ...state,
                isFetching: true,
            };
        case GET_PRODUCT_DETAIL_ACTION_TYPES.success:
            return {
                ...state,
                isFetching: false,
                staticProduct: payload.data,
            };
        case GET_PRODUCT_DETAIL_ACTION_TYPES.failure:
            return {
                ...state,
                isFetching: false,
            };
        case ADD_PRODUCT_TO_CART_ACTION_TYPES.request:
            return {
                ...state,
                isFetching: true,
                addProductResult: {
                    result: null,
                    message: '',
                },
            };
        case ADD_PRODUCT_TO_CART_ACTION_TYPES.success:
            CartManager.set(payload.cart_id);
            // set the guest cart id.
            return {
                ...state,
                isFetching: false,
                addProductResult: {
                    result: payload,
                    message: payload ? 'Item was added to cart.' : 'There was a problem adding item to the cart.',
                },
            };
        case ADD_PRODUCT_TO_CART_ACTION_TYPES.failure:
            return {
                ...state,
                isFetching: false,
                addProductResult: {
                    result: false,
                    message: 'There was a problem adding item to the cart.',
                },
            };
        case ADD_TO_FAVORITE_ACTION_TYPES.request:
        case REMOVE_FROM_FAVORITE_ACTION_TYPES.request:
            return {
                ...state,
                isFetching: true,
            };
        case ADD_TO_FAVORITE_ACTION_TYPES.success:
            AuthManager.setAnonymousUserIfNotExists(payload.user_id);
            return {
                ...state,
                isFetching: false,
            };
        case ADD_TO_FAVORITE_ACTION_TYPES.failure:
        case REMOVE_FROM_FAVORITE_ACTION_TYPES.success:
        case REMOVE_FROM_FAVORITE_ACTION_TYPES.failure:
            return {
                ...state,
                isFetching: false,
            };
        case UPDATE_FAVORITES:
            return {
                ...state,
                staticProduct: {
                    ...state.staticProduct,
                    is_favorite: payload,
                },
            };

        case UPDATE_PRICE:
            return {
                ...state,
                updatedPrice: payload,
            };
        case RESET_ADD_TO_CART:
            return {
                ...state,
                addProductResult: null,
            };
        case RESET_CHANGE_LOG:
            return {
                ...state,
                staticProduct: {
                    ...state.staticProduct,
                    changes: [],
                },
            };
        case GET_RING_SIZE_ACTION_TYPES.request:
            return {
                ...state,
                isFetching: true,
            };
        case GET_RING_SIZE_ACTION_TYPES.success:
            return {
                ...state,
                isFetching: false,
                productSize: payload,
                staticProduct: {
                    ...state.staticProduct,
                },
            };
        case GET_RING_SIZE_ACTION_TYPES.failure:
            return {
                ...state,
                isFetching: false,
            };

        default:
            return state;
    }
}
