import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import globalReducer from './containers/AppContainer/reducer';
import productReducer from './containers/ProductContainer/reducer';
import categoryListReducer from './containers/RingCategoryListContainer/reducer';
import categoryReducer from './containers/CategoryContainer/reducer';
import searchReducer from './containers/SearchContainer/reducer';
import cartReducer from './containers/CartContainer/reducer';
import orderReducer from './containers/OrderStatus/reducer';
import staticProductReducer from './containers/StaticProductContainer/reducer';
import customerReducer from './containers/CustomerContainer/reducer';
import checkoutReducer from './containers/StripeContainer/reducer';
import subscriptionReducer from './containers/ManageSubscription/reducer';
import womensProductReducer from './containers/WomensProductContainer/reducer';
import customizeReducer from './containers/RingCustomizerContainer/reducer';

const createRootReducer = history =>
    combineReducers({
        router: connectRouter(history),
        global: globalReducer,
        product: productReducer,
        customize: customizeReducer,
        categoryList: categoryListReducer,
        categoryDetails: categoryReducer,
        searchResults: searchReducer,
        cart: cartReducer,
        order: orderReducer,
        staticProduct: staticProductReducer,
        customer: customerReducer,
        checkout: checkoutReducer,
        subscription: subscriptionReducer,
        womensProduct: womensProductReducer,
    });

export default createRootReducer;
