import axios from 'axios';
import merge from 'lodash/merge';
import { Cookies } from 'react-cookie';
import AuthManager from '../utils/AuthManager';
import CurrencyManager from '../utils/CurrencyManager';
import { CALL_API } from './constants';
import { instanceOf } from 'prop-types';

export const apiActionTypes = x => ({
    request: `${x}_REQUEST`,
    success: `${x}_SUCCESS`,
    failure: `${x}_FAILURE`,
});
const cookies = new Cookies();
const getDebug = () => cookies.get('debug');

const isTokenEmpty = x => x == null || x === undefined || x === 'undefined';

export const actionWith = (requestConfig, data) => {
    const finalAction = merge({}, requestConfig, data);
    delete finalAction[CALL_API];
    return finalAction;
};

export const callApi = requestConfig => {
    const auth = AuthManager.get() || AuthManager.getGuestToken();
    const authHeaders = requestConfig.useAuth && !isTokenEmpty(auth) ? { Authorization: `Bearer ${auth}` } : {};
    // const currencyCodeHeader = CurrencyManager.get() ? { 'currency-code': CurrencyManager.get() } : {};
    const cookieHeader = getDebug() ? { debug: `${getDebug()}` } : {};
    const headers = { headers: { ...authHeaders, ...cookieHeader } };

    const axiosConfig = merge({}, headers, requestConfig, {
        url: `${requestConfig.baseUrl}${requestConfig.url}`,
        method: requestConfig.method,
        mode: 'no-cors',
    });

    return axios(axiosConfig)
        .then(response => {
            if (response.status >= 200 && response.status <= 204) {
                if (response.data) {
                    if (response.data.error) {
                        return Promise.reject(response.data.error);
                    }
                    return response.data;
                }
                // TODO: Decide what to return when response = "204 No Content"
                return null;
            }
            return Promise.reject(new Error('Unknown API Error'));
        })
        .catch(error => Promise.reject(error));
};
