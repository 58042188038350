import {
    GET_RING_LIST_ACTION_TYPES,
    PAGINATED_GET_RING_LIST_ACTION_TYPES,
    RESET_CATEGORY,
    TOGGLE_FAVORITE,
    TOGGLE_CATEGORIES_FAVORITE,
    GET_FAVORITE_RING_LIST_ACTION_TYPES,
    SYNC_FAVORITES,
} from './constants';

const initialData = {
    isFetching: false,
    isFetchingPaginatedResults: false,
    categoryDetails: {
        products: [],
        filters: [],
    },
    favorites: {
        data: [],
    },
};

const getUpdatedProductList = (products = []) => products.filter((product) => product.active && product.ring_options_url);

export default function categoryReducer(state = initialData, { payload, ...action }) {
    switch (action.type) {
        case GET_RING_LIST_ACTION_TYPES.request:
            return {
                ...state,
                isFetching: true,
            };
        case GET_RING_LIST_ACTION_TYPES.success:
            return {
                ...state,
                isFetching: false,
                categoryDetails: {
                    ...state.categoryDetails,
                    ...payload,
                    products: getUpdatedProductList(payload.products),
                },
            };
        case GET_RING_LIST_ACTION_TYPES.failure:
            return {
                ...state,
                isFetching: false,
                categoryDetails: {},
            };
        case GET_FAVORITE_RING_LIST_ACTION_TYPES.request:
            return {
                ...state,
                isFetching: true,
            };
        case GET_FAVORITE_RING_LIST_ACTION_TYPES.success:
            return {
                ...state,
                isFetching: false,
                favorites: payload,
            };
        case GET_FAVORITE_RING_LIST_ACTION_TYPES.failure:
            return {
                ...state,
                isFetching: false,
                favorites: {},
            };
        case SYNC_FAVORITES.request:
            return {
                ...state,
                isFetching: true,
            };
        case SYNC_FAVORITES.success:
            return {
                ...state,
                ...initialData,
            };
        case SYNC_FAVORITES.failure:
            return {
                ...state,
                isFetching: false,
            };
        case PAGINATED_GET_RING_LIST_ACTION_TYPES.request:
            return {
                ...state,
                isFetchingPaginatedResults: true,
            };
        case PAGINATED_GET_RING_LIST_ACTION_TYPES.success: {
            let { products } = state.categoryDetails;
            if (!products) {
                products = [];
            }
            const nextList = getUpdatedProductList(payload.products);
            const filtered = Object.assign(state.categoryDetails, payload, { products: [...products, ...nextList] });
            return {
                ...state,
                isFetchingPaginatedResults: false,
                categoryDetails: filtered,
            };
        }
        case PAGINATED_GET_RING_LIST_ACTION_TYPES.failure:
            return {
                ...state,
                isFetchingPaginatedResults: false,
                categoryDetails: {},
            };
        case TOGGLE_CATEGORIES_FAVORITE:
            return {
                ...state,
                categoryDetails: {
                    ...state.categoryDetails,
                    products: payload,
                },
            };
        case TOGGLE_FAVORITE:
            return {
                ...state,
                favorites: {
                    data: payload,
                },
            };
        case RESET_CATEGORY:
            return {
                ...state,
                isFetching: false,
                categoryDetails: {},
            };
        default:
            return state;
    }
}
