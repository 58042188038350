import React from 'react';
import If from '../../components/Shared/If';
import SidePanel from '../../components/Customer/SidePanel';

const Wrapper = ({ displaySidePanel, children }) => (
    <div className="wrapper-container d-lg-flex">
        <If condition={displaySidePanel}>
            <SidePanel />
        </If>
        <main className="side-panel-offset">{React.Children.map(children, child => child)}</main>
    </div>
);

export default Wrapper;
