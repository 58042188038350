import React, { useEffect, useState, Fragment, useRef } from 'react';
import { Row, Col, Tooltip } from 'reactstrap';
import PropTypes from 'prop-types';
import Loader from '../Shared/Loader';

const Summary = ({
    isFetching,
    customerProducts,
    summary,
    shippingCost,
    handleShipping,
    handleSummaryHeight,
    directOrder,
    currentStep,
}) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [tooltipOpenTax, setTaxTooltipOpen] = useState(false);
    const [isMobile, setMobile] = useState(false);
    const [showSummary, summaryToggle] = useState(false);
    const [tax, setTax] = useState(false);
    const summaryContainer = useRef(null);
    const arr = customerProducts.map(item => false);
    const [hasOpen, isSetOpen] = useState([...arr]);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    const toggleTax = () => setTaxTooltipOpen(!tooltipOpenTax);
    useEffect(() => {
        if (window.innerWidth < 768) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    }, [window.innerWidth]);

    useEffect(() => {
        if (summary && summary.shipping_cost && summary.shipping_method_name) {
            handleShipping(summary.shipping_cost, summary.shipping_method_name);
        }
    }, []);

    useEffect(() => {
        if (summary && summary.items) {
            handleSummaryHeight(summaryContainer.current.offsetHeight);
        }
    }, [summary]);

    useEffect(() => {
        if ((directOrder && currentStep === 1) || (!directOrder && currentStep === 2)) {
            setTax(false);
        } else {
            setTax(true);
        }
    }, [currentStep]);

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const toggleSummary = () => summaryToggle(!showSummary);
    return (
        <div className={`customer-cart-summary-container guest-summary ${directOrder ? 'px-0 py-3' : 'p-3'}`} ref={summaryContainer}>
            <Row className="justify-content-between">
                <Col xs={12}>
                    <section>
                        <div className="font-universe fs-18 font-weight-bolder">Order Summary:</div>
                        <div className="font-universe d-flex justify-content-between" onClick={(isMobile) ? toggleSummary : null}>
                            {isMobile && <i className={showSummary ? 'fa fa-angle-up' : 'fa fa-angle-down'} aria-hidden="true" />}
                        </div>
                        <hr />
                        {(!isMobile || (isMobile && showSummary)) &&
                        <div className="fs-14">
                            <div className="products direct-products" >
                                {customerProducts &&
                                customerProducts.map((item, i) => (
                                    <Fragment key={i}>
                                        <p className="font-universe d-flex justify-content-between">
                                            <span>{`${item.name} x ${item.quantity}`}</span>
                                            <span>{item.total_amount}</span>
                                        </p>
                                        <hr />
                                    </Fragment>
                                ))}
                            </div>
                            <div className="price-summary">
                                <p className="font-universe d-flex justify-content-between">
                                    <span>Shipping:</span>
                                    <span>{shippingCost}</span>
                                </p>
                                {!directOrder &&
                                <p className="font-universe d-flex justify-content-between">
                                    <span>Financing Fee:</span>

                                    <span>
                                        {summary && summary.processing_fee}
                                    </span>
                                </p>}
                                {!directOrder &&
                                    tax &&
                                    <p className="font-universe d-flex justify-content-between">
                                        <span>Total Sales Tax:</span>

                                        <span>
                                            {summary && summary.sales_tax && summary.sales_tax.total_sales_tax}
                                        </span>
                                    </p>}
                                {directOrder &&
                                    tax &&
                                    <p className="font-universe d-flex justify-content-between">
                                        <span>Sales Tax:</span>

                                        <span>
                                            {summary && formatter.format(summary.tax_amount / 100)}
                                        </span>
                                    </p>}
                                <hr />
                                {directOrder ?
                                    <p className="font-universe d-flex justify-content-between">
                                        <span>Grand Total:</span>

                                        <span>
                                            {summary && summary.grand_total}
                                        </span>
                                    </p> :
                                    <p className="font-universe d-flex justify-content-between">
                                        <span>Grand Total:</span>

                                        <span>
                                            {summary && summary.total_amount}
                                        </span>
                                    </p>
                                }
                                {!directOrder &&
                                <p className="font-universe d-flex justify-content-between">
                                    <span>Monthly Payment: {tax && <i
                                        className="fa fa-info-circle ml-1"
                                        aria-hidden="true"
                                        id="sales_tax"
                                    />}
                                    </span>

                                    <span>
                                        {summary && summary.monthly_payment}
                                    </span>
                                    {tax && <Tooltip
                                        placement="bottom"
                                        isOpen={tooltipOpenTax}
                                        target="sales_tax"
                                        toggle={toggleTax}
                                    >
                                        {`Including ${summary && summary.sales_tax && summary.sales_tax.sales_tax_for_installment} sales tax`}
                                    </Tooltip>}
                                </p>}
                                {!directOrder && tax &&
                                <p className="font-universe d-flex justify-content-between">
                                    <span>Sales tax on first payment:</span>

                                    <span>
                                        {summary && summary.sales_tax && summary.sales_tax.sales_tax_for_first_invoice}
                                    </span>
                                </p>}
                                {!directOrder &&
                                <p className="font-universe d-flex justify-content-between">
                                    <span>Amount due Today:<i className="fa fa-info-circle ml-1" aria-hidden="true" id="down_payment" /></span>
                                    <span>{summary && summary.down_payment}</span>
                                    <Tooltip
                                        placement="bottom"
                                        isOpen={tooltipOpen}
                                        target="down_payment"
                                        toggle={toggle}
                                    >
                                        This total includes 16% of total of all items in cart and a 7.9% Financing Fee, plus your protection plan, shipping, taxes, if applicable.
                                    </Tooltip>
                                </p>}
                            </div>
                        </div>}
                    </section>
                </Col>
            </Row>
            <Loader timedOut={isFetching} />
        </div>
    );
};

Summary.propTypes = {
    isFetching: PropTypes.bool,
    customerProducts: PropTypes.array,
    summary: PropTypes.object,
};

export default Summary;
