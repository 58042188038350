import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import config from '../../config';

const Engravings = () => (
    <Container className="mb-5">
        <Row>
            <Col xs={12} className="text-center mt-5 d-flex align-items-center flex-column">
                <img
                    src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/wp-content/uploads/2016/03/25x25-mini-icon-classics.png"
                    alt="size-icon"
                />
                <h1 className="font-syncopate text-uppercase mt-3 mb-3">Engravings</h1>
                <p className="font-universe byline">
                    Rings without sleeves or inlays can be engraved with design patterns or special messages.
                </p>
                <hr className="content-hr" color="#dcc67d" />
            </Col>
            <Col xs={12} className="size-video-wrapper mx-auto my-4">
                <ReactPlayer url="https://youtu.be/ev0PJBsP2wI" controls height="100%" width="100%" />
            </Col>
            <Col xs={12} className="font-universe fs-24">
                <h4 className="font-italic">Engraving the Outside</h4>
                <p className="color-grey">
                    To select an engraved pattern on the outside of your band, select “Outside Feature” and then “Design
                    Pattern” on the
                    <Link to={config.buildYourOwnProductUrl} className="gold-links">
                        {' Lashbrook Builder '}
                    </Link>
                    . You have lots of options to choose from; we show our most popular styles in that menu. We’re also
                    able to engrave nearly anything else you can come up with- a pattern of your own design, a unique
                    text, we’ve seen it all. If you choose to add a personalized engraving (soundwave, roman numerals,
                    duck band, fingerprint, handwriting), our customer service team will contact you via email once your
                    order is placed to get the additional information needed and help you through the process:
                </p>
                <p className="ml-4 color-grey">
                    Soundwave engravings require an MP3 of the phrase (in your own voice if you’d like), song, sonnet or
                    whatever you wish to have engraved.
                </p>
                <p className="ml-4 color-grey">
                    Fingerprint and handwriting engravings require the sample to be physically sent in, we’ll provide
                    the instructions/materials for getting a clean sample.
                </p>
                <p className="ml-4 color-grey">Roman numeral engravings simply require the date sequence.</p>
                <p className="ml-4 color-grey">
                    Duck band engravings also simply require the date sequence and name initials.
                </p>
                <p className="fs-12 color-grey">
                    *Note that rings with inlays may not be compatible with engraving the outside of the ring, so
                    contact us with questions- we’re happy to talk through any and all of the options.
                </p>
            </Col>
            <Col xs={12} className="font-universe fs-24">
                <h4 className="font-italic">Engraving the Inside</h4>
                <p className="color-grey">
                    To engrave on the inside of your band, select “Engraving” on any product page and enter the desired
                    font and text. Listed on the
                    <Link to={config.buildYourOwnProductUrl} className="gold-links">
                        {' Lashbrook Builder '}
                    </Link>
                    are our most popular fonts: Arial, Lucinda Calligraphy, Lucia BJ, French Script, Times New Roman,
                    Vladimir Script, Tempus Sans, Old London, Kells So. There is also a ‘Custom’ option which, when
                    selected, allows you to enter any font name. Chances are we have the font you wish to use including
                    diverse languages (we have 4 types of Elvish!)– we just can’t show the hundreds of fonts we have on
                    the website! If, on the rare occasion, we are unable to use the font you have entered, a member from
                    our customer service team will contact you via email to discuss similar font options or ask for
                    another font choice.
                </p>
                <p className="fs-12 color-grey">
                    *Rings with hardwood sleeves cannot be personalized with an inside engraving. We reserve the right
                    to reject any custom engraving text that uses offensive or discriminating language.
                </p>
            </Col>
        </Row>
    </Container>
);

export default Engravings;
