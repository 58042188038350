import React from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { Route, Switch, Redirect } from 'react-router-dom';
import Login from '../../components/Customer/Login';
import ForgotPassword from '../../components/Customer/ForgotPassword';
import ConfirmPassword from '../../components/Customer/ConfirmPassword';
import * as actions from './actions';
import { emailPortal } from '../ManageSubscription/actions';
import { getCartDetail, resetProductData, clearCart } from '../CartContainer/actions';
import AuthManager from '../../utils/AuthManager';
import Account from '../../components/Customer';
import {
    makeSelectIsFetching,
    makeSelectAddresses,
    makeSelectSelectedAddress,
    makeSelectCountries,
    makeSelectStates,
    makeSelectAccountInfo,
    makeSelectOrderData,
    makeSelectShipping,
    makeSelectOrder,
    makeSelectTransactionHistory,
    makeSelectOpenTransactions,
    makeSelectOrderHistory,
    makeSelectTransactionDetails,
    makeSelectTransactionalData,
    makeSelectCustomerData,
    makeSelectIsFetchingTransactionalData,
    makeSelectIsFetchingCustomerData,
    makeSelectStoreList,
    makeSelectIsFetchingStoreList,
    makeSelectIsFetchingTaskContactNotes,
    makeSelectTaskContactNotes,
    makeSelectAllTransactionsData,
    makeSelectIsFetchingAllTransactionsData,
    makeSelectStockSalesSummary,
    makeSelectIsFetchingStockSalesSummary,
} from './selectors';
import { makeSelectProducts, makeSelectCartSummary } from '../CartContainer/selectors';
import { makeSelectIsFetching as makeSelectIsFetchingManageSubscription } from '../ManageSubscription/selectors';
import ProtectedRoute from '../../ProtectedRoute';
import AccountInfoEdit from '../../components/Customer/AccountInfoEdit';
import OrderHistory from '../../components/Customer/OrderHistory';
import ViewOrder from '../../components/Customer/ViewOrder';
import Address from '../../components/Customer/Address';
import EditAddress from '../../components/Customer/EditAddress';
import CreateAddress from '../../components/Customer/CreateAddress';
import BillingAgreement from '../../components/Customer/BillingAgreement';
import Checkout from '../../components/Customer/Checkout';
import OrderStatus from '../../components/Customer/OrderStatus';
import Transactions from '../../components/Customer/Transactions';
import OpenTransactions from '../../components/Customer/Open Transactions';
import History from '../../components/Customer/History';
import PaymentSuccess from '../../components/Customer/Open Transactions/PaymentSuccess';
import MarketingMaterials from '../MarketingMaterialsContainer';
import StoreSuccess from '../../components/Customer/StoreSuccess';
import SalesDashboard from '../../components/Customer/SalesDashboard';

class CustomerContainer extends React.Component {
    componentWillUnmount = () => {
        this.props.resetCustomerData();
    };

    handlePassword = async ({ username }) => {
        const { emailReset } = this.props;
        try {
            await emailReset({ username });

            toast.success('The link to reset password has been sent to the E-mail.', { className: 'toast-success' });
        } catch (error) {
            let errorMessage;
            if (error && error.response && error.response.data && error.response.data.message) {
                errorMessage = error.response.data.message;
            }
            toast.info(errorMessage, { className: 'toast-error' });
        }
    };
    handleConfirmPassword = async ({ data }) => {
        const { history, passwordReset } = this.props;
        const queryParams = queryString.parse(this.props.location.search);
        try {
            await passwordReset({ password: data.password, ...queryParams });
            toast.success('Password has been successfully reset.', { className: 'toast-success' });
            setTimeout(() => {
                history.push('/shop/customer/account');
            }, 4000);
        } catch (error) {
            let errorMessage;
            if (error && error.response && error.response.data && error.response.data.password) {
                errorMessage = error.response.data.password[0];
            }
            toast.info(errorMessage, { className: 'toast-error' });
        }
    };

    handleLogin = async ({ username, password }) => {
        const { login, history } = this.props;
        try {
            await login({ username, password });
            toast.success('Login Successful', { className: 'toast-success' });
            history.push('/shop/customer/account');
        } catch (e) {
            toast.error('Login Failed', { className: 'toast-error' });
        }
    };

    render() {
        const {
            isFetching,
            getAllAddresses,
            addresses,
            getOneAddress,
            selectedAddress,
            updateAddress,
            deleteAddress,
            createAddress,
            getCountries,
            getStates,
            countries,
            states,
            resetSelectedAddress,
            accountInfo,
            getAccountInfo,
            editAccountInfo,
            getOneOrder,
            orderData,
            resetAccountInfo,
            resetStates,
            getOrders,
            getShippingDetail,
            getOrder,
            getCartDetail,
            shippingMethods,
            products,
            summary,
            createOrder,
            updateOrder,
            order,
            resetProductData,
            clearCart,
            getTransactionHistory,
            getOpenTransactions,
            transactionHistory,
            openTransactions,
            getTransactionDetails,
            transactionDetails,
            getOrderHistory,
            orderHistory,
            stripeSession,
            isFetchingManageSubscription,
            emailPortal,
            getTransactionalData,
            getCustomerData,
            resetTransactionalCustomerData,
            transactionalData,
            customerData,
            isFetchingTransactionalData,
            isFetchingCustomerData,
            storeList,
            isFetchingStoreList,
            getStoreList,
            isFetchingTaskContactNotes,
            taskContactNotes,
            getTaskContactNotes,
            resetTaskContactNotes,
            allTransactionsData,
            isFetchingAllTransactionsData,
            getAllTransactionsData,
            resetAllTransactionsData,
            stockSalesSummary,
            isFetchingStockSalesSummary,
            getStockSalesSummary,
            resetStockSalesSummary,
        } = this.props;

        return (
            <Switch>
                <Route
                    exact
                    path="/shop/customer/login"
                    render={props =>
                        !AuthManager.get() ? (
                            <Login
                                {...props}
                                login={this.handleLogin}
                                isFetching={isFetching}
                                isFetchingManageSubscription={isFetchingManageSubscription}
                                emailPortal={emailPortal}
                            />
                        ) : (
                            <Redirect to={{ pathname: '/shop/customer/account', state: { from: props.location } }} />
                        )
                    }
                />
                <ProtectedRoute
                    exact
                    displaySidePanel
                    path="/shop/customer/account"
                    isFetching={isFetching}
                    getAccountInfo={getAccountInfo}
                    getAllAddresses={getAllAddresses}
                    addresses={addresses}
                    accountInfo={accountInfo}
                    component={Account}
                />
                <ProtectedRoute
                    exact
                    displaySidePanel
                    path="/shop/customer/store-success"
                    isFetching={isFetching}
                    getAccountInfo={getAccountInfo}
                    accountInfo={accountInfo}
                    storeList={storeList}
                    isFetchingStoreList={isFetchingStoreList}
                    component={StoreSuccess}
                    getTransactionalData={getTransactionalData}
                    resetTransactionalCustomerData={resetTransactionalCustomerData}
                    getCustomerData={getCustomerData}
                    transactionalData={transactionalData}
                    customerData={customerData}
                    isFetchingTransactionalData={isFetchingTransactionalData}
                    isFetchingCustomerData={isFetchingCustomerData}
                    getStoreList={getStoreList}
                    isFetchingTaskContactNotes={isFetchingTaskContactNotes}
                    taskContactNotes={taskContactNotes}
                    getTaskContactNotes={getTaskContactNotes}
                    resetTaskContactNotes={resetTaskContactNotes}
                />
                <ProtectedRoute
                    exact
                    displaySidePanel
                    path="/shop/customer/sales-dashboard"
                    component={SalesDashboard}
                    isFetching={isFetching}
                    accountInfo={accountInfo}
                    getAccountInfo={getAccountInfo}
                    allTransactionsData={allTransactionsData}
                    isFetchingAllTransactionsData={isFetchingAllTransactionsData}
                    getAllTransactionsData={getAllTransactionsData}
                    resetAllTransactionsData={resetAllTransactionsData}
                    stockSalesSummary={stockSalesSummary}
                    isFetchingStockSalesSummary={isFetchingStockSalesSummary}
                    getStockSalesSummary={getStockSalesSummary}
                    resetStockSalesSummary={resetStockSalesSummary}
                />

                <ProtectedRoute
                    exact
                    displaySidePanel
                    getAllAddresses={getAllAddresses}
                    addresses={addresses}
                    path="/shop/customer/address"
                    component={Address}
                    deleteAddress={deleteAddress}
                    isFetching={isFetching}
                />

                <ProtectedRoute
                    exact
                    displaySidePanel
                    path="/shop/customer/address/edit/:id"
                    getOneAddress={getOneAddress}
                    address={selectedAddress}
                    updateAddress={updateAddress}
                    deleteAddress={deleteAddress}
                    getCountries={getCountries}
                    getStates={getStates}
                    countries={countries}
                    states={states}
                    isFetching={isFetching}
                    resetSelectedAddress={resetSelectedAddress}
                    resetStates={resetStates}
                    component={EditAddress}
                />

                <ProtectedRoute
                    exact
                    displaySidePanel
                    path="/shop/customer/address/create"
                    isFetching={isFetching}
                    getCountries={getCountries}
                    getStates={getStates}
                    countries={countries}
                    states={states}
                    createAddress={createAddress}
                    getAllAddresses={getAllAddresses}
                    displayPage={addresses && addresses.data && addresses.data.length < 2}
                    component={CreateAddress}
                />

                <Route
                    exact
                    path="/shop/customer/forgot-password"
                    render={props => (
                        <ForgotPassword handlePassword={this.handlePassword} {...props} isFetching={isFetching} />
                    )}
                />

                <Route
                    exact
                    path="/shop/customer/confirm-password"
                    render={props => (
                        <ConfirmPassword
                            handleConfirmPassword={this.handleConfirmPassword}
                            {...props}
                            isFetching={isFetching}
                        />
                    )}
                />

                <ProtectedRoute
                    exact
                    displaySidePanel
                    path="/shop/customer/account/me/edit"
                    accountInfo={accountInfo}
                    isFetching={isFetching}
                    getAccountInfo={getAccountInfo}
                    editAccountInfo={editAccountInfo}
                    resetAccountInfo={resetAccountInfo}
                    component={AccountInfoEdit}
                />

                <ProtectedRoute
                    exact
                    displaySidePanel
                    path="/shop/customer/account/me/order/history"
                    isFetching={isFetching}
                    getOrders={getOrders}
                    orderData={orderData}
                    component={OrderHistory}
                />

                <ProtectedRoute
                    exact
                    displaySidePanel
                    path="/shop/customer/account/me/order/view/:orderId"
                    isFetching={isFetching}
                    getOneOrder={getOneOrder}
                    orderData={orderData}
                    getAccountInfo={getAccountInfo}
                    accountInfo={accountInfo}
                    updateOrder={updateOrder}
                    component={ViewOrder}
                />

                <ProtectedRoute
                    exact
                    displaySidePanel
                    path="/shop/customer/account/me/transHistory"
                    isFetching={isFetching}
                    getTransactionHistory={getTransactionHistory}
                    transactionHistory={transactionHistory}
                    getTransactionInfo={getTransactionDetails}
                    transactionInfo={transactionDetails}
                    component={Transactions}
                />

                <ProtectedRoute
                    exact
                    displaySidePanel
                    path="/shop/customer/account/me/openTrans"
                    isFetching={isFetching}
                    getOpenTransactions={getOpenTransactions}
                    openTransactions={openTransactions}
                    stripeSession={stripeSession}
                    getTransactionInfo={getTransactionDetails}
                    openTransactionInfo={transactionDetails}
                    component={OpenTransactions}
                />

                <ProtectedRoute
                    exact
                    displaySidePanel
                    path="/shop/customer/account/me/orderHistory"
                    isFetching={isFetching}
                    getOrderHistory={getOrderHistory}
                    orderHistory={orderHistory}
                    getTransactionInfo={getTransactionDetails}
                    transactionInfo={transactionDetails}
                    component={History}
                />
                <ProtectedRoute
                    exact
                    displaySidePanel
                    path="/shop/customer/billing-agreement"
                    component={BillingAgreement}
                />
                <ProtectedRoute
                    exact
                    path="/shop/customer/cart/checkout"
                    getOneAddress={getOneAddress}
                    getAllAddresses={getAllAddresses}
                    addresses={addresses}
                    getShippingDetail={getShippingDetail}
                    address={selectedAddress}
                    updateAddress={updateAddress}
                    deleteAddress={deleteAddress}
                    getCountries={getCountries}
                    getStates={getStates}
                    countries={countries}
                    states={states}
                    isFetching={isFetching}
                    resetSelectedAddress={resetSelectedAddress}
                    resetStates={resetStates}
                    getOrder={getOrder}
                    shippingMethods={shippingMethods}
                    getCartDetail={getCartDetail}
                    customerProducts={products}
                    summary={summary}
                    component={Checkout}
                    createOrder={createOrder}
                    createAddress={createAddress}
                    clearCart={clearCart}
                    resetCart={resetProductData}
                />
                <ProtectedRoute
                    exact
                    path="/shop/customer/order-confirmation/:orderId"
                    order={order}
                    getAccountInfo={getAccountInfo}
                    accountInfo={accountInfo}
                    getOrder={getOneOrder}
                    orderData={orderData}
                    component={OrderStatus}
                />
                <ProtectedRoute exact path="/shop/customer/account/me/paymentSuccess" component={PaymentSuccess} />
                <ProtectedRoute exact path="/shop/customer/request-marketing-materials" component={MarketingMaterials} />
            </Switch>
        );
    }
}
const mapStateToProps = createSelector(
    [
        makeSelectIsFetching(),
        makeSelectAddresses(),
        makeSelectSelectedAddress(),
        makeSelectCountries(),
        makeSelectStates(),
        makeSelectAccountInfo(),
        makeSelectOrderData(),
        makeSelectShipping(),
        makeSelectProducts(),
        makeSelectCartSummary(),
        makeSelectOrder(),
        makeSelectTransactionHistory(),
        makeSelectOpenTransactions(),
        makeSelectOrderHistory(),
        makeSelectTransactionDetails(),
        makeSelectIsFetchingManageSubscription(),
        makeSelectTransactionalData(),
        makeSelectCustomerData(),
        makeSelectIsFetchingTransactionalData(),
        makeSelectIsFetchingCustomerData(),
        makeSelectStoreList(),
        makeSelectIsFetchingStoreList(),
        makeSelectIsFetchingTaskContactNotes(),
        makeSelectTaskContactNotes(),
        makeSelectAllTransactionsData(),
        makeSelectIsFetchingAllTransactionsData(),
        makeSelectStockSalesSummary(),
        makeSelectIsFetchingStockSalesSummary(),
    ],
    (
        isFetching,
        addresses,
        selectedAddress,
        countries,
        states,
        accountInfo,
        orderData,
        shippingMethods,
        products,
        summary,
        order,
        transactionHistory,
        openTransactions,
        orderHistory,
        transactionDetails,
        isFetchingManageSubscription,
        transactionalData,
        customerData,
        isFetchingTransactionalData,
        isFetchingCustomerData,
        storeList,
        isFetchingStoreList,
        isFetchingTaskContactNotes,
        taskContactNotes,
        allTransactionsData,
        isFetchingAllTransactionsData,
        stockSalesSummary,
        isFetchingStockSalesSummary,
    ) => ({
        isFetching,
        addresses,
        selectedAddress,
        countries,
        states,
        accountInfo,
        orderData,
        shippingMethods,
        products,
        summary,
        order,
        transactionHistory,
        openTransactions,
        orderHistory,
        transactionDetails,
        isFetchingManageSubscription,
        transactionalData,
        customerData,
        isFetchingTransactionalData,
        isFetchingCustomerData,
        storeList,
        isFetchingStoreList,
        isFetchingTaskContactNotes,
        taskContactNotes,
        allTransactionsData,
        isFetchingAllTransactionsData,
        stockSalesSummary,
        isFetchingStockSalesSummary,
    })
);

CustomerContainer.propTypes = {
    isFetching: PropTypes.bool,
    handlePassword: PropTypes.func,
    handleConfirmPassword: PropTypes.func,
    history: PropTypes.object.isRequired,
    accountInfo: PropTypes.object,
    orderData: PropTypes.object,
    getAccountInfo: PropTypes.func,
    resetCustomerData: PropTypes.func,
    editAccountInfo: PropTypes.func,
    getOneOrder: PropTypes.func,
    resetAccountInfo: PropTypes.func,
    getOrders: PropTypes.func,
    shippingMethods: PropTypes.object,
    products: PropTypes.object,
    createOrder: PropTypes.func,
    order: PropTypes.func,
    allTransactionsData: PropTypes.object,
    isFetchingAllTransactionsData: PropTypes.bool,
    getAllTransactionsData: PropTypes.func,
    resetAllTransactionsData: PropTypes.func,
    stockSalesSummary: PropTypes.object,
    isFetchingStockSalesSummary: PropTypes.bool,
    getStockSalesSummary: PropTypes.func,
    resetStockSalesSummary: PropTypes.func,
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            ...actions,
            getCartDetail,
            resetProductData,
            clearCart,
            emailPortal,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(CustomerContainer);
