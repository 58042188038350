import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import URLs from '../../utils/url';

const Terms = ({ next }) => (
    <Row className="font-universe text-center mt-3">
        <Col xs={12} className="d-flex align-items-center flex-column">
            <p className="m-0 fs-18">Pay over time with 12 low</p>
            <p className="m-0 fs-18">monthly payments*</p>
            <p className="m-0 fs-18">0.00% APR*</p>
            <p className="m-0 fs-18">
                Checking your down payment and monthly payment options have no impact on your credit score!
            </p>
            <p className="m-0 fs-18">
                By continuing, you are confirming that you have read and accepted the{' '}
                <a href="/financing-terms-conditions" target="_blank" rel="noopener noreferrer">
                    <span className="tnc-link">terms and conditions*</span>
                </a>
            </p>
        </Col>
        <Col xs={12} className="d-flex justify-content-center mt-4">
            <Button className="mx-auto" onClick={next}>
                Continue to Payment Totals and enter Customer Details
            </Button>
        </Col>
        <Col xs={12} className="mt-5">
            <div>
                *An electronic copy of Lashbrook's{' '}
                <a href="/financing-terms-conditions" target="_blank" rel="noopener noreferrer">
                    <span className="tnc-link">Terms and Conditions</span>
                </a>{' '}
                will be in your confirmation email.
            </div>
        </Col>
    </Row>
);

export default Terms;
