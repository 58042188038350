export const MATERIALS_LIST = [
    {
        material: 'Camo',
        byline: 'The hunt is over.',
        link: '/shop/unique-materials/camo',
        icon: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2017/02/25x25-mini-icon-camo.png',
        background: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2017/02/header-chamo.jpg',
    },
    {
        material: 'Carbon Fiber',
        byline: 'Carbon Fiber is a material with one of the highest strength-to-weight ratios of any product ever created.',
        link: '/shop/unique-materials/carbon-fiber',
        icon: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2017/02/25x25-mini-icon-carbon-fiber.png',
        background: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2017/02/header-carbon-fiber.jpg',
    },
    {
        material: 'Cerakote',
        byline: 'Introducing 48 colors to add to any ring.',
        link: '/shop/unique-materials/cerakote',
        icon: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2017/02/25x25-mini-icon-hardwood.png',
        background:
        'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2019/02/30145533/Cerakote_shutterstock_173334179.jpg',
    },
    {
        material: 'Cobalt Chrome',
        byline: 'A ring to last a lifetime.',
        link: '/shop/unique-materials/cobalt-chrome',
        icon: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2017/02/25x25-mini-icon-cobalt-chrome.png',
        background: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2017/02/header-cobalt-chrome.jpg',
    },
    {
        material: 'Damascus',
        byline: 'Damascus Steel rings are made of two alternating types of stainless steel.',
        link: '/shop/unique-materials/damascus',
        icon: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2017/02/25x25-mini-icon-damascus.png',
        background: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2017/02/header-damascus.jpg',
    },
    {
        material: 'Diamonds',
        byline: 'Diamonds and gemstones come in a range of colors, shapes, sizes, and sources.',
        link: '/shop/content/diamonds-gemstones',
        icon: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2016/03/25x25-mini-icon-classics.png',
        background: 'http://assets.lashbrookdesigns.com/assets/category/header/bg/DIAMONDS.png',
    },
    {
        material: 'Dinosaur Bone',
        byline: 'Beautiful, rich, dimensional fossil.',
        link: '/shop/unique-materials/dinosaur-bone',
        icon: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2017/02/25x25-mini-icon-precious.png',
        background: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2019/02/30130151/Dino-1528x998.jpg',
    },
    {
        material: 'Hardwood',
        byline: 'The beauty that lies in hardwood is as natural as truly loving someone.',
        link: '/shop/unique-materials/hardwood',
        icon: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2017/02/25x25-mini-icon-hardwood.png',
        background: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2017/02/header-hardwood.jpg',
    },
    {
        material: 'Meteorite',
        byline:
            'Sometimes it takes millions of years to craft the perfect ring. And then have it delivered from space.',
        link: '/shop/unique-materials/meteorite',
        icon: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2017/02/25x25-mini-icon-meteorite.png',
        background: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2017/02/header-meteorite.jpg',
    },
    {
        material: 'Mosaics',
        byline: 'The beauty that lies in hardwood is as natural as truly loving someone.',
        link: '/shop/unique-materials/mosaics',
        icon: 'https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/2022/learn-pages/mosaics/mosaic-icon.png',
        background: 'https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/2022/learn-pages/mosaics/mosaic-background-image.png',
    },
    {
        material: 'Precious Metal',
        byline: 'Sophisticated, classic and traditional.',
        link: '/shop/unique-materials/precious-metal',
        icon: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2016/03/25x25-mini-icon-classics.png',
        background: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2017/02/header-precious.jpg',
    },
    {
        material: 'Shell',
        byline: 'Starting with the most responsible sources to purchase abalone and shell for making jewelry, we cover everything you’re curious about regarding rings made with this lovely gift from the oceans.',
        link: '/shop/unique-materials/shell',
        icon: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2017/02/25x25-mini-icon-cobalt-chrome.png',
        background: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2021/01/02144727/abalone_mother_of_pearl_tahiti_shell.jpg',
    },
        {
        material: 'Superconductor',
        byline: 'Light and dark ultra-cool combination of copper and titanium allow sliced to perfection',
        link: '/shop/unique-materials/superconductor',
        icon: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2017/02/25x25-mini-icon-meteorite.png',
        background: 'https://assets.lashbrookdesigns.com/assets/category/bg/SUPERCONDUCTOR.png',
    },
    {
        material: 'Titanium',
        byline: 'Titanium is a light grey metal with great durability and strength.',
        link: '/shop/unique-materials/titanium',
        icon: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2017/02/25x25-mini-icon-titanium.png',
        background: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2017/02/header-titanium.jpg',
    },
    {
        material: 'Titanium Damascus',
        byline: 'Corrosion resistant, non-magnetic, and lightweight, Titanium Damascus is an eye-catching wonder.',
        link: '/shop/unique-materials/titanium-damascus',
        icon: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2017/02/25x25-mini-icon-titanium.png',
        background: 'https://assets.lashbrookdesigns.com/assets/category/bg/TITANIUM-DAMASCUS.png',
    },
    {
        material: 'Tantalum',
        byline: 'Tantalum rings are dark grey with a heavy, premium weight.',
        link: '/shop/unique-materials/tantalum',
        icon: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2016/03/25x25-mini-icon-classics.png',
        background:
            'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2019/08/21155345/shutterstock_644027071-1-min.jpg',
    },
    {
        material: 'Zirconium',
        byline: 'The black with millions of options.',
        link: '/shop/unique-materials/zirconium',
        icon: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2017/02/25x25-mini-icon-zirconium.png',
        background: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2017/02/header-zirconium.jpg',
    },
];
