import get from 'lodash/get';
import { createSelector } from 'reselect';

export const selectCheckout = state => get(state, 'checkout');

export const makeSelectIsFetching = () =>
    createSelector([selectCheckout], state => get(state, 'isFetching', false));

export const makeSelectCountries = () => createSelector([selectCheckout], countries => get(countries, 'countries', {}));

export const makeSelectStates = () => createSelector([selectCheckout], states => get(states, 'states', {}));

export const makeSelectCountriesBilling = () => createSelector([selectCheckout], countriesBilling => get(countriesBilling, 'countriesBilling', {}));

export const makeSelectStatesBilling = () => createSelector([selectCheckout], statesBilling => get(statesBilling, 'statesBilling', {}));

export const makeSelectAddresses = () => createSelector([selectCheckout], addresses => get(addresses, 'addresses', {}));

export const makeSelectShipping = () => createSelector([selectCheckout], shippingMethods => get(shippingMethods, 'shippingMethods', {}));

export const makeSelectOrder = () => createSelector([selectCheckout], orderDetails => get(orderDetails, 'orderDetails', {}));

export const makeSelectOrderCreate = () => createSelector([selectCheckout], orderCreateData => get(orderCreateData, 'orderCreateData', {}));

export const makeSelectSubscriptionSummary = () => createSelector([selectCheckout], summary => get(summary, 'summary', {}));

export const makeSelectAccountInfo = () => createSelector([selectCheckout], accountInfo => get(accountInfo, 'accountInfo', {}));

export const makeSelectPaymentMethods = () => createSelector([selectCheckout], paymentMethods => get(paymentMethods, 'paymentMethods', {}));

export const makeSelectShippingMethod = () => createSelector([selectCheckout], selectedShipping => get(selectedShipping, 'selectedShipping', {}));
