import { CALL_API, METHODS } from '../../middleware';
import URLs from '../../utils/url';
import { GET_CATEGORY_LIST_ACTION_TYPES } from './constants';

const getCategoryList = () => ({
    [CALL_API]: {
        requestConfig: {
            baseUrl: URLs.apiBaseUrl,
            url: URLs.category,
            method: METHODS.GET,
            params: {},
        },
        types: GET_CATEGORY_LIST_ACTION_TYPES,
    },
});

export { getCategoryList };
