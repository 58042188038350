import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';

const CurrentPlans = props => {
    const { list } = props;
    const [showInfo, toggleInfo] = useState(false);
    const handleToggle = () => toggleInfo(!showInfo);

    if (!list) return <div>No plans yet</div>;
    return (
        <Row className="fs-18">
            <Col xs={12}>
                <h4 className="text-uppercase">Current Plan</h4>
                <hr />
            </Col>
            {list.order_id &&
            <Col xs={7} className="d-flex justify-content-between">
                <span>Order ID:</span><span>{list.order_id}</span>
            </Col>}
            {list.ring_total &&
            <Col xs={7} className="d-flex justify-content-between">
                <span>Ring Total:</span><span>{list.ring_total}</span>
            </Col>}
            {list.total_paid &&
            <Col xs={7} className="d-flex justify-content-between">
                <span>Total Amount Paid:</span><span>{list.total_paid}</span>
            </Col>}
            {list.down_payment &&
            <Col xs={7} className="d-flex justify-content-between">
                <span>Down Payment:</span><span>{list.down_payment}</span>
            </Col>}
            {list.final_invoice_date &&
            <Col xs={7} className="d-flex justify-content-between">
                <span>Last invoice date:</span><span>{new Date(list.final_invoice_date).toLocaleDateString('en-US')}</span>
            </Col>}
            {(list.invoices || list.installment) &&
            <Col xs={12} className="text-center my-2">
                <span onClick={handleToggle}>
                    <span className="d-inline-block mr-1">{`${showInfo ? 'Less' : 'More'} info`}</span>
                    <i className={showInfo ? 'fa fa-angle-up' : 'fa fa-angle-down'} aria-hidden="true"/>
                </span>
            </Col>}
            {showInfo && list.invoices &&
            <Col xs={6} className="mt-3">
                <h5 className="font-weight-bold">Status:</h5>
                <div>{`${list.invoices.remaining?.amount} pending across ${list.invoices.remaining?.count || ''} installments`}</div>
                <div>{`${list.invoices.paid?.amount} paid across ${list.invoices.paid?.count || ''} installments`}</div>
            </Col>}
            {showInfo && list.installment &&
            <Col xs={4} className="mt-3">
                <h5 className="font-weight-bold">Installment info:</h5>
                <div className="d-flex justify-content-between">
                    <span>Monthly Payment:</span><span>{list.installment.monthly_payment || ''}</span>
                </div>
                <div className="d-flex justify-content-between">
                    <span>Sales Tax:</span><span>{list.installment.sales_tax || ''}</span>
                </div>
                <div className="d-flex justify-content-between"><span>Total:</span><span>{list.installment.total || ''}</span>
                </div>
            </Col>}
        </Row>
    );
};

export default CurrentPlans;
