import one from '../../assets/images/1.png';
import two from '../../assets/images/2.png';
import three from '../../assets/images/3.png';
import four from '../../assets/images/4.png';
import five from '../../assets/images/5.png';
import six from '../../assets/images/6.png';
import seven from '../../assets/images/7.png';
import eight from '../../assets/images/8.png';
import nine from '../../assets/images/9.png';
import Lifetime from '../../assets/images/Lifetime_Warranty.png';
import Handcrafted from '../../assets/images/handcrafted_here.png';
import Sizing from '../../assets/images/Sizing_for_Life.png';
import GenuineMaterials from '../../assets/images/Genuine.png';

export const faqList = [
    {
        label: 'How does sizing work?',
        subText:
          '<ul><li>Estimate your finger size on your order.</li><li>We’ll process your order by first sending you a sizing set of five real rings, the size you estimated and two sizes smaller/bigger for the perfect fit.</li><li>It’s important that you use our sizers, we manufacture them with a comfort fit and to a very tight tolerance to ensure the right fit the first time. No need to over stress, with our protection plan we have you covered.</li><li>Wear the sizers for a day or so until your confident, then confirm your size with us.</li><li>We make your ring in the right size.</li></ul>',
        contact: 'Learn More About Sizing from Home',
        href: '/accurate-ring-sizing',
    },
    {
        label: 'How long does it take to receive my custom ring?',
        subText:
          '<ul><li>Rather than pull rings from a shelf, for over 20 years we have made all our rings by hand in Utah. Every order is made to your exact specifications.</li></ul>',
        contact: 'How We Make Custom',
        href: '/shop/unique-materials',
    },
    {
        label: 'Can you rush my ring?',
        subText:
          '<ul><li>After you’ve confirmed your finger size, most rings take between 3-5 weeks to make depending on complexity.</li><li>Don’t panic, lots of people wait until the last minute and need a rush. We can usually accommodate even the worst procrastinators. Call us and let’s see what we can do.</li></ul>',
        contact: 'How Can We Help',
        href: '/contact-us',
    },
    {
        label: 'Do you offer financing?',
        subText:
        'We provide easy and affordable financing through DD Finance, L.C., so you can pay for your purchase over time.',
        contact: 'Finance Options',
        href: '/finance-option',
    },
    {
        label: 'What type of coverage is included?',
        subText:
          '<ul><li>We stand behind our craftsmanship on all our rings, for life.</li><li>For returns, sizing problems, exchanges, cancellations and even lost or stolen rings- we’ve got you covered with our protection plan.</li></ul>',
        contact: 'Protection Plan Details',
        href: '/shop/content/protection-plan',
    },
];

export const swiperList = [
    {
        label: 'Lifetime Warranty',
        content: 'We are committed to the highest quality craftsmanship. Lashbrook jewelry is 100% warrantied against imperfections in materials or manufacturing for life.  This coverage includes refurbishing and refinishing–– so you can enjoy your ring worry-free forever. ',
        src: Lifetime,
    },
    {
        label: 'Handcrafted Here',
        content: 'Lashbrook rings are made from scratch with high-tech machinery and high-touch craftsmanship from our state-of-the-art facility located in Draper, Utah.',
        src: Handcrafted,
    },
    {
        label: 'Sizing for Life',
        content: 'Rings purchased through our retailers are available for resizing through that retailer. Rings purchased directly from Lashbrook receive one complimentary size adjustment with our Protection Plan. Future sizing will cost the same as without the plan- 30% of MSRP.',
        src: Sizing,
    },
    {
        label: 'Genuine Materials',
        content: 'From meteorite (yes, meteorite!) to dinosaur bone to diamonds, all Lashbrook materials are authentic and of the highest quality. We take pride in crafting genuine, lifetime symbols of love.',
        src: GenuineMaterials,
    },
];


export const loveStories1 = one;
export const storiesList = [two, three, four, five, six, seven, eight, nine];
