import React, { Component } from 'react';
import { Tooltip } from 'reactstrap';
import PropTypes from 'prop-types';

class Favorite extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tooltipOpen: false,
        };
        this.favIcon = null;
    }

    toggle = () => {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen,
        });
    };

    render() {
        const { toggleFavorite, isFavorite, id, className } = this.props;
        return (
            <span>
                <i
                    className={`fa fa-2x text-muted cursor-pointer ${isFavorite ? 'fa-heart' : 'fa-heart-o'} favorite-mobile ${className}`}
                    aria-hidden="true"
                    id={id}
                    onClick={toggleFavorite}
                />
                <Tooltip placement="top" isOpen={this.state.tooltipOpen} target={id} toggle={this.toggle}>
                    {isFavorite ? 'Remove From Favorite' : 'Add To Favorite'}
                </Tooltip>
            </span>
        );
    }
}

Favorite.propTypes = {
    isFavorite: PropTypes.bool.isRequired,
    toggleFavorite: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    className: PropTypes.string,
};

export default Favorite;
