import URLs from '../../../utils/url';

const unique = [
    {
        material: 'Camo',
        link: '/shop/unique-materials/camo',
    },
    {
        material: 'Carbon Fiber',
        link: '/shop/unique-materials/carbon-fiber',
    },
    {
        material: 'Cerakote',
        link: '/shop/unique-materials/cerakote',
    },
    {
        material: 'Cobalt Chrome',
        link: '/shop/unique-materials/cobalt-chrome',
    },
    {
        material: 'Damascus',
        link: '/shop/unique-materials/damascus',
    },
    {
        material: 'Dinosaur Bone',
        link: '/shop/unique-materials/dinosaur-bone',
    },
    {
        material: 'Diamonds & Gemstones',
        link: '/shop/content/diamonds-gemstones',
    },
    {
        material: 'Hardwood',
        link: '/shop/unique-materials/hardwood',
    },
    {
        material: 'Meteorite',
        link: '/shop/unique-materials/meteorite',
    },
    {
        material: 'Mosaics',
        link: '/shop/unique-materials/mosaics',
    },
    {
        material: 'Precious Metal',
        link: '/shop/unique-materials/precious-metal',
    },
    {
        material: 'Shell',
        link: '/shop/unique-materials/shell',
    },
    {
        material: 'Superconductor',
        link: '/shop/unique-materials/superconductor',
    },
    {
        material: 'Tantalum',
        link: '/shop/unique-materials/tantalum',
    },
    {
        material: 'Titanium Damascus',
        link: '/shop/unique-materials/titanium-damascus',
    },
    {
        material: 'Titanium',
        link: '/shop/unique-materials/titanium',
    },
    {
        material: 'Zirconium',
        link: '/shop/unique-materials/zirconium',
    },
];

export const learnItems = [
    {
        tab: 'Unique Materials',
        subMenu: unique,
        link: '/shop/unique-materials',
    },
    {
        tab: 'Accurate Ring Sizing',
        link: '/accurate-ring-sizing',
    },
    {
        tab: 'Engravings',
        link: '/shop/content/engravings',
    },
    {
        tab: 'FAQs',
        link: '/shop/content/faqs',
    },
    {
        tab: 'Policies',
        link: '/shop/content/policies',
    },
    {
        tab: 'The Protection Plan',
        link: '/shop/content/protection-plan',
    },
    {
        tab: 'Finance Option',
        link: '/finance-option',
    },
];

export const company = [
    {
        tab: 'Contact',
        link: '/contact-us',
    },
    {
        tab: 'About Us',
        link: '/shop/content/about-us',
    },
    {
        tab: 'Awards',
        link: '/shop/content/awards',
    },
    {
        tab: 'IP Notices',
        link: '/shop/content/lashbrook-designs-patents-trademarks/',
    },
];
