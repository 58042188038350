import React from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

const FinanceOption = () => (
    <>
        <div className="financing-header financing-section financing-style font-universe">
            <div className="financing-content-inner">
                <div className="financing-points">
                    <div className="financing-three-col">
                        <h1 className="text-uppercase">Can we give you a gift for your big day?</h1>
                        <p className="fs-18">Financing with Lashbrook is simple and <br /> Straightforward so you can. <br />Focus
                            on more important things.
                        </p>
                        <Button className="d-none d-lg-inline-block"><Link className="text-white" to="/shop/rings/profiles/D/widths/7/ring_materials/14KW?ring_finish=SATIN&finish_type=F&weight=P&size=10&feature_group=N&sleeve=NONE">Build your ring to see your rates.</Link></Button>
                    </div>
                </div>
            </div>
            <div className="financing-couple">
                <img
                    className="d-none d-lg-block"
                    src="https://wpassets.lashbrookdesigns.com/wp-content/uploads/2020/11/09120732/finance-your-wedding-ring-for-him-or-her-with-USA-ring-maker.png"
                    alt="0% APR Financing Available. We provide easy and affordable way to finance your wedding ring so you can pay for your purchase over time. Check your rate today."
                />
            </div>
        </div>
        <Container className="mt-5 font-universe">
            <Row className="pt-5">
                <Col xs={12} className="text-center">
                    <h3 className="text-uppercase">PAY OVER TIME FOR AS LOW AS 0% APR*</h3>
                    <p>Clear, transparent terms. Fair rates. No prepayment penalties.</p>
                </Col>
                <Col xs={12} lg={4} className="text-center">
                    <img
                        src="https://i.imgur.com/FlZwpDG.png"
                        alt="0% APR Financing Available. We provide easy and affordable way to finance your wedding ring so you can pay for your purchase over time. Check your rate today."
                    />
                    <h3>Affordable Monthly Payments</h3>
                    <p>Pay for your order over 12 months at competitive interest rates as low as 0% APR*. Start
                            by choosing or building a ring.
                    </p>
                </Col>
                <Col xs={12} lg={4} className="text-center">
                    <img src="https://i.imgur.com/OWbJQey.png" alt="thumbs up icon" />
                    <h3>No Prepayment Penalties</h3>
                    <p>Pay for your purchase with monthly payments and prepay at any time without penalty.</p>
                </Col>
                <Col xs={12} lg={4} className="text-center">
                    <img src="https://i.imgur.com/sqFc119.png" alt="clock icon" />
                    <h3>Quick and Easy Application</h3>
                    <p>Make a decision in seconds with no obligation to buy. Seeing your total financed amount
                            and payments are part of the checkout process, so no surprises.
                    </p>
                </Col>
            </Row>
            <Row className="pt-5 d-none d-md-flex">
                <Col xs={12} className="text-center">
                    <h3 className="text-uppercase">FAST AND EASY</h3>
                    <p>Why wait months when you could buy a ring right now? Buy today with easy financing options.</p>
                </Col>
                <Col xs={12} lg={4} className="text-center">
                    <img
                        src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/wp-content/uploads/2019/08/28035825/finance-step1.jpg"
                        alt="finance step"
                        className="finance-helper-img"
                    />
                    <h4 className="text-uppercase">Step 1: Choose your ring</h4>
                    <p>Choose from one of our popular mens or womens wedding bands, or build one specially
                            customized by you, for you.
                    </p>
                </Col>
                <Col xs={12} lg={4} className="text-center">
                    <img
                        src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/wp-content/uploads/2020/11/09140348/financeoptions.png"
                        alt="finance step"
                        className="finance-helper-img"
                    />
                    <h4 className="text-uppercase">Step 2: Choose Finance Options</h4>
                    <p>As soon as you have the ring selected, and proceed to the shipping options, you will see
                            the down payment, finance fees, and montly payments before you hit accept.
                    </p>
                </Col>
                <Col xs={12} lg={4} className="text-center">
                    <img
                        src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/wp-content/uploads/2020/11/09141347/order-summary.png"
                        alt="finance step"
                        className="finance-helper-img"
                    />
                    <h4 className="text-uppercase">Step 3: Check out &amp; pay</h4>
                    <p>After you finish your downpayment, we will send a sizing kit, confirm your size, and get
                            to making your ring. You will be able to make payments here, change payment method, and
                            more.
                    </p>
                </Col>
            </Row>
            <Row className="py-5 text-center fs-20">
                <Col xs={12}>
                    <p>Make a ring and see what your payments will be today!</p>
                    <Button size="lg"><Link className="text-white" to="/shop/rings/profiles/D/widths/7/ring_materials/14KW?ring_finish=SATIN&finish_type=F&weight=P&size=10&feature_group=N&sleeve=NONE">Build your ring</Link></Button>
                </Col>
            </Row>
        </Container>
        <div className="py-5 mt-5 grey-background">
            <Container>
                <Row className="text-center font-universe">
                    <Col xs={12}>
                        <h3>QUESTIONS?</h3>
                        <p className="financing-contact">Call us directly @
                            <a href="tel:888-252-7388">888-252-7388</a> or
                            for other options,
                            <a
                                href="/contact-us"
                                target="_blank"
                                alt="contact lashbrook to discuss financing your wedding rings at 0% interest"
                            >contact us
                            </a>.
                        </p>
                    </Col>
                    <Col xs={12} className="pt-5 mt-3">
                        <p className="fs-12">*Example of terms: You purchase the perfect ring for $500, with a protection plan at around $40, shipped standard for no cost. With a low finance fee of 7.9% and 0% APR Interest, the entire financed amount would be around $672, with an approx. $170 down payment and 11 equal payments of around $46 each. All payments made on time for the life of the term result in a 0% APR Financing.</p>
                    </Col>
                </Row>
            </Container>
        </div>
        <Container>
            <Row className="font-universe py-5">
                <Col xs={12} md={4} className="text-center">
                    <h4 className="font-weight-bold fs-3rem">4.8</h4>
                    <p>Over 150 Reviews</p>
                </Col>
                <Col xs={12} md={8} className="fs-20 text-center">
                    <p>Lashbrook was so great to work with. Ordered and did everything online and it was a great experience. They ensured his sizing was correct before making the ring and sent sizers. Then when we got the ring it came quick and had great packaging! Definitely recommend.</p>
                    <p>Brittany L.</p>
                </Col>
                <Col xs={12} className="text-center text-md-right">
                    <Button size="lg"><Link className="text-white" to="/shop/rings/profiles/D/widths/7/ring_materials/14KW?ring_finish=SATIN&finish_type=F&weight=P&size=10&feature_group=N&sleeve=NONE">Build your ring</Link></Button>
                </Col>
            </Row>
        </Container>
    </>
);

export default FinanceOption;
