import SwatchFilter from '../components/SwatchFilter';
import { ITab, ITabs } from '../types/customizer';
import { getFilterMetaData, getSelectedOption, getWarning } from './commonRingUtils';

export const getShapeItems = (product: any) => {
    const items: ITabs = [
        {
            title: 'Profile',
            label: 'Profile',
            eventKey: 'profile',
            component: SwatchFilter,
            isEnabled: product.profile?.length > 0,
            content: {
                // showDefaultTitle: false,
                // displayShowMoreOption: false,
                // meta: getFilterMetaData(product, 'profile'),
                // warning: getWarning(product, 'profile'),
                hasSwatch: true,
                fieldType: 'profile',
                options: product.profile,
                selectedOption: getSelectedOption(product.profile),
            },
        },
        {
            title: 'Width',
            label: 'Width',
            eventKey: 'width',
            component: SwatchFilter,
            isEnabled: product.width?.length > 0,
            content: {
                // showDefaultTitle: true,
                // displayShowMoreOption: false,
                // meta: getFilterMetaData(product, 'width'),
                // warning: getWarning(product, 'width'),
                hasSwatch: false,
                fieldType: 'width',
                options: product.width,
                selectedOption: getSelectedOption(product.width),
            },
        },
        {
            title: 'Weight',
            label: 'Weight',
            eventKey: 'weight',
            component: SwatchFilter,
            isEnabled: product.weight?.length > 0,
            content: {
                // showDefaultTitle: true,
                // displayShowMoreOption: false,
                // meta: getFilterMetaData(product, 'weight'),
                // warning: getWarning(product, 'weight'),
                hasSwatch: false,
                fieldType: 'weight',
                options: product.weight,
                selectedOption: getSelectedOption(product.weight),
            },
        },
    ];
    const filtered = items.filter((tab: ITab) => tab.isEnabled || tab.items?.length);
    return filtered;
};

export const getV3ShapeItems = (product: any) => {
    const items: ITabs = [
        {
            title: 'Shape',
            label: 'Shape',
            eventKey: 'signet_shape',
            component: SwatchFilter,
            isEnabled: product.shape?.length > 0,
            content: {
                hasSwatch: false,
                fieldType: 'shape',
                options: product.shape,
                selectedOption: getSelectedOption(product.shape),
            },
        },
        {
            title: 'Size',
            label: 'Size',
            eventKey: 'signet_size',
            component: SwatchFilter,
            isEnabled: product.size?.length > 0,
            content: {
                hasSwatch: false,
                fieldType: 'size',
                options: product.size,
                selectedOption: getSelectedOption(product.size),
            },
        },
        {
            title: 'Shank',
            label: 'Shank',
            eventKey: 'signet_shank',
            component: SwatchFilter,
            isEnabled: product.shank?.length > 0,
            content: {
                hasSwatch: false,
                fieldType: 'shank',
                options: product.shank,
                selectedOption: getSelectedOption(product.shank),
            },
        },
        {
            title: 'Weight',
            label: 'Weight',
            eventKey: 'signet_weight',
            component: SwatchFilter,
            isEnabled: product.weight?.length > 0,
            content: {
                hasSwatch: false,
                fieldType: 'weight',
                options: product.weight,
                selectedOption: getSelectedOption(product.weight),
            },
        },
    ];
    const filtered = items.filter((tab: ITab) => tab.isEnabled || tab.items?.length);
    return filtered;
};
