/* eslint-disable camelcase */
/* eslint-disable no-shadow */
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { ICategoryDesc, ICategoryOption, IOption, ISwatchContent } from '../../types/customizer';
import If from '../../../../components/Shared/If';
import questionMark from '../../../../assets/images/question-mark.svg';
import SwatchOption from '../SwatchOption';
import MaterialInfo from './MaterialInfo';
import GroupMaterialInfo from './GroupMaterialInfo';
import closeIcon from '../../../../assets/images/close-icon.svg';
import DiamondInfo from './DiamondInfo';
import PersonalizedMachinePatternInfo from './PersonalizedMachinePatternInfo';

interface ISwatchCategory {
  name: string;
  category_desc?: Array<ICategoryDesc> | null;
  
  options: Array<IOption | ICategoryOption>;
  hasSwatch: boolean;
  fieldType: string;
  selectedOption: IOption;

  onChange: (updated: any) => void;
  isChanging: string;
  rootCategory?: boolean;
}

export const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        zIndex: 100,
        minWidth: '300px',
        transform: 'translate(-50%, -50%)',
    },
};

const SwatchCategory = ({ name, category_desc, options, hasSwatch, fieldType, selectedOption, 
    onChange, isChanging, rootCategory }: ISwatchCategory) => {

    const [enableMoreOption, setEnableMoreOption] = useState(false);
    const [seeMore, setSeeMore] = useState(true);
    const [modalIsOpen, setIsOpen] = useState(false);

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    /*useEffect(() => {
        if (displayShowMoreOption) {
            const isAnySubCategory = items.find(i => i.type === 'category');
            setEnableMoreOption(!isAnySubCategory && items.length > 5);
        }
    }, [displayShowMoreOption, items]);

    const options = enableMoreOption && seeMore ? items.slice(0, 5) : items;*/
    const hasSubCategory = options.find(item => item.type === 'category');

    return (
        <div
            className={clsx({
                'category-wrapper tw-mx-2 tw-pt-2 tw-w-full': true,
                'tw-border-none': hasSubCategory,
                'tw-border-dashed tw-border-gray-100 tw-border-b-2': !rootCategory && !hasSubCategory,
            })}
        >
            {name &&
            <div className="tw-flex tw-text-sm tw-text-blue-900 tw-font-semibold primary-font-family">
                {name}
                {(name === 'Premium Diamond' || name === 'Standard Diamond' || name === 'Personalized') ?
                    <button className="tw-bg-transparent tw-border-none hover:tw-bg-transparent hover:tw-border-none p-0 tw-leading-none focus:tw-outline-0 focus:tw-shadow-none focus:tw-border-none" onClick={openModal}><img src={questionMark} className="tw-pl-1" alt="none" /></button> :
                     null}
            </div>}
            <div className={clsx('tw-flex tw-w-full tw-pr-3', { 'tw-flex-wrap': hasSubCategory || rootCategory, 'tw-overflow-x-auto': !hasSubCategory })}>
                {options.map((option: IOption | ICategoryOption, index: number) => {
                    if (option.type === 'category') {
                        return (
                            <SwatchCategory
                                key={index}
                                name={option.name}
                                category_desc={option.category_desc}
                                
                                options={option.items}
                                hasSwatch={hasSwatch}
                                fieldType={fieldType}
                                selectedOption={selectedOption}

                                onChange={onChange}
                                isChanging={isChanging}
                            />
                        );
                    } else if (option.type === 'option') {
                        return (
                            <SwatchOption
                                key={index}
                                {...option}
                                hasSwatch={hasSwatch}
                                fieldType={fieldType}
                                onChange={onChange}
                                selectedOption={selectedOption}
                                isChanging={isChanging}
                                moreInfo={selectedOption?.description}
                            />
                        );
                    }
                    return null;
                })}
                <If condition={enableMoreOption && hasSwatch}>
                    <div className="tw-w-28 tw-h-28 tw-p-2.5 tw-mr-5 tw-mt-2" role="presentation" onClick={() => setSeeMore(!seeMore)}>
                        <div
                            className="tw-w-full tw-h-full tw-rounded-full tw-flex tw-justify-center tw-items-center tw-cursor-pointer tw-border
              tw-border-solid tw-border-cyan hover:tw-bg-cyan hover:tw-text-white tw-text-sm tw-text-cyan tw-font-semibold"
                        >
                            {seeMore ? 'See More' : 'See Less'}
                        </div>
                    </div>
                </If>
                <If condition={enableMoreOption && !hasSwatch}>
                    <div className="tw-p-2 tw-cursor-pointer" role="presentation" onClick={() => setSeeMore(!seeMore)}>
                        <div
                            className="tw-p-2 tw-rounded-lg tw-cursor-pointer tw-border tw-border-solid
              tw-border-cyan hover:tw-bg-cyan hover:tw-text-white tw-text-sm tw-text-cyan tw-font-semibold"
                        >
                            {seeMore ? 'See More' : 'See Less'}
                        </div>
                    </div>
                </If>
            </div>

            {category_desc?.length && category_desc.length > 1 ?
                <GroupMaterialInfo
                    isOpen={modalIsOpen}
                    onClose={closeModal}
                    descriptions={category_desc}
                /> : null
            }
            {category_desc?.length && category_desc.length === 1 ?
                <Modal
                    isOpen={modalIsOpen}
                    ariaHideApp={false}
                    onRequestClose={closeModal}
                    className="tw-top-0 tw-bottom-0 tw-left-0 tw-right-0 tw-m-auto xs:tw-w-[350px] sm:tw-w-[500px] tw-min-h-[400px] tw-bg-white tw-absolute tw-p-5 material-info-modal secondary-font-family"
                    style={customStyles}
                    overlayClassName="tw-bg-[#999c9fab] tw-fixed tw-inset-0 tw-z-10"
                >
                    <img src={closeIcon} alt="close_icon" className="tw-absolute tw-top-4 tw-right-4 tw-cursor-pointer" role="presentation" onClick={closeModal} />
                    {category_desc && category_desc[0] && <MaterialInfo {...category_desc[0]} />}
                </Modal> : null
            }
            {(name === 'Premium Diamond' || name === 'Standard Diamond') ?
                <Modal
                    isOpen={modalIsOpen}
                    ariaHideApp={false}
                    onRequestClose={closeModal}
                    className="tw-top-0 tw-bottom-0 tw-left-0 tw-right-0 tw-m-auto xs:tw-w-[350px] sm:tw-w-[500px] tw-min-h-[400px] tw-bg-white tw-absolute tw-p-5 material-info-modal secondary-font-family"
                    style={customStyles}
                    overlayClassName="tw-bg-[#999c9fab] tw-fixed tw-inset-0 tw-z-10"
                >
                    <img src={closeIcon} alt="close_icon" className="tw-absolute tw-top-4 tw-right-4 tw-cursor-pointer" role="presentation" onClick={closeModal} />
                    <DiamondInfo />
                </Modal> : null
            }
            {(name === 'Personalized') ?
                <Modal
                    isOpen={modalIsOpen}
                    ariaHideApp={false}
                    onRequestClose={closeModal}
                    className="tw-top-0 tw-bottom-0 tw-left-0 tw-right-0 tw-m-auto xs:tw-w-[350px] sm:tw-w-[500px] tw-min-h-[400px] tw-bg-white tw-absolute tw-p-5 material-info-modal secondary-font-family"
                    style={customStyles}
                    overlayClassName="tw-bg-[#999c9fab] tw-fixed tw-inset-0 tw-z-10"
                >
                    <img src={closeIcon} alt="close_icon" className="tw-absolute tw-top-4 tw-right-4 tw-cursor-pointer" role="presentation" onClick={closeModal} />
                    <PersonalizedMachinePatternInfo />
                </Modal> : null
            }
        </div>);
};

export default SwatchCategory;
