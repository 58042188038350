import Axios from 'axios';
import { CALL_API, METHODS } from '../../middleware';
import URLs from '../../utils/url';
import {
    GET_PRODUCT_DETAIL_ACTION_TYPES,
    GET_PRODUCT_SUMMARY_ACTION_TYPES,
    ADD_PRODUCT_TO_CART_ACTION_TYPES,
    ADD_TO_FAVORITE_ACTION_TYPES,
    GET_SIMILAR_RINGS_ACTION_TYPES,
    RESET_ADD_TO_CART,
    UPDATE_FAVORITES,
    REMOVE_FROM_FAVORITE_ACTION_TYPES,
    GET_STATIC_PRODUCT_DETAIL_ACTION_TYPES,
    RESET_PRODUCT_DATA,
} from './constants';
import AuthManager from '../../utils/AuthManager';
import PricingTypeManager from '../../utils/PricingTypeManager';

let cancelToken;

export const getProductDetail = (url, type = 1) => {
    const priceType = PricingTypeManager.get();
    const userId = AuthManager.getAnonymousUserId();
    const resultantUrl = `${url}${(userId ? `&user_id=${userId}` : '')}`;

    if (typeof cancelToken !== typeof undefined) {
        cancelToken.cancel('Operation canceled due to new request.');
    }

    cancelToken = Axios.CancelToken.source();


    return {
        [CALL_API]: {
            requestConfig: {
                useAuth: true,
                baseUrl: `${URLs.apiBaseUrl}${type === 1 ? URLs.apisV1 : URLs.apisV2}`,
                url: resultantUrl,
                method: METHODS.GET,
                headers: { 'Lashbrook-PTPE': priceType },
                cancelToken: cancelToken.token,
            },
            types: GET_PRODUCT_DETAIL_ACTION_TYPES,
        },
    };
};

export const getStaticProductDetail = (url) => {
    const priceType = PricingTypeManager.get();
    const userId = AuthManager.getAnonymousUserId();
    return {
        [CALL_API]: {
            requestConfig: {
                useAuth: true,
                baseUrl: `${URLs.apiBaseUrl}${URLs.apisV2}`,
                url,
                method: METHODS.POST,
                headers: { 'Lashbrook-PTPE': priceType },
                data: { user_id: userId },
            },
            types: GET_STATIC_PRODUCT_DETAIL_ACTION_TYPES,
        },
    };
};

export const getProductSummary = (url, type = 1) => {
    const priceType = PricingTypeManager.get();
    return {
        [CALL_API]: {
            requestConfig: {
                useAuth: true,
                baseUrl: `${URLs.apiBaseUrl}${type === 1 ? URLs.apisV1 : URLs.apisV2}`,
                url,
                method: METHODS.GET,
                headers: { 'Lashbrook-PTPE': priceType },
            },
            types: GET_PRODUCT_SUMMARY_ACTION_TYPES,
        },
    };
};

export const getSimilarRings = (ringMaterial) => {
    const priceType = PricingTypeManager.get();
    return {
        [CALL_API]: {
            requestConfig: {
                useAuth: true,
                baseUrl: URLs.apiBaseUrl,
                url: `${URLs.similarRings}/${ringMaterial}`,
                method: METHODS.GET,
                headers: { 'Lashbrook-PTPE': priceType },
            },
            types: GET_SIMILAR_RINGS_ACTION_TYPES,
        },
    };
};

export const addProductToCart = (url, cartId, type = 1, data = undefined) => ({
    // specify the cart id to add the item to. If this is blank, the auth token will be used to look up the cart id.
    [CALL_API]: {
        requestConfig: {
            useAuth: true,
            baseUrl: `${URLs.apiBaseUrl}${type === 1 ? URLs.apisV1 : URLs.apisV2}`,
            url,
            method: METHODS.POST,
            headers: { 'Lashbrook-Cart': cartId },
            data,
        },
        types: ADD_PRODUCT_TO_CART_ACTION_TYPES,
    },
});

export const resetAddToCart = () => ({
    type: RESET_ADD_TO_CART,
});

/**
* The below action is added to handle add ring to favorite.
*
* @param favorite
*
* favorite object structure { "product_url": "XYZ", "ring_image_url": "XYZ"}
*
*
* */

export const addToFavorite = favorite => {
    const userId = AuthManager.getAnonymousUserId();
    return {
        [CALL_API]: {
            requestConfig: {
                useAuth: true,
                baseUrl: URLs.apiBaseUrl,
                url: URLs.favorite,
                method: METHODS.POST,
                data: { user_id: userId, ...favorite },
            },
            types: ADD_TO_FAVORITE_ACTION_TYPES,
        },
    };
};


export const removeFromFavorite = favorite => {
    const userId = AuthManager.getAnonymousUserId();
    const url = `${URLs.favorite}${(userId ? `?user_id=${userId}` : '')}`;
    return {
        [CALL_API]: {
            requestConfig: {
                useAuth: true,
                baseUrl: URLs.apiBaseUrl,
                url,
                method: METHODS.DELETE,
                data: favorite,
            },
            types: REMOVE_FROM_FAVORITE_ACTION_TYPES,
        },
    };
};

/**
 * updateFavorites will update the is_favorite flag under product in redux store.
 *
 * @param payload
 *
 * payload is a boolean flag containing updated values of is_favorite flag.
 *
 * */

export const updateFavorites = payload => ({
    type: UPDATE_FAVORITES,
    payload,
});

export const resetProductData = () => ({
    type: RESET_PRODUCT_DATA,
});
