import React, { useEffect, useState } from 'react';
import { IOption, ISwatchContent } from '../../types/customizer';
import { extendTailwindMerge } from 'tailwind-merge';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';
import { defaultRingUrls } from '../../utils/basic';

export interface IBoxFilterProps {
  match: any;
  location: any;
  history: any;
  content: ISwatchContent;
  onChange: (updated: any) => void;
  innerTab?: number;
  isFetching: boolean;
 };

 const twMerge = extendTailwindMerge({prefix: 'tw-'})

const BoxFilter = ({ content, onChange, isFetching, ...rest }: IBoxFilterProps) => {

  const options: Array<IOption> = content.options as Array<IOption>;
  const { fieldType } = content;

  const [isChanging, setIsChanging] = useState('');

  useEffect(() => {
      if (!isFetching) {
        setIsChanging('');
      }
  }, [isFetching]);

  const handleChange = (option: IOption) => () => {
      if(!option.selected) {
        if(fieldType === 'category') {
          switch (option.sku) {
            case 'band':
              rest.history.push(`/shop${defaultRingUrls.band}`);
              break;
            case 'womens':
              rest.history.push(`/shop${defaultRingUrls.womens}`);
              break;
            case 'collegiate_signet':
              rest.history.push(`/shop${defaultRingUrls.collegiate_signet}`);
              break;
            case 'signet':
              rest.history.push(`/shop${defaultRingUrls.signet}`);
              break;
            default:
              break;
          }
        } else if(fieldType === 'types') {
          switch (option.sku) {
            case 'collegiate_signet':
              rest.history.push(`/shop${defaultRingUrls.collegiate_signet}`);
              break;
            default:
              break;
          }
        }
        setIsChanging(option.sku); // used for deseclecting old one till response recieved
      }
      // onChange(updatedObj);
  };

  return (
      <div className="tw-h-full tw-overflow-auto">
        <div className='tw-flex tw-flex-wrap tw-px-6 tw-py-4 swatch-filter-border lg:tw-gap-6 md:tw-gap-4 xs:tw-gap-2'>
          {options.map((option: IOption, index: number) => (
            <div className={clsx('tw-relative', { 'selected-border-square': isChanging === option.sku  })}>
              <div role="presentation" onClick={handleChange(option)} className={twMerge(clsx({
                'tw-flex tw-flex-col tw-justify-center tw-items-center tw-border tw-border-solid tw-border-gray-400 tw-rounded tw-cursor-pointer': true,
                'tw-border-dashed tw-border-black': option.selected && !isChanging
              }))}>
                <img src={option.swatch_url} alt={option.name} className='lg:tw-w-48 lg:tw-h-24 md:tw-w-44 md:tw-h-22 xs:tw-w-40 xs:tw-h-20 tw-p-2'/>
                <div className='secondary-font-family tw-text-sm tw-mb-2'>{option.name}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
  );
};

export default withRouter(BoxFilter);
