import React from 'react';

const SavedPayment = ({ paymentMethods, removePaymentMethod, paymentMethod, setMethod, newCard, directOrder }) => {
    const renderPaymentMethods = () =>
        paymentMethods && paymentMethods.data && paymentMethods.data.map((method, i, arr) => (
            <li key={`${method.last_four}-${i}`} className="py-2">
                <input type="radio" name="payment-source" value={method.id} checked={paymentMethod === method.id && !newCard} onChange={() => handlePaymentChange(method.id)} />
                <span className="d-inline-block ml-3">{`**** **** **** ${method.last_four}`}</span>
                <span className="d-inline-block ml-3">{`Valid through: ${method.exp_month}/${method.exp_year}`}</span>
                {arr.length > 1 && <i className="fa fa-trash-o" aria-hidden="true" onClick={() => removePaymentMethod(method.id)} />}
            </li>
        ));

    const handlePaymentChange = id => setMethod(id);

    if (!paymentMethods.data) return null;
    return (
        <div className="font-universe">
            <div className="fs-18 font-weight-bold">Saved Payment Methods:</div>
            <ul className={`saved-methods-list ${directOrder && 'saved-payments-direct'}`}>
                {renderPaymentMethods()}
            </ul>
        </div>);
};

export default SavedPayment;
