import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Container, Row, Form, FormGroup, FormText, Input, Col, Button } from 'reactstrap';
import Loader from '../Shared/Loader';
import '../Customer/styles.css';

const Manage = ({ emailPortal, isFetching = false }) => {
    const [email, setEmail] = useState('');

    const handleSubmit = async e => {
        e.preventDefault();
        emailPortal({ email })
            .then(() => toast.success('Please check your email to access the financing portal', { className: 'toast-success' }))
            .catch(() => toast.error('Something went wrong, please try again', { className: 'toast-error' }));
    };

    return (
        <Container className="forgot-password-container d-flex flex-column justify-content-center">
            <Row className="flex-column">
                <Col xs={12} md={6} lg={5} className="mx-auto d-flex flex-column align-items-center manage-subscription">
                    <h5 className="text-center">Manage Financing</h5>
                    <Form className="mt-2 d-flex flex-column forgot-password-form" onSubmit={handleSubmit}>
                        <FormGroup>
                            <FormText className="font-universe fs-18">
                                Please enter your registered email id to receive a link to manage your financing.
                            </FormText>
                            <Input
                                className="mt-3"
                                type="text"
                                name="username"
                                id="username"
                                placeholder="Email"
                                onChange={e => setEmail(e.target.value)}
                                value={email}
                                required
                            />
                        </FormGroup>
                        <Button>Submit</Button>
                        <Loader timedOut={isFetching} />
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

Manage.propTypes = {
    emailPortal: PropTypes.func,
    isFetching: PropTypes.bool,
};
export default Manage;
