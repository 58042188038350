import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, Link } from 'react-router-dom';
import { Card, Row, CardTitle, CardText, CardBody, Container, Col, Button } from 'reactstrap';
import { toast } from 'react-toastify';
import Loader from '../Shared/Loader';

const Address = ({ isFetching, getAllAddresses, addresses, deleteAddress }) => {
    const history = useHistory();

    useEffect(() => {
        async function fetchData() {
            await getAllAddresses();
        }
        fetchData().catch(() => toast.error('Unable to get Addresses', { className: 'toast-error' }));
    }, []);

    const handleDelete = async id => {
        try {
            await deleteAddress(id);
            toast.success('Address Deleted', { className: 'toast-success' });
            history.push('/shop/customer/address');
        } catch (e) {
            toast.error('Unable to delete Address', { className: 'toast-error' });
        }
        getAllAddresses();
    };

    const renderAddresses = () =>
        addresses.data.map(address => (
            <Col lg={6} key={address.id} className="mt-2">
                <Card>
                    <CardBody className="text-capitalize">
                        <CardTitle>{`${address.first_name} ${address.last_name}`}</CardTitle>
                        <CardText className="mb-0">{address.street_address1}</CardText>
                        <CardText className="mb-0">{address.street_address2}</CardText>
                        <CardText className="mb-0">{address.city}</CardText>
                        <CardText className="mb-0">{address.state}</CardText>
                        <CardText className="mb-0">{address.zip}</CardText>
                        <CardText className="mb-0">
                            <a href={`tel:${address.phone_number}`}>{address.phone_number}</a>
                        </CardText>
                    </CardBody>
                </Card>
            </Col>
        ));

    if (!addresses || !addresses.data) return null;

    return (
        <Container>
            <div>Addresses: </div>
            <Row className="font-universe d-lg-flex">
                {addresses && addresses.data && addresses.data.length > 0 ? (
                    renderAddresses()
                ) : (
                    <Col xs={12} className={`${isFetching && 'd-none'} text-center font-universe py-5 fs-20`}>
                        No addresses added yet
                    </Col>
                )}
            </Row>
            <Link className="font-universe mt-3 ml-2 d-inline-block" to="/shop/customer/account">
                Go Back
            </Link>
            <Loader timedOut={isFetching} />
        </Container>
    );
};

Address.propTypes = {
    isFetching: PropTypes.bool,
    getAllAddresses: PropTypes.func,
    addresses: PropTypes.object,
    deleteAddress: PropTypes.func,
};
export default Address;
