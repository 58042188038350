import React, { useState, useEffect } from 'react';
import { Table, Button } from 'reactstrap';
import { toast } from 'react-toastify';
import TablePagination from '../Open Transactions/TablePagination';
import getDetailsPDF from './getPdf';
import { PAGE_LIMIT_OPEN_TRANSACTIONS, translatedSizeInvoices } from '../../../containers/CustomerContainer/constants';

const InvoicesTable = props => {
    const { data, isFetching, page, pageSize, setPage, setPageSize, pageCount, sort, setSort, handleStripeSession, handleAging = null, isAging, getTransactionInfo, recordType, totalAmountDue } = props;
    const [selectAll, handleSelectAll] = useState(true);
    const [selectOne, handleSelectOne] = useState({});
    const [total, setTotal] = useState(0);

    useEffect(() => {
        if (typeof data !== 'undefined') {
            const invoiceObject = data.reduce((invoices, nextInvoice) => ({ ...invoices, [nextInvoice.tranNumber]: nextInvoice.amountDue }), {});
            handleSelectOne(invoiceObject);
        }
    }, [data]);

    useEffect(() => {
        let isNotSelectAll;
        const totalAmount = Object.keys(selectOne).reduce((invoice, nextInvoice) => {
            if (!selectOne[nextInvoice]) {
                isNotSelectAll = true;
            }
            return invoice + selectOne[nextInvoice];
        }, 0);
        setTotal(totalAmount);
        if (selectAll && isNotSelectAll) {
            handleSelectAll(false);
        }
    }, [selectOne]);

    useEffect(() => {
        handleSelectAll(true);
    }, [page]);

    if (!data) return null;

    const renderTable = () => data.map((record) => (
        <tr key={record.tranNumber}>
            <td>
                <input
                    type="checkbox"
                    value={record.tranNumber}
                    name={record.tranNumber}
                    onChange={() => handleInvoiceSelect(record.tranNumber, record.amountDue)}
                    checked={selectOne[record.tranNumber]}
                />
            </td>
            <td>{record.webStoreOrderId}</td>
            <td>{record.tranNumber}</td>
            <td>{record.poNumber}</td>
            <td>{record.RelatedSO}</td>
            <td>{record.customer}</td>
            <td>{record.date}</td>
            <td>{`$${record.amountDue}`}</td>
            <td>{`$${record.totalAmount}`}</td>
            <td className="pdf-link" onClick={() => handlePdfDownload(record.tranNumber)}>Download Details</td>
        </tr>
    ));

    const handleInvoiceSelect = (id, amount) => {
        let updatedSelection;

        if (selectOne[id]) {
            updatedSelection = { ...selectOne, [id]: false };
        } else {
            updatedSelection = { ...selectOne, [id]: amount };
        }
        handleSelectOne(updatedSelection);
    };
    const handleInvoiceSelectAll = () => {
        handleSelectAll(!selectAll);
        if (!selectAll) {
            const invoices = data.reduce((all, record) => ({ ...all, [record.tranNumber]: record.amountDue }), {});
            handleSelectOne(invoices);
        } else {
            handleSelectOne({});
        }
    };

    const handlePayNow = () => {
        const invoices = Object.keys(selectOne).filter(invoice => selectOne[invoice]);
        handleStripeSession(invoices);
    };

    const handlePdfDownload = id => {
        const formattedId = id.slice(3);
        getTransactionInfo({ recordType, transactionid: formattedId })
            .then(({ payload: { data: pdfData } }) => {
                const { PDFBinary } = pdfData[0];
                getDetailsPDF(PDFBinary, id);
            })
            .catch(() => {
                toast.error('Something went wrong, please try again', { className: 'toast-error' });
            });
    };

    return (
        <>
            <div className="d-flex font-universe fs-20 align-items-center justify-content-center mb-2 mb-md-0">
                <span className="d-inline-block mr-2">Total Outstanding Amount: </span>
                <span>{` $${totalAmountDue?.toFixed(2)}`}</span>
            </div>
            <div className="d-flex justify-content-between flex-column flex-md-row">
                <div className="align-self-center align-self-lg-start d-flex font-universe fs-18 align-items-center order-2 order-md-1">
                    <Button className="outlined-button" onClick={handleAging}>{isAging ? 'Show All' : 'Show 30+ days past Due'}</Button>
                </div>
                <div className="align-self-center align-self-lg-end d-flex flex-column font-universe fs-18 align-items-center order-1 order-md-2">
                    <span className="d-flex align-items-center">
                        <div className="mr-3">{`$${total.toFixed(2)}*`}</div>
                        <Button className="pay-invoices" onClick={handlePayNow} disabled={Object.keys(selectOne).filter(record => selectOne[record]).length < 1}>Pay Now</Button>
                    </span>
                    <div className="fs-14 mb-2">*Amount due for current page of invoices</div>
                </div>
            </div>
            {data ? (
                <Table hover className="font-universe transactions-table">
                    <thead>
                        <tr>
                            <th><input type="checkbox" onChange={handleInvoiceSelectAll} checked={selectAll} /></th>
                            <th>Web ID #</th>
                            <th>INV #</th>
                            <th>PO #</th>
                            <th>SO #</th>
                            <th>Name</th>
                            <th onClick={setSort} className="d-flex align-items-center cursor-pointer">Date<i className={`${sort === 'OldToNew' ? 'fa fa-angle-up' : 'fa fa-angle-down'} ml-1`} aria-hidden="true" /></th>
                            <th>Amount Due</th>
                            <th>Invoice Total</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {renderTable()}
                    </tbody>
                    <TablePagination
                        pageSize={pageSize}
                        pageCount={pageCount}
                        currentPage={page}
                        setPage={setPage}
                        setPageSize={setPageSize}
                        translatedSize={translatedSizeInvoices}
                    />
                </Table>
            ) : (
                <div className={`${isFetching && 'd-none'} text-center font-universe fs-20`}>
                    No data
                </div>
            )}
        </>
    );
};

export default InvoicesTable;
