import {
    GET_ORDER_ACTION_TYPES,
    RECHECK_ORDER_ACTION_TYPES,
    CLONE_CART_ITEMS_ACTION_TYPES,
    HIDE_LOADER,
} from './constants';

const initialData = {
    isFetching: false,
    orderData: {},
};

export default function orderReducer(state = initialData, { payload, ...action }) {
    switch (action.type) {
        case GET_ORDER_ACTION_TYPES.request:
        case CLONE_CART_ITEMS_ACTION_TYPES.request:
        case RECHECK_ORDER_ACTION_TYPES.request:
            return {
                ...state,
                isFetching: true,
            };
        case GET_ORDER_ACTION_TYPES.success:
            return {
                ...state,
                orderData: payload,
            };
        case RECHECK_ORDER_ACTION_TYPES.success:
            return {
                ...state,
                orderData: payload,
            };
        case CLONE_CART_ITEMS_ACTION_TYPES.success:
        case GET_ORDER_ACTION_TYPES.failure:
        case RECHECK_ORDER_ACTION_TYPES.failure:
        case CLONE_CART_ITEMS_ACTION_TYPES.failure:
        case HIDE_LOADER:
            return {
                ...state,
                isFetching: false,
            };
        default:
            return state;
    }
}
