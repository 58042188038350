import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import queryString from 'qs';
import { toast } from 'react-toastify';
import 'react-datepicker/dist/react-datepicker.css';
import { useLocation, useHistory } from 'react-router-dom';
import Loading from '../../Shared/Loader';
import Filters from '../Open Transactions/Filters';
import { PAGE_LIMIT_OPEN_TRANSACTIONS } from '../../../containers/CustomerContainer/constants';
import PaymentsTable from './Table';

const History = ({ isFetching, getOrderHistory, orderHistory, getTransactionInfo }) => {
    const { search, pathname } = useLocation();
    const history = useHistory();
    const query = queryString.parse(search.slice(1));

    const [page, setPage] = useState(query.page || 1);
    const [pageSize, setPageSize] = useState(query.limit || PAGE_LIMIT_OPEN_TRANSACTIONS);

    async function fetchData(params) {
        await getOrderHistory(params);
    }

    useEffect(() => {
        fetchData(query)
            .catch(response => {
                const errors = response?.response?.data?.errors;
                if (typeof errors !== 'undefined') {
                    const error = Object.keys(errors)[0];
                    toast.error(errors[error][0], { className: 'toast-error' });
                }
            });
    }, [search]);


    const updatePageNumber = pageNumber => {
        setPage(pageNumber + 1);
        const params = { ...query, page: pageNumber + 1 };
        history.push(`${pathname}?${queryString.stringify(params)}`);
    };

    const updatePageSize = pageSize => {
        setPageSize(pageSize);
        const params = { ...query, limit: pageSize, page: 1 };
        history.push(`${pathname}?${queryString.stringify(params)}`);
    };

    if (!isFetching && (!orderHistory || Object.keys(orderHistory).length < 1)) return <div>No records found</div>;
    return (
        <Container className="order-history-container d-flex flex-column justify-content-center">
            <Row className="flex-column">
                <Col xs={12} md={12} className="mx-auto d-flex flex-column">
                    <h5 className="text-center">{`${query.recordType} history`}</h5>
                </Col>
                <Filters
                    updateSearch={getOrderHistory}
                    page={page}
                    limit={PAGE_LIMIT_OPEN_TRANSACTIONS}
                />
                <Col xs={12} md={12} className="mx-auto d-flex flex-column">
                    <PaymentsTable
                        recordType={query.recordType}
                        data={orderHistory.data}
                        isFetching={isFetching}
                        page={page - 1}
                        isOrderHistory
                        pageSize={pageSize}
                        setPage={updatePageNumber}
                        setPageSize={updatePageSize}
                        pageCount={orderHistory.totalPages}
                        getTransactionInfo={getTransactionInfo}
                    />
                </Col>
            </Row>
            <Loading timedOut={isFetching} />
        </Container>
    );
};
History.propTypes = {
    isFetching: PropTypes.bool,
    getOrderHistory: PropTypes.func,
    orderHistory: PropTypes.object,
};

export default History;
