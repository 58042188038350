import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

export const MaterialCard = ({ info }) => {
    const { background, icon, material, byline, link } = info;
    return (
        <div className="materials-card" style={{ backgroundImage: `url(${background})` }}>
            <div className="material-slider d-flex flex-column align-items-center font-syncopate">
                <img src={icon} alt={`icon-${material}`} width={25} />
                <h3 className="text-uppercase mt-3 material-header">{material}</h3>
                <p className="font-open-sans-condensed fs-20">{byline}</p>
                <Link to={link}><Button className="materials-button">{`More on ${material}`}</Button></Link>
            </div>
        </div>
    );
};
