import get from 'lodash/get';
import { createSelector } from 'reselect';

export const selectOrder = global => get(global, 'order');

export const makeSelectOrder = () =>
    createSelector([selectOrder], order => get(order, 'orderData', {}));

export const makeSelectIsFetching = () =>
    createSelector([selectOrder], order => get(order, 'isFetching', false));
