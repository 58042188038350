import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Form, FormGroup, Label, Col } from 'reactstrap';
import { useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { toast } from 'react-toastify';
import Axios from 'axios';
import If from '../../components/Shared/If';
import './styles.scss';
import URLs from '../../utils/url';


const MarketingMaterials = () => {
    const history = useHistory();
    const { register, handleSubmit, getValues } = useForm();
    const [needDimensions, setNeedDimensions] = useState(false);
    const handleChange = (event) => {
        const { value } = event.target;
        if (value === 'Poster' || value === 'Billboard' || value === 'Website Banner') {
            setNeedDimensions(true);
        } else {
            setNeedDimensions(false);
        }
    };
    // Calculate the minimum selectable date (3 days from now)
    const minSelectableDate = new Date();
    minSelectableDate.setDate(minSelectableDate.getDate() + 3);
    const handleSave = async () => {
        const values = getValues();
        // Adding the selected date to the values object since we dont get it as part of the form
        values.date_needed = selectedDate.toLocaleDateString();
        try {
            await Axios.post(URLs.apiBaseUrl + URLs.requestMarketingMaterials, values);
            toast.success('Request submitted successfully', { className: 'toast-success' });
            history.push('/');
        } catch (error) {
            toast.error('Something went wrong, please try after sometime.', { className: 'toast-error' });
        }
    };
    const [selectedDate, setSelectedDate] = useState(minSelectableDate);

    return (
        <div className="marketing-materials">
            <div className="marketing-materials-heading tw-mb-5">Request Marketing Materials</div>
            <div className="marketing-materials-body">
                <div className="tw-mb-6">
                    <div className="tw-mb-4">
                        We have a number of assets available on Dropbox for you to download and use. If you see something you like but need some adjustments you can provide the Dropbox link in the form below and submit a request.
                    </div>
                    <button className="dropbox-button"><a href="https://www.dropbox.com/sh/0le0wnzpf511dwy/AABmAIi0t5FXojv-hD19_74Ma?dl=0" target="_blank">Browse Dropbox</a></button>
                </div>
                <div>
                    <div>
                        Need additional materials? Please provide more info and we’ll be in touch soon to lend a hand.
                    </div>
                    <Form className="tw-mt-10" onSubmit={handleSubmit(handleSave)}>
                        <div className="tw-flex tw-flex-wrap">
                            <Col lg={5}>
                                <FormGroup>
                                    <Label className="required tw-mt-5" for="needed">
                                        What do you need?
                                    </Label>
                                    <select
                                        name="needed"
                                        id="needed"
                                        placeholder="Country"
                                        ref={register}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="">Select an Option</option>
                                        <option value="Poster">Poster</option>
                                        <option value="Billboard">Billboard</option>
                                        <option value="Website Banner">Website Banner</option>
                                        <option value="Product Images (for website)">Product Images (for website)</option>
                                        <option value="Product Images (for print)">Product Images (for print)</option>
                                        <option value="Other">Other</option>
                                    </select>
                                    <If condition={needDimensions}>
                                        <Label className="required tw-mt-5" for="dimensions">
                                            Exact Dimensions Needed
                                        </Label>
                                        <input
                                            type="text"
                                            name="dimensions"
                                            id="dimensions"
                                            ref={register}
                                            required
                                        />
                                    </If>
                                    <Label className="required tw-mt-5" for="more-info">
                                        More info
                                    </Label>
                                    <textarea
                                        name="more-info"
                                        id="more-info"
                                        placeholder="Desired file type, link to the dropbox file you’d like us to modify, text you’d like to add or change etc."
                                        ref={register}
                                        required
                                        className="tw-h-32 tw-w-full tw-p-2 tw-border tw-border-gray-400 tw-rounded-md"
                                    />
                                    <Label className="required tw-mt-5" for="name">
                                        Name
                                    </Label>
                                    <input
                                        type="text"
                                        name="name"
                                        id="name"
                                        ref={register}
                                        required
                                    />
                                    <Label className="required tw-mt-5" for="email">
                                        Email
                                    </Label>
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        ref={register}
                                        required
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg={7} className="tw-text-center">
                                <FormGroup>
                                    <Label className="tw-mt-5" for="date">
                                        When do you need it by?<br />If you need it within the next few days, please call us<br />801-352-7388
                                    </Label>
                                    <DatePicker
                                        name="date"
                                        id="date"
                                        minDate={minSelectableDate}
                                        required
                                        selected={selectedDate}
                                        onChange={(date) => setSelectedDate(date)}
                                        inline
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg={6} className="submit-column">
                                <FormGroup>
                                    <Button type="submit" className="submit-button">
                                        Submit Request
                                    </Button>
                                </FormGroup>
                            </Col>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default MarketingMaterials;
