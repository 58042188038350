import {
    GET_CART_DETAIL_ACTION_TYPES,
    CREATE_ORDER_ACTION_TYPES,
    GET_STATES,
    GET_COUNTRIES,
    RESET_CHECKOUT_DATA,
    CREATE_CUSTOMER_ACTION_TYPES,
    GET_ALL_ADDRESSES,
    GET_SHIPPING_DETAILS_ACTION_TYPES,
    GET_ORDER_DETAIL_ACTION_TYPES,
    GET_SUBSCRIPTION_SUMMARY,
    GET_ORDER_SUMMARY,
    GET_PAYMENT_INTENT,
    CREATE_SUBSCRIPTION,
    GET_PAYMENT_METHODS,
    REMOVE_PAYMENT_METHOD,
    SAVE_PAYMENT_METHOD,
    GET_ACCOUNT_INFO_ACTION_TYPES,
    SET_SHIPPING_METHOD,
    CREATE_DIRECT_ORDER,
} from './constants';
import AuthManager from '../../utils/AuthManager';

const initialData = {
    isFetching: false,
    addresses: {},
    countries: {},
    states: {},
    accountInfo: {},
    shippingMethods: {},
    summary: {},
    orderDetails: {},
    orderCreateData: {},
    paymentMethods: {},
    selectedShipping: '1',
};

export default function checkoutReducer(state = initialData, { payload, ...action }) {
    switch (action.type) {
        case GET_CART_DETAIL_ACTION_TYPES.request:
        case CREATE_ORDER_ACTION_TYPES.request:
        case GET_STATES.request:
        case GET_COUNTRIES.request:
        case CREATE_CUSTOMER_ACTION_TYPES.request:
        case GET_ALL_ADDRESSES.request:
        case GET_SHIPPING_DETAILS_ACTION_TYPES.request:
        case GET_ORDER_DETAIL_ACTION_TYPES.request:
        case GET_SUBSCRIPTION_SUMMARY.request:
        case GET_ORDER_SUMMARY.request:
        case GET_PAYMENT_INTENT.request:
        case CREATE_SUBSCRIPTION.request:
        case CREATE_DIRECT_ORDER.request:
        case GET_PAYMENT_METHODS.request:
        case SAVE_PAYMENT_METHOD.request:
        case REMOVE_PAYMENT_METHOD.request:
        case GET_ACCOUNT_INFO_ACTION_TYPES.request:
            return {
                ...state,
                isFetching: true,
            };
        case GET_ACCOUNT_INFO_ACTION_TYPES.success:
            return {
                ...state,
                accountInfo: payload.data,
                isFetching: false,
            };
        case GET_ORDER_DETAIL_ACTION_TYPES.success:
        case GET_ORDER_DETAIL_ACTION_TYPES.failure:
            return {
                ...state,
                isFetching: false,
                orderDetails: payload,
            };
        case GET_SUBSCRIPTION_SUMMARY.success:
        case GET_ORDER_SUMMARY.success:
            return {
                ...state,
                isFetching: false,
                summary: payload,
                selectedShipping: payload.shipping_method_id || '1',
            };
        case GET_ORDER_SUMMARY.failure:
        case GET_SUBSCRIPTION_SUMMARY.failure:
            return {
                ...state,
                isFetching: false,
            };
        case GET_CART_DETAIL_ACTION_TYPES.success:
            return {
                ...state,
                isFetching: false,
                products: payload.products,
                cartSummary: payload.summary,
                additional: payload.additional,
            };
        case CREATE_ORDER_ACTION_TYPES.success:
            return {
                ...state,
                orderCreateData: payload,
            };
        case GET_ALL_ADDRESSES.success:
        case GET_ALL_ADDRESSES.failure:
            return {
                ...state,
                isFetching: false,
                addresses: payload,
            };
        case GET_PAYMENT_METHODS.success:
            return {
                ...state,
                isFetching: false,
                paymentMethods: payload,
            };
        case CREATE_ORDER_ACTION_TYPES.failure:
        case GET_PAYMENT_INTENT.failure:
        case CREATE_SUBSCRIPTION.success:
        case CREATE_SUBSCRIPTION.failure:
        case REMOVE_PAYMENT_METHOD.success:
        case GET_PAYMENT_METHODS.failure:
        case SAVE_PAYMENT_METHOD.failure:
        case REMOVE_PAYMENT_METHOD.failure:
        case GET_ACCOUNT_INFO_ACTION_TYPES.failure:
        case CREATE_DIRECT_ORDER.success:
        case CREATE_DIRECT_ORDER.failure:
        case CREATE_CUSTOMER_ACTION_TYPES.failure:
            return {
                ...state,
                isFetching: false,
            };
        // case SAVE_PAYMENT_METHOD.success:
        // case GET_PAYMENT_INTENT.success:
        //     return {
        //         ...state,
        //         isFetching: false,
        //     };
        case CREATE_CUSTOMER_ACTION_TYPES.success:
            AuthManager.setGuestToken(payload.access_token);
            return {
                ...state,
                isFetching: false,
            };

        case GET_COUNTRIES.success:
        case GET_COUNTRIES.failure:
            return {
                ...state,
                isFetching: false,
                countries: payload,
            };

        case GET_STATES.success:
        case GET_STATES.failure:
            return {
                ...state,
                isFetching: false,
                states: payload,
            };

        case GET_SHIPPING_DETAILS_ACTION_TYPES.success:
        case GET_SHIPPING_DETAILS_ACTION_TYPES.failure:
            return {
                ...state,
                isFetching: false,
                shippingMethods: payload,
            };

        case SET_SHIPPING_METHOD:
            return {
                ...state,
                selectedShipping: payload,
            };
        case RESET_CHECKOUT_DATA:
            return {
                ...state,
                ...initialData,
            };
        default:
            return state;
    }
}
