import { CALL_API, METHODS } from '../../middleware';
import URLs from '../../utils/url';
import {
    GET_RING_LIST_ACTION_TYPES,
    RESET_CATEGORY,
    PAGINATED_GET_RING_LIST_ACTION_TYPES,
    TOGGLE_FAVORITE,
    TOGGLE_CATEGORIES_FAVORITE,
    GET_FAVORITE_RING_LIST_ACTION_TYPES,
    SYNC_FAVORITES,
} from './constants';
import AuthManager from '../../utils/AuthManager';
import PricingTypeManager from '../../utils/PricingTypeManager';

const getCategoryProductList = (category, pageNumber = 0, pageSize, isPaginatedSearch, queryParams) => {
    const priceType = PricingTypeManager.get();
    const userId = AuthManager.getAnonymousUserId();
    const updatedQueryParams = userId ? { user_id: userId, ...queryParams } : { ...queryParams };
    const url = `${URLs.category}/${category}`;
    return ({
        [CALL_API]: {
            requestConfig: {
                baseUrl: URLs.apiBaseUrl,
                useAuth: true,
                url,
                method: METHODS.GET,
                params: { page_number: pageNumber, page_size: pageSize, ...updatedQueryParams },
                headers: { 'Lashbrook-PTPE': priceType },
            },
            types: isPaginatedSearch ? PAGINATED_GET_RING_LIST_ACTION_TYPES : GET_RING_LIST_ACTION_TYPES,
        },
    });
};

/**
 *
 * getFavoriteProductList will get the list of products marked as a favorite by the user.
 *
 * */

const getFavoriteProductList = () => {
    const userId = AuthManager.getAnonymousUserId();
    const url = `${URLs.favorite}${(userId ? `?user_id=${userId}` : '')}`;
    return ({
        [CALL_API]: {
            requestConfig: {
                useAuth: true,
                baseUrl: URLs.apiBaseUrl,
                url,
                method: METHODS.GET,
            },
            types: GET_FAVORITE_RING_LIST_ACTION_TYPES,
        },
    });
};

/**
 * toggleFavorite will update the is_favorite flag under product in redux store.
 *
 * @param payload
 *
 * payload is a boolean flag containing updated values of is_favorite flag.
 *
 * @param isFavorite
 *
 * */

const toggleFavorite = (payload, isFavorite) => ({
    type: isFavorite ? TOGGLE_FAVORITE : TOGGLE_CATEGORIES_FAVORITE,
    payload,
});

/**
 * The below action is added to sync the favorites added by user as a guest(non logged in user)
 *
 * @param favorites
 *
 * */

const syncFavorites = (favorites) => ({
    [CALL_API]: {
        requestConfig: {
            useAuth: true,
            baseUrl: URLs.apiBaseUrl,
            url: URLs.syncFavorites,
            method: METHODS.POST,
            data: favorites,
        },
        types: SYNC_FAVORITES,
    },
});

const resetCategory = () => ({
    type: RESET_CATEGORY,
});

export { getCategoryProductList, resetCategory, toggleFavorite, getFavoriteProductList, syncFavorites };
