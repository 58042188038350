/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { isEmpty } from '../../utils/basic';
import { ICategoryDesc } from '../../types/customizer';
import Rating from '../Rating';
import If from '../../../../components/Shared/If';
import MaterialInfo from './MaterialInfo';
import closeIcon from '../../../../assets/images/close-icon.svg';
import rightArrow from '../../../../assets/images/right-chevrolet.svg';
import URLs from '../../../../utils/url';

export type IGroupMaterialInfo = {
  isOpen: boolean;
  onClose: () => void;
  descriptions: Array<ICategoryDesc>
}

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        zIndex: 100,
        transform: 'translate(-50%, -50%)',
    },
};

const GroupMaterialInfo = ({ isOpen, onClose, descriptions }: IGroupMaterialInfo) => {
    const [listView, showListView] = useState<boolean>(true);
    const [item, setItem] = useState<null | ICategoryDesc>(null);

    useEffect(() => {
        if (isOpen) showListView(true);
    }, [isOpen]);

    const openIndividualView = (item: ICategoryDesc) => () => {
        setItem(item);
        showListView(false);
    };

    const closeIndividualView = () => {
        showListView(true);
    };


    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            className="tw-top-0 tw-bottom-0 tw-left-0 tw-right-0 tw-m-auto xs:tw-w-[350px] sm:tw-w-[500px] tw-min-h-[400px] tw-bg-white tw-absolute tw-p-5 group-material-info-modal tw-text-blue-900 secondary-font-family"
            style={customStyles}
            overlayClassName="tw-bg-[#999c9fab] tw-fixed tw-inset-0 tw-z-10"
        >
            <img src={closeIcon} alt="close_icon" className="tw-absolute tw-top-4 tw-right-4 tw-cursor-pointer" role="presentation" onClick={onClose} />
            <If condition={listView}>
                <>
                    <h2 className="tw-text-lg tw-text-blue-900 tw-my-4 primary-font-family">Select a Material to Learn More</h2>
                    <section className="tw-max-h-[450px] tw-overflow-auto tw-pr-3">
                        {descriptions.map((item, index) => (
                            <div className="tw-flex tw-items-center tw-justify-between tw-mb-4 tw-cursor-pointer" role="presentation" onClick={openIndividualView(item)} key={index}>
                                <section className="xs:tw-w-5/6 sm:tw-w-4/6 tw-text-right tw-font-normal">
                                    <h2 className="tw-text-base tw-mb-2 tw-font-normal">{item.name}</h2>
                                    <div className="tw-flex tw-items-center tw-justify-end">
                                        <label className="tw-text-xs mb-0">Weight:</label>
                                        {isEmpty(item.weight) ? <div className="tw-text-xs tw-ml-1">N/A</div> : <Rating count={Number(item.weight)} />}
                                    </div>
                                    <div className="tw-flex tw-items-center tw-justify-end">
                                        <label className="tw-text-xs mb-0">Scratch Resistance:</label>
                                        {isEmpty(item.scratch_resistance) ?
                                            <div className="tw-text-xs tw-ml-1">N/A</div> :
                                            <Rating count={Number(item.scratch_resistance)} />}
                                    </div>
                                </section>
                                <section className="tw-ml-1">
                                    <img
                                        src={`${URLs.assetsBaseUrl}${item.swatch_url}}`}
                                        alt="swatch_url"
                                        width={70}
                                        height={70}
                                        className="tw-rounded-full"
                                    />
                                </section>
                                <section className="tw-ml-1 tw-cursor-pointer">
                                    <img src={rightArrow} alt="close_icon" className="tw-w-6 tw-h-6" />
                                </section>
                            </div>))}
                    </section>
                </>
            </If>
            <If condition={!listView}>
                {item && <MaterialInfo {...item} onBack={closeIndividualView} />}
            </If>
        </Modal>
    );
};

export default GroupMaterialInfo;
