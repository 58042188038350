import React from 'react';
import clsx from 'clsx';
interface IRating {
  count: number;
}

const Rating = ({ count }: IRating) => (
    <div className="tw-ml-1">
        <span className={clsx('tw-inline-block tw-w-2 tw-h-2 tw-rounded-full tw-border tw-mr-1 tw-border-solid tw-border-blue-900', { 'tw-bg-blue-900': count >= 1 })} />
        <span className={clsx('tw-inline-block tw-w-2 tw-h-2 tw-rounded-full tw-border tw-mr-1 tw-border-solid tw-border-blue-900', { 'tw-bg-blue-900': count >= 2 })} />
        <span className={clsx('tw-inline-block tw-w-2 tw-h-2 tw-rounded-full tw-border tw-mr-1 tw-border-solid tw-border-blue-900', { 'tw-bg-blue-900': count >= 3 })} />
        <span className={clsx('tw-inline-block tw-w-2 tw-h-2 tw-rounded-full tw-border tw-mr-1 tw-border-solid tw-border-blue-900', { 'tw-bg-blue-900': count >= 4 })} />
        <span className={clsx('tw-inline-block tw-w-2 tw-h-2 tw-rounded-full tw-border tw-mr-1 tw-border-solid tw-border-blue-900', { 'tw-bg-blue-900': count >= 5 })} />
    </div>
);

export default Rating;
