/* eslint-disable no-shadow */
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import Tabs, { TabPane } from 'rc-tabs';
import SwatchFilter from '../SwatchFilter';

import leftArrow from '../../../../assets/images/Left-main-nav-arrow.svg';
import rightArrow from '../../../../assets/images/Right-main-nav-arrow.svg';
import { ITab, ITabs } from '../../types/customizer';


type IProps = {
  tabs: ITabs;
  tabStyle?: 'single' | 'box' | 'underline';
  onChange: (updated: any) => void;
  isFetching: boolean;
  activeKey: string;
  onChangeParentActiveKey?: (key: string) => void;
  innerTab?: number;
  images?:string[];
}

const AppTabs = ({ tabs, tabStyle = 'underline', onChange, isFetching, activeKey, onChangeParentActiveKey, innerTab = 1, images }: IProps) => {
    const [keyList, setKeyList] = useState<string[]>([]);
    const [key, setKey] = useState<string>(activeKey);

    useEffect(() => {
        const list = tabs.map(t => t.eventKey);
        setKeyList(list);
    }, [tabs]);

    useEffect(() => {
        if (!keyList.includes(key)) {
            setKey(keyList[0]);
        }
    }, [keyList, key]);

    useEffect(() => {
        setKey(activeKey);
    }, [activeKey]);

    const isInitial = () => {
        const currentIndex = keyList.indexOf(key);
        return currentIndex === 0;
    };

    const isLast = () => {
        const currentIndex = keyList.indexOf(key);
        return currentIndex === (keyList.length - 1);
    };

    const handlePrev = () => {
        const currentIndex = keyList.indexOf(key);
        let index = currentIndex - 1;
        if (index < 0) {
            index = keyList.length - 1;
        }
        setKey(keyList[index]);
        if (onChangeParentActiveKey) onChangeParentActiveKey(keyList[index]);
    };

    const handleNext = () => {
        const currentIndex = keyList.indexOf(key);
        let index = currentIndex + 1;
        if (index >= keyList.length) {
            index = 0;
        }
        setKey(keyList[index]);
        if (onChangeParentActiveKey) onChangeParentActiveKey(keyList[index]);
    };

    const handleTabClick = (selectedOpt: string) => {
        if (keyList.length) {
            const currentIndex = keyList.indexOf(selectedOpt);
            const currentTab = tabs[currentIndex];
            if (currentTab?.actionPayload) {
                onChange(currentTab?.actionPayload);
            }
        }
    };

    const getTabTitle = (tab: ITab) => {
        const isActive = tab.eventKey === key;
        return (
            <>
                <div
                    className={clsx({
                        'rc-single-tab tw-flex tw-justify-between sm:tw-hidden tw-items-center': tabStyle === 'single',
                        'tw-hidden': tabStyle === 'single' && !isActive,
                        'rc-box': tabStyle === 'box',
                        'rc-box-active': tabStyle === 'box' && isActive,
                        'rc-underline': tabStyle === 'underline',
                        'rc-underline-active': tabStyle === 'underline' && isActive,
                    })}
                >
                    {tabStyle === 'single' && <i
                        className={clsx('fa fa-arrow-left tw-text-blue-900 tw-cursor-pointer', { invisible: isInitial() })}
                        onClick={handlePrev}
                        role="presentation"
                    />}
                    <span className="tw-flex tw-justify-center tw-min-w-[20px] tw-text-blue-900">
                        {typeof tab.title === 'string' && tab.title}
                        {typeof tab.title === 'function' && tab.title(isActive)}
                    </span>
                    {tabStyle === 'single' && <i
                        className={clsx('fa fa-arrow-right tw-text-blue-900 tw-cursor-pointer', { invisible: isLast() })}
                        onClick={handleNext}
                        role="presentation"
                    />}
                </div>
            </>
        );
    };

    const getActiveTab = (items: ITab[]) => {
        const selectedTab = items.find(tab => tab.isSelected);
        let key = '';
        if (selectedTab) {
            key = selectedTab.eventKey;
        } else {
            key = items?.[0]?.eventKey || '';
        }
        return key;
    };

    const getTabContent = (tab: any) => {
        const Component = tab.component || SwatchFilter;
        return <Component content= {tab.content} onChange={onChange} isFetching={isFetching} innerTab={innerTab} images={images} tabs={tabs} />;
    };

    return (
        <Tabs
            activeKey={key}
            onChange={setKey}
            onTabClick={handleTabClick}
            className={clsx('tw-border-hidden', { 'single-tab-container': tabStyle === 'single' })}
        >
            {tabs.map((tab: ITab) => (
                <TabPane key={tab.eventKey} tab={getTabTitle(tab)}>
                    {tab.items && tab.items.length > 0 && 
                        <AppTabs
                            tabs={tab.items}
                            tabStyle={tab.tabStyle}
                            activeKey={getActiveTab(tab.items)}
                            isFetching={isFetching}
                            onChange={onChange}
                            innerTab={innerTab + 1}
                    />}
                    {(!tab.items || !tab.items.length) && getTabContent(tab)}
                </TabPane>
            ))}
        </Tabs>
    );
};

export default AppTabs;
