import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import OrderStatus from '../../components/OrderStatus/OrderStatus';
import { resetProductData } from '../CartContainer/actions';
import { makeSelectOrder, makeSelectIsFetching } from '../OrderStatus/selectors';
import { getOrder, cloneOrder, hideLoader } from '../OrderStatus/actions';
import Loader from '../../components/Shared/Loader';
import CartManager from '../../utils/CartManager';
import { HOOK_TYPE, ORDER_STATUS, TIME_INTERVAL, TIMEOUT_THRESOLD } from './constants';

class OrderStatusContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentIteration: 0,
        };
    }

    componentDidMount = async () => {
        this.getOrderData(false);
    };

    componentWillUnmount() {
        const { resetProductData } = this.props;
        resetProductData();
    }

    getOrderData = async isRecheck => {
        const { getOrder, match: { params }, hideLoader } = this.props;
        try {
            const orderData = await getOrder(params.orderId, isRecheck);
            if (
                orderData.payload.status === ORDER_STATUS.APPROVED ||
                orderData.payload.hook_type === HOOK_TYPE.PAYMENT
            ) {
                CartManager.remove();
                hideLoader();
            } else if (orderData.payload.status === ORDER_STATUS.PENDING) {
                hideLoader();
            } else {
                setTimeout(() => {
                    this.setState({ currentIteration: this.state.currentIteration + 1 }, () => {
                        this.getOrderData(true);
                    });
                }, TIME_INTERVAL);
            }
        } catch (e) {
            // TODO: Error handling
        }
    };

    cloneOrderData = async () => {
        const { cloneOrder, history } = this.props;
        try {
            const cartId = CartManager.get();
            const newOrderData = await cloneOrder(cartId);
            const newCartId =
                newOrderData && newOrderData.payload && newOrderData.payload.cart_id
                    ? newOrderData.payload.cart_id
                    : '';
            if (newCartId) {
                CartManager.set(newOrderData.payload.cart_id);
                history.push(`/shop/cart/${newOrderData.payload.cart_id}`);
            }
        } catch (e) {
            // TODO: Error handling
        }
    };

    render() {
        const { orderData, isFetching } = this.props;
        return (
            <div>
                <OrderStatus
                    orderData={orderData}
                    isTimeout={this.state.currentIteration * TIME_INTERVAL > TIMEOUT_THRESOLD}
                    cloneOrderData={this.cloneOrderData}
                />
                {isFetching && <Loader timedOut={isFetching} message="Please wait and do not refresh the page" />}
            </div>
        );
    }
}

const mapStateToProps = createSelector([makeSelectOrder(), makeSelectIsFetching()], (orderData, isFetching) => ({
    orderData,
    isFetching,
}));

const mapDispatchToProps = dispatch =>
    bindActionCreators({ resetProductData, getOrder, cloneOrder, hideLoader }, dispatch);

OrderStatusContainer.propTypes = {
    orderData: PropTypes.object.isRequired,
    resetProductData: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderStatusContainer);
