import { apiActionTypes } from '../../middleware';
const base = '@@Containers/CustomerContainer/';

export const EMAIL_PORTAL = apiActionTypes(`${base}EMAIL_PORTAL`);
export const GET_CURRENT_PLAN_DETAILS = apiActionTypes(`${base}GET_CURRENT_PLAN_DETAILS`);
export const GET_BILLING_HISTORY = apiActionTypes(`${base}GET_BILLING_HISTORY`);
export const GET_PAYMENT_METHODS = apiActionTypes(`${base}GET_PAYMENT_METHODS`);
export const ADD_PAYMENT_METHODS = apiActionTypes(`${base}ADD_PAYMENT_METHODS`);
export const DELETE_PAYMENT_METHOD = apiActionTypes(`${base}DELETE_PAYMENT_METHOD`);
export const MAKE_DEFAULT_PAYMENT_METHOD = apiActionTypes(`${base}MAKE_DEFAULT_PAYMENT_METHOD`);
export const GET_CUSTOMER_DETAILS = apiActionTypes(`${base}GET_CUSTOMER_DETAILS`);

export const CARDS = {
    visa: {
        iconClass: 'fa fa-cc-visa',
    },
    amex: {
        iconClass: 'fa fa-cc-amex',
    },
    diners: {
        iconClass: 'fa fa-cc-diners-club',
    },
    discover: {
        iconClass: 'fa fa-cc-discover',
    },
    mastercard: {
        iconClass: 'fa fa-cc-mastercard',
    },
    others: {
        iconClass: 'fa fa-credit-card',
    },
};
