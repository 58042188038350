/* eslint-disable no-shadow */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import qs from 'qs';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import currency from 'currency.js';
import { SYMBOLS } from '../../utils/Constants';
import { getUpdatedParams, getSelectedInfo } from './utils/commonRingUtils';
import { getPageData } from './utils/Pages';
import { ITab } from './types/customizer';
// import CartManager from '../../utils/CartManager';
// import config from '../../config';
import DesktopSidebar from './components/Sidebar/DesktopSidebar';
import MobileSidebar from './components/Sidebar/MobileSidebar';
import './index.scss';
import If from '../../components/Shared/If';
import undoIcon from '../../assets/images/Undo-Arrow.svg';
import refreshIcon from '../../assets/images/refresh.png';
import ReverseIcon from '../../assets/images/contrast.png';
import { getProductDetail, getProductSummary, addProductToCart, resetProductData } from './action';
import MyGallery from './components/MyGallery';
import AppTabs from './components/AppTabs';
import Loading from '../../components/Shared/Loader';
import twoRingIcon from '../../assets/images/two-ring.jpg';
import AuthManager from '../../utils/AuthManager';

interface IParams {
    pathParams: any,
    queryParams: any
}

interface IUrl {
    pathString: string;
    queryString: string;
    ring_url: string;
}
interface IProps {
    match: any;
    location: any;
    history: any;
    type: number;
    product: any;
    isFetching: boolean,
    productSummary: any,
    addProductResult: any,
    currentCurrencyCode: 'USD' | 'CAD' | 'EUR',
    currencyConversion: {
        USD: number,
        CAD: number,
        EUR: number
    },
    getProductDetail: (URL: string, type?: number) => any;
    addProductToCart: (URL: string, cartId: string, type?: number) => any;
    resetProductData: () => void;
}

const RingCustomizer = ({ match, location, history, type, product, isFetching, currentCurrencyCode, currencyConversion,
    getProductDetail, resetProductData, productSummary, addProductResult, addProductToCart }: IProps) => {
    const [params, setParams] = useState<IParams>({ pathParams: {}, queryParams: {} });
    const [url, setUrl] = useState<IUrl>({ pathString: '', queryString: '', ring_url: '' });
    const [pages, setPages] = useState<ITab[]>([]);
    const [key, setKey] = useState<string>('');
    const [open, setOpen] = useState<boolean>(false);
    const [myHistory, setMyHistory] = useState<string[]>(() => {
        const list = sessionStorage.getItem('myHistory');
        return list ? JSON.parse(list) : [];
    });

    useEffect(() => () => {
        /**
         * On unmount reset product data and remove session item.
         */
        resetProductData();
        sessionStorage.removeItem('myHistory');
    }, []);


    useEffect(() => {
        const { params } = match;
        const { search: searchStr } = location;
        const searchParams = new URLSearchParams(searchStr || '');
        const obj = Object.fromEntries(searchParams.entries());
        setParams({
            pathParams: params || {},
            queryParams: obj,
        });
        if (type === 1) {
            const { profile, width, ring_materials } = params;
            const path = `/rings/profiles/${profile}/widths/${width}/ring_materials/${ring_materials}`;
            setUrl({ pathString: path, queryString: searchStr, ring_url: `${path}${searchStr}` });
        } else if (type === 2) {
            const { styles, ring_materials, color_arrangements, stone_quantities, variants } = params;
            const path = `/women/rings/styles/${styles}/ring_materials/${ring_materials}/color_arrangements/${color_arrangements}/stone_quantities/${stone_quantities}/variants/${variants}`;
            setUrl({ pathString: path, queryString: searchStr, ring_url: `${path}${searchStr}` });
        } else if (type === 3) {
            const { ring_type, ring_materials } = params;
            const path = `/rings/${ring_type}/ring_materials/${ring_materials}`;
            setUrl({ pathString: path, queryString: searchStr, ring_url: `${path}${searchStr}` });
        }
    }, [match.params, location.search]);


    useEffect(() => {
        if (url.ring_url) {
            getProductDetail(url.ring_url, type);
        }
    }, [url]);

    useEffect(() => {
        if (!product || !url.ring_url) {
            return;
        }
        if (product.ring_options_url && product.ring_options_url !== url.ring_url) {
            navigateToUrl(product.ring_options_url, true);
        }
        const isDataTabAccess = AuthManager.hasDataTabAccess();
        const tabData = getPageData(product, isDataTabAccess, type, params.pathParams.ring_type) as ITab[];
        const activekey = getActiveTab(tabData);

        setPages(tabData);
        if (!key) {
            setKey(activekey);
        }
    }, [product]);


    const navigateToUrl = (url: string, replace = true) => {
        const navigateUrl = `/shop${url}`;
        if (replace) {
            history.replace(navigateUrl); // After url is navigated it calls a useffect of location dependencies
        } else {
            history.push(navigateUrl); // After url is navigated it calls a useffect of location dependencies
        }
    };

    const handleUrlChange = ({ pathParams, queryParams }: IParams) => {
        let URL = '';
        if (type === 1) {
            const { profile, width, ring_materials } = pathParams;
            const searchString = qs.stringify(queryParams);
            URL = `/rings/profiles/${profile}/widths/${width}/ring_materials/${ring_materials}?${searchString}`;
        } else if (type === 2) {
            const { styles, ring_materials, color_arrangements, stone_quantities, variants } = pathParams;
            const searchString = qs.stringify(queryParams);
            URL = `/women/rings/styles/${styles}/ring_materials/${ring_materials}/color_arrangements/${color_arrangements}/stone_quantities/${stone_quantities}/variants/${variants}?${searchString}`;
        } else if (type === 3) {
            const { ring_type, ring_materials } = pathParams;
            const searchString = qs.stringify(queryParams);
            URL = `/rings/${ring_type}/ring_materials/${ring_materials}?${searchString}`;
        }
        addHistory();
        navigateToUrl(URL, false); // push new URL
    };

    const handleUpdate = (tobeUpdated: any) => {
        const updatedParams = getUpdatedParams(tobeUpdated, params);
        handleUrlChange(updatedParams);
    };

    /*
    const handleAddToCart = () => {
        const cartId = CartManager.get();
        if (config.allowAddToCart) {
            const cartUrl = `${url.pathString}/cart${url.queryString}`;
            addProductToCart(cartUrl, cartId, type).then(() => setTimeout(() => {}, 5000));
        }
    };
    */

    const getActiveTab = (items: ITab[]) => {
        const selectedTab = items.find(tab => tab.isSelected);
        let key = '';
        if (selectedTab) {
            key = selectedTab.eventKey;
        } else {
            key = items?.[0]?.eventKey || '';
        }
        return key;
    };

    const toggleOpen = () => {
        setOpen(!open);
    };

    const handleActiveTab = (key: string, toggleVisibility: boolean) => () => {
        setKey(key);
        if (toggleVisibility) {
            toggleOpen();
        }
    };

    const addHistory = () => {
        const url = `${location.pathname}${location.search}`;
        const list = [...myHistory, url];
        sessionStorage.setItem('myHistory', JSON.stringify(list));
        setMyHistory(list);
    };

    const removeHistory = () => {
        const cloned = myHistory.slice();
        cloned.pop();
        sessionStorage.setItem('myHistory', JSON.stringify(cloned));
        setMyHistory(cloned);
    };

    const handleReset = () => {
        history.push(myHistory[0]);
        sessionStorage.removeItem('myHistory');
        setMyHistory([]);
    };

    const handleUndo = () => {
        if (myHistory.length) {
            history.goBack();
            removeHistory();
        }
    };

    const handleDone = () => {
        const productUrl = `/shop/product${url.ring_url}`;
        history.push(productUrl);
    };

    useEffect(() => {
        function handleResize() {
            const vh = window.innerHeight;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getPrice = () => {
        const conversionRate = currencyConversion ? currencyConversion[currentCurrencyCode] : 1;
        const symbol = SYMBOLS[currentCurrencyCode];
        const convertedCurrency = currency(product?.price || 0).multiply(conversionRate);
        return currency(convertedCurrency).format({ symbol });
    };

    const handleEngravingReversePattern = () => {
        const { selectedOption } = engravingInfo;
        if (selectedOption.is_reverse_selected) {
            handleUpdate({ engraving_pattern: selectedOption.sku });
        } else {
            handleUpdate({ engraving_pattern: `${selectedOption.sku}REV` });
        }
    };

    const handleLogoReversePattern = () => {
        const { selectedOption } = SignetEngravingInfo;
        if (selectedOption.is_reverse_selected) {
            handleUpdate({ signet_engraving: selectedOption.sku });
        } else {
            handleUpdate({ signet_engraving: `${selectedOption.sku}REV` });
        }
    };

    const selectedPage = pages.find(item => item.eventKey === key);
    const engravingInfo = key === 'engraving' ? getSelectedInfo(selectedPage) : null;
    const SignetEngravingInfo = key === 'logo' || key === 'outsideFeature_1' ? getSelectedInfo(selectedPage) : null;

    return (
        <div className="tw-flex tw-flex-col app-container secondary-font-family">
            <div className="tw-flex tw-items-center tw-justify-between tw-px-3 tw-pb-2 tw-pt-3 tw-w-full tw-top-0 tw-bg-white sm:tw-hidden">
                <div className="tw-flex tw-items-center">
                    <Link to="/" className="tw-flex">
                        <img src={twoRingIcon} width={50} height={41} alt="Lashbrook" />
                    </Link>
                </div>
                <button className="tw-bg-blue-500 tw-text-white tw-text-base tw-px-9 tw-py-2 tw-rounded-full tw-normal-case tw-font-[inherit]" onClick={handleDone}>Done</button>
                <div className="tw-text-blue-900 tw-text-lg tw-min-w-[64px]">{getPrice()}</div>
            </div>
            <div className="customizer_main_container tw-flex-1 tw-flex tw-flex-wrap tw-h-full">
                <DesktopSidebar
                    pages={pages}
                    selectedTab={key}
                    handleActiveTab={handleActiveTab}
                    handleDone={handleDone}
                    price={getPrice()}
                />
                <div className="tw-flex-1 tw-flex tw-flex-col tw-w-0 tw-h-full main_inner_container">
                    <div className={clsx('gallery_container tw-relative tw-pb-5 tw-pt-5 lg:tw-pt-10', { 'sm:tw-hidden': ['summary', 'data'].includes(key) })}>
                        <MyGallery images={product?.images || []} showRefresh={type !== 3} />
                        <div className="tw-absolute tw-bottom-1 tw-left-3 sm:tw-left-6 lg:tw-left-20 tw-text-center tw-cursor-pointer tw-flex xs:tw-flex-col lg:tw-flex-row xs:tw-gap-2 lg:tw-gap-5">
                            <If condition={myHistory.length}>
                                <div onClick={handleUndo} role="presentation">
                                    <img src={undoIcon} alt="undo_icon" className="tw-inline-block width-20" />
                                    <div className="tw-underline tw-text-gray-600 tw-text-sm">Undo</div>
                                </div>
                                <div onClick={handleReset} role="presentation">
                                    <img src={refreshIcon} alt="undo_icon" className="tw-inline-block tw-w-6" />
                                    <div className="tw-underline tw-text-gray-600 tw-text-sm tw-m-0.5">Reset</div>
                                </div>
                            </If>
                            <If condition={key === 'engraving' && engravingInfo?.selectedOption?.has_inside_reversed_option}>
                                <div onClick={handleEngravingReversePattern} role="presentation">
                                    <img src={ReverseIcon} alt="undo_icon" className="tw-inline-block tw-w-6" />
                                    <div className="tw-underline tw-text-gray-600 tw-text-sm tw-m-0.5">Reverse <br />Pattern</div>
                                </div>
                            </If>
                            <If condition={(key === 'logo' || key === 'outsideFeature_1') && SignetEngravingInfo?.selectedOption?.has_outside_reversed_option}>
                                <div onClick={handleLogoReversePattern} role="presentation">
                                    <img src={ReverseIcon} alt="undo_icon" className="tw-inline-block tw-w-6" />
                                    <div className="tw-underline tw-text-gray-600 tw-text-sm tw-m-0.5">Reverse <br />Pattern</div>
                                </div>
                            </If>
                        </div>
                    </div>
                    <div className={clsx(`tw-relative tw-flex-1 customizer_container ${key}`)}>
                        <div id="main-content" className={clsx('tw-h-full', { 'tw-hidden': open })}>
                            {pages.length > 0 &&
                                <AppTabs
                                    tabs={pages}
                                    tabStyle="single"
                                    onChange={handleUpdate}
                                    isFetching={isFetching}
                                    activeKey={key}
                                    onChangeParentActiveKey={setKey}
                                    images={product?.images}
                                />}
                        </div>
                        <MobileSidebar
                            pages={pages}
                            selectedTab={key}
                            handleActiveTab={handleActiveTab}
                            open={open}
                            toggleOpen={toggleOpen}
                        />
                    </div>
                </div>
            </div>
            <Loading timedOut={isFetching} />
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    product: state.customize.product,
    isFetching: state.customize.isFetching,
    productSummary: state.customize.productSummary,
    addProductResult: state.customize.addProductResult,
    currentCurrencyCode: state.global.currentCurrencyCode,
    currencyConversion: state.global.currencyConversion,
});

const mapDispatchToProps = {
    getProductDetail,
    getProductSummary,
    addProductToCart,
    resetProductData,
};

export default connect(mapStateToProps, mapDispatchToProps)(RingCustomizer);
