import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Container, Row, Form, FormGroup, Label, Col, Button, Input, Tooltip } from 'reactstrap';
import Loader from '../Shared/Loader';
import './styles.css';

const AccountInfoEdit = ({ isFetching, accountInfo, getAccountInfo, editAccountInfo, resetAccountInfo }) => {
    const [checked, setChecked] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggle = () => setTooltipOpen(!tooltipOpen);

    const { register, handleSubmit, errors, control, getValues } = useForm();

    const submit = async data => {
        // eslint-disable-next-line camelcase
        try {
            const { first_name, last_name, email, ...rest } = accountInfo;

            await editAccountInfo({ data, ...rest });
            toast.success('User Details has been successfully updated.', { className: 'toast-success' });
        } catch (error) {
            let errorMessage;
            if (error && error.response && error.response.data && error.response.data.errors.old_password) {
                errorMessage = error.response.data.errors.old_password[0];
            } else if (error && error.response && error.response.data && error.response.data.errors.password) {
                errorMessage = error.response.data.errors.password[0];
            } else if (error && error.response && error.response.data && error.response.data.errors.password) {
                errorMessage = error.response.data.errors.password[1];
            } else if (error && error.response && error.response.data && error.response.data.errors.username) {
                errorMessage = error.response.data.errors.username[0];
            }
            toast.info(errorMessage, { className: 'toast-error' });
        }
    };
    useEffect(() => {
        getAccountInfo();
        return () => {
            resetAccountInfo();
        };
    }, []);

    return (
        <Container className="account-info-edit-container d-flex flex-column justify-content-between">
            <Row className="flex-column">
                <Form
                    className="mt-4 d-flex flexColumn flex flex-wrap edit-account-form"
                    onSubmit={handleSubmit(submit)}
                >
                    <Col xs={12} md={6} lg={5} className="mx-auto d-flex flex-column mb-2">
                        <h5 className="text-center customFont">Account Information</h5>
                        <FormGroup>
                            <Label className="font-universe font-weight-bolder required" for="name">
                                First Name
                            </Label>
                            <input
                                type="text"
                                name="first_name"
                                ref={register}
                                defaultValue={accountInfo && accountInfo.first_name ? accountInfo.first_name : ''}
                                placeholder="First Name"
                                className="mb-2"
                                required
                            />

                            <Label className="font-universe font-weight-bolder required" for="name">
                                Last Name
                            </Label>
                            <input
                                type="text"
                                name="last_name"
                                ref={register}
                                defaultValue={accountInfo && accountInfo.last_name ? accountInfo.last_name : ''}
                                placeholder="Last Name"
                                className="mb-2"
                                required
                            />
                            <Label className="font-universe font-weight-bolder required" for="email">
                                Email Address
                            </Label>
                            <input
                                type="text"
                                name="email"
                                ref={register}
                                placeholder="Email Address"
                                defaultValue={accountInfo && accountInfo.email ? accountInfo.email : ''}
                                className="mb-2"
                                required
                            />
                            <Label className="font-universe font-weight-bolder required" for="username">
                                Username
                            </Label>
                            <input
                                type="text"
                                name="username"
                                ref={register}
                                placeholder="Username"
                                defaultValue={accountInfo && accountInfo.username ? accountInfo.username : ''}
                                className="mb-2"
                                required
                            />
                        </FormGroup>
                        <span className="font-universe font-weight-bolder mb-2">
                            <input type="checkbox" checked={checked} onChange={() => setChecked(!checked)} /> Change
                            Password
                        </span>
                    </Col>
                    <Col xs={12} md={6} lg={5} className="mx-auto d-flex flex-column">
                        <FormGroup style={{ display: checked ? 'block' : 'none' }}>
                            <h5 className="text-center customFont">Reset Password</h5>
                            <Label className="font-universe font-weight-bolder required" for="current-password">
                                Current Password
                            </Label>
                            <Controller
                                name="old_password"
                                label="Current Pasword"
                                control={control}
                                rules={{}}
                                as={
                                    <Input
                                        name="old_password"
                                        type="password"
                                        placeholder="Current Password"
                                        id="old_password"
                                        ref={register({ required: true })}
                                        className="mb-2"
                                        required={checked}
                                    />
                                }
                            />

                            <Label className="font-universe font-weight-bolder required" for="password">
                                New Password
                                <i className="fa fa-info-circle" aria-hidden="true" href="#" id="password-tooltip"></i>
                                <Tooltip
                                    placement="right"
                                    isOpen={tooltipOpen}
                                    target="password-tooltip"
                                    toggle={toggle}
                                    style={{ backgroundColor: '#dcc67d', width: 200 }}
                                    id="tooltip-top"
                                >
                                    Must contain at least one number one special character(@,#,$,%,^,& etc) one
                                    uppercase and lowercase letter, and at least 8 or more characters
                                </Tooltip>
                            </Label>
                            <Controller
                                name="password"
                                label="Password"
                                control={control}
                                rules={{}}
                                as={
                                    <Input
                                        name="password"
                                        type="password"
                                        placeholder="Password"
                                        id="password"
                                        ref={register({ required: true })}
                                        className="mb-2"
                                        required={checked}
                                    />
                                }
                            />
                            {errors.password && <p className="font-universe warning">{errors.password.message}</p>}

                            <Label className="font-universe font-weight-bolder required" for="confirm-password">
                                Confirm New Password
                            </Label>

                            <Controller
                                name="password_repeat"
                                label="Confirm Password"
                                control={control}
                                rules={{
                                    validate: value => {
                                        if (value === getValues().password) {
                                            return true;
                                        } else {
                                            return 'The passwords do not match';
                                        }
                                    },
                                }}
                                as={
                                    <Input
                                        name="password_repeat"
                                        type="password"
                                        placeholder="Confirm Password"
                                        id="confirm-password"
                                        ref={register({ required: true })}
                                        className="mb-2"
                                        required={checked}
                                    />
                                }
                            />
                            {errors.password_repeat && (
                                <p className="font-universe warning"> {errors.password_repeat.message}</p>
                            )}
                        </FormGroup>
                    </Col>
                    <Loader timedOut={isFetching} />

                    <Button className="customSet">Submit</Button>
                </Form>
            </Row>
        </Container>
    );
};

AccountInfoEdit.propTypes = {
    isFetching: PropTypes.bool,
    accountInfo: PropTypes.object,
    editAccountInfo: PropTypes.func,
    getAccountInfo: PropTypes.func,
    resetAccountInfo: PropTypes.func,
};
export default AccountInfoEdit;
