import React from 'react';
import CollapsibleCard from '../Shared/CollapsibleCard';
import { Table } from 'reactstrap';

function AccountGrowth({ data }) {
  return (
    <CollapsibleCard title="Account Growth">
      <div className="account-growth">
        <div className="table-responsive">
          <Table hover className="font-open-sans-condensed">
            <thead>
              <tr>
                <th className="font-weight-bold">Sales Rep</th>
                <th className="font-weight-bold text-right">Net Category Change (LRQ vs Q-1)</th>
              </tr>
            </thead>
            <tbody>
              {data?.map(rep => (
                <tr key={rep.salesRep}>
                  <td className="font-16">{rep.salesRep}</td>
                  <td className={`font-16 text-right ${rep.netCategoryChange > 0 ? 'text-success' : 'text-danger'}`}>
                    {rep.netCategoryChange}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </CollapsibleCard>
  );
}

export default AccountGrowth;