import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { loadStripe } from '@stripe/stripe-js';
import { createSelector } from 'reselect';
import * as actions from '../StripeContainer/actions';
import {
    makeSelectIsFetching,
    makeSelectCountries,
    makeSelectStates,
    makeSelectCountriesBilling,
    makeSelectStatesBilling,
    makeSelectAddresses,
    makeSelectShipping,
    makeSelectOrder,
    makeSelectSubscriptionSummary,
    makeSelectOrderCreate,
    makeSelectAccountInfo,
    makeSelectPaymentMethods,
    makeSelectShippingMethod,
} from './selectors';
import Loader from '../../components/Shared/Loader';
import StripeCheckout from '../../components/Stripe Checkout';
import config from '../../config';
import AuthManager from '../../utils/AuthManager';

// Setup Stripe.js and the Elements provider
const apiKey = (config.useProductionValues === true || config.id === 'PRODUCTION')
    ? 'pk_live_51HUwyxG0olkRzMMtAV770A941IqlheuQWGT4ikGEagT0y3kcsDCdX0nw4qnefXB068WPKaBA4hhWr4X9r5gcNALr008hkAxavl'
    : 'pk_test_51HUwyxG0olkRzMMtRld5JPv8sQaBMuHYa0sRSjMIaxfT44XzZMlwSFHJ9sCXicCiGjn5QygvuzkiKVaI3N8WiIT600vQgxY23l';
export const stripePromise = loadStripe(apiKey);

class StripeContainer extends React.Component {
    componentDidMount() {
        this.props.getOrder().then(({ payload }) => {
            if (this.props.directOrder) {
                this.props.getOrderSummary(payload.id);
            } else {
                this.props.getSubscriptionSummary(payload.id);
            }
        });
    }

    componentWillUnmount() {
        AuthManager.removeGuestToken();
        this.props.resetCheckoutData();
    }

    render() {
        const { isFetching,
            getCountries,
            getCountriesBilling,
            getStates,
            getStatesBilling,
            countries,
            countriesBilling,
            states,
            statesBilling,
            createCustomer,
            getAllAddresses,
            addresses,
            getShippingDetail,
            shippingMethods,
            createOrder,
            getOrder,
            getSubscriptionSummary,
            getOrderSummary,
            orderDetails,
            summary,
            orderCreateData,
            setUpPaymentIntent,
            getPaymentMethods,
            savePaymentMethod,
            removePaymentMethod,
            paymentMethods,
            getAccountInfo,
            accountInfo,
            createSubscription,
            createDirectOrder,
            directOrder,
            selectedShipping,
            setShippingMethod,
            orderType,
        } = this.props;
        return (
            <div className={`content-container ${directOrder && 'direct-order-container'}`}>
                <StripeCheckout
                    getCountries={getCountries}
                    getCountriesBilling={getCountriesBilling}
                    getStates={getStates}
                    getStatesBilling={getStatesBilling}
                    countries={countries}
                    countriesBilling={countriesBilling}
                    states={states}
                    statesBilling={statesBilling}
                    createCustomer={createCustomer}
                    getAllAddresses={getAllAddresses}
                    addresses={addresses}
                    getShippingDetail={getShippingDetail}
                    shippingMethods={shippingMethods}
                    createOrder={createOrder}
                    orderCreateData={orderCreateData}
                    getOrder={getOrder}
                    getSubscriptionSummary={directOrder ? getOrderSummary : getSubscriptionSummary}
                    orderDetails={orderDetails}
                    summary={summary}
                    isFetching={isFetching}
                    setUpPaymentIntent={setUpPaymentIntent}
                    getPaymentMethods={getPaymentMethods}
                    savePaymentMethod={savePaymentMethod}
                    removePaymentMethod={removePaymentMethod}
                    getAccountInfo={getAccountInfo}
                    accountInfo={accountInfo}
                    createSubscription={createSubscription}
                    createDirectOrder={createDirectOrder}
                    paymentMethods={paymentMethods}
                    directOrder={directOrder}
                    orderType={orderType}
                    selectedShipping={selectedShipping}
                    setShippingMethod={setShippingMethod}
                    getOrderSummary={getOrderSummary}
                />
                <Loader timedOut={isFetching} />
            </div>
        );
    }
}

StripeContainer.propTypes = {
    isFetching: PropTypes.bool,
};

const mapStateToProps = createSelector(
    [makeSelectIsFetching(),
        makeSelectCountries(),
        makeSelectStates(),
        makeSelectCountriesBilling(),
        makeSelectStatesBilling(),
        makeSelectAddresses(),
        makeSelectShipping(),
        makeSelectOrder(),
        makeSelectSubscriptionSummary(),
        makeSelectOrderCreate(),
        makeSelectAccountInfo(),
        makeSelectPaymentMethods(),
        makeSelectShippingMethod(),
    ],
    (isFetching, countries, states, countriesBilling, statesBilling, addresses, shippingMethods, orderDetails, summary, orderCreateData, accountInfo, paymentMethods, selectedShipping) => ({
        isFetching,
        countries,
        countriesBilling,
        states,
        statesBilling,
        addresses,
        shippingMethods,
        orderDetails,
        summary,
        orderCreateData,
        accountInfo,
        paymentMethods,
        selectedShipping,
    })
);

const mapDispatchToProps = dispatch => bindActionCreators({ ...actions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StripeContainer);
