import React from 'react';
import { Col, Row, Table } from 'reactstrap';

const BillingHistory = props => {
    const { history } = props;

    const dateFormatter = date => {
        const displayDate = new Date(date * 1000);
        return displayDate.toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    };

    const priceFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const renderHistory = () => history?.map(item => (
        <tr>
            <td className="font-weight-bold">{dateFormatter(item.created)}</td>
            <td>{priceFormatter.format(item.total / 100)}</td>
            <td className="text-capitalize">{item.billing_reason.replace('_', ' ')}</td>
            <td><a href={item.hosted_invoice_url} target="_blank" rel="noopener noreferrer">View Invoice Details</a></td>
        </tr>
    ));
    return (
        <Row className="fs-16">
            <Col xs={12}>
                <h4 className="text-uppercase">Billing History</h4>
                <hr />
            </Col>
            <Col xs={12} md={8}>
                <Table borderless>
                    <tbody>
                        {renderHistory()}
                    </tbody>
                </Table>
            </Col>
        </Row>
    );
};

export default BillingHistory;
