import React, { useEffect, useRef, useState } from 'react';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import PinchZoomPan from 'react-responsive-pinch-zoom-pan';
import loaderInv from '../../../../assets/images/loader2inv.gif';
import Cancel from '../../../../assets/images/close_icon@3x.png';
import If from '../../../../components/Shared/If';
import './my-gallery-styles.scss';
import URLs from '../../../../utils/url';
import refreshIcon from '../../../../assets/images/Undo-Arrow.svg';

type IProps = {
  images: string[];
  showNav?:boolean;
  goFullscreen?:boolean;
  showRefresh?:boolean;
}

const MyGallery = ({ images, showNav = false, goFullscreen = true, showRefresh = false }: IProps) => {
    const [data, setData] = useState<ReactImageGalleryItem[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [fullScreen, setFullScreen] = useState<boolean>(false);

    const ref = useRef<any>(null);

    useEffect(() => {
        setImageList();
    }, [images]);

    const hideLoader = () => {
        setLoading(false);
    };

    const setImageList = () => {
        const imageList = images.map(item => {
            const imageStr = `${URLs.assetsBaseUrl}${item}`;
            return ({
                original: `${imageStr}${imageStr.indexOf('?') !== -1 ? '&' : '?'}image_size=1200&rand=${Math.random().toString(12)}`,
                thumbnail: `${imageStr}${imageStr.indexOf('?') !== -1 ? '&' : '?'}image_size=90&rand=${Math.random().toString(12)}`,
            });
        });
        setData(imageList);
        if (imageList.length) {
            setLoading(true);
        }
    };

    const openFullScreen = () => {
        if (!goFullscreen) return;
        if (!fullScreen) {
            setFullScreen(true);
            ref.current.fullScreen();
        }
    };

    const closeFullScreen = () => {
        setFullScreen(false);
        ref.current.exitFullScreen();
    };

    const renderItem = (item: ReactImageGalleryItem) => {
        if (fullScreen) {
            return (
                <PinchZoomPan maxScale={3} zoomButtons={fullScreen} position="center">
                    <img
                        src={item.original}
                        className="image-gallery-image"
                        alt={item.originalAlt}
                        srcSet={item.srcSet}
                        sizes={item.sizes}
                        onLoad={hideLoader}
                    />
                </PinchZoomPan>
            );
        }
        return (<img
            src={item.original}
            className="image-gallery-image"
            alt={item.originalAlt}
            srcSet={item.srcSet}
            sizes={item.sizes}
            onLoad={hideLoader}
        />);
    };

    const renderFullScreenButton = () => (
        <img
            src={Cancel}
            alt="close_icon"
            onClick={closeFullScreen}
            role="presentation"
            className="tw-absolute tw-top-3 tw-right-3 tw-w-9 tw-h-9 tw-cursor-pointer"
        />
    );

    return (
        <>
            <If condition={loading}>
                <div className="loading-mask" style={{ backgroundImage: `url(${loaderInv})` }} />
            </If>
            <ImageGallery
                ref={ref}
                items={data}
                thumbnailPosition="left"
                showPlayButton={false}
                disableThumbnailScroll
                disableKeyDown
                useBrowserFullscreen={false}
                showFullscreenButton={fullScreen}
                showThumbnails={fullScreen}
                showBullets={!showNav}
                showNav={showNav}
                renderItem={renderItem}
                renderFullscreenButton={renderFullScreenButton}
                onClick={openFullScreen}
            />
            <If condition={showRefresh}>
                <div className="tw-absolute tw-bottom-1 tw-right-3 sm:tw-right-6 lg:tw-right-20 tw-text-center tw-cursor-pointer tw-flex tw-gap-5">
                    <div onClick={setImageList} role="presentation">
                        <img src={refreshIcon} alt="refresh_icon" className="tw-inline-block tw-w-5" style={{ transform: 'scaleX(-1)' }} />
                        <div className="tw-underline tw-text-gray-600 tw-text-sm tw-m-0.5">Refresh</div>
                    </div>
                </div>
            </If>
        </>
    );
};

export default MyGallery;
