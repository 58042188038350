import config from '../config';

const URLs = {
    // builds a url from host, path and params. Only uses host if path doesn't already
    // contain a prefix specifying a scheme.
    buildUrl: (host, path, ...qsparams) => {
        let url = '';
        if (path) {
            url = path.startsWith('http') ? path : `${host}${path}`;
        }
        // append querystring params
        if (qsparams) {
            const qsprefix = url.indexOf('?') < 0 ? '?' : '&';
            url = `${url}${qsprefix}${qsparams.join('&')}`;
        }
        return url;
    },
    rootPath: '/shop',
    favorite: '/v1/favorites',
    currencies: '/v1/currencies',
    currencyConversion: '/v2/product/currencyConversation',
    category: '/v1/category',
    search: '/v1/rings/search',
    cart: '/v2/cart',
    order: '/v2/orders',
    cloneOrder: '/v2/cart/clone',
    shipping: '/v2/shipping',
    syncFavorites: '/v1/favorites/sync',
    storeLogin: '/account/v1/login',
    emailReset: '/account/v1/password/email',
    passwordReset: '/account/v1/password/reset',
    storeLogout: '/account/v1/logout',
    address: '/account/v1/netsuite/addresses/shipping',
    shipToAddress: '/account/v1/address',
    countries: '/account/v1/countries',
    accountInfo: '/account/v1/me',
    accountOrders: '/account/v1/orders',
    accountOneOrder: '/account/v1/orders',
    transactions: '/account/v1/netsuite/restlet',
    commonRestlet: '/account/v1/netsuite/commonRestlet',
    stripeSession: '/account/v1/netsuite/invoices/payment',
    ringSizer: '/static/size-options',
    accountShipping: '/account/v1/shipping',
    createOrder: '/account/v1/orders',
    customer: '/v2/customer',
    subscriptionSummary: '/v2/customer/subscription',
    paymentIntent: '/v2/customer/payment-intent',
    paymentMethod: '/v2/customer/payment/methods',
    createSubscription: '/v2/customer/subscribe',
    directOrder: '/v2/customer/order',
    customerPortal: '/v2/customer/portal',
    currentPlan: '/v2/portal/subscription',
    billingHistory: '/v2/portal/billing/history',
    paymentMethodPortal: '/v2/portal/payment/methods',
    customerDetails: '/v2/portal/customer',
    partners: '/account/v1/netsuite/partner/details',
    similarRings: '/v2/product/getSimilar',
    requestMarketingMaterials: '/v2/requestMarketingMaterials',
    apisV1: '/v1',
    apisV2: '/v2',
    subscribeToMarketingEmails: 'v2/subscribeToMarketingEmails',
    ...config,
};
export default URLs;
