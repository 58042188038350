import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import If from '../Shared/If';
import Shipping from './Shipping';
import Summary from './Summary';
import AuthManager from '../../utils/AuthManager';
import Terms from './Terms';
import Progress from './Progress';
import { DIRECT_ORDER, FINANCING_ORDER, directSteps, financingSteps } from '../../containers/StripeContainer/constants';
import CustomerDetails from './CustomerDetails';
import Payment from './Payment';

class StripeCheckout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentStep: 1,
            shippingCost: '$0.00',
            shippingMethod: 'Ground Shipping',
            summaryHeight: '',
        };
    }


    handleSummaryHeight = summaryHeight => this.setState({ summaryHeight });

    handleShipping = (shippingCost, shippingMethod) => this.setState({ shippingCost, shippingMethod });

    customerCreationHandler = async details => {
        const { createCustomer, createOrder, getAllAddresses, getSubscriptionSummary, getOrderSummary, selectedShipping, directOrder } = this.props;
        await createCustomer(details)
            .then(() => getAllAddresses())
            .then(({ payload: { data } }) => {
                let billing_address;
                let shipping_address;
                if (typeof data !== 'undefined') {
                    data.map(selectedAddress => {
                        if (selectedAddress.address_type === 'billing') {
                            billing_address = selectedAddress.id;
                        }
                        if (selectedAddress.address_type === 'shipping') {
                            shipping_address = selectedAddress.id;
                        }
                        if (selectedAddress.address_type === 'both') {
                            shipping_address = selectedAddress.id;
                            billing_address = selectedAddress.id;
                        }
                    });
                }
                createOrder({ shipping_address, billing_address, shipping_id: selectedShipping, is_subscription: !directOrder })
                    .then(({ payload }) => directOrder ? getOrderSummary(payload.id) : getSubscriptionSummary(payload.id))
                    .then(() => this.next())
                    .catch(({ response: { data: { message } } }) => {
                        toast.error(message, { className: 'toast-error' });
                    });
            });
    };

    next = () => {
        const { currentStep } = this.state;
        const updatedStep = currentStep + 1;
        this.setState({
            currentStep: updatedStep,
        });
    };

    prev = () => {
        let { currentStep } = this.state;
        currentStep = currentStep <= 1 ? 1 : currentStep - 1;
        this.setState({
            currentStep,
        });
    };
    render() {
        const { currentStep, shippingCost, shippingMethod, summaryHeight } = this.state;
        const { getCountries,
            getStates,
            countries,
            states,
            createCustomer,
            getAllAddresses,
            addresses,
            getShippingDetail,
            shippingMethods,
            createOrder,
            orderCreateData,
            orderDetails,
            setUpPaymentIntent,
            getPaymentMethods,
            savePaymentMethod,
            removePaymentMethod,
            getSubscriptionSummary,
            summary,
            getAccountInfo,
            accountInfo,
            createSubscription,
            createDirectOrder,
            paymentMethods,
            directOrder,
            setShippingMethod,
            selectedShipping,
            orderType,
        } = this.props;

        const steps = directOrder ? directSteps : financingSteps;

        return (
            <Container className="px-lg-1 px-2 py-3 stripe-checkout-container">
                <Progress
                    steps={steps}
                    currentStep={currentStep}
                    previous={this.prev}
                    directOrder={directOrder}
                />
                <Row className="m-0">
                    <Col xs={12} lg={(currentStep === 1 && !directOrder) ? 12 : 7}>
                        <If condition={orderType === DIRECT_ORDER}>
                            <If condition={currentStep === 1}>
                                <CustomerDetails
                                    next={this.next}
                                    previous={this.prev}
                                    getAccountInfo={getAccountInfo}
                                    accountInfo={accountInfo}
                                    getAllAddresses={getAllAddresses}
                                    addresses={addresses}
                                    getCountries={getCountries}
                                    getStates={getStates}
                                    countries={countries}
                                    states={states}
                                    paymentIntent={setUpPaymentIntent}
                                    summaryHeight={summaryHeight}
                                    customerCreationHandler={this.customerCreationHandler}
                                />
                            </If>
                            <If condition={currentStep === 2 && AuthManager.getGuestToken()}>
                                <Shipping
                                    next={this.next}
                                    previous={this.prev}
                                    getAllAddresses={getAllAddresses}
                                    addresses={addresses}
                                    getShippingDetail={getShippingDetail}
                                    shippingMethods={shippingMethods}
                                    summary={summary}
                                    getSubscriptionSummary={getSubscriptionSummary}
                                    handleShipping={this.handleShipping}
                                    shippingCost={shippingCost}
                                    shippingMethod={shippingMethod}
                                    paymentIntent={setUpPaymentIntent}
                                    getPaymentMethods={getPaymentMethods}
                                    savePaymentMethod={savePaymentMethod}
                                    removePaymentMethod={removePaymentMethod}
                                    paymentMethods={paymentMethods}
                                    getAccountInfo={getAccountInfo}
                                    accountInfo={accountInfo}
                                    orderDetails={orderDetails}
                                    createSubscription={createSubscription}
                                    directOrder={directOrder}
                                    setShippingMethod={setShippingMethod}
                                    createOrder={createOrder}
                                />
                            </If>
                            <If condition={currentStep === 3 && AuthManager.getGuestToken()}>
                                <Payment
                                    next={this.next}
                                    previous={this.prev}
                                    getAllAddresses={getAllAddresses}
                                    addresses={addresses}
                                    getShippingDetail={getShippingDetail}
                                    shippingMethods={shippingMethods}
                                    createOrder={createOrder}
                                    summary={summary}
                                    getSubscriptionSummary={getSubscriptionSummary}
                                    handleShipping={this.handleShipping}
                                    shippingCost={shippingCost}
                                    shippingMethod={shippingMethod}
                                    paymentIntent={setUpPaymentIntent}
                                    getPaymentMethods={getPaymentMethods}
                                    savePaymentMethod={savePaymentMethod}
                                    removePaymentMethod={removePaymentMethod}
                                    paymentMethods={paymentMethods}
                                    getAccountInfo={getAccountInfo}
                                    accountInfo={accountInfo}
                                    orderDetails={orderDetails}
                                    createDirectOrder={createDirectOrder}
                                    directOrder={directOrder}
                                    selectedShipping={selectedShipping}
                                />
                            </If>
                        </If>
                        <If condition={orderType === FINANCING_ORDER}>
                            <If condition={currentStep === 1}>
                                <Terms next={this.next} />
                            </If>
                            <If condition={currentStep === 2}>
                                <CustomerDetails
                                    next={this.next}
                                    previous={this.prev}
                                    getAccountInfo={getAccountInfo}
                                    accountInfo={accountInfo}
                                    getAllAddresses={getAllAddresses}
                                    addresses={addresses}
                                    getCountries={getCountries}
                                    getStates={getStates}
                                    countries={countries}
                                    states={states}
                                    paymentIntent={setUpPaymentIntent}
                                    summaryHeight={summaryHeight}
                                    customerCreationHandler={this.customerCreationHandler}
                                />
                            </If>
                            <If condition={currentStep === 3 && AuthManager.getGuestToken()}>
                                <Shipping
                                    next={this.next}
                                    previous={this.prev}
                                    getAllAddresses={getAllAddresses}
                                    addresses={addresses}
                                    getShippingDetail={getShippingDetail}
                                    shippingMethods={shippingMethods}
                                    createOrder={createOrder}
                                    summary={summary}
                                    getSubscriptionSummary={getSubscriptionSummary}
                                    handleShipping={this.handleShipping}
                                    shippingCost={shippingCost}
                                    shippingMethod={shippingMethod}
                                    paymentIntent={setUpPaymentIntent}
                                    getPaymentMethods={getPaymentMethods}
                                    savePaymentMethod={savePaymentMethod}
                                    removePaymentMethod={removePaymentMethod}
                                    paymentMethods={paymentMethods}
                                    getAccountInfo={getAccountInfo}
                                    accountInfo={accountInfo}
                                    orderDetails={orderDetails}
                                    createSubscription={createSubscription}
                                    setShippingMethod={setShippingMethod}
                                    selectedShipping={selectedShipping}
                                />
                            </If>
                        </If>
                    </Col>
                    <Col xs={12} lg={5}>
                        <If condition={summary && summary.items && (currentStep !== 1 || (currentStep === 1 && directOrder))}>
                            <Summary
                                customerProducts={summary.items}
                                summary={summary}
                                handleShipping={this.handleShipping}
                                shippingCost={shippingCost}
                                shippingMethod={shippingMethod}
                                handleSummaryHeight={this.handleSummaryHeight}
                                directOrder={directOrder}
                                currentStep={currentStep}
                            />
                        </If>
                    </Col>
                </Row>
            </Container>
        );
    }
}


export default StripeCheckout;
