import queryString from 'qs';
import { CALL_API, METHODS } from '../../middleware';
import URLs from '../../utils/url';
import {
    LOGIN,
    EMAIL_RESET,
    PASSWORD_RESET,
    LOGOUT,
    GET_ACCOUNT_INFO_ACTION_TYPES,
    UPDATE_ACCOUNT_INFO_ACTION_TYPES,
    GET_ONE_ORDER,
    CREATE_ADDRESS,
    DELETE_ADDRESS,
    GET_ALL_ADDRESSES,
    GET_COUNTRIES,
    GET_ONE_ADDRESS,
    GET_STATES,
    UPDATE_ADDRESS,
    RESET_CUSTOMER_DATA,
    RESET_SELECTED_ADDRESS,
    RESET_ACCOUNT_INFO,
    RESET_STATES,
    GET_ACCOUNT_ORDER_ACTION_TYPES,
    GET_SHIPPING_DETAILS_ACTION_TYPES,
    CREATE_ORDER_ACTION_TYPES,
    ACCOUNT_CREATE_ORDER_ACTION_TYPES,
    UPDATE_ORDER,
    GET_TRANSACTION_HISTORY,
    GET_TRANSACTION_DETAILS,
    GET_OPEN_TRANSACTIONS,
    GET_ORDER_HISTORY,
    STRIPE_SESSION,
    GET_TRANSACTIONAL_DATA,
    GET_CUSTOMER_DATA,
    GET_STORE_LIST,
    RESET_TRANSACTIONAL_CUSTOMER_DATA,
    GET_TASKS_CONTACT_NOTES,
    RESET_TASKS_CONTACT_NOTES,
    GET_ALL_TRANSACTIONS_DATA,
    RESET_ALL_TRANSACTIONS_DATA,
    GET_STOCK_SALES_SUMMARY,
    RESET_STOCK_SALES_SUMMARY,
} from './constants';
import AuthManager from '../../utils/AuthManager';

const login = ({ username, password }) => ({
    [CALL_API]: {
        requestConfig: {
            baseUrl: URLs.apiBaseUrl,
            useAuth: true,
            url: URLs.storeLogin,
            method: METHODS.POST,
            data: { username, password },
        },
        types: LOGIN,
    },
});

const emailReset = ({ username }) => ({
    [CALL_API]: {
        requestConfig: {
            baseUrl: URLs.apiBaseUrl,
            useAuth: true,
            url: URLs.emailReset,
            method: METHODS.POST,
            data: { username },
        },
        types: EMAIL_RESET,
    },
});

const passwordReset = ({ username, password, token }) => ({
    [CALL_API]: {
        requestConfig: {
            baseUrl: URLs.apiBaseUrl,
            useAuth: true,
            url: URLs.passwordReset,
            method: METHODS.POST,
            data: { username, password, token },
        },
        types: PASSWORD_RESET,
    },
});

const logout = () => {
    const token = AuthManager.get();
    return {
        [CALL_API]: {
            requestConfig: {
                baseUrl: URLs.apiBaseUrl,
                useAuth: true,
                url: URLs.storeLogout,
                method: METHODS.POST,
                data: token,
            },
            types: LOGOUT,
        },
    };
};

const getAllAddresses = () => ({
    [CALL_API]: {
        requestConfig: {
            baseUrl: URLs.apiBaseUrl,
            useAuth: true,
            url: URLs.address,
            method: METHODS.GET,
        },
        types: GET_ALL_ADDRESSES,
    },
});

const getOneAddress = id => ({
    [CALL_API]: {
        requestConfig: {
            baseUrl: URLs.apiBaseUrl,
            useAuth: true,
            url: `${URLs.address}/${id}`,
            method: METHODS.GET,
        },
        types: GET_ONE_ADDRESS,
    },
});

const createAddress = data => ({
    [CALL_API]: {
        requestConfig: {
            baseUrl: URLs.apiBaseUrl,
            useAuth: true,
            url: URLs.shipToAddress,
            method: METHODS.POST,
            data,
        },
        types: CREATE_ADDRESS,
    },
});

const updateAddress = ({ data, id }) => ({
    [CALL_API]: {
        requestConfig: {
            baseUrl: URLs.apiBaseUrl,
            useAuth: true,
            url: `${URLs.address}/${id}`,
            method: METHODS.PUT,
            data,
        },
        types: UPDATE_ADDRESS,
    },
});

const deleteAddress = id => ({
    [CALL_API]: {
        requestConfig: {
            baseUrl: URLs.apiBaseUrl,
            useAuth: true,
            url: `${URLs.address}/${id}`,
            method: METHODS.DELETE,
        },
        types: DELETE_ADDRESS,
    },
});

const getCountries = () => ({
    [CALL_API]: {
        requestConfig: {
            baseUrl: URLs.apiBaseUrl,
            useAuth: true,
            url: URLs.countries,
            method: METHODS.GET,
        },
        types: GET_COUNTRIES,
    },
});

const getStates = country => ({
    [CALL_API]: {
        requestConfig: {
            baseUrl: URLs.apiBaseUrl,
            useAuth: true,
            url: `${URLs.countries}/${country}/states`,
            method: METHODS.GET,
        },
        types: GET_STATES,
    },
});

const getAccountInfo = () => ({
    [CALL_API]: {
        requestConfig: {
            useAuth: true,
            baseUrl: URLs.apiBaseUrl,
            url: URLs.accountInfo,
            method: METHODS.GET,
            params: {},
        },
        types: GET_ACCOUNT_INFO_ACTION_TYPES,
    },
});
const editAccountInfo = ({ data }) => ({
    [CALL_API]: {
        requestConfig: {
            useAuth: true,
            baseUrl: URLs.apiBaseUrl,
            url: URLs.accountInfo,
            method: METHODS.PUT,
            headers: {},
            data,
        },
        types: UPDATE_ACCOUNT_INFO_ACTION_TYPES,
    },
});
const getOrders = () => ({
    [CALL_API]: {
        requestConfig: {
            useAuth: true,
            baseUrl: URLs.apiBaseUrl,
            url: URLs.accountOrders,
            method: METHODS.GET,
            params: {},
        },
        types: GET_ACCOUNT_ORDER_ACTION_TYPES,
    },
});
const getOneOrder = id => ({
    [CALL_API]: {
        requestConfig: {
            baseUrl: URLs.apiBaseUrl,
            useAuth: true,
            url: `${URLs.accountOrders}/${id}`,
            method: METHODS.GET,
        },

        types: GET_ONE_ORDER,
    },
});
const getShippingDetail = () => ({
    [CALL_API]: {
        requestConfig: {
            useAuth: true,
            baseUrl: URLs.apiBaseUrl,
            url: URLs.accountShipping,
            method: METHODS.GET,
        },
        types: GET_SHIPPING_DETAILS_ACTION_TYPES,
    },
});
const getOrder = cartId => ({
    [CALL_API]: {
        requestConfig: {
            useAuth: true,
            baseUrl: URLs.apiBaseUrl,
            url: URLs.order,
            method: METHODS.POST,
            headers: { 'guest-cart': cartId },
        },
        types: CREATE_ORDER_ACTION_TYPES,
    },
});
// eslint-disable-next-line camelcase
const createOrder = ({ shipping_address, billing_address, shipping_id, cartId }) => ({
    [CALL_API]: {
        requestConfig: {
            baseUrl: URLs.apiBaseUrl,
            useAuth: true,
            url: URLs.createOrder,
            method: METHODS.POST,
            data: { shipping_address, billing_address, shipping_id },
            headers: { 'guest-cart': cartId },
        },
        types: ACCOUNT_CREATE_ORDER_ACTION_TYPES,
    },
});

const updateOrder = (data, orderId) => ({
    [CALL_API]: {
        requestConfig: {
            useAuth: true,
            baseUrl: URLs.apiBaseUrl,
            url: `${URLs.createOrder}/${orderId}`,
            method: METHODS.PATCH,
            data,
        },
        types: UPDATE_ORDER,
    },
});

const getTransactionHistory = queryParams => {
    const params = queryString.stringify(queryParams);
    return ({
        [CALL_API]: {
            requestConfig: {
                useAuth: true,
                baseUrl: URLs.apiBaseUrl,
                url: `${URLs.transactions}?script=435&deploy=1&${params}`,
                method: METHODS.GET,
            },
            types: GET_TRANSACTION_HISTORY,
        },
    });
};

const getOpenTransactions = queryParams => {
    const params = queryString.stringify(queryParams);
    return ({
        [CALL_API]: {
            requestConfig: {
                useAuth: true,
                baseUrl: URLs.apiBaseUrl,
                url: `${URLs.transactions}?script=438&deploy=1&${params}`,
                method: METHODS.GET,
            },
            types: GET_OPEN_TRANSACTIONS,
        },
    });
};

const getOrderHistory = queryParams => {
    const params = queryString.stringify(queryParams);
    return ({
        [CALL_API]: {
            requestConfig: {
                useAuth: true,
                baseUrl: URLs.apiBaseUrl,
                url: `${URLs.transactions}?script=437&deploy=1&${params}`,
                method: METHODS.GET,
            },
            types: GET_ORDER_HISTORY,
        },
    });
};

const getTransactionDetails = queryParams => {
    const params = queryString.stringify(queryParams);
    return ({
        [CALL_API]: {
            requestConfig: {
                useAuth: true,
                baseUrl: URLs.apiBaseUrl,
                url: `${URLs.transactions}?script=436&deploy=1&${params}`,
                method: METHODS.GET,
            },
            types: GET_TRANSACTION_DETAILS,
        },
    });
};

const stripeSession = data => ({
    [CALL_API]: {
        requestConfig: {
            useAuth: true,
            baseUrl: URLs.apiBaseUrl,
            url: URLs.stripeSession,
            method: METHODS.POST,
            data,
        },
        types: STRIPE_SESSION,
    },
});

const getTransactionalData = queryParams => {
    const params = queryString.stringify(queryParams);
    return ({
        [CALL_API]: {
            requestConfig: {
                useAuth: true,
                baseUrl: URLs.apiBaseUrl,
                url: `${URLs.commonRestlet}?script=1725&deploy=1&${params}`,
                method: METHODS.GET,
            },
            types: GET_TRANSACTIONAL_DATA,
        },
    });
};

const getCustomerData = queryParams => {
    const params = queryString.stringify(queryParams);
    return ({
        [CALL_API]: {
            requestConfig: {
                useAuth: true,
                baseUrl: URLs.apiBaseUrl,
                url: `${URLs.commonRestlet}?script=445&deploy=1&${params}`,
                method: METHODS.GET,
            },
            types: GET_CUSTOMER_DATA,
        },
    });
};

const getStoreList = queryParams => {
    const params = queryString.stringify(queryParams);
    return ({
        [CALL_API]: {
            requestConfig: {
                useAuth: true,
                baseUrl: URLs.apiBaseUrl,
                url: `${URLs.commonRestlet}?script=1726&deploy=1&${params}`,
                method: METHODS.GET,
            },
            types: GET_STORE_LIST,
        },
    });
};

const getTaskContactNotes = queryParams => {
    const params = queryString.stringify(queryParams);
    return ({
        [CALL_API]: {
            requestConfig: {
                useAuth: true,
                baseUrl: URLs.apiBaseUrl,
                url: `${URLs.commonRestlet}?script=1727&deploy=1&${params}`,
                method: METHODS.GET,
            },
            types: GET_TASKS_CONTACT_NOTES,
        },
    });
};

const getAllTransactionsData = queryParams => {
    const params = queryString.stringify(queryParams);
    return ({
        [CALL_API]: {
            requestConfig: {
                useAuth: true,
                baseUrl: URLs.apiBaseUrl,
                url: `${URLs.commonRestlet}?script=1828&deploy=1&${params}`,
                method: METHODS.GET,
            },
            types: GET_ALL_TRANSACTIONS_DATA,
        },
    });
};

const getStockSalesSummary = queryParams => {
    const params = queryString.stringify(queryParams);
    return ({
        [CALL_API]: {
            requestConfig: {
                useAuth: true,
                baseUrl: URLs.apiBaseUrl,
                url: `${URLs.commonRestlet}?script=1829&deploy=1&${params}`,
                method: METHODS.GET,
            },
            types: GET_STOCK_SALES_SUMMARY,
        },
    });
};

const resetCustomerData = () => ({
    type: RESET_CUSTOMER_DATA,
});

const resetSelectedAddress = () => ({
    type: RESET_SELECTED_ADDRESS,
});
const resetAccountInfo = () => ({
    type: RESET_ACCOUNT_INFO,
});
const resetStates = () => ({
    type: RESET_STATES,
});

const resetTransactionalCustomerData = () => ({
    type: RESET_TRANSACTIONAL_CUSTOMER_DATA,
});

const resetTaskContactNotes = () => ({
    type: RESET_TASKS_CONTACT_NOTES,
});

const resetAllTransactionsData = () => ({
    type: RESET_ALL_TRANSACTIONS_DATA,
});

const resetStockSalesSummary = () => ({
    type: RESET_STOCK_SALES_SUMMARY,
});

export {
    login,
    emailReset,
    passwordReset,
    logout,
    getAllAddresses,
    getOneAddress,
    createAddress,
    updateAddress,
    deleteAddress,
    getCountries,
    getStates,
    resetCustomerData,
    resetSelectedAddress,
    getAccountInfo,
    editAccountInfo,
    getOneOrder,
    resetAccountInfo,
    resetStates,
    getOrders,
    getShippingDetail,
    getOrder,
    createOrder,
    updateOrder,
    getTransactionHistory,
    getOpenTransactions,
    getTransactionDetails,
    getOrderHistory,
    stripeSession,
    getTransactionalData,
    getCustomerData,
    resetTransactionalCustomerData,
    getStoreList,
    getTaskContactNotes,
    resetTaskContactNotes,
    getAllTransactionsData,
    resetAllTransactionsData,
    getStockSalesSummary,
    resetStockSalesSummary,
};
