import {
    GET_CATEGORY_LIST_ACTION_TYPES,
} from './constants';

const initialData = {
    isFetching: false,
    categoryList: [],
};

export default function categoryListReducer(state = initialData, { payload, ...action }) {
    switch (action.type) {
        case GET_CATEGORY_LIST_ACTION_TYPES.request:
            return {
                ...state,
                isFetching: true,
            };
        case GET_CATEGORY_LIST_ACTION_TYPES.success:
            return {
                ...state,
                isFetching: false,
                categoryList: payload.categoryList.filter((category) => category.active),
            };
        case GET_CATEGORY_LIST_ACTION_TYPES.failure:
            return {
                ...state,
                isFetching: false,
                categoryList: [],
            };
        default:
            return state;
    }
}
