import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form, FormGroup, FormText, Label, Container, Col } from 'reactstrap';
import { useForm } from 'react-hook-form';
import Loader from '../Shared/Loader';

const CreateAddress = ({
    createAddress,
    getCountries,
    getStates,
    countries,
    states,
    isFetching,
    displayPage,
    getAllAddresses,
}) => {
    const history = useHistory();
    const { register, handleSubmit, getValues, triggerValidation, errors } = useForm();
    const [billing, setBilling] = useState(false);
    const [shipping, setShipping] = useState(false);

    useEffect(() => {
        getAllAddresses();
        async function fetchCountries() {
            await getCountries();
        }

        fetchCountries().catch(() => {
            toast.error('Error getting countries', { className: 'toast-error' });
        });
    }, []);

    const handleSave = async data => {
        const address_type =
            data.address_type.includes('billing') && data.address_type.includes('shipping')
                ? 'both'
                : data.address_type[0];
        const editedData = {
            ...data,
            address_type,
        };
        triggerValidation({ name: 'address_type' });
        try {
            await createAddress(editedData);
            history.push('/shop/customer/address');
            toast.success('Address Created', { className: 'toast-success' });
        } catch (e) {
            const firstError = Object.keys(e.response.data.errors)[0];
            const error = e.response.data.errors[firstError][0] || 'ERROR';
            toast.error(error, { className: 'toast-error' });
        }
    };

    const handleAddressChange = event => {
        if (event.target.value === 'billing') {
            setBilling(!billing);
        }

        if (event.target.value === 'shipping') {
            setShipping(!shipping);
        }
    };

    const loadStates = async () => {
        const selectedCountry = getValues().country_code;
        try {
            await getStates(selectedCountry);
        } catch (e) {
            toast.error('Unable to get States', { className: 'toast-error' });
        }
    };
    // if (!countries || !countries.data) return null;
    if (!displayPage) {
        return (
            <div className="font-universe text-center py-5">
                <Loader timedOut={isFetching} />
                To add a new address please delete or update an existing address.
            </div>
        );
    }

    return (
        <Form className="font-universe" onSubmit={handleSubmit(handleSave)}>
            <Container className="d-flex flex-wrap justify-content-between">
                <Col lg={5}>
                    <FormGroup>
                        <h3>Contact Information</h3>
                        <Label className="required" for="first_name">
                            First Name
                        </Label>
                        <input
                            type="text"
                            name="first_name"
                            id="first_name"
                            placeholder="First Name"
                            ref={register}
                            required
                        />
                        <Label className="required" for="last_name">
                            Last Name
                        </Label>
                        <input
                            type="text"
                            name="last_name"
                            id="last_name"
                            placeholder="Last Name"
                            ref={register}
                            required
                        />
                        <Label for="company">Company</Label>
                        <input type="text" name="company" id="company" placeholder="Company" ref={register} />
                        <Label className="required" for="phone_number">
                            Phone Number
                        </Label>
                        <input
                            type="text"
                            name="phone_number"
                            id="phone_number"
                            placeholder="Phone Number"
                            ref={register}
                            pattern="[0-9.]+"
                            required
                        />
                    </FormGroup>
                </Col>
                <Col lg={5}>
                    <FormGroup>
                        <h3>Address</h3>
                        <Label className="required" for="street_address1">
                            Street Address
                        </Label>
                        <input type="text" name="street_address1" id="street_address1" ref={register} required />
                        <input type="text" name="street_address2" id="street_address2" ref={register} />
                        <Label className="required" for="city">
                            City
                        </Label>
                        <input
                            type="text"
                            name="city"
                            id="city"
                            placeholder="City"
                            ref={register}
                            pattern="[a-zA-Z][a-zA-Z ]+"
                            required
                        />
                        <Label className="required" for="zip">
                            Zip/Postal Code
                        </Label>
                        <input
                            type="text"
                            name="zip"
                            id="zip"
                            placeholder="Zip/Postal Code"
                            ref={register}
                            pattern="^[a-zA-Z0-9]{1,10}$"
                            required
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label className="required" for="country_code">
                            Country
                        </Label>
                        <select
                            name="country_code"
                            id="country_code"
                            placeholder="Country"
                            ref={register}
                            onChange={loadStates}
                            required
                        >
                            <option value="">--Country--</option>
                            {countries &&
                                countries.data &&
                                countries.data.map(country => (
                                    <option key={country.name} value={country.code}>
                                        {country.name}
                                    </option>
                                ))}
                        </select>
                    </FormGroup>
                    <FormGroup>
                        <Label className="required" for="state">
                            State
                        </Label>
                        <select name="state" id="state" placeholder="State" ref={register} required>
                            <option value="">--State--</option>
                            {states &&
                                states.data &&
                                states.data.map(state => (
                                    <option key={state.name} value={state.name}>
                                        {state.name}
                                    </option>
                                ))}
                        </select>
                    </FormGroup>
                    <FormGroup className="inputChecks">
                        <Label className="required" for="address_type">
                            Address Type
                        </Label>
                        <FormText color="muted">This will replace any existing shipping or billing address.</FormText>
                        <Label className="mt-3 mr-1" for="address_type_billing">
                            Billing Address
                        </Label>
                        <input
                            type="checkbox"
                            name="address_type"
                            id="address_type_billing"
                            ref={register({ required: true })}
                            value="billing"
                            onChange={handleAddressChange}
                        />
                        <Label className="mt-3 ml-3 mr-1" for="address_type_shipping">
                            Shipping Address
                        </Label>
                        <input
                            type="checkbox"
                            name="address_type"
                            id="address_type_shipping"
                            ref={register({ required: true })}
                            value="shipping"
                            onChange={handleAddressChange}
                        />
                    </FormGroup>
                    <div className="submitError">{errors && errors.address_type && 'Please select an address'}</div>
                </Col>
                <Col lg={6}>
                    <FormGroup className="d-flex align-items-center">
                        <Button type="submit" className="mr-3">
                            Save
                        </Button>
                        <Link to="/shop/customer/address">Go Back</Link>
                    </FormGroup>
                </Col>
            </Container>
            <Loader timedOut={isFetching} />
        </Form>
    );
};

CreateAddress.propTypes = {
    createAddress: PropTypes.func,
    getCountries: PropTypes.func,
    getStates: PropTypes.func,
    countries: PropTypes.object,
    states: PropTypes.object,
    isFetching: PropTypes.func,
    displayPage: PropTypes.bool,
    getAllAddresses: PropTypes.func,
};

export default CreateAddress;
