import React, { useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import ReactPlayer from 'react-player';
import './styles.css';

const VideoFile = () => {
    const [modal, setModal] = useState(false);
    const [showControl, setShowControl] = useState(false);

    const toggle = () => setModal(!modal);

    const togglePlay = () => {
        if (showControl) {
            setShowControl(false);
        } else {
            setShowControl(true);
        }
    };
    return (
        <div>
            <div className="videoThumbnail">
                <img
                    src="https://wpassets.lashbrookdesigns.com/wp-content/uploads/2020/07/22054822/size-your-wedding-ring-from-home.png"
                    alt="accurate ring size"
                    onClick={toggle}
                    className="videoThumbnail"
                />
                <i className="fa fa-youtube-play customPlay fa-2x" aria-hidden="true" onClick={toggle}></i>
            </div>
            <Modal isOpen={modal} toggle={toggle} className="full-screen-modal">
                <i className="fal fa-times-thin icon-close" onClick={toggle} />
                <ModalBody className="custom-modal">
                    <ReactPlayer
                        className="video-wrapper"
                        url="https://youtu.be/ISEJQgLpOv4"
                        playing={!showControl}
                        onClick={togglePlay}
                        controls
                        height="100%"
                        width="100%"
                        onStart={() => setShowControl(false)}
                    />
                    {showControl && <div id="btn-play" className="btn-play" onClick={togglePlay} />}
                </ModalBody>
            </Modal>
        </div>
    );
};

export default VideoFile;
