import SwatchFilter from '../components/SwatchFilter';
import { ITab, ITabs } from '../types/customizer';
import { getFilterMetaData, getSelectedOption, getWarning } from './commonRingUtils';

export const getV1FinishItems = (product: any) => {
    const items: ITabs = [];
    const finishes = product.ring_finish || [];
    finishes.map((finish: any, id: any) => {
        items.push(
            {
                title: finish.name,
                label: finish.name,
                eventKey: finish.name + id,
                component: SwatchFilter,
                isEnabled: finish.items?.length > 0,
                content: {
                    // showDefaultTitle: true,
                    // displayShowMoreOption: false,
                    // meta: getFilterMetaData(product, 'ring_finish'),
                    // warning: getWarning(product, `ring_finish_${id}`),
                    hasSwatch: true,
                    fieldType: `ring_${id}`,
                    options: finish.items,
                    selectedOption: getSelectedOption(finish.items),
                },
            }
        );
        return null;
    });
    const filtered = items.filter((tab: ITab) => tab.isEnabled || tab.items?.length);
    return filtered;
};

export const getV3FinishItems = (product: any) => {
    const items: ITabs = [];
    const finishes = product.finish || {};
    if(finishes.first_finish) {
        items.push({
            title: 'First Finish',
            eventKey: 'first_finish',
            component: SwatchFilter,
            isEnabled: finishes.first_finish?.length > 0,
            content: {
                hasSwatch: true,
                fieldType: 'ring_0',
                options: finishes.first_finish,
                selectedOption: getSelectedOption(finishes.first_finish),
            },
        });
    }
    if(finishes.second_finish) {
        items.push({
            title: 'Second Finish',
            eventKey: 'second_finish',
            component: SwatchFilter,
            isEnabled: finishes.second_finish?.length > 0,
            content: {
                hasSwatch: true,
                fieldType: 'ring_1',
                options: finishes.second_finish,
                selectedOption: getSelectedOption(finishes.second_finish),
            },
        });
    }
    const filtered = items.filter((tab: ITab) => tab.isEnabled || tab.items?.length);
    return filtered;
};


