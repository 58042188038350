import { CALL_API, METHODS } from '../../middleware';
import URLs from '../../utils/url';
import { GET_CURRENT_CURRENCY, GET_CURRENCY_CONVERSION, SET_CURRENT_CURRENCY } from './constants';

const getCurrentCurrency = () => ({
    [CALL_API]: {
        requestConfig: {
            baseUrl: URLs.apiBaseUrl,
            url: URLs.currencies,
            method: METHODS.GET,
        },
        types: GET_CURRENT_CURRENCY,
    },
});

const setCurrentCurrency = (data) => ({
    type: SET_CURRENT_CURRENCY,
    payload: data,
});

const getCurrencyConversion = () => ({
    [CALL_API]: {
        requestConfig: {
            baseUrl: URLs.apiBaseUrl,
            url: URLs.currencyConversion,
            method: METHODS.GET,
        },
        types: GET_CURRENCY_CONVERSION,
    },
});

export { getCurrentCurrency, getCurrencyConversion, setCurrentCurrency };
