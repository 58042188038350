/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Carousel } from 'react-responsive-carousel';
import './Home.scss';
import '../../../node_modules/react-responsive-carousel/lib/styles/carousel.min.css';
import URLs from '../../utils/url';
import { POPULAR_RINGS } from '../../utils/Constants';
import Helmet from 'react-helmet';

const Home = ({ list }) => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const selected = POPULAR_RINGS[selectedIndex];
    const [centerSlidePercentage, setCenterSlidePercentage] = useState(23);

    useEffect(() => {
        const updateDimension = () => {
            const WIDTH = window.innerWidth;
            if (WIDTH > 1200) {
                setCenterSlidePercentage(23);
            } else if (WIDTH > 992) {
                setCenterSlidePercentage(35);
            } else if (WIDTH > 768) {
                setCenterSlidePercentage(52);
            } else {
                setCenterSlidePercentage(68);
            }
        };

        window.addEventListener('resize', updateDimension);
        setSelectedIndex(1);

        updateDimension();

        return () => window.removeEventListener('resize', updateDimension);
    }, []);

    return (
        <section>
            <Helmet>
                <link rel="canonical" href="https://www.lashbrookdesigns.com/" />
                <title>Lashbrook | Custom Mens Wedding Rings</title>
                <meta name="description" content="With billions of combinations, custom men's wedding rings in our Lashbrook Builder, our ring customizer software has never been easier. Getting married or looking for a ring for any occasion, you will be able to create what you have been dreaming about. From start to finish, your ring is custom built in the USA." />
                <meta name="keywords" content="Wedding rings, mens wedding bands, womens wedding rings, custom wedding bands" />
            </Helmet>
            <div
                className="hero-parent-container"
                title="artisans creating men's custom wedding rings"
                alt="artisans creating men's custom wedding rings"
            >
                <div className="hero-main-container font-syncopate">
                    <h2>QUALITY. HANDMADE. RINGS.</h2>
                    <div className="hero-button-container">
                        <Link className="overlaybtn" to="/shop">
                            <button className="hero-button" aria-label="Browse">BROWSE COLLECTIONS </button>
                        </Link>
                        <Link className="overlaybtn hidden-sm" to="/shop/categories/all-rings">
                            <button className="hero-button" aria-label="SeeAll">SHOW ME ALL RINGS </button>
                        </Link>
                    </div>
                </div>
                <div className="hero-icons">
                    <img
                        className="hero-icon"
                        src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/wp-content/uploads/2016/02/15003134/USA_ICON.png"
                        alt="Lashbrook rings are handmade in the USA"
                    />
                    <img
                        className="hero-icon"
                        src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/wp-content/uploads/2016/02/15002508/EXOTIC_ICON.png"
                        alt="We use exotic materials to create each unique wedding ring"
                    />
                    <img
                        className="hero-icon"
                        src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/wp-content/uploads/2016/02/15003136/WARRANTY_ICON.png"
                        alt="Lashbrook warranties rings for a lifetime of durability and peace of mind"
                    />
                    <img
                        className="hero-icon"
                        src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/wp-content/uploads/2016/02/15003133/RETAILERS_ICON.png"
                        alt="Get a feel for a ring in person no matter where you live by visiting one of Lashbrook's 2000 local retailers"
                    />
                </div>
                <video
                    className="hero-video hidden-sm"
                    style={{ width: '100vw', margin: '0 auto', display: 'block', backgroundSize: 'cover' }}
                    src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/wp-content/uploads/2016/02/23140102/custom-mens-wedding-rings.mp4"
                    autoPlay
                    loop
                    muted
                    playsinline
                />
            </div>
            <h2 className="text-center font-syncopate text-uppercase home-headings">
                AS EASY AS...
            </h2>
            <div className="row m-0 m-auto steps-holder justify-content-lg-between justify-content-center">
                <div className="d-flex align-items-center pr-4 mb-4">
                    <div className="font-syncopate number">1</div>
                    <div className="d-flex flex-column justify-content-around align-items-center py-3">
                        <img
                            src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/store/pub/media/images/category-images/classics/demo-ring.png"
                            className="img-medium"
                            alt="Browse our collection of wedding rings for inspiration"
                        />
                        <Link
                            to="/shop/rings/profiles/D/widths/7/ring_materials/14KW?ring_finish=SATIN&finish_type=F&weight=P&size=10&feature_group=N&sleeve=NONE"
                            className="step-text font-syncopate pt-3"
                            tabIndex="0"
                            aria-label="BrowseSelection"
                        >
                            BROWSE FOR INSPIRATION
                        </Link>
                    </div>
                </div>
                <div className="d-flex align-items-center pr-4 mb-4">
                    <div className="font-syncopate number">2</div>
                    <div className="d-flex flex-column justify-content-around align-items-center py-3">
                        <div>
                            <img
                                className="img-small mr-2"
                                src="http://assets.lashbrookdesigns.com/swatches/patterns/LCVTREES/images/1"
                                alt="Customize your favorite ring"
                                data-link=""
                            />
                            <img
                                className="img-small mr-2"
                                src="http://assets.lashbrookdesigns.com/swatches/materials/HW-MAPLEBURL/images/1"
                                alt="Customize your favorite ring"
                                data-link=""
                            />
                        </div>
                        <Link
                            to="/shop/rings/profiles/D/widths/7/ring_materials/14KW?ring_finish=SATIN&finish_type=F&weight=P&size=10&feature_group=N&sleeve=NONE"
                            className="step-text font-syncopate pt-3"
                            tabIndex="0"
                            aria-label="CustomizeFavorite"
                        >
                            CUSTOMIZE YOUR FAVORITE
                        </Link>
                    </div>
                </div>
                <div className="d-flex align-items-center pr-4 mb-4">
                    <div className="font-syncopate number">3</div>
                    <div className="d-flex flex-column justify-content-around align-items-center py-3">
                        <img
                            src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/wp-content/uploads/2019/11/14215243/We-create-your-design.png"
                            className="img-medium"
                            alt="img-sizing"
                        />
                        <Link
                            to="/shop/rings/profiles/D/widths/7/ring_materials/14KW?ring_finish=SATIN&finish_type=F&weight=P&size=10&feature_group=N&sleeve=NONE"
                            className="step-text font-syncopate pt-3"
                            tabIndex="0"
                            aria-label="WeMakeYourRing"
                        >
                            WE CREATE YOUR DESIGN
                        </Link>
                    </div>
                </div>
            </div>
            <h2 className="text-center font-syncopate text-uppercase home-headings mt-4">
                COLLECTIONS
            </h2>
            <div className="row m-0 m-auto steps-holder collections-holder">
                {list.map(item => (
                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-6 px-0 d-flex flex-column align-items-center justify-content-around">
                        <img src={item.icon_image} className="collection-icon" alt="icon" />
                        <Link to={`/shop/categories/${item.category_sku}`}>
                            <img
                                className="collection-image"
                                src={`${URLs.assetsBaseUrl}/${item.ring_image}`}
                                alt={item.name}
                            />
                        </Link>
                        <Link to={`/shop/categories/${item.category_sku}`} tabIndex="0" className="collection-text font-syncopate">
                            {item.name}
                        </Link>
                    </div>
                ))}
            </div>
            {/* Popular rings */}
            <h2 className="text-center font-syncopate text-uppercase home-headings">
                Popular rings
            </h2>
            <div className="popular-holder m-auto position-relative">
                <img
                    src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/wp-content/themes/rinzler/images/fader.png"
                    className="left-fader"
                    alt="fader"
                />
                <Carousel
                    centerMode
                    centerSlidePercentage={centerSlidePercentage}
                    autoPlay={true}
                    showIndicators={false}
                    infiniteLoop={true}
                    showStatus={false}
                    showThumbs={false}
                    onClickItem={true}
                    selectedItem={selectedIndex}
                    renderArrowPrev={clickHandler => (
                        <i
                            className="fa fa-angle-left left arrow cursor-pointer"
                            onClick={clickHandler}
                            role="presentation"
                        />
                    )}
                    renderArrowNext={clickHandler => (
                        <i
                            className="fa fa-angle-right right arrow cursor-pointer"
                            onClick={clickHandler}
                            role="presentation"
                        />
                    )}
                    onChange={setSelectedIndex}
                >
                    {POPULAR_RINGS.map(item => (
                        <div className="d-flex flex-column align-items-center justify-content-around">
                            <Link to={selected ? selected.path : ''}>
                            <img src={item.src} alt={item.alt} />
                            </Link>
                        </div>
                    ))}
                </Carousel>
                <Link to={selected ? selected.path : ''} tabIndex="0" className="ring-link font-universe">
                    <img
                        src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/wp-content/themes/rinzler/images/fader.png"
                        className="right-fader"
                        alt="fader"
                    />
                    <div className="text-center my-5">
                        <h2 className="ring-name font-universe">{selected ? selected.name : ''}</h2>
                        View <i className="fa fa-angle-right" />
                    </div>
                </Link>
            </div>
            <div className="banner-section">
                <section className="full-banner statement-img d-flex align-items-center justify-content-center">
                    <div className="d-flex flex-column align-items-center">
                        <img
                            className="banner-icon my-4"
                            src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/wp-content/uploads/2020/10/27073625/Lashbrook_ORings.png"
                            alt="banner-icon"
                        />
                        <h2 className="font-syncopate text-uppercase text-light text-center text-shadow">
                            Make a <br /> Statement
                        </h2>
                        <div className="goldline my-4" />
                        <Link className="overlaybtn" to="/shop/categories/zirconium">
                            <button aria-label="Zirconium" className="banner-btn">Shop Zirconium</button>
                        </Link>
                    </div>
                </section>

                <div className="d-flex flex-wrap justify-content-between">
                    <section className="half-banner first d-flex align-items-center justify-content-center">
                        <div className="d-flex flex-column align-items-center">
                            <img
                                className="banner-icon my-4"
                                src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/wp-content/uploads/2020/10/27073625/Lashbrook_ORings.png"
                                alt="banner-icon"
                            />
                            <h2 className="font-syncopate text-uppercase text-light text-center text-shadow">
                                Bring Out His <br /> Finest Features
                            </h2>
                            <div className="goldline my-4" />
                            <Link className="overlaybtn" to="/shop/categories/all-rings">
                                <button aria-label="Mens" className="banner-btn">Shop All Men's</button>
                            </Link>
                        </div>
                    </section>
                    <section className="half-banner second d-flex align-items-center justify-content-center">
                        <div className="d-flex flex-column align-items-center">
                            <img
                                className="banner-icon my-4"
                                src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/wp-content/uploads/2020/10/27073625/Lashbrook_ORings.png"
                                alt="banner-icon"
                            />
                            <h2 className="font-syncopate text-uppercase text-light text-center text-shadow">
                                She will <br /> Shimmer and Glow
                            </h2>
                            <div className="goldline my-4" />
                            <Link className="overlaybtn" to="/shop/categories/womens">
                                <button aria-label="Womens" className="banner-btn">Shop All Women's</button>
                            </Link>
                        </div>
                    </section>
                </div>

                <section className="full-banner wearable-img d-flex align-items-center justify-content-center">
                    <div className="d-flex flex-column align-items-center">
                        <img
                            className="banner-icon my-4"
                            src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/wp-content/uploads/2020/10/27073625/Lashbrook_ORings.png"
                            alt="banner-icon"
                        />
                        <h2 className="font-syncopate text-uppercase text-light text-center text-shadow">
                            Wearable <br /> Waves
                        </h2>
                        <div className="goldline my-4" />
                        <Link className="overlaybtn" to="/shop/categories/shell">
                            <button aria-label="Shell" className="banner-btn">Shop Shell</button>
                        </Link>
                    </div>
                </section>
            </div>
        </section>
    );
};

const mapStateToProps = state => ({
    list: state.categoryList.categoryList || [],
});

Home.propTypes = {
    list: PropTypes.array.isRequired,
};

export default connect(mapStateToProps, null)(Home);
