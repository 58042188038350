import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ITab, ITabs } from '../../types/customizer';
import './index.scss';
import URLs from '../../../../utils/url';
import { REQUIRED_TAB_FIELDS, TABS_TO_AVOID, getRowContent } from '../../utils/summary';

type PrintProps = {
  tabs: ITabs;
  product: any;
  image:string;
};


const PrintSummary = React.forwardRef((props:PrintProps, ref) => {
    const { tabs, product, image } = props;

    const imageStr = `${URLs.assetsBaseUrl}${image}`;
    const imageURL = `${imageStr}${imageStr.indexOf('?') !== -1 ? '&' : '?'}image_size=1200&rand=${Math.random().toString(12)}`;

    const handleKeyDown = (event: KeyboardEvent) => {
        const printButton = document.getElementById('print-btn');

        if (event.keyCode === 80 && (event.ctrlKey || event.metaKey) && printButton) {
            event.preventDefault();
            printButton.click();
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const REQUIRED_TABS = tabs.filter((tab: ITab) => REQUIRED_TAB_FIELDS.includes(tab.eventKey));
    const ADDITIONAL_TABS = tabs.filter((tab: ITab) => !REQUIRED_TAB_FIELDS.includes(tab.eventKey) && !TABS_TO_AVOID.includes(tab.eventKey));

    return (
        // @ts-ignore
        <div ref={ref} className="print-summary tw-py-10 tw-px-4 tw-text-prussian-blue tw-mx-auto tw-w-[calc(100%-2cm)]">
            <div className="tw-text-center tw-mb-4">
                <img className="tw-h-[285px]" src={imageURL} alt="" />
            </div>
            <p>
                {product?.description}
            </p>
            <div className="tw-mt-3 tw-text-lg tw-font-bold">Required</div>
            <div className="print-summary-container tw-mt-3 ml-3">
                <div className="tw-mt-1">
                    <p>Magic Number</p>
                    <p>{product?.magic_number}</p>
                </div>
                {REQUIRED_TABS.map((tab: ITab, index) => 
                    (<React.Fragment key={index}> {getRowContent(tab, index)}</React.Fragment>))
                }
            </div>
            <div className="tw-mt-3 tw-text-lg tw-font-bold">Additional Details</div>
            <div className="print-summary-container tw-mt-3 ml-3">
                <div className="tw-mt-1">
                    <p>Style</p>
                    <p>{product?.sku}</p>
                </div>
                {ADDITIONAL_TABS.map((tab: ITab, index) => 
                    (<React.Fragment key={index}> { getRowContent(tab, index) }</React.Fragment>))
                }
            </div>
            <div className="tw-absolute tw-right-5 tw-mt-20">
                Signature: _________________________
            </div>
        </div>
    );
});


const mapStateToProps = (state: any) => ({
    product: state.customize.product,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(PrintSummary);

