import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button } from 'reactstrap';
import { LEARN_ITEMS } from '../../containers/ContentContainer/constants';
import URLs from '../../utils/url';

const Learn = () => (
    <Container>
        <Row>
            <Col xs={12} className="text-center mt-5 mb-5 d-flex align-items-center flex-column">
                <img src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/wp-content/themes/rinzler/images/icon-awards.png" alt="size-icon" />
                <h1 className="font-syncopate text-uppercase mt-3 mb-3">LEARN CENTER</h1>
                <p className="font-universe byline">Choose any of the areas below to learn more about our policies, products, and processes.</p>
                <hr className="content-hr" color="#dcc67d" />
            </Col>
            {LEARN_ITEMS.map((item, index) => (
                <>
                    <Col xs={12} md={4} className={`text-center mb-5 ${[0, 1, 3, 4, 6, 7].includes(index) && 'border-gold'}`}>
                        <h3 className="font-syncopate text-uppercase fs-20">{item.title}</h3>
                        <hr className="content-hr-wider" color="#dcc67d" />
                        <p className="font-universe color-grey fs-24">{item.byline}</p>
                        <Link to={item.link}><Button size="lg">Learn More</Button></Link>
                    </Col>
                    {[2, 5].includes(index) && <Col xs={12}><hr className="content-hr-full mb-5" color="#dcc67d" /></Col>}
                </>
            ))}
        </Row>
    </Container>
);

export default Learn;
