import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import If from '../../components/Shared/If';

class OrderPagination extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: props.currentPage,
        };
    }

    handleClick = (e, index) => {
        e.preventDefault();
        this.setState({
            currentPage: index,
        });
    };
    formatDate(string) {
        return new Date(string).toLocaleDateString();
    }

    render() {
        const { pageSize, pageCount, data, isParent } = this.props;
        const { currentPage } = this.state;
        const pageNumbers = [];

        for (let i = 0; i < pageCount; i++) {
            pageNumbers.push(
                <PaginationItem key={i} active={currentPage === i}>
                    <PaginationLink onClick={e => this.handleClick(e, i)} href="#">
                        {i + 1}
                    </PaginationLink>
                </PaginationItem>
            );
        }
        const paginatedData = data.slice(currentPage * pageSize, (currentPage + 1) * pageSize);

        return (
            <React.Fragment>
                {paginatedData.map((data, i) => (
                    <tr className="data-slice" key={i}>
                        <td>{data.order_id}</td>
                        <td>{data.account?.store?.store_name}</td>
                        <td>{this.formatDate(data.created_at)}</td>
                        {data.addresses &&
                            data.addresses.length &&
                            data.addresses.map((subData, j) => (
                                <If condition={subData.address_type === 'shipping'} key={j}>
                                    <td>
                                        {`${subData.street_address1} ${subData.street_address2} ${subData.city} ${
                                            subData.state
                                        } ${subData.zip}`}
                                    </td>
                                </If>
                            ))}
                        <td>{data.grand_total}</td>
                        {!isParent && <td className="text-capitalize">{data.status}</td>}
                        <td className="view-order">
                            <Link to={`/shop/customer/account/me/order/view/${data.id}`}>View Order</Link>
                        </td>
                    </tr>
                ))}

                <React.Fragment>
                    <If condition={pageCount > 1}>
                        <Pagination>
                            <PaginationItem disabled={currentPage < pageCount - 1}>
                                <PaginationLink onClick={e => this.handleClick(e, currentPage - 1)} previous href="#" />
                            </PaginationItem>
                            {pageNumbers}
                            <PaginationItem disabled={currentPage >= pageCount - 1}>
                                <PaginationLink onClick={e => this.handleClick(e, currentPage + 1)} next href="#" />
                            </PaginationItem>
                        </Pagination>
                    </If>
                </React.Fragment>
            </React.Fragment>
        );
    }
}

OrderPagination.propTypes = {
    currentPage: PropTypes.number,
};

export default OrderPagination;
