import { apiActionTypes } from '../../middleware';
const base = '@@Containers/SearchContainer/';

export const SEARCH_ACTION_TYPES = apiActionTypes(`${base}SEARCH`);
export const PAGINATED_SEARCH_ACTION_TYPES = apiActionTypes(`${base}PAGINATED_SEARCH`);


export const CONSTANTS = {
    ABOUT: 'ABOUT',
    BROWSE_CATEGORY: 'BROWSE_CATEGORY',
    CUSTOMIZE: 'CUSTOMIZE',
    BROWSE_LIST: 'BROWSE_LIST',
    EXPLORE: 'EXPLORE',
};
