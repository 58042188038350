import { CALL_API, METHODS } from '../../middleware';
import URLs from '../../utils/url';
import {
    GET_ORDER_ACTION_TYPES,
    UPDATE_ORDER_ACTION_TYPES,
    RECHECK_ORDER_ACTION_TYPES,
    CLONE_CART_ITEMS_ACTION_TYPES,
    HIDE_LOADER,
} from './constants';
import CartManager from '../../utils/CartManager';

const getOrder = (orderId, isRecheck) => ({
    [CALL_API]: {
        requestConfig: {
            useAuth: true,
            baseUrl: URLs.apiBaseUrl,
            url: `${URLs.order}/${orderId}`,
            method: METHODS.GET,
        },
        types: isRecheck ? RECHECK_ORDER_ACTION_TYPES : GET_ORDER_ACTION_TYPES,
    },
});

const updateOrder = (orderId, hook_type, status) => ({
    [CALL_API]: {
        requestConfig: {
            useAuth: true,
            baseUrl: URLs.apiBaseUrl,
            url: `${URLs.order}/${orderId}`,
            method: METHODS.PATCH,
            headers: { 'guest-cart': CartManager.get() },
            data: { hook_type, status },
        },
        types: UPDATE_ORDER_ACTION_TYPES,
    },
});

const cloneOrder = cartId => ({
    [CALL_API]: {
        requestConfig: {
            useAuth: true,
            baseUrl: URLs.apiBaseUrl,
            url: URLs.cloneOrder,
            method: METHODS.POST,
            headers: { 'guest-cart': cartId },
        },
        types: CLONE_CART_ITEMS_ACTION_TYPES,
    },
});

const hideLoader = () => ({
    type: HIDE_LOADER,
});

export {
    getOrder,
    updateOrder,
    cloneOrder,
    hideLoader,
};
