import React from 'react';
import { Col, ListGroup, ListGroupItem, Row } from 'reactstrap';

const CustomerDetails = props => {
    const { details } = props;

    if (Object.keys(details) < 1) return null;
    const { address, name } = details.shipping;

    return (
        <Row>
            <Col>
                <h4 className="text-uppercase">Customer Details</h4>
                <hr />
                <ListGroup className="customer-details-portal fs-18">
                    <ListGroupItem className="font-weight-bold fs-20 text-capitalize">{name}</ListGroupItem>
                    <ListGroupItem>
                        <span className="font-weight-bold">Address:</span><span className="text-capitalize">{` ${address.line1}${address.line2 ? `, ${address.line2}` : ''}, ${address.city}-${address.postal_code}, ${address.state}, ${address.country}`}</span>
                    </ListGroupItem>
                    <ListGroupItem>
                        <span className="font-weight-bold">Email: </span><span>{details.email}</span>
                    </ListGroupItem>
                </ListGroup>
            </Col>
        </Row>
    );
};

export default CustomerDetails;
