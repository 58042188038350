import { getV1PageData } from './v1';
import { getV2PageData } from './v2';
import { getV3PageData } from './v3';

export const getPageData = (product: any, isDataTabAccess = false, type: number, ring_type: string) => {
    if(type === 1) {
        return getV1PageData(product, isDataTabAccess);
    } else if (type === 2) {
        return getV2PageData(product, isDataTabAccess);
    } else if (type === 3) {
        return getV3PageData(product, isDataTabAccess, ring_type);
    }
    return [];
};
