import React from 'react';
import { withRouter } from 'react-router-dom';
import UniqueHandmade from '../../components/Content/UniqueHandmade';
import '../../components/Content/styles.css';

class UniqueHandmadeContainer extends React.Component {
    render() {
        return <UniqueHandmade />;
    }
}

export default withRouter(UniqueHandmadeContainer);
