import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Button, Form, FormGroup, Label, Input, Col, Modal, ModalBody } from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import Loader from '../../components/Shared/Loader';
import './styles.css';
import Manage from '../Manage Subscription';

const Login = ({ login, isFetching, location, isFetchingManageSubscription, emailPortal }) => {
    const history = useHistory();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    const handleSubmit = event => {
        event.preventDefault();
        login({ username, password }).then(() => {
            if (
                location?.state?.from?.pathname.startsWith('/customer/account/me/order/view/') &&
                history.location.pathname !== '/customer/forgot-password'
            ) {
                const { pathname, search } = location?.state?.from;
                history.push(pathname + search);
            }
        });
    };

    return (
        <Container className="login-container d-flex flex-column justify-content-center">
            <Row className="flex-column">
                <Col xs={12} md={6} lg={5} className="mx-auto d-flex flex-column align-items-center">
                    <h5 className="text-center">JEWELRY STORE LOGIN</h5>
                    <Form className="mt-4 d-flex flex-column login-form" onSubmit={handleSubmit}>
                        <FormGroup>
                            <Label className="font-universe font-weight-bolder" for="username">
                                Email/Username
                            </Label>
                            <Input
                                type="text"
                                name="username"
                                id="username"
                                onChange={e => setUsername(e.target.value)}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label className="font-universe font-weight-bolder" for="password">
                                Password
                            </Label>
                            <Input
                                type="password"
                                name="password"
                                id="password"
                                onChange={e => setPassword(e.target.value)}
                                required
                            />
                        </FormGroup>
                        <Button>Login</Button>
                        <Link to="/shop/customer/forgot-password" color="primary" className="font-universe link-text">
                            Forgot Password
                        </Link>
                    </Form>
                    <hr />
                    <Button className="manage-financing-button" onClick={toggle}>
                        Manage Financing Order
                    </Button>
                    <Modal className="manage-financing-modal" isOpen={modal} toggle={toggle}>
                        <ModalBody>
                            <i
                                className="fa fa-times icon-close manage-financing-close-button"
                                onClick={toggle}
                                onKeyPress={event => {
                                    if (event.key === 'Enter') toggle(event);
                                }}
                                role="Button"
                                tabIndex="0"
                            />
                            <Manage emailPortal={emailPortal} isFetching={isFetchingManageSubscription} />
                        </ModalBody>
                    </Modal>
                </Col>
            </Row>
            <Loader timedOut={isFetching} />
        </Container>
    );
};

Login.propTypes = {
    login: PropTypes.func,
    isFetching: PropTypes.bool,
    location: PropTypes.object,
};

export default Login;
