import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row } from 'reactstrap';
import './styles.css';
import { ORDER_STATUS } from '../../containers/OrderStatus/constants';

const OrderStatus = ({ isTimeout = false, orderData, cloneOrderData }) => {
    const checkOutMethod = orderData && orderData.checkout_method;
    const orderStatus = orderData.status;
    const isPending = orderStatus === ORDER_STATUS.PENDING;
    const isCompleted = orderStatus === ORDER_STATUS.APPROVED || orderStatus === ORDER_STATUS.COMPLETED;
    const isFailed = orderStatus === ORDER_STATUS.FAILED || orderStatus === ORDER_STATUS.CANCELLED;

    const getConfirmationIcon = () => {
        if (isCompleted) {
            return 'fa-check-circle-o';
        } else if (isPending || isTimeout) {
            return 'fa-exclamation-triangle';
        }
    };

    const getConfirmationText = () => {
        if (isCompleted) {
            return 'Thank you for placing your order with Lashbrook Designs';
        } else if (isPending || isTimeout) {
            return 'Order Pending';
        }
    };

    const getConfirmationErrorMessage = () => {
        if (isCompleted || isPending || isTimeout) {
            return "We'll email you an order confirmation with details and tracking info";
        }
    };

    const renderConfirmationButton = () => {
        if (isCompleted) {
            return (
                <Link to="/shop/categories" className="btn mt-3 continue-btn" style={{ color: 'white' }}>
                    Continue Shopping
                </Link>
            );
        } else if (isFailed) {
            return (
                <button className="mt-3 continue-btn" onClick={cloneOrderData} style={{ color: 'white' }}>
                    Try Again
                </button>
            );
        }
        return null;
    };

    return (
        <Row className="status-container font-universe flex-column align-items-center justify-content-center px-5 px-md-0">
            <i className={`${getConfirmationIcon()} fa fa-3x`} aria-hidden="true" />
            <h2 className="mb-3 text-center">{getConfirmationText()}</h2>
            {(isCompleted || isPending) && (
                <div className="text-center">
                    Your order number is: <strong>{orderData ? orderData.order_id : ''}</strong>
                </div>
            )}
            <div className="text-center">{getConfirmationErrorMessage()}</div>
            {renderConfirmationButton()}
        </Row>
    );
};

OrderStatus.propTypes = {
    orderData: PropTypes.object.isRequired,
    cloneOrderData: PropTypes.func.isRequired,
    isTimeout: PropTypes.bool.isRequired,
};

export default OrderStatus;
