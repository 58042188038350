import { apiActionTypes } from '../../middleware';
const base = '@@Containers/OrderStatusContainer/';

export const GET_ORDER_ACTION_TYPES = apiActionTypes(`${base}GET_ORDER`);
export const UPDATE_ORDER_ACTION_TYPES = apiActionTypes(`${base}UPDATE_ORDER`);
export const RECHECK_ORDER_ACTION_TYPES = apiActionTypes(`${base}RECHECK_ORDER`);
export const HIDE_LOADER = `${base}HIDE_LOADER`;
export const CLONE_CART_ITEMS_ACTION_TYPES = apiActionTypes(`${base}CLONE_CART_ITEMS`);


export const ORDER_STATUS = {
    PENDING: 'pending',
    COMPLETED: 'completed',
    CANCELLED: 'cancelled',
    FAILED: 'failed',
    AUTHORIZED: 'authorized',
    REJECTED_REVERSIBLE: 'rejected_reversible',
    REJECTED_IRREVERSIBLE: 'rejected_irreversible',
    APPROVED: 'approved',
};

export const HOOK_TYPE = {
    PENDING: 'pending',
    PAYMENT: 'payment',
    CREDIT: 'credit',
    CAPTURE: 'capture',
    VOID: 'void',
    AUTH: 'auth',
    REJECTED_REVERSIBLE: 'rejected_reversible',
    REJECTED_IRREVERSIBLE: 'rejected_irreversible',
};

export const TIMEOUT_THRESOLD = 180000;
export const TIME_INTERVAL = 10000;
