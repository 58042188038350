import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import './spiff.scss';

const Spiff = () => (
    <div className="our-container font-syncopate">
        {/* <div className="wrapper"> */}
            <Row className="content">
                <Col sm={12} md={12} lg={12}>
                    <h1 className="font-open-sans-condensed font-open-sans-condensed bolder">SPIFF is Here!</h1>
                    <p className="font-open-sans-condensed description">
                        <span className="tw-block">Lashbrook’s SPIFF program is currently on pause. Enter your information below to receive signup details and to be ready to earn SPIFFs when the program resumes!</span>
                        <iframe className="iFrame" src="https://lashbrookdesigns.us8.list-manage.com/subscribe?u=20ce7d78462d997a92aeb08ab&amp;id=d731dbd72f" allowscroll="false" allowFullScreen="false" height="800px" width="800px" style={{ border : 'none' }}></iframe>
                        <Link to="/spiff-terms"><button className="buildbtn">See Terms &amp; Conditions</button></Link></p>
                </Col>
            </Row>
        {/* </div> */}
    </div>
);

export default Spiff;
