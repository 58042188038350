import React, { useState } from 'react';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import cards from '../../assets/images/cards.png';
import { CARD_ELEMENT_OPTIONS } from '../Stripe Checkout/Payment';
import { stripePromise } from '../../containers/StripeContainer/StripeContainer';
import Actions from './Actions';
import { CARDS } from '../../containers/ManageSubscription/constants';

const PaymentMethods = props => {
    const { getPaymentMethods, methods, deletePaymentMethod, addPaymentMethod, makeDefaultMethod, token } = props;
    const [error, setError] = useState(null);
    const [newCard, setNewCard] = useState(false);
    const [defaultMethod, setDefault] = useState(true);
    const elements = useElements();
    const stripe = useStripe();

    const renderPaymentMethods = () =>
        methods.map((method, i, arr) => (
            <li key={method.id} className="py-2 position-relative">
                <i className={`${CARDS[method.brand].iconClass || CARDS.others.iconClass}`} aria-hidden="true" />
                <span className="d-inline-block ml-3">{`**** ${method.last_four}`}</span>
                <span className={`d-inline-block ml-3 ${method.default && 'default-method'}`}>{`Expires: ${method.exp_month}/${method.exp_year}`}</span>
                {arr.length > 1 && !method.default &&
                <Actions
                    hasDelete={arr.length > 1}
                    hasDefault={!method.default}
                    handleDelete={handleDelete}
                    handleDefaultMethod={handleDefaultMethod}
                    id={method.id}
                />}
            </li>
        ));

    const handleChange = (event) => {
        setNewCard(!event.empty);
        if (event.error) {
            setError(event.error.message);
        } else {
            setError(null);
        }
    };

    const handleDelete = ({ id }) => {
        deletePaymentMethod({ token, id })
            .then(() => getPaymentMethods({ token }))
            .catch(() => toast.error('Something went wrong, please try again', { className: 'toast-error' }));
    };

    const handleDefaultMethod = ({ id }) => {
        makeDefaultMethod({ token, id })
            .then(() => getPaymentMethods({ token }))
            .catch(() => toast.error('Something went wrong, please try again', { className: 'toast-error' }));
    };
    const handleAddNewCard = async event => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }
        const cardElement = elements.getElement(CardElement);
        const { error: cardError, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (cardError) {
            toast.error(cardError, { className: 'toast-error' });
        } else {
            addPaymentMethod({ token, id: paymentMethod, isDefault: defaultMethod ? 1 : 0 })
                .then(() => getPaymentMethods({ token }))
                .then(() => cardElement.clear())
                .catch(() => toast.error('Something went wrong, please try again', { className: 'toast-error' }));
        }
    };

    return (
        <Row>
            <Col xs={12}>
                <h4 className="text-uppercase">Payment Methods</h4>
                <hr />
                <ul className="saved-methods-list">
                    {renderPaymentMethods()}
                </ul>
            </Col>
            <Col xs={12}>
                <form onSubmit={handleAddNewCard}>
                    <FormGroup className="form-row flex-column">
                        <Label className="font-universe mb-3 fs-18" for="card-element">
                            Add new Credit or Debit Card
                        </Label>
                        <CardElement
                            id="card-element"
                            options={CARD_ELEMENT_OPTIONS}
                            onChange={handleChange}
                        />
                        <Label className="font-universe mt-2" for="card-element">
                            <input type="checkbox" className="mr-2" value={defaultMethod ? 1 : 0} onChange={() => setDefault(!defaultMethod)} checked={defaultMethod} />
                            Set as Default Payment Method
                        </Label>
                        <div className="card-errors font-universe" role="alert">{error}</div>
                        <div className="d-flex justify-content-center mt-2">
                            <img src={cards} alt="cards" />
                        </div>
                        <Button className="add-card-button" type="submit" disabled={error || !newCard}>Add New Card</Button>
                    </FormGroup>
                </form>
            </Col>
        </Row>
    );
};

const Payment = props => (
    <Elements stripe={stripePromise}>
        <PaymentMethods {...props} />
    </Elements>
);

export default Payment;
