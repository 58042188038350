import React from 'react';
import { Modal, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import protectionPlanLogo from '../../assets/images/protectionPlanLogo.png';
import URLs from '../../utils/url';

const ProtectionPlanModal = ({
    toggleAddToCartModal,
    showAddToCartModal,
    addToCartWithProtectionPlan,
    addToCartWithoutProtectionPlan,
}) => {
    const withProtectionPlan = () => {
        toggleAddToCartModal();
        addToCartWithProtectionPlan();
    };

    const withoutProtectionPlan = () => {
        toggleAddToCartModal();
        addToCartWithoutProtectionPlan();
    };

    return (
        <Modal isOpen={showAddToCartModal} className="full-screen-modal gallery">
            <i className="fal fa-times-thin icon-close" onClick={toggleAddToCartModal} />
            <Row className="justify-content-center mt-3">
                <Col xs={11} lg={10}>
                    <Row>
                        <Col sm={3} lg={4}>
                            <Row className="d-sm-none">
                                <Col xs={2}>
                                    <img src={protectionPlanLogo} alt="logo" />
                                </Col>
                                <Col xs={10}>
                                    <p className="h6 label-text-color d-sm-none">Protect your purchase</p>
                                </Col>
                            </Row>
                            <div className="text-center d-none d-sm-block">
                                <img src={protectionPlanLogo} alt="logo" />
                            </div>
                            <div className="bordered-box mb-4 d-none d-sm-block">
                                <p className="font-universe text-justify mb-0 py-4">
                                    With The Protection Plan, we&#39;ve got you covered. For a small fee, you will be
                                    covered for one free size exchange and lost/stolen protection as well as be eligible
                                    for discounted rates on refurbishment, cancellation, and returns.
                                    <a href="/shop/content/protection-plan" target="_blank" className="menu-link ml-2">
                                        Learn More &#8594;
                                    </a>
                                </p>
                            </div>
                        </Col>
                        <Col sm={8} lg={7} className="ml-auto">
                            <p className="h4 label-text-color d-none d-sm-block">Protect your purchase</p>
                            <div className="mt-5">
                                <table className="table table-responsive mobi table-striped font-universe">
                                    <thead>
                                        <tr>
                                            <th scope="col" />
                                            <th scope="col">With Protection Plan</th>
                                            <th scope="col">No Protection Plan</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row">Sizing</th>
                                            <td className="font-weight-bold">1 Free (w/LB sizers)</td>
                                            <td className="font-weight-bold">30% (w/LB sizers)</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Lost/Stolen +</th>
                                            <td className="font-weight-bold">20%</td>
                                            <td className="font-weight-bold">NONE</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Cancellation</th>
                                            <td className="font-weight-bold">15%</td>
                                            <td className="font-weight-bold">30%</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Returns</th>
                                            <td className="font-weight-bold">25% - 30 days</td>
                                            <td className="font-weight-bold">50% - 30 days</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Refurbishment</th>
                                            <td className="font-weight-bold">
                                                $20<sup>&#42;</sup>
                                            </td>
                                            <td className="font-weight-bold">
                                                $50<sup>&#42;</sup>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Warranty</th>
                                            <td className="font-weight-bold">Lifetime</td>
                                            <td className="font-weight-bold">Lifetime</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" />
                                            <td className="font-weight-bold">
                                                <button
                                                    className="pricing-btn-action btn btn-lb-primary no-print"
                                                    onClick={withProtectionPlan}
                                                >
                                                    Select Protection plan
                                                </button>
                                            </td>
                                            <td className="font-weight-bold">
                                                <button
                                                    className="pricing-btn-action btn btn-lb-primary no-print"
                                                    onClick={withoutProtectionPlan}
                                                >
                                                    Select no plan
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="bordered-box my-5 d-sm-none">
                                <p className="font-universe text-justify mb-0 py-4">
                                    With The Protection Plan, we&#39;ve got you covered. For a small fee, you will be
                                    covered for one free size exchange and lost/stolen protection as well as be eligible
                                    for discounted rates on refurbishment, cancellation, and returns.
                                    <a href="shop/content/protection-plan" target="_blank" className="menu-link ml-2">
                                        Learn More &#8594;
                                    </a>
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Modal>
    );
};

export default ProtectionPlanModal;

ProtectionPlanModal.propTypes = {
    toggleAddToCartModal: PropTypes.func.isRequired,
    addToCartWithProtectionPlan: PropTypes.func.isRequired,
    addToCartWithoutProtectionPlan: PropTypes.func.isRequired,
    showAddToCartModal: PropTypes.bool,
};
