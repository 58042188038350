import React from 'react';
import { Col, Container, Row } from 'reactstrap';

const FinancingTerms = () => (
    <Container>
        <Row>
            <Col xs={12} className="text-center mt-5">
                <h1 className="font-syncopate text-uppercase mt-3 mb-3 fs-3rem">Financing terms & conditions</h1>
                <hr className="content-hr" color="#dcc67d" />
            </Col>
            <Col xs={12} className="color-grey font-universe fs-24 ml-lg-5">
                <p>Lashbrook Financing Terms and Conditions</p>
                <p>Last Updated: November 14, 2020</p>
                <p>Financier: DD Finance, L.C.</p>
                <p>Effective Date: The date you confirm the purchase through the website with a down payment,
                    payment of fees, payment of protection plan (if applicable) and payment of shipping (if
                    applicable).
                </p>
                <p>Promise to Pay: Borrower hereby promised to pay Financier together with its successors and
                    assigns, in lawful money of the United States of America, the principal sum of the Financed
                    Amount, together with applicable fees and interest at the rate set forth below in these terms
                    and conditions.
                </p>
                <p>Financed Amount: The financed amount represents all costs of the transaction. These include cost
                    of chosen merchandise, processing fee, protection plan, taxes, and shipping and any other fees
                    as disclosed during the checkout process.
                </p>
                <p>Cost of Merchandise: This base cost represents 100% cost of item(s) purchased on
                    LashbrookDesigns.com.
                </p>
                <p>Processing Fee: In consideration of the financed amount being made available to you, the
                    Borrower, you agree to pay a fee of 7.9% over and above the cost of merchandise as a one-time
                    fee, payable at finance origination.
                </p>
                <p>Protection Plan: If chosen, the protection plan is addition amount depending on the costs of the
                    ring, and will be added to the total purchase amount (details outlined here).
                </p>
                <p>Shipping &amp; Taxes: Added to the total purchase amount based on your location and selected
                    shipping methods.
                </p>
                <p>Interest: So long as the Note is not in default, no interest will be due. Upon an Event of
                    Default, default interest shall accrue on the unpaid principal balance of this Note at a rate of
                    6% per annum until the Event of Default is cured. Upon the occurrence of an Event of Default (as
                    described below) all amounts owing hereunder shall be assessed a late charge of $10.00 on the
                    date that such amount is past due. Financier shall not be required to accept any payment from
                    Borrower that is not accompanied by an applicable late charge payment amount. All interest
                    accrued but not paid when due hereunder shall be compounded to principal on each applicable
                    annual anniversary of the Effective Date.
                </p>
                <p>Initial Payment: A payment of 16% of the purchase price of the merchandise, 7.9% plus processing
                    fee, plus protection plan cost (if applicable), plus taxes (if applicable), and plus shipping
                    cost (if applicable), will be immediately paid on the Effective Date. Commencing one month from
                    Effective Date and continuing on a monthly basis for 11 months total, Borrower shall pay to
                    Financier the amount of 6.37% of cost of merchandise on a monthly basis. Borrower shall pay to
                    Financier all amounts remaining owing represented by this Note, including principal, interest,
                    penalties and other amounts payable to Financier hereunder.
                </p>
                <p>Unless otherwise agreed or required by applicable law, payments will be applied first to any
                    collection costs, late or other fees, second to unpaid interest, and finally to remaining
                    principal hereunder. Borrower will make all payments of sums due hereunder to Financier as
                    Financier may designate in writing.
                </p>
                <p>Prepayment: Borrower may pay, without penalty, all or a portion of the amount owed earlier than
                    it is due and not be obligated for any interest that may have otherwise accrued following such
                    payment.
                </p>
                <p>Collateral: This Note is secured by merchandise purchased from Lashbrook as outlined in initial
                    checkout documentation.
                </p>
                <p>Security Interest: Pursuant to the terms under this Note, Borrower hereby grants to Financier a
                    security interest in the Collateral. Financier does not intend to immediately evidence such
                    security interest, but it reserves the right to do so at any time the Loan is outstanding, and
                    Borrower hereby grants to Financier the right to evidence the Loan and security interest and
                    further secure its rights hereunder.
                </p>
                <p>Borrower Representations and Warranties: In order to induce Financier to enter into this
                    Agreement and to make the disbursements provided for herein, Borrower represents and warrants to
                    Financier as of the date of this Agreement and any Advance as follows:
                </p>
                <p>Borrower has all requisite authority and power to execute and deliver any document required
                    hereunder and to perform any condition or obligation imposed under the terms of such
                    documents.<br />
                        The execution, delivery and performance of this Agreement, any other Financing Document, and
                        each document incident hereto will not violate any provision of any applicable law,
                        regulation, order, judgment, decree, indenture, contract, agreement, or other undertaking to
                        which Borrower is a party, or which purports to be binding on Borrower or its assets and
                        will not result in the creation or imposition of a lien on any of its assets, other than the
                        security interest granted to Financier and the Permitted Encumbrances.<br />
                        There is no action, suit, investigation, or proceeding pending or, to the knowledge of
                        Borrower, threatened, against or affecting Borrower or any of its assets which, if adversely
                        determined, would have a material adverse effect on the financial condition of Borrower.<br />
                        Any financial statements or information which have heretofore been provided to Financier by
                        Borrower or at Borrower’s request are correct, complete, and truly, fairly, and accurately
                        represent the financial position of Borrower as of the date of such financial statements.
                        Since the date of such statements, there have been no material adverse changes.<br />
                        No information or report furnished by Borrower to Financier in connection with the
                        negotiation of this Agreement contained any material misstatement of fact or omitted to
                        state a material fact or any fact necessary to make the statements contained therein not
                        misleading.<br />
                        The Loan and the proceeds thereof are only being used to purchase the Collateral.<br />
                        Default: Borrower will be in default under this Note if any of the following happens (each
                        an “Event of Default”): (a) Borrower fails to make any payment when due; (b) Borrower or any
                        guarantor of this Note fails to comply with or to perform when due any other term,
                        obligation, covenant, or condition contained in this Note or in any agreement securing or
                        guaranteeing payment of this Note, or in any other agreement or loan Borrower or such
                        guarantor has with Financier; (c) Borrower defaults under any loan, extension of credit,
                        security agreement, purchase or sales agreement, or any other agreement, in favor of any
                        other creditor or person that may materially affect any of Borrower’s property or Borrower’s
                        ability to repay this Note or to perform Borrower’s obligations under this Note; (d) Any
                        representation or statement made or furnished to Financier by Borrower or on Borrower’s
                        behalf is false or misleading in any material respect either now or at the time made or
                        furnished; (e) Borrower becomes insolvent, a receiver is appointed for any part of
                        Borrower’s property, Borrower makes an assignment for the benefit of creditors, or any
                        proceeding is commenced either by Borrower or against Borrower, under any bankruptcy or
                        insolvency laws; (f) Any creditor attempts to take any of Borrower’s accounts with
                        Financier; (g) Any of the events described in this Default section occurs with respect to
                        any guarantor of this Note; (h) A material adverse change occurs in Borrower’s financial
                        condition or in the financial condition of any guarantor of this Note, or Financier believes
                        the prospect of payment or performance of the indebtedness is impaired; or (i) Financier in
                        good faith deems itself insecure.
                </p>
                <p>Financier Rights: Time is of the essence hereof. In the event of any Event of Default hereunder,
                    then the whole principal sum plus accrued interest and all other obligations of Borrower to
                    Financier, direct or indirect, absolute or contingent, now existing or hereafter arising, shall,
                    at the option of Financier, become immediately due and payable without notice or demand, and
                    Financier shall have and may exercise any or all of the rights and remedies provided herein as
                    it may be amended, modified or supplemented from time to time. Financier shall have and may
                    exercise any or all of the rights and remedies available to it under any law with regard to the
                    Collateral.
                </p>
                <p>Usury Savings Clause. Borrower expressly agrees and acknowledges that Borrower and Financier
                    intend and agree that this Note shall not be subject to the usury laws of any state other than
                    the state of Utah. Notwithstanding anything contained in this Note to the contrary, if
                    collection from Borrower of interest at the rate set forth herein would be contrary to
                    applicable laws, then the applicable interest rate upon default shall be the highest interest
                    rate that may be collected from maker under applicable laws at such time.
                </p>
                <p>Electronic Signatures. By proceeding through the checkout process on LashbrookDesigns.com and
                    submitting initial payment as outlined in Financed Amount, you are wholly agreeing to electronic
                    transmission of this Note and the Lashbrook Financing Terms and Conditions, and as such shall be
                    deemed to have the same legal effect as delivery of an original executed copy of this Note for
                    all purposes.
                </p>
                <p>General Conditions: This Note is made under the laws of the State of Utah and shall be governed
                    by and construed and enforced in accordance with the laws of such state. If any term or
                    provision of this Note shall be determined to be illegal or unenforceable, all other terms and
                    provisions hereof shall nevertheless remain effective and shall be in force to the fullest
                    extent permitted by applicable law. In the event suit is brought to enforce this Note or an
                    attorney is employed by Financier or other expenses are incurred by Financier to compel payment
                    of this Note or any portion of the indebtedness evidenced hereby, whether or not any suit or
                    other proceeding be commenced, Borrower promises to pay all such expenses and actual attorneys’
                    fees, including, without limitation, any attorneys’ fees incurred in any negotiation,
                    alternative dispute resolution proceeding subsequently agreed to by the parties, if any,
                    litigation, or bankruptcy proceeding or any appeals from any of such proceedings. Financier has
                    been advised to obtain its own legal counsel with respect to the negotiation, terms and
                    execution of this Note.
                </p>
                <p>EACH PARTY HERETO EXPRESSLY WAIVES THE RIGHT TO TRIAL BY JURY IN ANY LAWSUIT OR PROCEEDING
                    RELATING TO OR ARISING IN ANY WAY FROM THIS AGREEMENT OR THE MATTERS CONTEMPLATED HEREBY.
                </p>
                <p>PRIOR TO PURCHASING MERCHANDISE ON LASHBROOKDESIGNS.COM, BORROWER REPRESENTS AND WARRANTS TO
                    FINANCIER THAT BORROWER HAS READ AND UNDERSTANDS ALL OF THE PROVISIONS OF THE NOTE, INCLUDING
                    THE LATE PAYMENT PROVISIONS. BORROWER AGREES TO THE TERMS OF THE NOTE AND ACKNOWLEDGES RECEIPT
                    OF A COMPLETED COPY OF THE NOTE.
                </p>
            </Col>
        </Row>
    </Container>
);

export default FinancingTerms;
