import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import './styles.css';
import { MATERIALS_LIST } from '../../containers/UniqueMaterialsContainer/constants';
import { MaterialCard } from './MaterialCard';

const ListPage = () => (
    <Container>
        <Row>
            <Col xs={12} className="d-flex flex-column align-items-center text-center my-3 text-uppercase">
                <img
                    src="https://wpassets.lashbrookdesigns.com/wp-content/themes/rinzler/images/icon-unique.png"
                    alt="Page Icon"
                />
                <h1 className="mb-3 mt-2 font-syncopate fs-3rem">Unique Materials</h1>
                <p className="font-universe fs-24">
                    <Link className="gold-links" to="/shop/content/compare-materials">
                        Click Here
                    </Link>{' '}
                    TO SEE A COMPARISON CHART OF ALL MATERIALS.
                </p>
            </Col>
            <Row className="justify-content-between materials-list-container">
                {MATERIALS_LIST.map(material => (
                    <Col xs={12} md={6} className="mb-4">
                        <MaterialCard info={material} key={material.material} />
                    </Col>
                ))}
            </Row>
        </Row>
    </Container>
);

export default ListPage;
