import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import { PAGE_SIZE } from '../../utils/Constants';
import { dateFormat } from '../../containers/CustomerContainer/constants';
import CollapsibleCard from '../Shared/CollapsibleCard';

const NotesList = ({ notes }) => {
    const [currentNotes, setCurrentNotes] = useState([]);
    const [filter, setFilter] = useState('');
    const [authors, setAuthors] = useState([]);
    const tableContainerRef = useRef();

    useEffect(() => {
        setAuthors([...new Set(notes?.map(note => note.author))]);
    }, [notes]);

    useEffect(() => {
        const filteredNotes = filter ? notes.filter(note => note.author === filter) : notes;
        setCurrentNotes(filteredNotes.slice(0, PAGE_SIZE));
    }, [filter, notes]);

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    };

    useEffect(() => {
        const handleScroll = () => {
            const container = tableContainerRef.current;
            const scrollPosition = container.scrollTop + container.clientHeight;
            const threshold = container.scrollHeight * 0.8;
            const filteredNotes = filter ? notes.filter(note => note.author === filter) : notes;
            if (scrollPosition >= threshold && currentNotes.length < filteredNotes.length) {
                const currentLength = currentNotes.length;
                const nextNotes = filteredNotes.slice(currentLength, currentLength + PAGE_SIZE);
                setCurrentNotes([...currentNotes, ...nextNotes]);
            }
        };
        // eslint-disable-next-line no-unused-expressions
        tableContainerRef?.current?.addEventListener('scroll', handleScroll);
        return () => tableContainerRef.current?.removeEventListener('scroll', handleScroll);
    }, [filter, notes, currentNotes]);

    return (
        <CollapsibleCard
            title="Notes"
            filter={(
                <select
                    className="mr-2 border-0 p-0 mb-1"
                    value={filter}
                    onChange={handleFilterChange}
                    onClick={stopPropagation}
                >
                    <option key="all" value="">All</option>
                    {authors.map((author, index) => (
                        <option key={index} value={author}>{author}</option>
                    ))}
                </select>
            )}
        >
            <div className="scroll-table" ref={tableContainerRef}>
                {currentNotes?.length > 0 ?
                    <Table hover className="font-universe font-weight-bolder table-responsive orders-table mt-4">
                        <thead>
                            <tr>
                                <th>Author</th>
                                <th className="text-nowrap">Date</th>
                                <th colSpan={2}>Note</th>
                            </tr>
                        </thead>
                        <tbody className="table-body">
                            {currentNotes.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.author || '-'}</td>
                                    <td>{item.notedate ? new Date(item.notedate).toLocaleString('en-US', dateFormat) : '-'}</td>
                                    <td colSpan={2}>{item.note || '-'}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table> :
                    <div className="text-center font-open-sans-condensed fs-24">No notes available</div>}
            </div>
        </CollapsibleCard>
    );
};

NotesList.propTypes = {
    notes: PropTypes.array.isRequired,
};

export default NotesList;
