import React, { useState, useEffect } from 'react';
import { Input, InputGroupAddon, InputGroup, InputGroupText, Col } from 'reactstrap';
import queryString from 'qs';
import 'react-datepicker/dist/react-datepicker.css';
import { useLocation, useHistory } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { useWindowSize } from '../../../utils/WindowSize';
import { fromDate as start, toDate as end } from '../../../containers/CustomerContainer/constants';

const Filters = () => {
    const { search, pathname } = useLocation();
    const history = useHistory();
    const { width } = useWindowSize();
    const query = queryString.parse(search.slice(1));
    const [searchQuery, setSearchQuery] = useState(query.customer || '');
    const [isOpen, setOpen] = useState(false);
    const [startDate, setStartDate] = useState(query.fromDate ? new Date(query.fromDate) : start);
    const [endDate, setEndDate] = useState(query.toDate ? new Date(query.toDate) : end);
    const isMobile = width < 768;

    useEffect(() => {
        setSearchQuery(query.customer || '');
        setStartDate(new Date(query.fromDate));
        setEndDate(new Date(query.toDate));
    }, [query.recordType, query.fromDate, query.toDate]);

    const handleSearchChange = (e) => setSearchQuery(e.target.value);

    const handleSearch = (e) => {
        e.preventDefault();
        const updatedParams = { ...query, customer: searchQuery, page: 1 };
        history.push(`${pathname}?${queryString.stringify(updatedParams)}`);
    };

    const handleClear = (e) => {
        e.preventDefault();
        setSearchQuery('');
        const { customer, ...updatedParams } = query;
        if (customer?.length > 0) {
            history.push(`${pathname}?${queryString.stringify({ ...updatedParams, page: 1 })}`);
        }
    };

    const handleStartDate = event => {
        const fromDate = event.toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });
        const params = { ...query, fromDate, page: 1 };
        history.push(`${pathname}?${queryString.stringify(params)}`);
        setStartDate(event);
    };

    const handleEndDate = event => {
        const toDate = event.toLocaleString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });
        const params = { ...query, toDate, page: 1 };
        history.push(`${pathname}?${queryString.stringify(params)}`);
        setEndDate(event);
    };

    return (
        <form onSubmit={handleSearch} className="mb-1">
            {isMobile && <i onClick={() => setOpen(!isOpen)} className={`${isOpen ? 'fa fa-times' : 'fa fa-filter'} ml-4`} aria-hidden="true" />}
            {((isMobile && isOpen) || !isMobile) &&
            <Col xs={12} className="d-flex flex-column flex-md-row justify-content-between align-items-center">
                <InputGroup className="customer-filter">
                    <Input
                        type="text"
                        name="customer"
                        id="customer-filter"
                        placeholder="Customer Search"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        onKeyPress={event => {
                            if (event.key === 'Enter') {
                                handleSearch(event);
                            }
                        }}
                    />
                    {searchQuery.length > 0 &&
                    <InputGroupAddon addonType="prepend" onClick={handleClear}>
                        <InputGroupText>
                            <i className="fa fa-times" aria-hidden="true" />
                        </InputGroupText>
                    </InputGroupAddon>}
                    <InputGroupAddon addonType="prepend" onClick={handleSearch}>
                        <InputGroupText>
                            <i className="fa fa-search" aria-hidden="true" />
                        </InputGroupText>
                    </InputGroupAddon>
                </InputGroup>
                <span
                    className="d-flex font-universe align-items-center date-filter-container justify-content-between flex-column flex-md-row"
                >
                    <span>
                        <span>From: </span>
                        <DatePicker
                            selected={startDate}
                            onChange={handleStartDate}
                            placeholderText="Select start date"
                            locale="en-US"
                            showYearDropdown
                            dateFormat="P"
                        />
                    </span>
                    <span>
                        <span>To: </span>
                        <DatePicker
                            selected={endDate}
                            onChange={handleEndDate}
                            placeholderText="Select end date"
                            locale="en-US"
                            showYearDropdown
                            dateFormat="P"
                        />
                    </span>
                </span>
            </Col>}
        </form>
    );
};

export default Filters;
