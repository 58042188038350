import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import './styles.css';

class CurrencyDropDown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownOpen: false,
        };
    }

    togglePricingDropdown = () => {
        this.setState({ dropdownOpen: !this.state.dropdownOpen });
    };

    render() {
        const { selected, updateCurrency, isTop } = this.props;
        const { dropdownOpen } = this.state;
        return (
            <Dropdown
                size="sm"
                isOpen={dropdownOpen}
                toggle={this.togglePricingDropdown}
                className="price-dropdown"
            >
                <DropdownToggle
                    caret
                    tag="a"
                    className={isTop ? 'nav-link dropdown-nav-link font-universe dropdown-nav-link-primary cursor-pointer' : 'text-light nav-link dropdown-nav-link font-universe cursor-pointer'}
                >
                    {selected}
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem tag="a" className={`font-universe cursor-pointer ${selected === 'USD' ? 'active' : ''}`} onClick={() => updateCurrency('USD')}>USD</DropdownItem>
                    <DropdownItem tag="a" className={`font-universe cursor-pointer ${selected === 'CAD' ? 'active' : ''}`} onClick={() => updateCurrency('CAD')}>CAD</DropdownItem>
                    {/* <DropdownItem tag="a" className={`font-universe cursor-pointer ${selected === 'EUR' ? 'active' : ''}`} onClick={() => updateCurrency('EUR')}>EUR</DropdownItem> */}
                </DropdownMenu>
            </Dropdown>
        );
    }
}


CurrencyDropDown.propTypes = {
    selected: PropTypes.string,
    updateCurrency: PropTypes.func.isRequired,
    isTop: PropTypes.bool,
};

export default CurrencyDropDown;
