import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import config from '../../config';
import { Link } from 'react-router-dom';

const RingAnatomy = () => (
    <Container>
        <Row>
            <Col xs={12} className="d-flex flex-column align-items-center text-center mt-5 mb-5">
                <h1 className="font-syncopate text-uppercase mt-3 mb-3">Ring Anatomy</h1>
                <hr className="content-hr" color="#dcc67d" />
            </Col>
            <Col xs={12} className="text-center">
                <img
                    loading="lazy"
                    className="aligncenter wp-image-1552"
                    src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/wp-content/uploads/2019/05/30153537/Ring-Anatomy.jpg"
                    alt="unique men's rings"
                    width="957"
                    height="538"
                    srcSet="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/wp-content/uploads/2019/05/30153537/Ring-Anatomy.jpg 1366w, https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/wp-content/uploads/2019/05/30153537/Ring-Anatomy-300x169.jpg 300w, https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/wp-content/uploads/2019/05/30153537/Ring-Anatomy-768x432.jpg 768w, https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/wp-content/uploads/2019/05/30153537/Ring-Anatomy-1024x576.jpg 1024w"
                    sizes="(max-width: 957px) 100vw, 957px"
                />
            </Col>
            <Col xs={12} className="my-5">
                <p className="font-universe fs-24 text-center color-grey">
                    Ready to try it with our
                    <Link to={config.buildYourOwnProductUrl} className="gold-links">
                        {' '}
                        Lashbrook Builder
                    </Link>
                    ?</p>
            </Col>
        </Row>
    </Container>
);

export default RingAnatomy;
