import React, { useState } from 'react';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const Actions = ({ id, handleDelete, handleDefaultMethod, hasDefault, hasDelete }) => {
    const [dropdownOpen, setOpen] = useState(false);

    const toggle = () => setOpen(!dropdownOpen);

    return (
        <ButtonDropdown isOpen={dropdownOpen} toggle={toggle} className="actions-button">
            <DropdownToggle size="sm">
                <i className="fa fa-ellipsis-h" aria-hidden="true" />
            </DropdownToggle>
            <DropdownMenu>
                {hasDelete && <DropdownItem onClick={() => handleDelete({ id })}>Delete</DropdownItem>}
                {hasDefault && <DropdownItem onClick={() => handleDefaultMethod({ id })}>Make Default</DropdownItem>}
            </DropdownMenu>
        </ButtonDropdown>
    );
};

export default Actions;
