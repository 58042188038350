import React, { useEffect, useState } from 'react';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { FormGroup, Label, Container, Col, Button } from 'reactstrap';
import SavedPayment from './SavedPayment';
import cards from '../../assets/images/cards.png';
import { stripePromise } from '../../containers/StripeContainer/StripeContainer';
// Custom styling can be passed to options when creating an Element.
export const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: '#32325d',
            fontFamily: '"Open Sans Condensed", "Univers LT W04_47 Light Cond", Helvetica, Arial, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
                color: '#aab7c4',
            },
        },
        invalid: {
            color: '#fa755a',
            iconColor: '#fa755a',
        },
    },
};

const CheckoutForm = ({
    paymentIntent,
    getPaymentMethods,
    savePaymentMethod,
    removePaymentMethod,
    getAccountInfo,
    accountInfo,
    createSubscription,
    orderDetails,
    paymentMethods,
    directOrder,
    createDirectOrder,
}) => {
    const [selectedMethod, setMethod] = useState('');
    const [error, setError] = useState(null);
    const [newCard, setNewCard] = useState(false);
    const elements = useElements();
    const stripe = useStripe();
    const history = useHistory();

    useEffect(() => {
        getAccountInfo();
    }, []);

    useEffect(() => {
        getPaymentMethods();
    }, []);

    // Handle real-time validation errors from the card Element.
    const handleChange = event => {
        setNewCard(!event.empty);
        if (event.error) {
            setError(event.error.message);
        } else {
            setError(null);
        }
    };

    const handleRemovePayment = id => {
        removePaymentMethod(id).then(() => getPaymentMethods());
    };

    const handleOrderCreation = async (orderId, paymentMethod) => {
        if (directOrder) {
            return createDirectOrder(orderId, paymentMethod);
        }

        return createSubscription(orderId, paymentMethod);
    };

    const setDataLayer = (orderId, totalAmount) => {
        const paymentMethod = directOrder ? 'Direct Order' : 'Financing Order';
        window.dataLayer.push({
            event: 'purchase',
            ecommerce: {
                purchase: {
                    orderComplete: {
                        id: orderId, // Transaction ID. Required for purchases and refunds.
                        revenue: totalAmount, // Total transaction value (incl. tax and shipping)
                        'payment-method': paymentMethod,
                    },
                },
            },
        });
    };

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const handleSubmit = async event => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);

        if (selectedMethod.length > 1) {
            handleOrderCreation(orderDetails.id, selectedMethod)
                .then(() => {
                    setDataLayer(orderDetails.id, formatter.format(orderDetails.total_amount / 100));
                    history.push(`/shop/orderConfirmation/${orderDetails.id || 'undefined'}`);
                })
                .catch(({ response: { data } }) => {
                    toast.error(data.message, { className: 'toast-error' });
                });
        } else {
            const { error: cardError, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
                billing_details: {
                    name: `${accountInfo.first_name} ${accountInfo.last_name}`,
                    email: accountInfo.email,
                },
            });

            if (cardError) {
                console.log('[createPaymentMethod error]', cardError);
                toast.error(cardError, { className: 'toast-error' });
            } else {
                const paymentMethodId = paymentMethod.id;
                paymentIntent().then(({ payload }) => {
                    stripe
                        .confirmCardSetup(payload.intent.client_secret, {
                            payment_method: {
                                card: cardElement,
                                billing_details: {
                                    name: `${accountInfo.first_name} ${accountInfo.last_name}`,
                                    email: accountInfo.email,
                                },
                            },
                        })
                        .then(() => {
                            savePaymentMethod(paymentMethodId);
                            handleOrderCreation(orderDetails.id, paymentMethodId)
                                .then(() => {
                                    setDataLayer(orderDetails.id, formatter.format(orderDetails.total_amount / 100));
                                    history.push(`/shop/orderConfirmation/${orderDetails.id || 'undefined'}`);
                                })
                                .catch(({ response: { data } }) => {
                                    toast.error(data.message, { className: 'toast-error' });
                                });
                        });
                });
            }
        }
    };

    return (
        <Container className="d-flex justify-content-between align-items-start font-universe p-0">
            <form onSubmit={handleSubmit} className="stripe-container">
                <FormGroup className="form-row flex-column">
                    <Label className="font-universe required mb-3 fs-18 font-weight-bold" for="card-element">
                        Add new Credit or Debit Card
                    </Label>
                    <CardElement id="card-element" options={CARD_ELEMENT_OPTIONS} onChange={handleChange} />
                    <div className="card-errors font-universe" role="alert">
                        {error}
                    </div>
                    <div className="d-flex justify-content-center justify-content-lg-start">
                        <img src={cards} alt="cards" />
                    </div>
                </FormGroup>
                {paymentMethods &&
                    paymentMethods.data &&
                    paymentMethods.data.length > 0 && <p className="horizontal-rule">or</p>}
                {paymentMethods &&
                    paymentMethods.data &&
                    paymentMethods.data.length > 0 && (
                    <SavedPayment
                        directOrder={directOrder}
                        paymentMethods={paymentMethods}
                        removePaymentMethod={handleRemovePayment}
                        paymentMethod={selectedMethod}
                        setMethod={setMethod}
                        newCard={newCard}
                    />
                )}
                <Col
                    xs={12}
                    className={`d-flex ${
                        directOrder ? 'justify-content-center pay-direct-now' : 'justify-content-end'
                    }`}
                >
                    {!directOrder && (
                        <Button
                            type="submit"
                            className="submit-payment"
                            onClick={handleSubmit}
                            disabled={error || (!selectedMethod && !newCard)}
                        >
                            Pay Amount Due Today
                        </Button>
                    )}
                    {directOrder && (
                        <Button
                            type="submit"
                            className="submit-payment-direct"
                            onClick={handleSubmit}
                            disabled={error || (!selectedMethod && !newCard)}
                        >
                            Pay Now
                        </Button>
                    )}
                </Col>
            </form>
        </Container>
    );
};

const Payment = ({
    previous,
    paymentIntent,
    getPaymentMethods,
    savePaymentMethod,
    removePaymentMethod,
    getAccountInfo,
    accountInfo,
    createOrder,
    createSubscription,
    orderDetails,
    paymentMethods,
    directOrder,
    selectedShipping,
    createDirectOrder,
    addresses,
}) => (
    <Elements stripe={stripePromise}>
        <CheckoutForm
            previous={previous}
            paymentIntent={paymentIntent}
            getPaymentMethods={getPaymentMethods}
            savePaymentMethod={savePaymentMethod}
            removePaymentMethod={removePaymentMethod}
            getAccountInfo={getAccountInfo}
            accountInfo={accountInfo}
            createSubscription={createSubscription}
            orderDetails={orderDetails}
            paymentMethods={paymentMethods}
            createOrder={createOrder}
            directOrder={directOrder}
            selectedShipping={selectedShipping}
            createDirectOrder={createDirectOrder}
            addresses={addresses}
        />
    </Elements>
);

export default Payment;
