import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import './styles.css';

class PriceDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownOpen: false,
        };
    }

    togglePricingDropdown = () => {
        this.setState({ dropdownOpen: !this.state.dropdownOpen });
    };

    render() {
        const { selectedPriceType, updatePriceType, isTop } = this.props;
        const { dropdownOpen } = this.state;
        return (
            <Dropdown
                size="sm"
                isOpen={dropdownOpen}
                toggle={this.togglePricingDropdown}
                className="price-dropdown"
            >
                <DropdownToggle
                    caret
                    tag="a"
                    className={isTop ? 'nav-link dropdown-nav-link font-universe dropdown-nav-link-primary cursor-pointer' : 'text-light nav-link dropdown-nav-link font-universe cursor-pointer'}
                >
                    {selectedPriceType}
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem tag="a" className="font-universe cursor-pointer" onClick={() => updatePriceType('Wholesale')}>Wholesale</DropdownItem>
                    <DropdownItem tag="a" className="font-universe cursor-pointer" onClick={() => updatePriceType('Retail')}>Retail</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        );
    }
}


PriceDropdown.propTypes = {
    selectedPriceType: PropTypes.string,
    updatePriceType: PropTypes.func.isRequired,
    isTop: PropTypes.bool,
};

export default PriceDropdown;
