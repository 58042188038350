import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import Helmet from 'react-helmet';
import 'font-awesome/css/font-awesome.css';


const AGSLanding = () => (
    <Container className="ags-container">
        <Helmet>
            <link rel="canonical" href="https://www.lashbrookdesigns.com/ags" />
            <title>AGS</title>
            <meta name="description" content="Lashbrook designs custom wedding rings, top quality, top mens wedding band manufacturer in the US" />
            <meta name="keywords" content="jewelry, rings, wedding bands, engagement rings" />
        </Helmet>
        <Row>
            <Col xs={12} className="d-flex align-items-center flex-column">
                <img width="110px" src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/landingPages/JFC_Icon_1.png" />
                <div className="blue-box"><p>For every attendee Lashbrook will<br />donate $50 to Jewelers for Children</p></div>
            </Col>
            <Col xs={12} className="d-flex flex-column">
                <h2 className="ags-heading">Men's Wedding Bands as A Major Profit Center</h2>
                <h2 className="ags-byline">3-4 pm  &#x2022;  Wednesday, May 3 &#x2022;  Olmsted 5</h2>
                <hr className="ags-hr" />
            </Col>
            <Col xs={12} className="d-flex flex-column">
                <div>
                    <div className="q-image-text">
                        <img width="300px" src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/landingPages/Eric_Headshot.png" /></div>
                    <div className="half-image-text">
                        <p className="ags-p">Eric Laker, Founder/Owner</p>
                        <img width="110px" alt="Lashbrook Logo" src="https://wpassets.lashbrookdesigns.com/wp-content/uploads/2019/09/17025314/LB_Logoblue.png" />
                    </div>
                </div>
                <p className="ags-p">Learn how to maximize an untapped segment of the jewelry industry: men's wedding bands. After embarking on an extensive study, it became evident that as an industry we could maximize profits by enhanding the way we market and sell men's wedding bands. During this session, we will show you how the average jewelry store could profit an additional $40,000 per year by employing key strategies.</p>
                <p className="ags-p">The focus of this presentation is not a sales pitch, but rather a deep look at practices we should all be implmenting and improving as an industry.</p>
            </Col>
        </Row>
    </Container>
);

export default AGSLanding;
