import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import './our.scss';

const Our = () => (
    <div className="our-container font-syncopate">
        <img
            src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/wp-content/uploads/2019/11/11123652/OUR-BANNER.jpg"
            className="img-header"
            alt="Crafting custom mens rings out of exotic materials since 2000. Made to order in the USA.
          We supply to customers throughout the US and Canada"
        />
        <div className="wrapper pt-0">
            <Row className="content">
                <Col sm={6} lg={12}>
                    <p className="font-open-sans-condensed description">
                        Lashbrook partners with Operation Underground Railroad to help combat child trafficking.
                        <br />
                        <br />
                        Lashbrook was founded on the mission of creating - Rings with meaning. Rings that tell a story.
                        Rings that make a difference.
                        <br />
                        <br />
                        We feel privileged to be able to give back, and live out our mission beyond the symbolism of a
                        wedding band. We hope you&apos;ll spend some time{' '}
                        <Link to="/shop/categories/all-rings"> designing a piece </Link>
                        that will always remind you of your occasion and your contribution to O.U.R.
                        <br />
                        <br />
                        Once you're finished designing your ring or if you need some assistance with something more custom, call or text us with what you had in mind at 801-352-7388.
                    </p>
                </Col>
            </Row>
        </div>
        <div className="wrapper pt-0 font-open-sans-condensed description">
            <Row>
                <Col sm={6} lg={4}>
                    <div>
                        <img
                            className="shoppable"
                            src="https://assets.lashbrookdesigns.com/rings/profiles/B/widths/8/ring_materials/Z/images/1?ring_finish=SATIN%2CPOLISH&amp;finish_type=FS&amp;size=10&amp;feature_group=M%2CN&amp;machine_pattern=LCVTREES_Z_COLOR_CER-BLACK&amp;sleeve=HW-NATURALOAK&amp;&amp;image_size=280&amp;"
                            alt="Hardwood mens wedding ring with zirconium and trees"
                        />
                        <Link to="/shop/categories/all-rings"><button className="buildbtn">Shop Mens</button></Link>
                    </div>
                </Col>
                <Col sm={6} lg={4}>
                    <div>
                        <img
                            className="shoppable"
                            src="https://assets.lashbrookdesigns.com/women/rings/styles/021/ring_materials/14KR/color_arrangements/A/stone_quantities/HALFETERNITY/variants/1/images/1?dia=DARKRUBY,LABDIA&amp;dia_size=.015,.1&amp;dia_shape=R,M&amp;&amp;image_size=280&amp;"
                            alt="Diamonds and rubies womens wedding ring"
                        />
                        <Link to="/shop/categories/timeless"><button className="buildbtn">Shop Womens</button></Link>
                    </div>
                </Col>
                <Col sm={6} lg={4}>
                    <div>
                        <img
                            className="shoppable"
                            src="https://assets.lashbrookdesigns.com/rings/profiles/D/widths/7/ring_materials/14KW/images/1?ring_finish=SATIN&amp;finish_type=F&amp;weight=P&amp;size=10&amp;feature_group=N&amp;&amp;image_size=400"
                            alt="White gold traditional wedding ring"
                        />
                        <Link to="/shop/rings/profiles/D/widths/7/ring_materials/T?ring_finish=SATIN&amp;finish_type=F&amp;size=10&amp;feature_group=N&amp;sleeve=NONE"><button className="buildbtn">Build Your Own</button></Link>
                    </div>
                </Col>
            </Row>
            <Row className="content">
                <Col sm={12} lg={12}>
                    <img
                        className="rings"
                        src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/2022/our/gold-and-damascus-rings-operation-underground-railroad.png"
                        alt="Gold and damascus ring to support operation underground railroad to prevent sex trafficking"
                    />
                    <img
                        className="rings sm"
                        src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/2022/our/black-diamonds-zirconium-wedding-rings-operation-underground-railroad.png"
                        alt="Black diamonds and zirconium wedding rings to support operation underground railroad to prevent sex trafficking"
                    />
                    <img
                        className="rings sm"
                        src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/2022/our/blue-custom-rings.png"
                        alt="Blue cerakote and white diamonds and gold rings to support operation underground railroad to prevent sex trafficking"
                    />
                    <p>Every day, we create custom rings for customers around the world! We'd be delighted to create a special piece for you. If you’d like to design a ring you can’t find on our site or have special requests, please reach out.</p>
                    <Link to="/contact-us"><button className="buildbtnbigger">Contact Us</button></Link>

                </Col>
            </Row>
        </div>
    </div>
);

export default Our;
