import get from 'lodash/get';
import { createSelector } from 'reselect';

export const selectSubscription = state => get(state, 'subscription');

export const makeSelectIsFetching = () =>
    createSelector([selectSubscription], isFetching => get(isFetching, 'isFetching', {}));

export const makeSelectCurrentPlans = () => createSelector([selectSubscription], currentPlans => get(currentPlans, 'currentPlans', {}));
export const makeSelectPaymentMethods = () => createSelector([selectSubscription], paymentMethods => get(paymentMethods, 'paymentMethods', {}));
export const makeSelectBillingHistory = () => createSelector([selectSubscription], billingHistory => get(billingHistory, 'billingHistory', {}));
export const makeSelectCustomerDetails = () => createSelector([selectSubscription], customerDetails => get(customerDetails, 'customerDetails', {}));

