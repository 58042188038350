// publishedDir: the directory where the application is published
// siteAssetsBaseUrl: the host where the site assets are published and available.
// siteBaseUrl: the host where the application will be published when live (used to detect published dir)
// apiBaseUrl: the path of the APIs used by the application
// assetsBaseUrl: the path of the external application ring assets used by the application

// these are general settings that apply to every instance of the site.
const generalAppDefaultSettings = {
    buildYourOwnProductUrl:
        '/shop/rings/profiles/D/widths/7/ring_materials/14KW?ring_finish=SATIN&finish_type=F&weight=P&size=10&feature_group=N&sleeve=NONE',
    publishedDir: window.location.href.includes('localhost') ? '/' : '/', // application lives under /shop (as defined in 'homepage' property of package.json for published builds)
    siteAssetsBaseUrl: 'https://wpassets.lashbrookdesigns.com',
    appVersion: '2.0.0',
    appDefaultBehavior: 'LASHBROOK',
};

// these are app setting defaults for different environments. These can override general app settings,
// and these can be overridden by specific environment settings also.
const appBehaviorDefaultSettings = {
    LASHBROOK: {
        // environment default settings
        id: 'LASHBROOK',
        name: 'Lashbrook Default',
        color: '#999999',
        showEnvironmentInfo: true,
        siteBaseUrl: 'http://www.staging.lashbrookdesigns.com',
        apiBaseUrl: 'http://api.staging.lashbrookdesigns.com/api',
        assetsBaseUrl: 'http://assets.staging.lashbrookdesigns.com',

        // app behavior default settings
        mainContainerClass: 'lashbrook',
        enableAssetImageResolution: true,
        guestCheckout: true,
        displayProductInfo: true,
        displayHeader: true,
        displayStoresNearMe: true,
        displayBreadCheckout: true,
        displayImageWatermark: true,
        displaySocialShareLinks: true,
        displayLashbrookSupportInfo: true,
        allowFavorites: true,
        allowSearch: true,
        allowCategories: true,
        allowWizardToggle: true,
        allowAddToCart: true,
        pricingLocation: 'left',
        promptForProtectionPlan: true,
        requireNoRefundAcceptance: false,
        loadGoogleTagManagerScripts: true,
        loadCheckoutScripts: true,
        useProductionValues: false,
        enableLogRocket: false,
        pageTitle: 'Lashbrook Designs',
        enableCookie: true,
        cartLabel: 'Add to Cart',
    },

    SHANECO: {
        // environment default settings
        id: 'SHANECO',
        name: 'ShaneCo Default',
        color: '#6f42c1',
        showEnvironmentInfo: true,
        siteBaseUrl: 'https://shaneco.com',
        apiBaseUrl: 'https://shaneco-api.staging.lashbrookdesigns.com/api',
        assetsBaseUrl: 'https://assets.lashbrookdesigns.com',

        // app behavior default settings
        mainContainerClass: 'shaneco',
        enableAssetImageResolution: true,
        guestCheckout: true,
        displayProductInfo: false,
        displayHeader: false,
        displayStoresNearMe: false,
        displayBreadCheckout: false,
        displayImageWatermark: false,
        displaySocialShareLinks: false,
        displayLashbrookSupportInfo: false,
        allowFavorites: false,
        allowSearch: false,
        allowCategories: false,
        allowWizardToggle: false,
        allowAddToCart: false,
        pricingLocation: 'right',
        imageGalleryThumbnailPosition: 'left',
        promptForProtectionPlan: false,
        requireNoRefundAcceptance: true,
        loadGoogleTagManagerScripts: false,
        loadCheckoutScripts: false,
        useProductionValues: false,
        enableLogRocket: false,
        pageTitle: 'ShaneCo',
        enableCookie: false,
        cartLabel: 'Add to Bag',
    },
    RINGDESIGNER: {
        // environment default settings
        id: 'RINGDESIGNER',
        name: 'RingDesigner Default',
        color: '#22e2c5',
        showEnvironmentInfo: true,
        siteBaseUrl: 'https://ringdesignersstudio.com',
        apiBaseUrl: 'http://api.staging.lashbrookdesigns.com/api',
        assetsBaseUrl: 'http://assets.staging.lashbrookdesigns.com',

        // app behavior default settings
        mainContainerClass: 'ring-designer',
        enableAssetImageResolution: true,
        guestCheckout: true,
        displayProductInfo: false,
        displayHeader: false,
        displayStoresNearMe: false,
        displayBreadCheckout: false,
        displayImageWatermark: false,
        displaySocialShareLinks: false,
        displayLashbrookSupportInfo: false,
        allowFavorites: false,
        allowSearch: false,
        allowCategories: false,
        allowWizardToggle: false,
        allowAddToCart: false,
        pricingLocation: 'left',
        imageGalleryThumbnailPosition: 'left',
        promptForProtectionPlan: false,
        requireNoRefundAcceptance: false,
        loadGoogleTagManagerScripts: false,
        loadCheckoutScripts: false,
        useProductionValues: false,
        enableLogRocket: false,
        pageTitle: 'Ring Designer',
        enableCookie: false,
        cartLabel: 'Add to Cart',
    },
};

const getSettings = () => {
    // get any override settings specified, if they exist.
    const overrideSettings = getOverrideSettings();
    // gets the app default behavior that is specified. The app default behavior is a simple way to specify
    // a group of configuration values (as defined above), rather than specifying individually. We have to do this
    // anyway because there are limitations to how many environment settings can be specified. We have a max of 25 in AWS cloudfront.
    const { appDefaultBehavior } = Object.assign(
        {},
        generalAppDefaultSettings,
        window.environmentSettings,
        overrideSettings
    );
    const appBehaviorSettings = appBehaviorDefaultSettings[appDefaultBehavior];

    // builds the final set of settings that is a combination of genera settings, THEN applicable app behavior settings, THEN any
    // environment settings specified, and then FINALLY any override settings.
    const finalSettings = Object.assign(
        {},
        generalAppDefaultSettings,
        appBehaviorSettings,
        window.environmentSettings,
        overrideSettings
    );
    return finalSettings;
};

// gets override settings that can be used for temporary session manipulation of settings
// values, typically for the purposes of testing. These should be used to make some settings toggleable
// or dynamic in the application for the current client/session only.
const overrideSettings = settings => {
    sessionStorage.setItem('environmentOverrideSettings', JSON.stringify(settings));
};

const toggleAppBehavior = () => {
    const settings = getSettings();
    let appDefaultBehavior = '';
    if (settings.appDefaultBehavior === 'SHANECO') {
        appDefaultBehavior = 'LASHBROOK';
    } else if (settings.appDefaultBehavior === 'LASHBROOK') {
        appDefaultBehavior = 'RINGDESIGNER';
    } else {
        appDefaultBehavior = 'SHANECO';
    }
    overrideSettings({ appDefaultBehavior });
};

const getOverrideSettings = () => {
    const settings = sessionStorage.getItem('environmentOverrideSettings');
    if (settings) {
        return JSON.parse(settings);
    }
    return null;
};

export default {
    getSettings,
    overrideSettings,
    toggleAppBehavior,
};
