import React from 'react';
import { Modal } from 'reactstrap';

const LabelValuePair = ({
    label,
    value,
    labelClassName,
    valueClassName,
    className,
    imageURL,
    isLink,
}) => {
    const [expanded, setExpanded] = React.useState(false);

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    return (
        <>
            <div className={`d-flex justify-content-between ${className}`}>
                <p className={`font-16 font-open-sans-condensed ${labelClassName}`}>
                    {label}
                </p>
                {isLink ?
                    <a href={value} className={`font-16 font-open-sans-condensed cursor-pointer ${valueClassName}`}>{value}</a> :
                    <p className={`font-16 font-open-sans-condensed ${valueClassName}`}>
                        {value}
                    </p>
                }
            </div>
            {imageURL && (
                <>
                    <div className="d-flex justify-content-start">
                        <img
                            src={imageURL}
                            alt={label}
                            className="w-100 h-100"
                            onClick={toggleExpanded}
                        />
                    </div>
                    <Modal isOpen={expanded} toggle={toggleExpanded} size="lg" className="d-flex align-items-center justify-content-center h-100">
                        <img
                            src={imageURL}
                            alt={label}
                            className="w-100 h-100"
                        />
                    </Modal>
                </>
            )}
        </>
    );
};

export default LabelValuePair;
