import { apiActionTypes } from '../../middleware';
const base = '@@Containers/CategoryContainer/';

export const GET_RING_LIST_ACTION_TYPES = apiActionTypes(`${base}GET_RING_LIST`);
export const GET_FAVORITE_RING_LIST_ACTION_TYPES = apiActionTypes(`${base}GET_FAVORITE_RING_LIST`);
export const PAGINATED_GET_RING_LIST_ACTION_TYPES = apiActionTypes(`${base}PAGINATED_GET_RING_LIST`);
export const FILTER_GET_RING_LIST_ACTION_TYPES = apiActionTypes(`${base}FILTER_GET_RING_LIST`);
export const SYNC_FAVORITES = apiActionTypes(`${base}SYNC_FAVORITES`);
export const TOGGLE_FAVORITE = `${base}TOGGLE_FAVORITE`;
export const TOGGLE_CATEGORIES_FAVORITE = `${base}TOGGLE_CATEGORIES_FAVORITE`;
export const RESET_CATEGORY = `${base}RESET_CATEGORY`;
export const FAVORITE = 'favorite';
export const CLASSICS = 'classics';

export const FAVORITE_MESSAGES = {
    add: 'Ring has been added as a favorite',
    remove: 'Ring has been removed from your favorites',
};

export const ACTION = {
    add: 'add',
    remove: 'remove',
};
