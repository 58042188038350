export const PAGE_SIZE = 20;

export const TYPE = {
    category: 'category',
    option: 'option',
};

export const WIZARD_PAGES = {
    MATERIAL: 'Material',
    PROFILE: 'Profile',
    DIMENSION: 'Dimension',
    FINISH: 'Finish',
    OUTSIDE_FEATURE: 'Outside Feature',
    OUTSIDE_FEATURE_2: 'Secondary Outside Feature',
    INLAY_OPTIONS: 'Inlay Options',
    INLAY_OPTIONS_2: 'Secondary Inlay Options',
    DIAMOND_AND_GEMSTONE_OPTIONS: 'Gemstones',
    DIAMOND_AND_GEMSTONE_OPTIONS_2: 'Secondary Gemstones',
    SLEEVES: 'Sleeves',
    ENGRAVING: 'Engraving',
};

export const WIZARD_PAGES_WOMEN = {
    MATERIAL: 'Material',
    STYLE: 'Style',
    ARRANGEMENT: 'Arrangement & Variant',
    GEMSTONE_SIZE: 'Gemstone Size',
    GEMSTONE_CUT: 'Gemstone Cut',
    GEMSTONE_QUANTITY: 'Gemstone Quantity',
    GEMSTONE_TYPE: 'Gemstone Type',
    GEMSTONE_DETAILS: 'Gemstone Details',
    GEMSTONE_DETAILS_2: 'Second Gemstone Details',
    GEMSTONE_DETAILS_3: 'Third Gemstone Details',
};

export const POPULAR_RINGS = [
    {
        src: 'https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/2023/home/1_Marble-With-Cerakote-Sleeve.png',
        name: 'Marble with Cerakote Sleeve',
        path: 'https://lashbrookdesigns.com/shop/rings/profiles/D/widths/9/ring_materials/D-MARBLE?ring_finish=BEAD&finish_type=F&size=10&feature_group=N&sleeve=CER-HUNTERORANGE',
        alt: 'Hunter orange Cerakote sleeve on a marble damascus steel band',
    },
    {
        src: 'https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/2023/home/2_Tantalum-Snake-Wood.png',
        name: 'Tantalum Snake Wood',
        path: 'https://lashbrookdesigns.com/shop/rings/profiles/F/widths/9/ring_materials/TA?ring_finish=POLISH&finish_type=F&size=10&feature_group=G%2CN&groove_style=N&groove_group=15-N&grooves=15-N_HW-SNAKEWOOD&sleeve=NONE',
        alt: 'Tantalum mens wedding band with snakewood inlay',
    },
    {
        src: 'https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/2023/home/3_Tantalum-Branches-With-Blood-Wood.png',
        name: 'Tantalum with LCV Branches and Blood Wood',
        path: 'https://lashbrookdesigns.com/shop/rings/profiles/F/widths/7/ring_materials/TA?ring_finish=POLISH&finish_type=F&size=10&feature_group=M%2CN&machine_pattern=LCVBRANCHES_TA_COLOR_NONE&sleeve=HW-BLOODWOOD',
        alt: 'Tantalum mens wedding ring with bloodwood inlay and laser carved branches',
    },
    {
        src: 'https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/2023/home/4_Cobalt-Chrome-Stipple.png',
        name: 'Cobalt chrome with stipple finish',
        path: 'https://lashbrookdesigns.com/shop/rings/profiles/FR/widths/8/ring_materials/CC?ring_finish=STIPPLE&finish_type=F&size=10&feature_group=N&sleeve=NONE',
        alt: 'Coablt chrome mens wedding band with a stipple finish',
    },
    {
        src: 'https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/2023/home/5_Titanium-Gold-Sand-Finish.png',
        name: 'Titanium with Gold and Sand Finish',
        path: 'https://lashbrookdesigns.com/shop/rings/profiles/F/widths/7/ring_materials/T?ring_finish=SAND%2CSAND&finish_type=FS&size=10&feature_group=G%2CN&groove_style=OC&groove_group=1.5-OC&grooves=1.5-OC_14KY_SAND&sleeve=NONE',
        alt: 'Titanium mens wedding band with yellow gold and a sand finish',
    },
    {
        src: 'https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/2023/home/6_Damascus_Crushed_Silver.png',
        name: 'Marble Damascus with Crushed Silver Cerakote Sleeve',
        path: 'https://lashbrookdesigns.com/shop/rings/profiles/B-NS/widths/8/ring_materials/D-MARBLE?ring_finish=ACID&finish_type=F&size=10&feature_group=G%2CN&groove_style=N&groove_group=11-N_MGA-CER-CRUSHEDSILVER&grooves=11-N_14KR_POLISH&sleeve=CER-CRUSHEDSILVER',
        alt: 'Marble damascus with rose gold and crushed silver cerakote sleeve',
    },
    {
        src: 'https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/2023/home/7_Celtic-Zirconium-Blue-Sleeve.png',
        name: 'Celtic LCV with Zirconium and a Blue Sleeve',
        path: 'https://lashbrookdesigns.com/shop/rings/profiles/D/widths/7/ring_materials/Z?ring_finish=POLISH&finish_type=F&size=10&feature_group=M%2CN&machine_pattern=CELTIC6_Z_COLOR_NONE&sleeve=CER-SEABLUE',
        alt: 'Zirconium mens wedding band with a blue cerakote sleeve and laser carved celtic pattern',
    },
    {
        src: 'https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/2023/home/8_Cobalt-Mjba.png',
        name: 'Cobalt Chrome with MJBA',
        path: 'https://lashbrookdesigns.com/shop/rings/profiles/D/widths/8/ring_materials/CC?ring_finish=POLISH&finish_type=F&size=10&feature_group=M%2CN&machine_pattern=MJBA_CC_COLOR_NONE&sleeve=NONE',
        alt: 'Cobalt Chrome mens wedding band with MJBA laser carved pattern and a polished finish',
    },
    {
        src: 'https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/2023/home/9_Superconductor-Tantalum.png',
        name: 'Superconductor with Tantalum and a Gunmetal Grey Sleeve',
        path: 'https://lashbrookdesigns.com/shop/rings/profiles/B-NS/widths/8/ring_materials/PFSC?ring_finish=BEAD%2CBEAD&finish_type=FS&size=10&feature_group=G%2CN&groove_style=N&groove_group=14-N_MGA-CER-GUNMETALGREY&grooves=14-N_TANTALUM_BEAD&sleeve=NONE',
        alt: 'Superconductor mens wedding band with a tantalum sleeve and a gunmetal grey cerakote sleeve',
    },
    {
        src: 'https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/2023/home/10_Meteorite-Sea-Blue.png',
        name: 'Meteorite with Sea Blue Cerakote Sleeve',
        path: 'https://lashbrookdesigns.com/shop/rings/profiles/B-NS/widths/7/ring_materials/MET?ring_finish=NONE&finish_type=F&size=10&feature_group=G%2CN&groove_style=N&groove_group=11-N&grooves=11-N_CER-SEABLUE&sleeve=TI',
        alt: 'Meteorite mens wedding band with a sea blue cerakote accent and titanium sleeve',
    },
    {
        src: 'https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/2023/home/11_Titanium-Damascus-Meteorite-Cerakote.png',
        name: 'Titanium Damascus with Cerakote and Meteorite',
        path: 'https://lashbrookdesigns.com/shop/rings/profiles/B-NS/widths/7/ring_materials/TD?ring_finish=SATINETCHED&finish_type=F&size=10&feature_group=G%2CG&groove_style=N%2CN&groove_group=15-N%2C11-N&grooves=15-N_METEORITE%2C11-N_CER-SEABLUE&sleeve=NONE',
        alt: 'Titanium Damascus with meteorite inlay and sea blue cerakote accents',
    },
    {
        src: 'https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/2023/home/12_Zirconium-Black-Diamonds.png',
        name: 'Zirconium with Black Diamonds',
        path: '/shop/rings/profiles/D/widths/8/ring_materials/Z?ring_finish=HAMMER&finish_type=F&size=10&feature_group=D%2CN&gemstones=ETERNITY-N_ROUND_BLKDIA_.05_BEAD',
        alt: 'Black Zirconium mens wedding ring with black diamonds',
    },
    {
        src: 'https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/2023/home/13_Square-Zebra-Damascus.png',
        name: 'Square zebra damascus',
        path: 'https://lashbrookdesigns.com/shop/static-product/profiles/FR/widths/8/ring_materials/D-ZEBRA?ring_finish=POLISH&finish_type=1&size=10',
        alt: 'Square zebra damascus mens wedding band',
    },
    {
        src: 'https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/2023/home/14_Titanium-Damascus-Flat.png',
        name: 'Flat Titanium Damascus',
        path: 'https://lashbrookdesigns.com/shop/rings/profiles/FR/widths/10/ring_materials/TD?ring_finish=POLISH&finish_type=F&size=10&feature_group=N&sleeve=NONE',
        alt: 'Flat titanium damascus mens wedding band',
    },
    {
        src: 'https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/2023/home/15_Escher-Red-Apple.png',
        name: 'Zirconium with Escher Pattern and Red Apple Cerakote',
        path: 'https://lashbrookdesigns.com/shop/rings/profiles/D/widths/8/ring_materials/Z?ring_finish=SATIN&finish_type=F&size=10&feature_group=M%2CN&machine_pattern=LCVESCHER1_Z_COLOR_CER-REDAPPLE&sleeve=NONE',
        alt: 'Black zirconium mens wedding band with an escher pattern and red apple cerakote accent',
    },
    {
        src: 'https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/2023/home/16_Woodgrain-Damascus-Inlay.png',
        name: 'Flat Zirconium with Woodgrain Damascus Inlay',
        path: 'https://lashbrookdesigns.com/shop/rings/profiles/F/widths/8/ring_materials/PFZ?ring_finish=POLISH&finish_type=F&size=10&feature_group=G%2CN&groove_style=N&groove_group=15-N&grooves=15-N_WOODGRAIN_ACID&sleeve=NONE',
        alt: 'Flat black zirconium mens wedding band with a woodgrain damascus inlay',
    },
    {
        src: 'https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/2023/home/17_Milgrained-Rose-Gold-With-Tantalum.png',
        name: 'Rose Gold with Tantalum',
        path: 'https://lashbrookdesigns.com/shop/rings/profiles/HB2UMIL/widths/8/ring_materials/PF14KR?ring_finish=POLISH%2CPOLISH&finish_type=FS&size=10&feature_group=G%2CN&groove_style=N&groove_group=15-N&grooves=15-N_TANTALUM_POLISH&sleeve=NONE',
        alt: 'Rose Gold mens wedding band with a tantalum inlay and milgrain edges',
    },
    {
        src: 'https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/2023/home/18_Black-Zirconium-Hunter-Orange.png',
        name: 'Zironium with Hunter Orange',
        path: 'https://lashbrookdesigns.com/shop/rings/profiles/F/widths/7/ring_materials/Z?ring_finish=POLISH&finish_type=F&size=10&feature_group=G%2CN&groove_style=N&groove_group=13-N&grooves=13-N_CER-HUNTERORANGE&sleeve=NONE',
        alt: 'Black zirconium mens wedding band with 3mm hunter orange cerakote inlay',
    },
    {
        src: 'https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/2023/home/19_Cobalt-Chrome-Accent-Groove.png',
        name: 'Cobalt Chrome with Off Center Accents',
        path: 'https://lashbrookdesigns.com/shop/rings/profiles/F1.5OC/widths/7/ring_materials/CC?ring_finish=SAND%2CPOLISH&finish_type=FS&size=10&feature_group=N&sleeve=NONE',
        alt: 'Cobalt chrome mens wedding band with off center accents, polish and sand finishes',
    },
    {
        src: 'https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/2023/home/20_Yellow-Gold-Antler-Sleeve.png',
        name: 'Yellow Gold with Antler ',
        path: 'https://lashbrookdesigns.com/shop/rings/profiles/B-NS/widths/4/ring_materials/14KY?ring_finish=POLISH%2CPOLISH&finish_type=FS&size=10&feature_group=G%2CN&groove_style=N&groove_group=11-N&grooves=11-N_14KY_POLISH&sleeve=ANTLER',
        alt: 'Yellow Gold mens wedding band with an antler sleeve and polish finish',
    },
];

export const SYMBOLS = {
    USD: '$',
    CAD: 'CAD',
    EUR: '€',
};

export const EMPTY_DATE = '--/--/--';
export const YES = 'YES';
export const NO = 'NO';
