import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
// import If from '../If';
import './styles.css';
import tailImage from '../../../assets/images/word-bubble-tail.png';
import URLs from '../../../utils/url';

class Map extends Component {
    componentDidMount() {
        const wpmapscript = document.createElement('script');
        wpmapscript.src = `${URLs.publishedDir}scripts/map.js`; // `${URLs.host}/wp-content/themes/rinzler/js/map.js`;
        document.body.appendChild(wpmapscript);
        wpmapscript.onload = () => {
            window.apiBaseUrl = URLs.apiBaseUrl;
             // add on a delay to ensure the script above is present.
            setTimeout(() => {
                const gmapscript = document.createElement('script');
                gmapscript.src = 'https://maps.googleapis.com/maps/api/js?callback=initMap&key=AIzaSyB2y4gaFvZo2sfYhFIxyHBgmkJ-jSyig-k';
                gmapscript.async = true;
                gmapscript.defer = true;
                document.body.appendChild(gmapscript);
            }, 500);
        };

    }

    closeMap = () => {
        this.props.handleClose && this.props.handleClose();
    };

    render() {
        const { alwaysShow = false, show, controlsPosition = 'bottom', showNearMeButton = true, showTitle = true } = this.props;
        return (
            <section id="map">
                <div id="storelocations-container" className={`${show || alwaysShow ? 'show' : 'd-none'}`}>
                    {controlsPosition === 'top' &&
                        <div className="address-search-div">
                            {showTitle &&
                                <div className="search-title"><i className="fa fa-map-marker" />FIND A LASHBROOK RETAILER
                                    NEAR YOU
                                </div>}
                            {showNearMeButton &&
                                <button id="near-me-btn" className="btn btn-lb-primary action primary">Find Near
                                    Me
                                </button>}
                            {showNearMeButton && <span id="near-me-or">&nbsp;&nbsp;OR&nbsp;&nbsp;</span>}
                            <div className="address-search-container">
                                <input
                                    id="address-search"
                                    type="text"
                                    className="address-search"
                                    placeholder="Enter a zip or address"
                                />
                                <span id="address-search-button" className="address-search-btn"><i
                                    className="fa fa-search"
                                />
                                </span>
                            </div>
                        </div>}
                    <div id="storelocations" className="storelocations" />
                    {!alwaysShow &&
                        <div className="closer">
                            <i className="fa fa-close" onClick={this.closeMap} />
                        </div>}
                    <div id="store-popup" className="store-popup off">
                        <i className="fa fa-close" />
                        <div className="store-info">
                            <h6 className="store-name">
                                Store Name
                            </h6>
                            <div className="store-logo" />
                            <p className="store-address">
                                Address
                            </p>
                            <p className="store-city">
                                City, state zip
                            </p>
                            <p className="store-phone">
                                999-999-9999
                            </p>
                            <p className="store-web">
                                <a href="" target="_blank">#</a>
                            </p>
                        </div>
                        <div className="store-collections">
                            <h6 id="collectionOnDisplay">
                                collections on display
                            </h6>
                            <div className="store-collections-list">

                            </div>
                        </div>
                    </div>
                    <img className="tail off" src={tailImage} alt="tail" />
                    <div id="results-container">
                        <div id="results-list" />
                    </div>

                    {controlsPosition === 'bottom' &&
                        <div className="address-search-div">
                            {showTitle &&
                            <div className="search-title"><i className="fa fa-map-marker" />FIND A LASHBROOK RETAILER
                                NEAR YOU
                            </div>}
                            {showNearMeButton &&
                            <button id="near-me-btn" className="btn btn-lb-primary action primary">Find Near
                                Me
                            </button>}
                            {showNearMeButton && <span id="near-me-or">&nbsp;&nbsp;OR&nbsp;&nbsp;</span>}
                            <div className="address-search-container">
                                <input
                                    id="address-search"
                                    type="text"
                                    className="address-search"
                                    placeholder="Enter a zip or address"
                                />
                                <span id="address-search-button" className="address-search-btn"><i
                                    className="fa fa-search"
                                />
                                </span>
                            </div>
                        </div>}
                </div>
            </section>
        );
    }
}

export default Map;

Map.propTypes = {
    show: PropTypes.bool,
    handleClose: PropTypes.func,
};
