/* eslint-disable no-case-declarations */
import {
    GET_PRODUCT_DETAIL_ACTION_TYPES,
    GET_PRODUCT_SUMMARY_ACTION_TYPES,
    ADD_PRODUCT_TO_CART_ACTION_TYPES,
    GET_SIMILAR_RINGS_ACTION_TYPES,
    RESET_ADD_TO_CART,
    ADD_TO_FAVORITE_ACTION_TYPES,
    REMOVE_FROM_FAVORITE_ACTION_TYPES,
    // RESET_CHANGE_LOG,
    // RESET_PRODUCT_DATA,
    UPDATE_FAVORITES,
    GET_STATIC_PRODUCT_DETAIL_ACTION_TYPES,
    RESET_PRODUCT_DATA,
} from './constants';
import CartManager from '../../utils/CartManager';
import AuthManager from '../../utils/AuthManager';

const initialData = {
    isFetching: false,
    product: null,
    productError: null,
    productSummary: null,
    similarRings: [],
    summaryError: null,
    isFetchingSimilar: false,
    isFavourite: false,
    addProductResult: null,
};

const customizeReducer = (state = initialData, { payload, type, error },) => {
    switch (type) {
        case GET_PRODUCT_DETAIL_ACTION_TYPES.request:
            return {
                ...state,
                isFetching: true,
            };
        case GET_PRODUCT_DETAIL_ACTION_TYPES.success:
            return {
                ...state,
                isFetching: false,
                product: payload,
            };
        case GET_PRODUCT_DETAIL_ACTION_TYPES.failure:
            return {
                ...state,
                isFetching: false,
                productError: error,
            };

        case GET_STATIC_PRODUCT_DETAIL_ACTION_TYPES.request:
            return {
                ...state,
                isFetching: true,
            };
        case GET_STATIC_PRODUCT_DETAIL_ACTION_TYPES.success:
            return {
                ...state,
                isFetching: false,
                staticProduct: payload ? payload.data : null,
            };
        case GET_STATIC_PRODUCT_DETAIL_ACTION_TYPES.failure:
            return {
                ...state,
                isFetching: false,
                productError: error,
            };
        case GET_PRODUCT_SUMMARY_ACTION_TYPES.request:
            return {
                ...state,
                isFetching: true,
            };
        case GET_PRODUCT_SUMMARY_ACTION_TYPES.success:
            return {
                ...state,
                isFetching: false,
                productSummary: payload,
            };
        case GET_PRODUCT_SUMMARY_ACTION_TYPES.failure:
            return {
                ...state,
                isFetching: false,
                summaryError: error,
            };
        case ADD_PRODUCT_TO_CART_ACTION_TYPES.request:
            return {
                ...state,
                isFetching: true,
                addProductResult: null,
            };
        case ADD_PRODUCT_TO_CART_ACTION_TYPES.success:
            const cartId = payload.cart_id || (AuthManager.get() ? payload.cartId : payload.guestCartId);
            CartManager.set(cartId);
            return {
                ...state,
                isFetching: false,
                addProductResult: {
                    ...payload,
                    result: !!cartId,
                    message: cartId
                        ? 'Item was added to cart.'
                        : 'There was a problem adding item to the cart.',
                },
            };
        case ADD_PRODUCT_TO_CART_ACTION_TYPES.failure:
            return {
                ...state,
                isFetching: false,
                addProductResult: {
                    result: false,
                    message: 'There was a problem adding item to the cart.',
                },
            };
        case RESET_ADD_TO_CART:
            return {
                ...state,
                addProductResult: null,
            };
        case ADD_TO_FAVORITE_ACTION_TYPES.request:
        case REMOVE_FROM_FAVORITE_ACTION_TYPES.request:
            return {
                ...state,
                isFetching: true,
            };
        case ADD_TO_FAVORITE_ACTION_TYPES.success:
            AuthManager.setAnonymousUserIfNotExists(payload.user_id);
            return {
                ...state,
                isFetching: false,
            };
        case ADD_TO_FAVORITE_ACTION_TYPES.failure:
        case REMOVE_FROM_FAVORITE_ACTION_TYPES.success:
        case REMOVE_FROM_FAVORITE_ACTION_TYPES.failure:
            return {
                ...state,
                isFetching: false,
            };
        case UPDATE_FAVORITES:
            return {
                ...state,
                product: {
                    ...state.product,
                    is_favorite: payload,
                },
            };
        case GET_SIMILAR_RINGS_ACTION_TYPES.request:
            return {
                ...state,
                isFetchingSimilar: true,
            };
        case GET_SIMILAR_RINGS_ACTION_TYPES.success:
            return {
                ...state,
                isFetchingSimilar: false,
                similarRings: payload,
            };
        case GET_SIMILAR_RINGS_ACTION_TYPES.failure:
            return {
                ...state,
                isFetchingSimilar: false,
            };

        case RESET_PRODUCT_DATA:
            return {
                ...state,
                product: null,
            };
        default:
            return state;
    }
};

export default customizeReducer;
