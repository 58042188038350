
import LogRocket from 'logrocket';
import config from './config';

// google tag manager scripts
let googleTagManagerScript;
let googleTagManagerNoScript;
let googleTagManagerNoScriptIFrame;

export default class Scripts {
    static async registerScripts() {
        if (config.enableLogRocket && config.enableLogRocket === 'true') {
            if (config.id === 'RINGDESIGNER') {
                LogRocket.init('lfr5jo/wearemblemcom');
            } else {
                LogRocket.init('lfr5jo/lashbrook-react-app');
            }
        }
        if (config.loadGoogleTagManagerScripts) {
            googleTagManagerScript = document.createElement('script');
            googleTagManagerNoScript = document.createElement('noscript');
            googleTagManagerNoScriptIFrame = document.createElement('iframe');
            googleTagManagerScript.innerHTML =
                "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n" +
                "      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n" +
                "      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n" +
                "      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n" +
                "      })(window,document,'script','dataLayer','GTM-W2GZNGM');";
            googleTagManagerNoScript.innerText = 'You need to enable JavaScript in your browser to use this site.';
            googleTagManagerNoScriptIFrame.setAttribute(
                'src',
                'https://www.googletagmanager.com/ns.html?id=GTM-W2GZNGM'
            );
            googleTagManagerNoScriptIFrame.setAttribute('height', 0);
            googleTagManagerNoScriptIFrame.setAttribute('width', 0);
            googleTagManagerNoScriptIFrame.setAttribute('style', 'display:none;visibility:hidden');
            await googleTagManagerNoScript.appendChild(googleTagManagerNoScriptIFrame);
            await document.body.appendChild(googleTagManagerScript);
            await document.body.appendChild(googleTagManagerNoScript);
        }
    }

    static async unregisterScripts() {
        if (googleTagManagerScript) {
            googleTagManagerScript.remove();
        }
        if (googleTagManagerNoScriptIFrame) {
            googleTagManagerNoScriptIFrame.remove();
        }
        if (googleTagManagerNoScript) {
            googleTagManagerNoScript.remove();
        }
    }
}

