import React from 'react';
import VideoFile from '../../components/Product/VideoFile';
import ReactPlayer from 'react-player';
import { Col } from 'reactstrap';

export const AWARD_RINGS = [
    {
        src: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2017/02/JCA-2016-Winner-A.png?image_size=400',
        link: '/shop/categories',
        secondary: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2017/02/JCA-2016-Winner.png',
        alt: 'ring 5',
    },
    {
        src: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2017/02/JCA-2016-Winner-B.png?image_size=400',
        link: '/shop/categories',
        secondary: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2017/02/JCA-2016-Winner.png',
        alt: 'ring 5',
    },
    {
        src: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2017/02/JCA-2015-Winner-A.png?image_size=400',
        link: '/shop/categories',
        secondary: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2017/02/JCA-2015-Winner.png',
        alt: 'ring 5',
    },
    {
        src: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2017/02/JCA-2015-Winner-B.png?image_size=400',
        link: '/shop/categories',
        secondary: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2017/02/JCA-2015-Winner.png',
        alt: 'ring 5',
    },
    {
        src: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2017/02/JCA-2015-Winner-C.png?image_size=400',
        link: '/shop/categories',
        secondary: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2017/02/JCA-2015-Winner.png',
        alt: 'ring 5',
    },
    {
        src: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2017/02/JCA-2014-Winner-A.png?image_size=400',
        link: '/shop/categories',
        secondary: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2017/02/JCA-2014-Winner.png',
        alt: 'ring 5',
    },
    {
        src: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2017/02/JCA-and-Platinum-2014-Winner.png?image_size=400',
        link: '/shop/categories',
        secondary: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2017/02/JCA-2014-Winner-and-Platinum.png',
        alt: 'ring 5',
    },
    {
        src: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2017/02/JCA-2014-Winner-B.png?image_size=400',
        link: '/shop/categories',
        secondary: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2017/02/JCA-2014-Winner.png',
        alt: 'ring 5',
    },
    {
        src: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2017/02/JCA-2014-Winner-C.png?image_size=400',
        link: '/shop/categories',
        secondary: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2017/02/JCA-2014-Winner.png',
        alt: 'ring 5',
    },
    {
        src: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2017/02/JCA-2013-Winner-B.png?image_size=400',
        link: '/shop/categories',
        secondary: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2017/02/JCA-2013-Winner.png',
        alt: 'ring 5',
    },
    {
        src: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2017/02/JCA-2013-Winner-C.png?image_size=400',
        link: '/shop/categories',
        secondary: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2017/02/JCA-2013-Winner.png',
        alt: 'ring 5',
    },
    {
        src: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2017/02/JCA-2013-Finalist-A.png?image_size=400',
        link: '/shop/categories',
        secondary: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2017/02/JCA-2013-Winner.png',
        alt: 'ring 5',
    },
    {
        src: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2017/02/JCA-2013-Finalist-B.png?image_size=400',
        link: '/shop/categories',
        secondary: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2017/02/JCA-2013-Finalist.png',
        alt: 'ring 5',
    },
    {
        src: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2017/02/JCA-2012-Winner.png?image_size=400',
        link: '/shop/categories',
        secondary: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2017/02/JCA-2013-Finalist.png',
        alt: 'ring 5',
    },
    {
        src: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2017/02/JCA-2013-Winner-A.png?image_size=400',
        link: '/shop/categories',
        secondary: 'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2017/02/JCA-2012-Winner-Logo.png',
        alt: 'ring 5',
    },
];

export const FAQs = [
    {
        label: 'How does sizing work?',
        subText:
            '<ol><li>Estimate your finger size on your order.</li><li>We will send you a sizing set of five real rings- the size you estimated and two sizes smaller/bigger to find the perfect fit. It’s important that you use our sizers because we manufacture them with our comfort fit and to a very tight tolerance to ensure the right fit the first time.</li><li>Wear the sizers for a day or so and then confirm your size with us.</li><li>We make your ring in your perfect fit.</li></ol>',
        contact: 'Learn More About Accurate Ring Sizing',
        href: '/accurate-ring-sizing',
        addBefore: (
            <Col xs={12} className="size-video-wrapper mx-auto my-4">
                <ReactPlayer url="https://youtu.be/Ny7CqX7ZSGw" controls height="100%" width="100%" />
            </Col>
        ),
    },
    {
        label: 'How long do your rings take to make?',
        subText:
            '<p>Each ring is made to order using authentic materials, because of this, a ring can take as much as 3-5 weeks to make. If you need your ring sooner, we do offer a rush service for $50. We accommodate rushes whenever possible, please call us at (888) 252-7388 for availability and scheduling.</p><p>Rather than pull rings from a shelf, for over 20 years we have made all our rings by hand in Utah. Every order is quickly made to your exact specifications</p>',
        contact: 'Learn more about How it’s made.',
        href: '/shop/content/about-us',
    },
    {
        label: 'Can you do personal engravings on rings?',
        subText:
            '<p>Rings without sleeves or inlays can be engraved. You can choose your engraving options on the Lashbrook Builder.</p>',
        contact: 'Learn more about Personalized Engravings.',
        href: '/shop/content/engravings',
    },
    {
        label: 'What does your warranty cover?',
        subText:
            '<p>We stand behind our craftsmanship. If there’s a problem with the ring that was from our original manufacturing, then the ring is covered for life. Pretty simple. We also offer a protection plan for further coverage.</p>',
        contact: 'Learn more about Protection Plan',
        href: '/shop/content/protection-plan',
        addAfter: (
            <p>
                If you have a problem with your ring or any questions, please contact us at{' '}
                <a href="mailto:website@lashbrookdesigns.com">website@lashbrookdesigns.com</a>
            </p>
        ),
    },
    {
        label: 'What is your resizing policy?',
        subText:
            'Sizing refers to needing a new ring size for any reason – usually because you are now happily married and have gained some weight. To be eligible for a size exchange of any kind, the Lashbrook sizers must be used to size your finger as part of the purchase process. With The Protection Plan, the first size exchange will be free (because we’ve been there too) and future sizing will cost the same as without the plan- 30% of MSRP. To be eligible for size exchanges, the original ring must be returned.',
        contact: 'Learn more about Exchanges.',
        href: '/shop/content/policies',
    },
    {
        label: 'How are your rings shipped?',
        subText:
            '<p>Our standard FREE United States shipping methods include USPS, FedEx and UPS. Standard shipping averages 3-5 business days depending on your location from Utah. Express 2 and 3-day shipping is available as an upgrade option during checkout for an additional fee. All rush orders get express 2-day shipping as part of the rush fee.</p><p>Tracking<br/>Once your ring is ready to ship and be picked up by our carrier, you will get a tracking number sent to the email you used at checkout. Please allow 24 hours minimum for your tracking number to update in their system.</p><p>If you did not receive a tracking number, make sure to check your spam or junk email folder. Also, make sure to check which email account you used at checkout. If you still did not get a tracking number, chances are your email address was entered incorrectly at checkout. Please email customer service to have your email updated and your shipping confirmation email resent to you. For any other inquiries, please email us at website@lashbrookdesigns.com.</p>',
    },
    {
        label: 'What is the comfort fit?',
        subText:
            '<p>Lashbrook’s ‘comfort fit wedding bands’ design is not alone but is unique in that the smallest part of the ring is at the very center of the band. Our design is easier to slide over the knuckles and causes less irritation between the skin and band.</p>',
    },
    {
        label: 'Can I cancel, change, or return my order?',
        subText:
            '<p>Cancellations<br/>You can cancel your order up until it is shipped. With The Protection Plan- a 15% of MSRP restocking fee applies, without- it’s 30%. Once the order is shipped it cannot be canceled, and the regular return process will be required.</p><p>Changes<br/>For your security, we cannot add extra items to an order that has already been placed. If you want to add extra items, you will need to order them separately. If you are needing to update your address or email on an order, please email us at website@lashbrookdesigns.com or call us at (888) 252-7388 to make the change. Please be aware that if your order has already been fulfilled, address changes will not be able to be corrected. We highly recommend double-checking all info entered at checkout.</p><p>Returns<br/>Sometimes things don’t work out quite the way you expected, and that’s okay! Here is what to expect if you change your mind about your Lashbrook ring. You have 30 days from the time you receive the ring to return it for any reason. Because we custom makes each ring to your exact specs/design, a restocking fee does apply. With The Protection Plan- it’s just 25% of MSRP, without- it’s 50%.*</p><p>If 30 days have passed since your purchase or your item has been damaged, unfortunately, we cannot offer you a refund or exchange. We reserve the right to deny any items that do not meet our policy requirements. If the item is damaged, we will reject the return/refund and will send the damaged item back to you.</p>',
        contact: 'Learn more about Returns.',
        href: '/shop/content/policies',
    },
    {
        label: 'How do I care for my Lashbrook ring?',
        subText:
            'Regardless of the metal/materials used in the ring, all Lashbrook rings should be treated as fine jewelry and should be removed during harsh activities to avoid scratching. We recommended avoiding prolonged exposure to water and chemicals. All Lashbrook rings – except for meteorite rings – can be cleaned with regular jewelry cleaner. Meteorite rings can be cleaned with soap and water. All touch-ups or refurbishing should be sent to us.',
        contact: 'Learn more about refurbishment.',
        href: '/shop/content/policies',
    },
    {
        label: 'Do you sell Tungsten? Why can’t I find Tungsten as a metal option?',
        subText:
            '<p>Lashbrook was one of the original manufacturers of Tungsten rings; today we’ve upgraded to Tantalum. Tantalum is a premium alternative that gives you the same heft and beautiful gunmetal gray color, plus loads of customization options all without the brittleness of tungsten. Yes, tungsten carbide is one of the hardest materials on Earth, but this also means that it is prone to cracking or breaking (think glass)- something we like to call the “Tungsten Myth.”</p>',
    },
    {
        label: 'Can you rush my order?',
        subText:
            '<p>After you’ve confirmed your finger size, most rings take between 3-5 weeks to make depending on complexity. Don’t panic, lots of people wait until the last minute and need a rush. We can usually accommodate even the biggest procrastinators.</p><p>Call us and let’s see what we can do (888)-252-7388.</p>',
    },
    {
        label: 'Can I create something beyond the custom options on your site?',
        subText:
            '<p>Have a crazy idea? Maybe you want to use your own antler or hardwood? We’ve seen it all- we make custom combinations every day that you won’t see on our website. Visit one of our 2,000+ retailers, use live chat down below, or call (888) 252-7388 within business hours or email us anytime- website@lashbrookdesigns.com.</p>',
    },
];

export const LEARN_ITEMS = [
    {
        title: 'Unique Materials',
        byline: 'Explore and compare the materials available for customizing your ring',
        link: '/shop/unique-materials',
    },
    {
        title: 'Accurate Sizing',
        byline: 'A quick guide to ensuring you get the right fit with your purchase',
        link: '/accurate-ring-sizing',
    },
    {
        title: 'Engravings',
        byline: 'Learn what engravings are available to make your ring truly yours',
        link: '/shop/content/engravings',
    },
    {
        title: 'Protection Plan',
        byline: "You fell in love with your ring at first sight - let's keep it looking that way",
        link: '/shop/content/protection-plan',
    },
    {
        title: 'FAQs',
        byline: 'Answers from our experts to your most pressing questions',
        link: '/shop/content/faqs',
    },
    {
        title: 'Policies',
        byline: 'Learn how we make sure you have the best possible experience with your ring',
        link: '/shop/content/policies',
    },
    {
        title: 'Ring Anatomy',
        byline: 'See all the parts of a ring',
        link: '/ring-ana',
    },
    {
        title: 'Diamonds & Gemstones',
        byline:
            'Explore the stunning possibilities when you add white diamonds, lab grown diamonds, black diamonds or colored gemstones.',
        link: '/shop/content/diamonds-gemstones',
    },
    {
        title: 'Finishes',
        byline:
            'The "Finish" on a ring is the final surface treatment constructed by hand, each creating a distinctive look and character.',
        link: '/shop/content/ring-finishes',
    },
];

export const TIMELESS_RINGS = [
    'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2020/10/27114242/14KR14BHALFETERNITYDIA.015.png',
    'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2020/10/27115626/14K_WHITE_GOLD_SAPPHIRES_DIAMONDS.png',
    'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2020/10/27115845/14K_YELLOW_GOLD_PRINCESS_DIAMONDS.png',
    'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2020/10/27115845/14K_YELLOW_GOLD_PRINCESS_DIAMONDS.png',
];

export const ALTERNATIVE_RINGS = [
    'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2020/10/27113517/3MM_TURQUOISE_COBALTCHROME.png',
    'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2020/10/27115137/14K_YELLOW_GOLD_HARDWOOD_DESERT_IRON_WOOD.png',
    'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2020/10/27115142/ZIRCONIUM_LAB_GROWN_DIAMONDS.png',
    'https://wpassets.lashbrookdesigns.com/wp-content/uploads/2020/10/27114741/14K_ROSE_GOLD_METEORITE.png',
];
