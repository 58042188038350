import React from 'react';
import queryString from 'qs';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { paramsInvoices } from '../../../containers/CustomerContainer/constants';

const PaymentSuccess = () => (
    <section className="centerAlign font-universe margin-top-half">
        <i className="fa fa-check-circle-o fa-3x" aria-hidden="true" />
        <h2 className="mb-0">Payment Successful</h2>
        <p className="font-weight-bolder mb-2">You will receive an email with payment details shortly.</p>
        <Button className="mt-2">
            <Link
                to={`/shop/customer/account/me/openTrans?recordType=invoices&${queryString.stringify(paramsInvoices)}`}
            >
                Back to Invoices
            </Link>
        </Button>
    </section>
);

export default PaymentSuccess;
