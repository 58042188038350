import get from 'lodash/get';
import { createSelector } from 'reselect';

export const selectCategoryList = state => get(state, 'categoryList');

export const makeSelectIsFetching = () =>
    createSelector([selectCategoryList], isFetching => get(isFetching, 'isFetching', {}));

export const makeSelectCategoryList = () =>
    createSelector([selectCategoryList], categoryList => get(categoryList, 'categoryList', {}));
