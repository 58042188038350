import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const LazyLoadedImage = ({ src = '', alt = '', loading = 'lazy', style = {}, className = '', ...props }) => {
    const imgRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.unobserve(entry.target);
                }
            });
        });

        if (imgRef.current) {
            observer.observe(imgRef.current);
        }

        return () => {
            if (imgRef.current) {
                observer.unobserve(imgRef.current);
            }
        };
    }, []);

    const defaultStyle = isVisible
        ? {
            opacity: 1,
            transition: 'opacity 0.5s ease-in-out',
        }
        : {
            opacity: 0,
            transition: 'opacity 0.5s ease-in-out',
        };

    return (
        <img
            {...props}
            ref={imgRef}
            loading={isVisible ? 'auto' : loading} // Change loading attribute when the image becomes visible
            src={isVisible ? src : ''}
            alt={alt}
            className={`lazy-loaded-image ${className}`}
            style={{ ...defaultStyle, ...style }}
        />
    );
};

LazyLoadedImage.defaultProps = {
    loading: 'lazy',
    style: {},
    className: '',
    src: '',
};
LazyLoadedImage.propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string.isRequired,
    loading: PropTypes.oneOf(['lazy', 'auto']),
    style: PropTypes.object,
    className: PropTypes.string,
};
export default LazyLoadedImage;

