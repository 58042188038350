/**
 * This is the commit message of the last commit before building or running this project
 * @see ./package.json git-info for how to generate this commit
 */
import GitCommit from '../_git_commit';


export default class VersionManager {
    static getLogMessage() {
        return GitCommit.logMessage;
    }
    static getCommit() {
        return GitCommit.commit;
    }
}
