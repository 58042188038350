import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { createSelector } from 'reselect';
import * as actions from './actions';
import Manage from '../../components/Manage Subscription';
import {
    makeSelectIsFetching,
    makeSelectCurrentPlans,
    makeSelectBillingHistory,
    makeSelectCustomerDetails,
    makeSelectPaymentMethods,
} from './selectors';
import Dashboard from '../../components/Manage Subscription/Dashboard';

class ManageSubscription extends React.Component {
    render() {
        const {
            emailPortal,
            isFetching,
            currentPlans,
            getCurrentPlan,
            getBillingHistory,
            billingHistory,
            getPaymentMethods,
            paymentMethods,
            addPaymentMethod,
            deletePaymentMethod,
            makeDefaultMethod,
            getCustomerDetails,
            customerDetails,
        } = this.props;

        return (
            <Switch>
                <Route
                    exact
                    path="/shop/manage-financing/email"
                    render={props => <Manage {...props} emailPortal={emailPortal} isFetching={isFetching} />}
                />
                <Route
                    exact
                    path="/shop/manage-financing/portal"
                    render={props => (
                        <Dashboard
                            {...props}
                            currentPlans={currentPlans}
                            getCurrentPlan={getCurrentPlan}
                            getBillingHistory={getBillingHistory}
                            billingHistory={billingHistory}
                            getPaymentMethods={getPaymentMethods}
                            paymentMethods={paymentMethods}
                            addPaymentMethod={addPaymentMethod}
                            deletePaymentMethod={deletePaymentMethod}
                            makeDefaultMethod={makeDefaultMethod}
                            getCustomerDetails={getCustomerDetails}
                            customerDetails={customerDetails}
                            isFetching={isFetching}
                        />
                    )}
                />
            </Switch>
        );
    }
}

const mapStateToProps = createSelector(
    [
        makeSelectIsFetching(),
        makeSelectCurrentPlans(),
        makeSelectBillingHistory(),
        makeSelectPaymentMethods(),
        makeSelectCustomerDetails(),
    ],
    (isFetching, currentPlans, billingHistory, paymentMethods, customerDetails) => ({
        isFetching,
        currentPlans,
        billingHistory,
        paymentMethods,
        customerDetails,
    })
);

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            ...actions,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ManageSubscription);
