import React from 'react';
import CollapsibleCard from '../Shared/CollapsibleCard';
import { formatPercentage } from '../../utils/currencyFormatter';
import { getColorClass } from '../../utils/colorUtils';

function AccountPerformanceSummary({ data }) {
  return (
    <CollapsibleCard title="Summary of  Account Performance">
      <div className="d-flex justify-content-between mt-3">
        <div className="text-center flex-grow-1">
          <div className="font-weight-bold font-16 font-open-sans-condensed">$L12M/$P12M</div>
          <div className={`${getColorClass(data?.l12mVsP12m)} font-16 font-open-sans-condensed`}>
            {formatPercentage(data?.l12mVsP12m)}
          </div>
        </div>
        <div className="text-center flex-grow-1">
          <div className="font-weight-bold font-16 font-open-sans-condensed">RQ/LYRQ</div>
          <div className={`${getColorClass(data?.rqVsLyrq)} font-16 font-open-sans-condensed`}>
            {formatPercentage(data?.rqVsLyrq)}
          </div>
        </div>
        <div className="text-center flex-grow-1">
          <div className="font-weight-bold font-16 font-open-sans-condensed">LM/LYLM</div>
          <div className={`${getColorClass(data?.lmVsLylm)} font-16 font-open-sans-condensed`}>
            {formatPercentage(data?.lmVsLylm)}
          </div>
        </div>
      </div>
    </CollapsibleCard>
  );
}

export default AccountPerformanceSummary;