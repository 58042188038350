import get from 'lodash/get';
import { createSelector } from 'reselect';

export const selectCart = state => get(state, 'cart');

export const makeSelectIsFetching = () =>
    createSelector([selectCart], state => get(state, 'isFetching', false));

export const makeSelectProducts = () =>
    createSelector([selectCart], state => get(state, 'products', []));

export const makeSelectCartSummary = () =>
    createSelector([selectCart], state => get(state, 'cartSummary', {}));

export const makeSelectAdditional = () =>
    createSelector([selectCart], state => get(state, 'additional', {}));

export const makeSelectOrder = () =>
    createSelector([selectCart], state => get(state, 'orderData', []));

export const makeSelectPartners = () =>
    createSelector([selectCart], state => get(state, 'partners', false));
