import React, { useState } from 'react';
import { Card, Collapse, CardBody, CardTitle } from 'reactstrap';
import './CollapsibleCard.css';

const CollapsibleCard = ({ title, filter, containerClassName, className, children }) => {
    const [isOpen, setIsOpen] = useState(true);

    const toggleCollapse = () => {
        setIsOpen(!isOpen);
    };

    return (
        <Card className={`mt-4 collapsible-card ${containerClassName}`}>
            <CardBody>
                <div onClick={toggleCollapse} className="d-flex justify-content-between align-items-center cursor-pointer pb-2">
                    <CardTitle className="mb-0">
                        {title}
                    </CardTitle>
                    <div className="d-flex align-items-center">
                        {filter}
                        <i
                            className={`fa fa-chevron-${isOpen ? 'up' : 'down'} va-sub title-text-color arrow-golden chevron`}
                            width={10}
                            fill="#777"
                            aria-hidden="true"
                            onClick={toggleCollapse}
                        />
                    </div>
                </div>
                <Collapse isOpen={isOpen}>
                    <div className={className}>
                        {children}
                    </div>
                </Collapse>
            </CardBody>
        </Card>
    );
};

export default CollapsibleCard;
