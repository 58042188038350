import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { get } from 'lodash';
import { Card, Row, CardTitle, CardText, CardBody, Col, Button, ListGroup, ListGroupItem } from 'reactstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import Loader from '../Shared/Loader';
import CartManager from '../../utils/CartManager';

const Checkout = ({
    isFetching,
    getShippingDetail,
    getAllAddresses,
    getCartDetail,
    shippingMethods,
    addresses,
    customerProducts,
    summary,
    createAddress,
    createOrder,
    resetCart,
    clearCart,
}) => {
    const [shipping, setShipping] = useState(0);
    const [shipping_id, setShippingId] = useState(1);
    const [billing_address, setBillingAddressId] = useState();
    const [shipping_address, setShippingAddressId] = useState();
    const [selectedAddress, setAddress] = useState();
    const [checked, setChecked] = useState(true);
    const arr = customerProducts.map(item => false);
    const [hasOpen, isSetOpen] = useState([...arr]);
    const history = useHistory();

    useEffect(() => {
        getAllAddresses();
    }, []);

    useEffect(() => {
        if (typeof addresses.data !== 'undefined') {
            addresses.data.map(address => {
                if (checked) {
                    if (
                        address.address_type === 'shipping' ||
                        address.address_type === 'billing' ||
                        address.address_type === 'both'
                    ) {
                        setBillingAddressId(address.id);
                        setShippingAddressId(address.id);
                    }
                } else {
                    if (address.address_type === 'billing') {
                        setBillingAddressId(address.id);
                    }
                    if (address.address_type === 'shipping') {
                        setShippingAddressId(address.id);
                    }
                }
            });
        }
    }, [addresses, checked]);

    useEffect(() => {
        getShippingDetail().then(response => {
            const defaultShippingCharges =
                response && response.payload.data && response.payload.data.length > 0 && response.payload.data[0].cost;
            setShipping(defaultShippingCharges);
        });
    }, []);

    useEffect(() => {
        const cartId = CartManager.get();
        getCartDetail(cartId);
    }, []);

    const placeOrder = () => {
        const cartId = CartManager.get();
        const shipToAddress = addresses?.data[selectedAddress];
        if (
            typeof shipToAddress !== 'undefined'
        ) {
            createAddress({ ...shipToAddress }).then(data => {
                const { id } = data?.payload?.data;
                createOrder({ shipping_address: id, billing_address: id, shipping_id, cartId })
                    .then(response => {
                        const orderId = response.payload.id;
                        window.dataLayer.push({
                            event: 'purchase',
                            ecommerce: {
                                purchase: {
                                    orderComplete: {
                                        id: orderId, // Transaction ID. Required for purchases and refunds.
                                        revenue: summary.total_price, // Total transaction value (incl. tax and shipping)
                                        'payment-method': 'store order',
                                    },
                                },
                            },
                        });
                        toast.success('Order Created', { className: 'toast-success' });
                        clearCart(cartId);
                        CartManager.remove();
                        resetCart();
                        history.push(`/shop/customer/order-confirmation/${orderId || 'undefined'}`);
                    }).catch(err => {
                        const error = get(err, 'response', {});
                        if (error.status === 500) {
                            toast.info(error.data.message, { className: 'toast-error' });
                            const url = CartManager.getCartLink();
                            history.push(url);
                        }
                    })
                    .catch(error => {
                        let errorMessage;
                        if (error && error.response && error.response.data && error.response.data.errors &&
                            error.response.data.errors.billing_address) {
                            errorMessage = error.response.data.errors.billing_address[0];
                        } else if (
                            error &&
                                    error.response &&
                                    error.response.data &&
                                    error.response.data.errors &&
                                    error.response.data.errors.shipping_address
                        ) {
                            errorMessage = error.response.data.errors.shipping_address[0];
                        }
                        toast.info(errorMessage, { className: 'toast-error' });
                    });
            }
            )
                .catch(error => {
                    const errors = error?.response?.data?.errors;
                    const firstError = Object.keys(errors)[0];
                    const errorMessage = errors[firstError][0];
                    toast.info(errorMessage, { className: 'toast-error' });
                });
        } else {
            toast.info('Something went wrong please try again', { className: 'toast-error' });
        }
    };

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const renderAddresses = () =>
        addresses &&
        addresses.data &&
        addresses.data.map((address, i) => (
            <ListGroupItem index={`${i}-${address.first_name}`} className="d-flex align-items-center justify-content-lg-start" key={i}>
                <input
                    type="radio"
                    key={`${i}-${address.city}-${address.zip}`}
                    name={`${i}-${address.city}-${address.zip}`}
                    value={i}
                    checked={i === selectedAddress}
                    onChange={() => { setAddress(i); }}
                />
                <Accordion title={`${address.first_name} ${address.last_name}`} index={`${i}-${address.city}-${address.zip}`} styles="mx-auto" key={i}>
                    <div className="text-capitalize">
                        <div className="font-weight-bold">{`${address.first_name} ${address.last_name}`}</div>
                        <div className="mb-0">{address.street_address1}</div>
                        <div className="mb-0">{address.street_address2}</div>
                        <div className="mb-0">{address.city}</div>
                        <div className="mb-0">{address.state}</div>
                        <div className="mb-0">{address.zip}</div>
                        <div className="mb-0">
                            <a href={`tel:${address.phone_number}`}>{address.phone_number}</a>
                        </div>
                    </div>
                </Accordion>
            </ListGroupItem>
        ));

    const renderShippingMethods = () =>
        // eslint-disable-next-line camelcase
        shippingMethods.data.map(shipping_options => (
            <Col lg={12} key={shipping_options.reference} className="mt-2">
                <input
                    key={shipping_options.reference}
                    type="radio"
                    name={shipping_options.service}
                    value={shipping_options.cost}
                    checked={shipping === shipping_options.cost}
                    onChange={e => {
                        setShipping(shipping_options.cost);
                        setShippingId(shipping_options.id);
                    }}
                />
                <span>{formatter.format(shipping_options.cost / 100)}</span> <span>{shipping_options.service}</span>
            </Col>
        ));

    const Accordion = ({ title, children, index, styles = '' }) => (
        <div className={`accordion-wrapper ${styles}`}>
            <div
                className={`accordion-title ${hasOpen[index] ? 'open' : ''} font-universe`}
                onClick={() => {
                    const newArr = [...hasOpen];
                    newArr[index] = !hasOpen[index];
                    isSetOpen(newArr);
                }}
            >
                {title}
            </div>
            <div className={`accordion-item ${!hasOpen[index] ? 'collapsed' : ''}`}>
                <div className="accordion-content">{children}</div>
            </div>
        </div>
    );

    return (
        <div className="customer-cart-summary-container p-3">
            <Row className="justify-content-between">
                <Col xs={12} md={6} lg={5}>
                    <div>
                        <div>Shipping Address: </div>
                        <Row className="font-universe d-lg-flex">
                            {addresses && addresses.data && addresses.data.length > 0 ? (
                                <Col xs={12}>
                                    <ListGroup>
                                        {renderAddresses()}
                                    </ListGroup>
                                </Col>
                            ) : (
                                <Col
                                    xs={12}
                                    className={`${isFetching && 'd-none'} text-center font-universe py-5 fs-20`}
                                >
                                    No addresses added yet
                                </Col>
                            )}
                        </Row>
                        <hr />
                        <div className="shipping-methods">Shipment Methods: </div>

                        <Row className="font-universe d-lg-flex shipment">
                            {shippingMethods &&
                                shippingMethods.data &&
                                shippingMethods.data.length > 0 &&
                                renderShippingMethods()}
                        </Row>
                        <hr />
                    </div>
                </Col>

                <Col xs={12} md={6} lg={5}>
                    <section>
                        <h4>Order Summary</h4>
                        <div className="font-universe d-flex justify-content-between">
                            <span>Items in Cart</span>
                            <span>{customerProducts && customerProducts.length > 0 && customerProducts.length}</span>
                        </div>
                        <hr />
                        <div>
                            {customerProducts &&
                                customerProducts.map((item, i) => (
                                    <>
                                        <p className="font-universe d-flex justify-content-between">
                                            <span>Name: </span>
                                            <span>{item.name}</span>
                                        </p>
                                        <p className="font-universe d-flex justify-content-between">
                                            <span>Price: </span>
                                            <span>
                                                Qty: {item.qty} x {item.unit_price}
                                            </span>
                                            <span>{item.price}</span>
                                        </p>
                                        <div>
                                            <Accordion title="View Product Details" index={i}>
                                                {item.options.map((detail, i) => (
                                                    <div key={i}>
                                                        <p className="font-universe d-flex justify-content-between">
                                                            <span>{detail.name}: </span>
                                                            <span>{detail.value}</span>
                                                        </p>
                                                    </div>
                                                ))}
                                            </Accordion>
                                        </div>
                                        <hr />
                                    </>
                                ))}
                            <p className="font-universe d-flex justify-content-between">
                                <span>Total:</span>
                                <span>{summary && summary.total_price}</span>
                            </p>
                            <p className="font-universe d-flex justify-content-between">
                                <span>Shipping:</span>

                                <span>{formatter.format(shipping / 100)}</span>
                            </p>
                            <p className="font-universe d-flex justify-content-between">
                                <span>Grand Total:</span>

                                <span>
                                    {formatter.format(
                                        parseInt(Number(summary && summary.total_price.replace(/[^0-9.]+/g, '')), 10) +
                                            shipping / 100
                                    )}
                                </span>
                            </p>
                        </div>
                    </section>
                </Col>
            </Row>

            <Row className="custom-place-order">
                <Col xs={12} md={6} lg={5}>
                    <Button className="mr-3" onClick={placeOrder} disabled={typeof selectedAddress === 'undefined'}>
                        Place Order
                    </Button>
                </Col>
            </Row>

            <Loader timedOut={isFetching} />
        </div>
    );
};

Checkout.propTypes = {
    isFetching: PropTypes.bool,
    getShippingDetail: PropTypes.func,
    getAllAddresses: PropTypes.func,
    getCartDetail: PropTypes.func,
    shippingMethods: PropTypes.object,
    addresses: PropTypes.object,
    customerProducts: PropTypes.object,
    createOrder: PropTypes.func,
    summary: PropTypes.object,
    resetCart: PropTypes.func,
    clearCart: PropTypes.func,
};

export default Checkout;
