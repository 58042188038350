import LocalStorage from './LocalStorage';

export default class AuthManager {
    static TOKEN = 'token';
    static USERNAME = 'username';
    static PRICE_TOGGLE = 'priceToggle';
    static USER_ID = 'user_id';
    static VIEW_INVOICE = 'viewInvoice';
    static PAY_INVOICE = 'payInvoice';
    static IS_PARENT = 'isParent';
    static SHOW_CUSTOMER_PORTAL = 'showCustomerPortal';
    static SHOW_DATA_TAB = 'showDataTab';

    // gets the token for the authenticated session.
    static get() {
        return LocalStorage.get(this.TOKEN) || '';
    }

    // gets the username of the authenticated session.
    static getUsername() {
        return LocalStorage.get(this.USERNAME) || '';
    }

    static isParent() {
        return !!LocalStorage.get(this.IS_PARENT);
    }

    static getInvoicePermissions() {
        const viewInvoice = !!LocalStorage.get(this.VIEW_INVOICE);
        const payInvoice = !!LocalStorage.get(this.PAY_INVOICE);
        return { viewInvoice, payInvoice };
    }

    static hasPortalAccess() {
        return !!LocalStorage.get(this.SHOW_CUSTOMER_PORTAL);
    }

    static hasDataTabAccess() {
        return !!LocalStorage.get(this.SHOW_DATA_TAB);
    }

    // returns boolean indicating whether there is an anonymous user id or authenticated session user.
    static hasIdentity() {
        const hasAnonymousUserIdentity = !!this.getAnonymousUserId();
        const hasAuthenticatedIdentity = !!this.get();
        return hasAnonymousUserIdentity || hasAuthenticatedIdentity;
    }

    // removes the anonymous user id.
    static removeAnonymousUserId() {
        return LocalStorage.remove(this.USER_ID);
    }

    // This was done to get around not setting the normal logged in token when a guest is going
    // through the stripe checkout process.
    // TODO: This can be removed and use the normal set when the Customer Dashboard is ready.
    static setGuestToken(guestToken) {
        return LocalStorage.add('GuestToken', typeof guestToken !== 'undefined' ? guestToken : '');
    }

    // This was done to get around not setting the normal logged in token when a guest is going
    // through the stripe checkout process.
    // TODO: This can be removed and use the normal get when the Customer Dashboard is ready.
    static getGuestToken() {
        return LocalStorage.get('GuestToken') || '';
    }

    static removeGuestToken() {
        return LocalStorage.remove('GuestToken');
    }

    // sets the anonymous user id that is used in contexts where there is no auth.
    static setAnonymousUserIfNotExists(userId) {
        // only set the user id if it hasn't already been set.
        const userIdValue = LocalStorage.get(this.USER_ID);
        if (!userIdValue) {
            LocalStorage.add(this.USER_ID, userId);
        }
        return userId;
    }

    // returns the anonymous user id if one exists.
    static getAnonymousUserId() {
        const userId = LocalStorage.get(this.USER_ID);
        return userId;
    }

    // gets the price toggle setting for the authenticated session.
    static allowPriceToggle() {
        // user must be authorized and be allowed to toggle price.
        return (!!LocalStorage.get(this.TOKEN) && !!LocalStorage.get(this.PRICE_TOGGLE));
    }

    // sets the token for the authenticated session.
    static setToken(token) {
        return LocalStorage.add(this.TOKEN, typeof token !== 'undefined' ? token : '');
    }

    // check if the account is a Parent Account
    static getAccountType() {
        return (!!LocalStorage.get(this.TOKEN) && !!LocalStorage.get(this.IS_PARENT));
    }

    // sets the account info for the authenticated session.
    static setAccountInfo(value) {
        const { first_name, show_price_toggle, store, customer_portal_access, show_data_tab } = value;
        const { is_parent_store } = store;

        const username = LocalStorage.add(this.USERNAME, typeof first_name !== 'undefined' ? first_name : '');
        const priceToggle = show_price_toggle ? LocalStorage.add(this.PRICE_TOGGLE, true) : null;
        const isParent = is_parent_store ? LocalStorage.add(this.IS_PARENT, true) :
            LocalStorage.remove(this.IS_PARENT);
        const showCustomerPortal = customer_portal_access ? LocalStorage.add(this.SHOW_CUSTOMER_PORTAL, true) :
            LocalStorage.remove(this.SHOW_CUSTOMER_PORTAL);
        const showDataTab = show_data_tab ? LocalStorage.add(this.SHOW_DATA_TAB, true) : null;
        return { username, priceToggle, isParent, showCustomerPortal, showDataTab };
    }

    // removes the information for the authenticated session.
    static remove() {
        LocalStorage.remove(this.PRICE_TOGGLE);
        LocalStorage.remove(this.USERNAME);
        LocalStorage.remove(this.IS_PARENT);
        LocalStorage.remove(this.SHOW_CUSTOMER_PORTAL);
        LocalStorage.remove(this.SHOW_DATA_TAB);
        return LocalStorage.remove(this.TOKEN);
    }
}
