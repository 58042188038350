import React from 'react';
import { Table } from 'reactstrap';
import { toast } from 'react-toastify';
import TablePagination from '../Open Transactions/TablePagination';
import getDetailsPDF from '../Open Transactions/getPdf';
import { PAGE_LIMIT_OPEN_TRANSACTIONS, translatedSizePayments } from '../../../containers/CustomerContainer/constants';

const PaymentsTable = props => {
    const { data, isFetching, page, pageSize, setPage, setPageSize, pageCount, getTransactionInfo, recordType } = props;

    if (!data) return null;

    const renderTable = () => data.map((record) => (
        <tr key={record.transactionId}>
            <td>{record.transactionId}</td>
            <td>{record.customer}</td>
            <td>{record.date}</td>
            <td>{`$${record.amount}`}</td>
            <td className="pdf-link" onClick={() => handlePdfDownload(record.transactionId)}>Download Details</td>
        </tr>
    ));

    const handlePdfDownload = id => {
        getTransactionInfo({ recordType, transactionid: id })
            .then(({ payload: { data: pdfData } }) => {
                const { PDFBinary } = pdfData[0];
                getDetailsPDF(PDFBinary, id);
            })
            .catch(() => {
                toast.error('Something went wrong, please try again', { className: 'toast-error' });
            });
    };

    return (
        <>
            {data ? (
                <Table hover className="font-universe transactions-table">
                    <thead>
                        <tr>
                            <th>Payment #</th>
                            <th>Name</th>
                            <th>Date</th>
                            <th>Total Amount</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderTable()}
                    </tbody>
                    <TablePagination
                        pageSize={pageSize}
                        pageCount={pageCount}
                        currentPage={page}
                        setPage={setPage}
                        setPageSize={setPageSize}
                        translatedSize={translatedSizePayments}
                    />
                </Table>
            ) : (
                <div className={`${isFetching && 'd-none'} text-center font-universe fs-20`}>
                    No data
                </div>
            )}
        </>
    );
};

export default PaymentsTable;
