import React from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ALTERNATIVE_RINGS, TIMELESS_RINGS } from '../../containers/ContentContainer/constants';

const UniqueHandmade = () => (
    <>
        <div className="d-none d-md-block font-universe">
            <div className="unique-rings-hero text-white text-center pt-5">
                <h1 className="text-uppercase font-weight-bold pt-5 fs-3rem">Unique Handmade Rings</h1>
                <p className="fs-24">free shipping, free sizing, guaranteed for life</p>
            </div>
            <Container>
                <Row>
                    <Col xs={12} className="color-grey text-center mt-4">
                        <p className="fs-24">Shop Timeless</p>
                        <p className="fs-20">gold, diamonds, sapphires &amp; more</p>
                        <Link className="color-grey border-bottom" to="/shop/categories/timeless">browse timeless</Link>
                    </Col>
                    {TIMELESS_RINGS.map((ring, index) => (
                        <Col xs={3}>
                            <img src={ring} alt={`timeless-ring-${index}`} />
                        </Col>
                    ))}
                </Row>
                <Row className="mt-5">
                    <Col xs={12} className="color-grey text-center mt-4">
                        <p className="fs-24">Shop Alternative</p>
                        <p className="fs-20">zirconium, meteorite, wood &amp; more</p>
                        <Link className="color-grey border-bottom" to="/shop/categories/alternative">browse alternative</Link>
                    </Col>
                    {ALTERNATIVE_RINGS.map((ring, index) => (
                        <Col xs={3}>
                            <img src={ring} alt={`alternative-ring-${index}`} />
                        </Col>
                    ))}
                </Row>
                <Row className="my-5 py-5">
                    <Col xs={6} className="text-center blue-background text-white fs-18">
                        <h3 className="mt-5">Perfect Pairings</h3>
                        <p>Create the perfect match with any of our customizable men’s bands. With dozens of exotic materials, you can create something he’ll want to wear that compliments your ring perfectly.</p>
                    </Col>
                    <Col xs={6} className="p-0">
                        <img className="h-100" src="http://wpassets.lashbrookdesigns.com/wp-content/uploads/2020/10/20083707/Perfect-Pairings-Banner.jpg" alt="perfect-pairings" />
                    </Col>
                </Row>
                <Row className="my-5">
                    <Col xs={6} className="p-0">
                        <img className="h-100" src="http://wpassets.lashbrookdesigns.com/wp-content/uploads/2020/10/20083857/womensshopring.jpg" alt="mission" />
                    </Col>
                    <Col xs={6} className="text-center color-grey d-flex flex-column justify-content-between fs-18">
                        <div>
                            <h3 className="mt-3">Living Our Mission</h3>
                            <p>Lashbrook has been handcrafting every ring from scratch for over 20 years. Our materials and craftsmanship have stood the test of time and your purchase helps support the removal of poisonous mercury from artisanal mining.</p>
                        </div>
                        <img src="http://wpassets.lashbrookdesigns.com/wp-content/uploads/2020/10/20083439/MFM-thumb.jpg" alt="mission-2" />
                    </Col>
                </Row>
            </Container>
        </div>
        <div className="d-md-none">
            <div className="outer-container font-universe">
                <div className="slider d-flex">
                    <section className="sliderMobile2 slider-section">
                        <div className="mt-5 pt-5">
                            <h3 className="text-white">Unique Handmade Rings</h3>
                            <hr className="content-hr-wider" color="#dcc67d" />
                            <p className="text-white">free shipping, free sizing, guaranteed for life</p>
                        </div>
                        <Button size="lg" className="unique-handmade-button"><Link className="text-white text-capitalize" to="/shop/categories/timeless">Shop Timeless</Link></Button>
                        <p className="dots">
                            <span className="filledDot" />
                            <span className="emptyDot" />
                        </p>
                    </section>
                    <section className="sliderMobile slider-section">
                        <div className="mt-5 pt-5">
                            <h3 className="text-white">Unique Handcrafted Materials</h3>
                            <hr className="content-hr-wider" color="#dcc67d" />
                            <p className="text-white">zirconium, meteorite, wood & more</p>
                        </div>
                        <Button size="lg" className="unique-handmade-button"><Link className="text-white text-capitalize" to="/shop/categories/alternative">Shop Alternative</Link></Button>
                        <p className="dots">
                            <span className="emptyDot" />
                            <span className="filledDot" />
                        </p>
                    </section>
                </div>
            </div>
        </div>
    </>
);

export default UniqueHandmade;
