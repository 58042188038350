import React from 'react';
import { getSelectedOption } from './commonRingUtils';
import { getV3FinishItems } from './finishItems';
import { getV3ShapeItems } from './shapeItems';
import { getOutsideFeatureItems } from './outsideFeatureItems';
import SwatchFilter from '../components/SwatchFilter';
import { ITab, ITabs } from '../types/customizer';
import SizeFilter from '../components/SizeFilter';
import Summary from '../components/Summary';
import Data from '../components/Data';
import BoxFilter from '../components/BoxFilter';
import { getCategorizedOptions, getTypeOptions } from './basic';
import { getLogoItems } from './logoItems';

export const getV3PageData = (product: any, isDataTabAccess = false, ring_type: string) => {
    product.categories = getCategorizedOptions(ring_type);
    product.types = getTypeOptions(ring_type);

    const items: ITabs = [
        {
            title: 'Category',
            eventKey: 'category',
            component: BoxFilter,
            isEnabled: true,
            content: {
                hasSwatch: true,
                fieldType: 'category',
                options: product.categories,
                selectedOption: getSelectedOption(product.categories),
            },
        },
        {
            title: 'Type',
            eventKey: 'type',
            component: BoxFilter,
            isEnabled: product.types.length > 0,
            content: {
                hasSwatch: true,
                fieldType: 'types',
                options: product.types,
                selectedOption: getSelectedOption(product.types),
            },
        },
        {
            title: 'School',
            eventKey: 'school',
            component: SwatchFilter,
            isEnabled: product.school?.length > 0,
            content: {
                hasSwatch: true,
                fieldType: 'school',
                options: product.school,
                selectedOption: getSelectedOption(product.school),
            },
        },
        {
            title: 'Shape',
            eventKey: 'shape',
            items: getV3ShapeItems(product),
            tabStyle: 'box',
        },
        {
            title: 'Logo',
            eventKey: 'logo',
            items: getLogoItems(product),
            tabStyle: 'box',
        },
        {
            title: 'Material',
            eventKey: 'material',
            component: SwatchFilter,
            isEnabled: product.ring_material?.length > 0,
            content: {
                hasSwatch: true,
                fieldType: 'ring_materials',
                options: product.ring_material,
                selectedOption: getSelectedOption(product.ring_material),
            },
        },
        {
            title: 'Finish',
            eventKey: 'finish',
            items: getV3FinishItems(product),
            tabStyle: 'box',
        },
        {
            title: 'Outside Feature',
            eventKey: 'outsideFeature_1',
            ...getOutsideFeatureItems(product),
            tabStyle: 'box',
        },
        {
            title: 'Size',
            eventKey: 'ringSize',
            component: SizeFilter,
            isEnabled: product.finger_size?.length > 0,
            content: {
                hasSwatch: false,
                fieldType: 'finger_size',
                options: product.finger_size || [],
                selectedOption: getSelectedOption(product.finger_size),
            },
        },
        {
            title: 'Summary',
            eventKey: 'summary',
            component: Summary,
            isEnabled: true
        },
        {
            title: 'Data',
            eventKey: 'data',
            component: Data,
            isEnabled: isDataTabAccess,
        },
    ];

    const filtered = items.filter((tab: ITab) => tab.isEnabled || tab.items?.length);
    return filtered;
};
