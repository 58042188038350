import { useForm } from 'react-hook-form';
import Axios from 'axios';
import { toast } from 'react-toastify';
// import { useHistory } from 'react-router-dom';
import React, { useCallback, useState } from 'react';
import { Col } from 'reactstrap';
import './Fashion.scss';
import LazyLoadedImage from '../LazyLoadedImage';
import LazyLoadedDiv from '../LazyLoadedDiv';
import { useWindowSize } from '../../utils/WindowSize';
import EachElement from '../Shared/Each';
import URLs from '../../utils/url';

const files = [
    'https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/landingPages/img-1.png',
    'https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/landingPages/img-2.png',
    'https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/landingPages/img-3.png',
    'https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/landingPages/img-4.jpeg',
    'https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/landingPages/img-5.png',
];

const Fashion = () => {
    const { register, handleSubmit, reset } = useForm();
    const { width } = useWindowSize();
    const [loading, setLoading] = useState(false);

    const isMobile = width < 768;
    const renderImages = useCallback(() => (
        <div className="tw-flex tw-overflow-auto image-container-fashion tw-gap-6  ">
            <EachElement
                render={(item, i) => (<div key={i} className="card">
                    <LazyLoadedImage src={item} loading="lazy" alt={item} />
                </div>)}
                of={files}
            />
        </div>
    ), [files]);

    const submitForm = useCallback(async (data) => {
        try {
            setLoading(true);
            const postData = {
                email: data.email,
            };
            await Axios.post(`${URLs.apiBaseUrl}/${URLs.subscribeToMarketingEmails}`, postData);
            setLoading(false);
            toast.success('Request submitted successfully', { className: 'toast-success' });
            reset();
        } catch (error) {
            setLoading(false);
            toast.error('Something went wrong, please try after sometime.', { className: 'toast-error' });
        }
    }, []);

    return (
        <div className="root">
            <Col xs={12} className="top__fashion">
                <div className="section-one">
                    <div className="tw-place-content-center">
                        <p>Fashion is loading...</p>
                    </div>
                    <div className=" tw-h-full  image_container_fashion  tw-relative">
                        <div className="bg-image-blur">
                            <LazyLoadedImage
                                className="blurred-image"
                                src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/landingPages/ipad.png"
                                alt="fashion"
                            />
                            <LazyLoadedImage
                                className="gif-overlay"
                                src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/static-site-images/landingPages/ring.gif"
                                alt="fashion-fig"
                            />
                        </div>
                    </div>
                </div>
                {!isMobile && renderImages()}
            </Col>
            <Col xs={12} className="middle__fashion">
                <LazyLoadedDiv className="bg-image" />
                {isMobile && renderImages()}
                <div className="tw-flex  tw-gap-1 tw-items-center tw-justify-between top__fashion-seacond">
                    <div className="tw-flex tw-flex-col tw-gap-4  first_half">
                        <div className="tw-flex tw-flex-col tw-gap-4">
                            <div className="head">Infinitely Custom</div>
                            <div className="content">Thousands of options will be available in the Lashbrook Builder at launch. As always, you’ll also be able to create fully custom pieces one-on-one with your customers.</div>
                        </div>
                        <div className="tw-flex tw-flex-col tw-gap-4">
                            <div className="head">Handcrafted with Care</div>
                            <div className="content">
                                    Just like our rings, each piece is handcrafted, one at a time, using authentic and ethical materials.
                            </div>
                        </div>
                        <div className="tw-flex tw-flex-col tw-gap-4">
                            <div className="head">Free for our Partners</div>
                            <div className="content">
                                    If you’re already a Lashbrook partner store you’ll instantly have access to our entire fashion line. The only cost to you would be adding a display of physical samples to your case.
                            </div>
                        </div>
                    </div>
                    <div className="seacond_half ">
                        <span>
                           Always the
                        </span>
                        <span className="tw-ml-20">
                           Lashbrook Way
                        </span>
                    </div>
                </div>

            </Col>
            <Col xs={12} className="bottom__fashion">
                <div className="tw-flex tw-items-center tw-h-full tw-flex-col tw-gap-4">
                    <h5>We’d Love Your Feedback</h5>
                    <h2>Sign up and receive early access as we develop our fashion line. We’d love to collaborate and make sure our line meets the needs of you and your customers.</h2>
                    <form onSubmit={handleSubmit(submitForm)}>

                        <div className="tw-flex tw-justify-center tw-gap-6 tw-flex-col form">
                            <div className="tw-flex tw-flex-col tw-gap-1">
                                <input
                                    type="email"
                                    id="email"
                                    ref={register}
                                    required
                                    name="email"
                                    placeholder="Email"
                                    disabled={loading}
                                    className="disabled:tw-bg-gray-300 disabled:tw-cursor-not-allowed"
                                />
                            </div>
                            <button disabled={loading} className="btn btn-primary  tw-bg-blue-1800   disabled:tw-bg-gray-300 disabled:tw-cursor-not-allowed hover:tw-text-white hover:tw-bg-light-yellow" type="submit">Sign Up</button>
                        </div>
                    </form>
                </div>
            </Col>
        </div>
    );
};

export default Fashion;
