import LocalStorage from '../../utils/LocalStorage';
import { GET_CURRENT_CURRENCY, GET_CURRENCY_CONVERSION, SET_CURRENT_CURRENCY } from './constants';

const initialData = {
    isFetching: false,
    currentCurrencyCode: LocalStorage.get('currentCurrencyCode') || 'USD',
    currencyConversion: null,
    /**
     *
     * The getCurrentCurrency code call has been commented as we are focusing only on the USD and not on UK any more
     * In case, if we want to go for multiple currency handling, we can uncomment this call.
     *
     */
};

const globalReducer = (state = initialData, { payload, type }) => {
    switch (type) {
        case GET_CURRENT_CURRENCY.request:
            return {
                ...state,
                isFetching: true,
            };
        case GET_CURRENT_CURRENCY.success:
            return {
                ...state,
                isFetching: false,
                currentCurrencyCode: payload.currencies[0],
            };
        case GET_CURRENT_CURRENCY.failure:
            return {
                ...state,
                isFetching: false,
                currentCurrencyCode: {},
            };

        case SET_CURRENT_CURRENCY:
            LocalStorage.add('currentCurrencyCode', payload);
            return {
                ...state,
                currentCurrencyCode: payload,
            };

        case GET_CURRENCY_CONVERSION.request:
            return {
                ...state,
                isFetching: true,
            };
        case GET_CURRENCY_CONVERSION.success:
            return {
                ...state,
                isFetching: false,
                currencyConversion: payload,
            };
        case GET_CURRENCY_CONVERSION.failure:
            return {
                ...state,
                isFetching: false,
                currencyConversion: null,
            };

        default:
            return state;
    }
};

export default globalReducer;
