import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { ISwatchContent } from '../../types/customizer';
import SwatchCategory from '../SwatchCategory';

export interface ISwatchFilterProps  {
  content: ISwatchContent;
  onChange: (updated: any) => void;
  innerTab?: number;
  isFetching: boolean;
}

const SwatchFilter = ({ content, onChange, isFetching, innerTab = 1 }:ISwatchFilterProps) => {

    const { options, hasSwatch, fieldType, selectedOption } = content;
    const [isChanging, setIsChanging] = useState('');

    useEffect(() => {
        if (!isFetching) {
            setIsChanging('');
        }
    }, [isFetching]);

    const handleChange = (updatedObj: {[name: string]: string}) => {
        setIsChanging(Object.values(updatedObj)[0]); // used for deseclecting old one till response recieved
        onChange(updatedObj);
    };

    return (
        <div className={clsx('tw-flex tw-justify-center tw-pb-2 tw-h-full', { 'swatch-filter-border': innerTab === 1 })}>
            <div className="tw-w-full sm:tw-w-full tw-px-4">
                <div className="tw-overflow-y-auto tw-overflow-x-hidden tw-h-full tw-pb-2">
                    {/* Pass default values for those fields  ( type / name / swatch_url / index )  */}
                    <SwatchCategory
                        name=""
                        category_desc={null}

                        options={options}
                        hasSwatch={hasSwatch}
                        fieldType={fieldType}
                        selectedOption={selectedOption}

                        onChange={handleChange}
                        isChanging={isChanging}
                        rootCategory
                    />
                </div>
            </div>
        </div>
    );
};

export default SwatchFilter;
