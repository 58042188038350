import React, { useEffect, useState } from 'react';
import { IOption, ISwatchContent } from '../../types/customizer';
import SwatchCategory from '../SwatchCategory';
import SwatchOption from '../SwatchOption';
export interface INoneFilterProps {
  content: ISwatchContent;
  onChange: (updated: any) => void;
  innerTab?: number;
  isFetching: boolean;
 }

const NoneTabFilter = ({ content, onChange, isFetching, innerTab = 1 }:INoneFilterProps) => {
    
    const options: Array<IOption> = content.options as Array<IOption>;
    const { hasSwatch, fieldType, selectedOption } = content;

    const [isChanging, setIsChanging] = useState('');

    useEffect(() => {
        if (!isFetching) {
            setIsChanging('');
        }
    }, [isFetching]);

    const handleChange = (updatedObj: {[name: string]: string}) => {
        setIsChanging(Object.values(updatedObj)[0]); // used for deseclecting old one till response recieved
        onChange(updatedObj);
    };

    return (
        <div className="tw-flex tw-flex-col tw-pb-2 tw-items-center tw-h-full swatch-filter-border">
            <div className="lg:tw-pt-8 xs:tw-pt-2 sm:tw-pt-4 md: tw-pt-6 tw-text-center lg:tw-text-base md:tw-text-lg xs:tw-text-base tw-w-4/6">
                <p>Add a feature to the outside of your ring</p>
            </div>
            <div className="tw-flex tw-flex-wrap tw-justify-center tw-overflow-auto">
                {options.map((option: IOption, index: number) => (
                    <SwatchOption 
                        key={index}
                        {...option}
                        hasSwatch={hasSwatch}
                        fieldType={fieldType}
                        onChange={handleChange}
                        selectedOption={selectedOption}
                        isChanging={isChanging}
                    />
                ))}
            </div>
            {/* <img src={imageUrl} alt="current_ring" className='tw-m-1 tw-rounded-full sm:tw-h-22.5 sm:tw-w-22.5 xs:tw-h-16 xs:tw-w-16'></img> */}
        </div>
    );
};

export default NoneTabFilter;
