/* eslint-disable no-case-declarations */
import {
    GET_CART_DETAIL_ACTION_TYPES,
    CREATE_ORDER_ACTION_TYPES,
    RESET_PRODUCT_DATA,
    UPDATE_PRODUCT_DETAIL_ACTION_TYPES,
    REMOVE_PRODUCT_ACTION_TYPES,
    REMOVE_ADDON_ACTION_TYPES,
    UPDATE_CART_ACTION_TYPES,
    CLEAR_CART_ACTION_TYPES,
    GET_PARTNERS_ACTION_TYPES,
} from './constants';
import CartManager from '../../utils/CartManager';

const initialData = {
    isFetching: false,
    orderData: {},
    products: [],
    cartSummary: null,
    additional: {},
    partners: [],
};

export default function cartReducer(state = initialData, { payload, ...action }) {
    switch (action.type) {
        case GET_CART_DETAIL_ACTION_TYPES.request:
        case CLEAR_CART_ACTION_TYPES.request:
        case UPDATE_CART_ACTION_TYPES.request:
        case REMOVE_PRODUCT_ACTION_TYPES.request:
        case REMOVE_ADDON_ACTION_TYPES.request:
        case UPDATE_PRODUCT_DETAIL_ACTION_TYPES.request:
        case CREATE_ORDER_ACTION_TYPES.request:
            return {
                ...state,
                isFetching: true,
            };
        case GET_CART_DETAIL_ACTION_TYPES.success:
            if (payload.products.length == 0) {
                CartManager.remove();
            }
            return {
                ...state,
                isFetching: false,
                products: payload.products,
                cartSummary: payload.summary,
                additional: payload.additional,
                orderData: payload.orderData,
            };
        case GET_CART_DETAIL_ACTION_TYPES.failure:
        case CLEAR_CART_ACTION_TYPES.failure:
        case CLEAR_CART_ACTION_TYPES.success:
        case UPDATE_CART_ACTION_TYPES.failure:
        case UPDATE_CART_ACTION_TYPES.success:
        case REMOVE_PRODUCT_ACTION_TYPES.failure:
        case REMOVE_PRODUCT_ACTION_TYPES.success:
        case REMOVE_ADDON_ACTION_TYPES.failure:
        case REMOVE_ADDON_ACTION_TYPES.success:
        case UPDATE_PRODUCT_DETAIL_ACTION_TYPES.failure:
        case UPDATE_PRODUCT_DETAIL_ACTION_TYPES.success:
        case CREATE_ORDER_ACTION_TYPES.success:
            return {
                ...state,
                isFetching: false,
                orderData: payload,
            };
        case CREATE_ORDER_ACTION_TYPES.failure:
            return {
                ...state,
                isFetching: false,
            };
        case GET_PARTNERS_ACTION_TYPES.success:
            const partners = payload.data.filter(item => (item.partnerId && item.partnerName));
            return {
                ...state,
                partners,
            };
        case GET_PARTNERS_ACTION_TYPES.failure:
            return {
                ...state,
                partners: [],
            };
        case RESET_PRODUCT_DATA:
            return {
                ...state,
                ...initialData,
            };
        default:
            return state;
    }
}
