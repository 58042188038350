import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import config from '../../config';

const RingSizing = () => (
    <Container className="mb-5">
        <Row>
            <Col xs={12} className="text-center mt-5 d-flex align-items-center flex-column">
                <img
                    src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/wp-content/uploads/2016/03/25x25-mini-icon-classics.png"
                    alt="size-icon"
                />
                <h1 className="font-syncopate text-uppercase mt-3 mb-3">Ring Sizing</h1>
                <p className="font-universe byline">
                    When it comes to wedding and engagement rings, one thing is for sure: size matters.
                </p>
                <hr className="content-hr" color="#dcc67d" />
            </Col>
            <Col xs={12} className="size-video-wrapper mx-auto my-4">
                <ReactPlayer url="https://youtu.be/Ny7CqX7ZSGw" controls height="100%" width="100%" />
            </Col>
            <Col xs={12} className="color-grey fs-24 font-universe">
                <p>
                    It is your responsibility to be certain of size before completing your order. Once you have
                    determined your ring size, we recommend wearing the Lashbrook ring sizer on your finger for 10-15
                    minutes to help determine how comfortable the size choice really is.
                </p>
                <p>
                    We do not recommend the use of online printable ring sizers. You may visit a reputable jeweler for
                    sizing help, but please note that sizing will vary from jeweler to jeweler. The safest option is to
                    order a set of our sizers to determine your ring size – and it is free with purchase!
                </p>
                <p className="header-italic">Sizing Instructions</p>
                <p className="text-italic color-grey">
                    *Please note: If you are not used to wearing a ring, then it is likely everything will feel too
                    tight to start. It is helpful to remind yourself that you won’t feel the ring on your finger after a
                    few weeks of wearing it.
                </p>
                <p>
                    Many people make the mistake of thinking that the ring should easily slip over the knuckle. Remember
                    that the ring does not sit on the knuckle, rather it sits below it, so sizing a ring to simply slip
                    over the knuckle will cause it to be too loose. If it is too loose, it could possibly catch on
                    something or even fall off and get lost. We ask that you size your ring using the “Bend and Twist”
                    method.
                </p>
            </Col>
            <Col xs={12} className="color-grey fs-24 font-universe">
                <p className="header-italic">First, Second, Third</p>
                <div className="d-flex flex-column flex-md-row align-items-center">
                    <img
                        src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/wp-content/uploads/2019/02/EmoryDay_MensRings_IconSet-001.png"
                        alt="step-1"
                        className="size-step-image"
                    />
                    <p className="ml-md-5">First, Place a Lashbrook ring sizer over your knuckle</p>
                </div>
                <div className="d-flex flex-column flex-md-row align-items-center">
                    <img
                        src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/wp-content/uploads/2019/02/EmoryDay_MensRings_IconSet-002.png"
                        alt="step-2"
                        className="size-step-image"
                    />
                    <p className="ml-md-5">Second, Bend your knuckle slightly</p>
                </div>
                <div className="d-flex flex-column flex-md-row align-items-center">
                    <img
                        src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/wp-content/uploads/2019/02/EmoryDay_MensRings_IconSet-003.png"
                        alt="step-3"
                        className="size-step-image"
                    />
                    <p className="ml-md-5">Third, Twist the ring off your finger</p>
                </div>
                <p className="text-italic color-grey">
                    If you try to take the ring off by pulling straight back over the knuckle without twisting it, the
                    ring will catch the skin and not give an accurate size. You’ll find the right ring size for your
                    ring if you feel a bit of “resistance” in taking off the ring.
                </p>
            </Col>
            <Col xs={12} className="color-grey fs-24 font-universe">
                <p className="header-italic">Other Considerations</p>
                <div className="d-flex justify-content-between flex-column flex-md-row align-items-center">
                    <img
                        src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/wp-content/uploads/2019/02/EmoryDay_MensRings_IconSet-004.png"
                        alt="consideration-1"
                        width="5%"
                    />
                    <p className="text-container-width-90">
                        <em>Keep temperature in mind.</em> Fingers can fluctuate throughout the year, swelling during
                        the hot and humid summer and shrinking during the cold and dry winter. If your fingers are
                        slightly swollen or cold, make sure you bring your hands to room temperature before trying on
                        the sizers.
                    </p>
                </div>
                <div className="d-flex justify-content-between flex-column flex-md-row align-items-center">
                    <img
                        src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/wp-content/uploads/2019/02/EmoryDay_MensRings_IconSet-005.png"
                        alt="consideration-2"
                        width="5%"
                    />
                    <p className="text-container-width-90">
                        <em>Take diet into account.</em> Alcohol consumption and salt intake can also cause temporary
                        fluctuations in fingers. Many people will even notice a difference in the way their rings fit
                        after eating a particularly salty meal.
                    </p>
                </div>
                <div className="d-flex justify-content-between flex-column flex-md-row align-items-center">
                    <img
                        src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/wp-content/uploads/2019/02/EmoryDay_MensRings_IconSet-006.png"
                        alt="consideration-3"
                        width="8%"
                    />
                    <p className="text-container-width-90">
                        <em>Consider the width of the band.</em> Very wide rings can be less comfortable to wear because
                        they take up more space on the finger. The ring “size” is the same, but it feels tighter because
                        the ring is wider.
                    </p>
                </div>
            </Col>
            <Col xs={12} className="color-grey fs-24 font-universe mt-4">
                <p className="header-italic">Lashbrook Sizers</p>
                <p className="text-italic color-grey">Free with Ring Purchase</p>
                <p>
                    The Lashbrook Sizers are automatically sent to you – free of charge – with ring purchase. Simply
                    choose the ring size you think you need when designing your ring on the{' '}
                    <Link to={config.buildYourOwnProductUrl} className="gold-links">
                        {' '}
                        Lashbrook Builder
                    </Link>, and when ready to order click “Add to Cart”. We will send you sizers for the size you chose
                    plus two sizes up and two sizes down (because we want you to love the way your ring fits!). After
                    your order is placed the sizers will be shipped out the following business day. Once you receive the
                    sizers, email us at
                    <a className="gold-links" href="mailto: website@lashbrookdesigns.com">
                        {' '}
                        website@lashbrookdesigns.com{' '}
                    </a>
                    with your order confirmation number and the size you need and we will start making your ring. We
                    will not make your ring until you confirm your ring size.
                </p>
            </Col>
            <Col xs={12} className="color-grey fs-24 font-universe mt-4">
                <p className="header-italic">Size Exchanges</p>
                <p>Sizing refers to needing a new ring size for any reason. To be eligible for a size exchange of any kind, the Lashbrook sizers must be used to size your finger as part of the purchase process.</p>
                <p>Despite the best of intentions, sometimes you just need to get a new size. We’ve got that taken care of!</p>
                <p>Rings purchased through our retailers are available for resizing through that retailer. Rings purchased directly from Lashbrook receive one complimentary size adjustment with our <Link to="/shop/content/protection-plan" className="gold-links">
                    {' '}
                Protection Plan
                </Link>. Future sizing will cost the same as without the plan- 30% of MSRP. To be eligible for size exchanges, the original ring must be returned.</p>
            </Col>
        </Row>
    </Container>
);

export default RingSizing;
