import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import Loader from '../Shared/Loader';
import Autocomplete from '../Shared/Autocomplete/Autocomplete';
import AccountPerformanceSummary from './AccountPerformanceSummary';
import PerformanceByAccount from './PerformanceByAccount';
import StoreDistribution from './StoreDistribution';
import AccountGrowth from './AccountGrowth';
import StockSalesSummary from './StockSalesSummary';
import './sales-dashboard.css';

const ALL_REP_OPTION = { label: 'All', value: 'All' };
const TWENTY_FOUR_HOURS = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

const SalesDashboard = ({
    isFetching,
    accountInfo,
    getAccountInfo,
    getAllTransactionsData,
    allTransactionsData,
    isFetchingAllTransactionsData,
    stockSalesSummary,
    isFetchingStockSalesSummary,
    getStockSalesSummary,
}) => {
    const [selectedSalesRep, setSelectedSalesRep] = useState(ALL_REP_OPTION);
    const {
        data: transactionsData,
        timestamp: transactionsTimestamp
    } = allTransactionsData || {};

    const {
        data: stockSales,
        timestamp: stockSalesTimestamp
    } = stockSalesSummary || {};

    const stockSalesData = stockSales?.stockSalesSummary || {};

    const {
        summaries,
        performanceByAccount,
        storeDistribution,
        accountGrowth,
        salesReps
    } = transactionsData || {};

    const summary = summaries?.[selectedSalesRep?.value || 'All'];
    const storeDistributionData = storeDistribution?.[selectedSalesRep?.value || 'All'];
    const stockSalesDataFiltered = selectedSalesRep?.value && selectedSalesRep?.value !== 'All' ? [stockSalesData?.[selectedSalesRep?.value]] : Object.keys(stockSalesData || {}).map(rep => stockSalesData[rep]);
 
    useEffect(() => {
        getAccountInfo();
    }, []);

    useEffect(() => {
        const currentTime = Date.now();
        if (accountInfo?.show_sales_dashboard && !isFetchingAllTransactionsData && (!transactionsData || !transactionsTimestamp || (currentTime - transactionsTimestamp > TWENTY_FOUR_HOURS))) {
            getAllTransactionsData();
        }
    }, [accountInfo?.show_sales_dashboard, transactionsData, transactionsTimestamp, isFetchingAllTransactionsData]);

    useEffect(() => {
        const currentTime = Date.now();
        if (accountInfo?.show_sales_dashboard && !isFetchingStockSalesSummary && (!stockSalesData || !stockSalesTimestamp || (currentTime - stockSalesTimestamp > TWENTY_FOUR_HOURS))) {
            getStockSalesSummary();
        }
    }, [accountInfo?.show_sales_dashboard, stockSalesData, stockSalesTimestamp, isFetchingStockSalesSummary]);

    const handleSalesRepChange = (selectedOption) => {
        setSelectedSalesRep(selectedOption || ALL_REP_OPTION);
    };

    const isLoading = useMemo(() => isFetching || isFetchingAllTransactionsData || isFetchingStockSalesSummary, [isFetching, isFetchingAllTransactionsData, isFetchingStockSalesSummary]);

    const accountGrowthData = useMemo(() => {
        if (selectedSalesRep?.value === 'All') {
            return accountGrowth;
        }
        return accountGrowth?.filter(item => item.salesRep === selectedSalesRep?.value);
    }, [accountGrowth, selectedSalesRep]);

    if (accountInfo && accountInfo?.show_sales_dashboard === false) {
        return <Redirect to={'/shop/customer/account'} />;
    }

    return (
        <Container className="mx-xl-1 sales-dashboard mb-4">
            <div className="d-flex justify-content-between">
                <h5>Sales Dashboard</h5>
                <div className="d-flex align-items-center">
                    <Autocomplete
                        placeholder="Select Sales Rep..."
                        options={[ALL_REP_OPTION, ...(salesReps?.map(rep => ({ label: rep, value: rep })) || [])]}
                        selectedValue={selectedSalesRep?.value}
                        onChange={handleSalesRepChange}
                        valueAttribute="value"
                        labelAttribute="label"
                        className="min-w-15-rem"
                    />
                </div>
            </div>
            <AccountPerformanceSummary data={summary} />
            <PerformanceByAccount data={performanceByAccount} selectedSalesRep={selectedSalesRep?.value} />
            <StoreDistribution data={storeDistributionData} />
            <AccountGrowth data={accountGrowthData} />
            <StockSalesSummary data={stockSalesDataFiltered} />
            <Loader timedOut={isLoading} />
        </Container>
    );
};

SalesDashboard.propTypes = {
    isFetching: PropTypes.bool,
    accountInfo: PropTypes.object,
    getAccountInfo: PropTypes.func,
    getAllTransactionsData: PropTypes.func,
    allTransactionsData: PropTypes.object,
    isFetchingAllTransactionsData: PropTypes.bool,
    stockSalesSummary: PropTypes.object,
    isFetchingStockSalesSummary: PropTypes.bool,
    getStockSalesSummary: PropTypes.func,
};

export default SalesDashboard;