import React from 'react';
import ClarityDiamonds from '../../../../assets/images/Clarity-diamonds.png';
import ColorDiamonds from '../../../../assets/images/Color-diamonds.png';

const DiamondInfo = () => (
    <div className="tw-text-blue-900 tw-px-6">
        <div className="tw-max-h-[550px] tw-overflow-auto">
            <div className="tw-py-4">
                <div className="tw-text-lg tw-mb-4 primary-font-family tw-font-bold">Premium Vs. Standard Diamonds</div>
                <p className="tw-text-gray-700 tw-text-sm tw-leading-6">
                    <span className="tw-font-bold primary-font-family tw-text-base">Premium VS - </span>Very Slightly Included stone with an FG-color rating, indicating high clarity and a near-colorless appearance.
                </p>
                <p className="tw-text-gray-700 tw-text-sm tw-leading-6 tw-mt-3">
                    <span className="tw-font-bold primary-font-family tw-text-base">Industry standard SI - </span> Included stone with a GH-color rating, a common grade found in many retail jewelry stores.
                </p>
            </div>
            <div className="pb-2">
                <div className="tw-text-base tw-mb-4 primary-font-family tw-font-bold">Clarity</div>
                <img src={ClarityDiamonds} alt="clarity-diamonds" />
            </div>
            <div className="pb-2">
                <div className="tw-text-base tw-mb-4 primary-font-family tw-font-bold">Color</div>
                <img src={ColorDiamonds} alt="clarity-diamonds" />
            </div>
        </div>
    </div>
);

export default DiamondInfo;
