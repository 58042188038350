import 'react-app-polyfill/stable';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import ReactResizeDetector from 'react-resize-detector';
import CookieConsent from 'react-cookie-consent';
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.css';
import 'react-rangeslider/lib/index.css';
import 'rc-tabs/assets/index.css';
import './assets/css/style.css';
import './assets/css/rc-tab.override.css';
import '../node_modules/swiper/swiper.min.css';
import '../node_modules/swiper/modules/navigation/navigation.min.css';
import '../node_modules/swiper/modules/pagination/pagination.min.css';

import configureStore, { history } from './store';
import App from './routes';
import config from './config';

if (process.env.NODE_ENV === 'development') {
    require('./assets/tailwind/output.css');
} else if (process.env.NODE_ENV === 'production') {
    require('./assets/tailwind/output.css'); // Due to react scripts low version, tailwind not compile properly
}

const reportSize = (width, height) => {
    // console.log('height:', height);
    const message = { action: 'resize', arguments: { rootHeight: height } };
    window.parent.postMessage(message, '*');
    console.log('message:', message);
};

ReactDOM.render(
    <Provider store={configureStore()}>
        <div>
            <ReactResizeDetector handleHeight onResize={reportSize}>
                <BrowserRouter history={history}>
                    <App key="app" />
                </BrowserRouter>
                <ToastContainer
                    key="toast"
                    className="toast-container"
                    bodyClassName="toast-container-body"
                    position="top-right"
                    type="default"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    pauseOnHover
                />
            </ReactResizeDetector>
            { config.enableCookie &&
                <CookieConsent
                    expires={150}
                    buttonText="Got it."
                    style={{ background: 'rgba(102,131,152,0.9)', textAlign: 'center', fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"', fontSize: '.7em' }}
                    buttonStyle={{ backgroundColor: '#fff', color: 'rgba(102,131,152)', fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"', fontSize: '.9em', width: '250px', left: '50px' }}
                >
                    We use cookies to provide a better browsing experience. If you continue to use this site, you consent to our use of cookies. Learn More at <a style={{ color: '#ffffff' }} href="/lashbrook-terms-conditions">Terms &amp; Conditions</a>.
                </CookieConsent>
            }
        </div>
    </Provider>,
    document.getElementById('root')
);

