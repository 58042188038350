import React, { useState, useEffect } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import './styles.css';

const Autocomplete = ({ options, selectedValue, valueAttribute, labelAttribute, labelSecondAttribute, onChange, placeholder, className }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [userInput, setUserInput] = useState('');

    useEffect(() => {
        if (selectedValue) {
            const selectedOption = options.find(option => option[valueAttribute] === selectedValue);
            if (selectedOption) {
                setUserInput(selectedOption[labelAttribute]);
            }
        }
    }, [selectedValue, options, valueAttribute, labelAttribute]);

    const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

    const handleInputChange = (e) => {
        const userInput = e.currentTarget.value;
        const filteredOptions = options.filter(
            option => {
                if (labelSecondAttribute) {
                    return option[labelAttribute].toLowerCase().includes(userInput.toLowerCase()) || (option[labelSecondAttribute] && option[labelSecondAttribute].toLowerCase().includes(userInput.toLowerCase()));
                } else {
                    return option[labelAttribute].toLowerCase().includes(userInput.toLowerCase());
                }
            }
        );

        setUserInput(e.currentTarget.value);
        setFilteredOptions(filteredOptions);
        setDropdownOpen(true);
    };

    const handleClick = (option) => {
        setUserInput(option[labelAttribute]);
        setFilteredOptions([]);
        setDropdownOpen(false);
        onChange(option);
    };

    const clearInput = () => {
        setUserInput('');
        setFilteredOptions([]);
        onChange(null);
    };

    return (
        <div className={`form-group position-relative autocomplete mb-0 ${className}`}>
            <input
                type="text"
                className="form-control"
                onChange={handleInputChange}
                value={userInput}
                placeholder={placeholder || 'Search...'}
            />
            {userInput && <i
                className="fa fa-times icon-close position-absolute"
                onClick={clearInput}
                role="Button"
            />}
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} className="w-100">
                <DropdownToggle
                    data-toggle="dropdown"
                    tag="span"
                >
                </DropdownToggle>
                <DropdownMenu className="w-100 autocomplete-list">
                    {filteredOptions.length ? (
                        filteredOptions.map((option, index) => (
                            <div
                                className="dropdown-item autocomplete-list-item font-open-sans-condensed font-16 cursor-pointer"
                                key={index}
                                onClick={() => handleClick(option)}
                            >
                                {`${option[labelAttribute]}${option[labelSecondAttribute] ? ` - (${option[labelSecondAttribute]})` : ''}`}
                            </div>
                        ))
                    ) : (
                        <div className="dropdown-item font-open-sans-condensed font-16">No options</div>
                    )}
                </DropdownMenu>
            </Dropdown>
        </div>
    );
};

export default Autocomplete;
