import {
    EMAIL_PORTAL,
    GET_CURRENT_PLAN_DETAILS,
    GET_BILLING_HISTORY,
    GET_PAYMENT_METHODS,
    GET_CUSTOMER_DETAILS,
    DELETE_PAYMENT_METHOD,
    MAKE_DEFAULT_PAYMENT_METHOD,
    ADD_PAYMENT_METHODS,
} from './constants';

const initialData = {
    isFetching: false,
    currentPlans: {},
    paymentMethods: {},
    billingHistory: {},
    customerDetails: {},
};

export default function subscriptionReducer(state = initialData, { payload, ...action }) {
    switch (action.type) {
        case EMAIL_PORTAL.request:
        case GET_CURRENT_PLAN_DETAILS.request:
        case GET_BILLING_HISTORY.request:
        case GET_PAYMENT_METHODS.request:
        case GET_CUSTOMER_DETAILS.request:
        case DELETE_PAYMENT_METHOD.request:
        case MAKE_DEFAULT_PAYMENT_METHOD.request:
        case ADD_PAYMENT_METHODS.request:
            return {
                ...state,
                isFetching: true,
            };

        case EMAIL_PORTAL.success:
        case EMAIL_PORTAL.failure:
            return {
                ...state,
                isFetching: false,
            };
        case GET_CURRENT_PLAN_DETAILS.success:
        case GET_CURRENT_PLAN_DETAILS.failure:
            return {
                ...state,
                isFetching: false,
                currentPlans: payload,
            };

        case GET_BILLING_HISTORY.success:
        case GET_BILLING_HISTORY.failure:
            return {
                ...state,
                isFetching: false,
                billingHistory: payload,
            };

        case GET_PAYMENT_METHODS.success:
        case GET_PAYMENT_METHODS.failure:
            return {
                ...state,
                isFetching: false,
                paymentMethods: payload,
            };

        case GET_CUSTOMER_DETAILS.success:
        case GET_CUSTOMER_DETAILS.failure:
            return {
                ...state,
                isFetching: false,
                customerDetails: payload,
            };

        case DELETE_PAYMENT_METHOD.success:
        case DELETE_PAYMENT_METHOD.failure:
        case MAKE_DEFAULT_PAYMENT_METHOD.success:
        case MAKE_DEFAULT_PAYMENT_METHOD.failure:
        case ADD_PAYMENT_METHODS.success:
        case ADD_PAYMENT_METHODS.failure:
            return {
                ...state,
                isFetching: false,
            };
        default:
            return state;
    }
}
