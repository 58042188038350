import { apiActionTypes } from '../../middleware';
const base = '@@Containers/StaticProductContainer/';

export const GET_PRODUCT_DETAIL_ACTION_TYPES = apiActionTypes(`${base}GET_PRODUCT_DETAIL`);
export const ADD_PRODUCT_TO_CART_ACTION_TYPES = apiActionTypes(`${base}ADD_PRODUCT_TO_CART`);
export const GET_PRODUCT_SUMMARY_ACTION_TYPES = apiActionTypes(`${base}GET_PRODUCT_SUMMARY`);
export const RESET_ADD_TO_CART = `${base}RESET_ADD_TO_CART`;
export const RESET_PRODUCT_DATA = `${base}RESET_PRODUCT_DATA`;
export const ADD_TO_FAVORITE_ACTION_TYPES = apiActionTypes(`${base}ADD_TO_FAVORITE`);
export const REMOVE_FROM_FAVORITE_ACTION_TYPES = apiActionTypes(`${base}REMOVE_FROM_FAVORITE`);
export const UPDATE_FAVORITES = `${base}UPDATE_FAVORITES`;
export const UPDATE_PRICE = `${base}UPDATE_PRICE`;
export const RESET_CHANGE_LOG = `${base}RESET_CHANGE_LOG`;
export const GET_RING_SIZE_ACTION_TYPES = apiActionTypes(`${base}GET_RING_SIZE_ACTION_TYPES`);

export const ringParams = [
    'ring_finish',
    'second_finish',
    'finish_type',
    'weight',
    'feature_group',
    'sleeve',
    'gemstones',
    'machine_pattern',
    'groove_style',
    'groove_group',
    'grooves',
    'size',
];

export const DEFAULT_RING_SIZE = 13;
