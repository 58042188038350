import {
    SEARCH_ACTION_TYPES,
    PAGINATED_SEARCH_ACTION_TYPES,
} from './constants';

const initialData = {
    isFetching: false,
    isFetchingPaginatedResults: false,
    searchResults: {},
};

export default function searchReducer(state = initialData, { payload, ...action }) {
    switch (action.type) {
        case SEARCH_ACTION_TYPES.request:
            return {
                ...state,
                isFetching: true,
                searchResults: {},
            };
        case PAGINATED_SEARCH_ACTION_TYPES.request:
            return {
                ...state,
                isFetchingPaginatedResults: true,
            };
        case SEARCH_ACTION_TYPES.success:
            return {
                ...state,
                isFetching: false,
                searchResults: payload,
            };
        case PAGINATED_SEARCH_ACTION_TYPES.success:
            return {
                ...state,
                isFetchingPaginatedResults: false,
                searchResults: {
                    ...state.searchResults,
                    actions: [
                        {
                            ...state.searchResults.actions[0],
                            results: [...state.searchResults.actions[0].results, ...payload.actions[0].results],
                        },
                    ],
                },
            };
        case PAGINATED_SEARCH_ACTION_TYPES.failure:
            return {
                ...state,
                isFetchingPaginatedResults: false,
                searchResults: {},
            };
        case SEARCH_ACTION_TYPES.failure:
            return {
                ...state,
                isFetching: false,
                searchResults: {},
            };
        default:
            return state;
    }
}
