import React from 'react';
import { Col, Container, Row } from 'reactstrap';

const Customization = () => (
    <Container className="mb-5 pb-5">
        <Row>
            <Col xs={12} className="text-center mt-5">
                <h1 className="font-syncopate text-uppercase mt-3 mb-3 fs-3rem smaller-header">RING CUSTOMIZATION</h1>
                <hr className="content-hr" color="#dcc67d" />
            </Col>
            <Col xs={12} md={8} className="color-grey font-universe fs-24 mt-5 pt-3">
                <p>
                    At Lashbrook Designs, we give each customer the freedom to create a ring of your own design. Using
                    our design program, you can select the different elements of your ring, and customize every detail.
                    Don’t settle for what someone else thinks is a great style. Instead, create a ring based on your
                    passion using unique materials, your design, cool engraving – everything. It’s your ring. Use our
                    design engine to design your unique ring.
                </p>
                <p>
                    If we don’t have what you are looking for, we can custom make your ring for you! Call our custom
                    department at (888) 252-7388 or email{' '}
                    <a href="mailto:custom@lashbrookdesigns.com" className="gold-links">
                        custom@lashbrookdesigns.com
                    </a>
                    to start a custom order.
                </p>
            </Col>
            <Col xs={12} md={4} className="color-grey font-universe fs-24 mt-5 pt-3">
                <img
                    aria-describedby="caption-attachment-2362"
                    loading="lazy"
                    className="wp-image-2362 size-medium"
                    title="creating mens wedding rings as unique and powerful as the man wearing it"
                    src="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/wp-content/uploads/2019/02/07083229/METEORITE-300x300.jpg"
                    alt="creating mens wedding rings as unique and powerful as the man wearing it"
                    width="300"
                    height="300"
                    srcSet="https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/wp-content/uploads/2019/02/07083229/METEORITE-300x300.jpg 300w, https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/wp-content/uploads/2019/02/07083229/METEORITE-150x150.jpg 150w, https://lashbrook-wp-assets.s3.us-west-2.amazonaws.com/wp-content/uploads/2019/02/07083229/METEORITE.jpg 600w"
                    sizes="(max-width: 300px) 100vw, 300px"
                />
                <p>
                    Titanium ring with meteorite inlay and a rose gold sleeve – Click through to try to customize this
                    to more of your own style.
                </p>
            </Col>
        </Row>
    </Container>
);

export default Customization;
