import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Container, Row, Form, FormGroup, Label, Col, Button } from 'reactstrap';
import Loader from '../Shared/Loader';
import './styles.css';
import AuthManager from '../../utils/AuthManager';

const CustomerDetails = ({ isFetching,
    next,
    previous,
    getCountries,
    getStates,
    countries,
    states,
    getAccountInfo,
    accountInfo,
    getAllAddresses,
    addresses,
    summaryHeight,
    customerCreationHandler }) => {
    const { register, handleSubmit, getValues } = useForm();
    const [buttonPosition, setPosition] = useState(0);
    let currentCountry;

    useEffect(() => {
        setPosition(summaryHeight + 30);
    }, [summaryHeight]);

    useEffect(() => {
        if (AuthManager.getGuestToken()) {
            getAccountInfo().then(() => {
                getAllAddresses()
                    .then(({ payload: { data } }) => {
                        data.map(item => {
                            currentCountry = item.country_code;
                            loadStates(currentCountry);
                        });
                    });
            });
        }
    }, []);

    useEffect(() => {
        async function fetchCountries() {
            await getCountries();
        }

        fetchCountries().catch(() => {
            toast.error('Error getting countries', { className: 'toast-error' });
        });
    }, []);

    const loadStates = async country => {
        const selectedCountry = typeof country === 'string' ? country : getValues().country_code;
        try {
            await getStates(selectedCountry);
        } catch (e) {
            toast.error('Unable to get States', { className: 'toast-error' });
        }
    };

    const submit = () => {
        const formValues = getValues();
        let data;
        const { first_name,
            last_name,
            street_address1,
            street_address2,
            city,
            zip,
            country_code,
            state,
            phone_number,
            address_type,
            ...rest } = formValues;
        data = {
            ...rest,
            first_name,
            last_name,
            address: [
                {
                    first_name,
                    last_name,
                    street_address1,
                    street_address2,
                    city,
                    zip,
                    country_code,
                    state,
                    phone_number,
                    address_type: 'both',
                },
            ],
        };

        customerCreationHandler(data)
            .catch(({ response: { data: { message } } }) => {
                toast.error(message, { className: 'toast-error' });
            });
    };

    const renderSavedShippingAddress = () => addresses &&
        addresses.data &&
        states &&
        states.data &&
        addresses.data.map(item => (
            <Row key={item.address_type}>
                <Col xs={12}>
                    <Label className="required" for="street_address1">
                            Street Address
                    </Label>
                    <input type="text" name="street_address1" id="street_address1" ref={register} defaultValue={item.street_address1} required />
                    <input type="text" name="street_address2" id="street_address2" ref={register} defaultValue={item.street_address2} />
                </Col>
                <Col xs={6}>
                    <Label className="required" for="city">
                            City
                    </Label>
                    <input type="text" name="city" id="city" placeholder="City" ref={register} defaultValue={item.city} pattern="[a-zA-Z][a-zA-Z ]+" required />
                </Col>
                <Col xs={6}>
                    <Label className="required" for="zip">
                            Zip/Postal Code
                    </Label>
                    <input
                        type="text"
                        name="zip"
                        id="zip"
                        placeholder="Zip/Postal Code"
                        ref={register}
                        defaultValue={item.zip}
                        pattern="^[a-zA-Z0-9]{1,10}$"
                        required
                    />
                </Col>
                <Col xs={6}>
                    <Label className="required" for="country_code">
                            Country
                    </Label>
                    <select
                        name="country_code"
                        id="country_code"
                        placeholder="Country"
                        ref={register}
                        defaultValue={item.country_code}
                        onChange={loadStates}
                        required
                    >
                        <option value="">--Country--</option>
                        {countries &&
                            countries.data &&
                            countries.data.map(country => (
                                <option key={country.name} value={country.code}>
                                    {country.name}
                                </option>
                            ))}
                    </select>
                </Col>
                <Col xs={6}>
                    <Label className="required" for="state">
                            State
                    </Label>
                    <select name="state" id="state" placeholder="State" ref={register} required defaultValue={item.state}>
                        <option value="">--State--</option>
                        {states &&
                            states.data &&
                            states.data.map(state => (
                                <option key={state.name} value={state.name}>
                                    {state.name}
                                </option>
                            ))}
                    </select>
                </Col>
                <Col xs={12}>
                    <Label className="required" for="phone_number">
                            Phone
                    </Label>
                    <input
                        type="text"
                        name="phone_number"
                        ref={register}
                        defaultValue={item.phone_number}
                        placeholder="Phone"
                        className="mb-2"
                        pattern="[0-9.]+"
                        required
                    />
                </Col>
            </Row>
        )
        );

    return (
        <Container className="customer-details d-flex flex-column justify-content-between font-universe">
            <Row className="flex-column">
                <Form
                    className="mt-4 d-flex flex flex-wrap flex-column flex-lg-row"
                    onSubmit={handleSubmit(submit)}
                >
                    <Col xs={12} lg={6} className="mx-auto d-flex flex-column mb-2">
                        <FormGroup>
                            <Label className="font-universe required" for="first_name">
                                First Name
                            </Label>
                            <input
                                type="text"
                                name="first_name"
                                ref={register}
                                defaultValue={accountInfo && accountInfo.first_name ? accountInfo.first_name : ''}
                                placeholder="First Name"
                                className="mb-2"
                                required
                            />
                            <Label className="font-universe required" for="last_name">
                                Last Name
                            </Label>
                            <input
                                type="text"
                                name="last_name"
                                ref={register}
                                defaultValue={accountInfo && accountInfo.last_name ? accountInfo.last_name : ''}
                                placeholder="Last Name"
                                className="mb-2"
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label className="font-universe required" for="email">
                                Email Address
                            </Label>
                            <input
                                type="email"
                                name="email"
                                ref={register}
                                placeholder="Email Address"
                                defaultValue={accountInfo && accountInfo.email ? accountInfo.email : ''}
                                className="mb-2"
                                required
                            />
                        </FormGroup>
                    </Col>
                    <Col xs={12} lg={6}>
                        {AuthManager.getGuestToken() ? renderSavedShippingAddress()
                            : <Row>
                                <Col xs={12}>
                                    <Label className="required" for="street_address1">
                                        Street Address
                                    </Label>
                                    <input type="text" name="street_address1" id="street_address1" ref={register} required />
                                    <input type="text" name="street_address2" id="street_address2" ref={register} />
                                </Col>
                                <Col xs={6}>
                                    <Label className="required" for="city">
                                        City
                                    </Label>
                                    <input type="text" name="city" id="city" placeholder="City" ref={register} pattern="[a-zA-Z][a-zA-Z ]+" required />
                                </Col>
                                <Col xs={6}>
                                    <Label className="required" for="zip">
                                        Zip/Postal Code
                                    </Label>
                                    <input
                                        type="text"
                                        name="zip"
                                        id="zip"
                                        placeholder="Zip/Postal Code"
                                        ref={register}
                                        pattern="^[a-zA-Z0-9]{1,10}$"
                                        required
                                    />
                                </Col>
                                <Col xs={6}>
                                    <Label className="required" for="country_code">
                                        Country
                                    </Label>
                                    <select
                                        name="country_code"
                                        id="country_code"
                                        placeholder="Country"
                                        ref={register}
                                        onChange={loadStates}
                                        required
                                    >
                                        <option value="">--Country--</option>
                                        {countries &&
                                        countries.data &&
                                        countries.data.map(country => (
                                            <option key={country.name} value={country.code}>
                                                {country.name}
                                            </option>
                                        ))}
                                    </select>
                                </Col>
                                <Col xs={6}>
                                    <Label className="required" for="state">
                                        State
                                    </Label>
                                    <select name="state" id="state" placeholder="State" ref={register} required>
                                        <option value="">--State--</option>
                                        {states &&
                                        states.data &&
                                        states.data.map(state => (
                                            <option key={state.name} value={state.name}>
                                                {state.name}
                                            </option>
                                        ))}
                                    </select>
                                </Col>
                                <Col xs={12}>
                                    <Label className="required" for="phone_number">
                                        Phone
                                    </Label>
                                    <input
                                        type="text"
                                        name="phone_number"
                                        ref={register}
                                        placeholder="Phone"
                                        className="mb-2"
                                        pattern="[0-9.]+"
                                        required
                                    />
                                </Col>
                            </Row>}
                    </Col>
                    <Col xs={12} className="d-flex flex-column align-items-center mt-3">
                        <Button className="submit-customer">Continue</Button>
                        <Button className="back-button text-capitalize" onClick={previous}>
                            <i className="fa fa-chevron-left mr-1" aria-hidden="true" />
                            Back
                        </Button>
                    </Col>
                    <Loader timedOut={isFetching} />
                </Form>
            </Row>
        </Container>
    );
};

CustomerDetails.propTypes = {
    isFetching: PropTypes.bool,
};

export default CustomerDetails;
