import { apiActionTypes } from '../../middleware';
const base = '@@Containers/ProductContainer/';

export const GET_PRODUCT_DETAIL_ACTION_TYPES = apiActionTypes(`${base}GET_PRODUCT_DETAIL`);
export const GET_PRODUCT_SUMMARY_ACTION_TYPES = apiActionTypes(`${base}GET_PRODUCT_SUMMARY`);
export const ADD_PRODUCT_TO_CART_ACTION_TYPES = apiActionTypes(`${base}ADD_PRODUCT_TO_CART`);
export const ADD_TO_FAVORITE_ACTION_TYPES = apiActionTypes(`${base}ADD_TO_FAVORITE`);
export const REMOVE_FROM_FAVORITE_ACTION_TYPES = apiActionTypes(`${base}REMOVE_FROM_FAVORITE`);
export const RESET_CHANGE_LOG = `${base}RESET_CHANGE_LOG`;
export const UPDATE_ENGRAVING = `${base}UPDATE_ENGRAVING`;
export const UPDATE_FAVORITES = `${base}UPDATE_FAVORITES`;
export const RESET_ADD_TO_CART = `${base}RESET_ADD_TO_CART`;
export const RESET_PRODUCT_DATA = `${base}RESET_PRODUCT_DATA`;


export const FILTER_NAME = {
    profile: 'Ring Profile',
    ring_material: 'Ring Material',
    weight: 'Ring Weight',
    width: 'Ring Width',
    size: 'Ring Size',
    ring_finish: 'Ring Finish',
    ring_finish_0: 'Ring Finish',
    ring_finish_1: 'Ring Finish',
    feature_group: 'Outside Feature',
    groove_style: 'Inlay Style',
    groove_group: 'Inlay Width',
    inlay_0: 'Inlay Material',
    inlay_1: 'Inlay Material',
    inlay_finish__0: 'Inlay Finish',
    inlay_finish__1: 'Inlay Finish',
    mga: 'Accent Color',
    machine_pattern: 'Design Pattern',
    machine_pattern_option: 'Accent Color',
    gemstone_arrangement: 'Gemstone Arrangement',
    gemstone_material: 'Gemstone Color',
    gemstone_cut: 'Gemstone Shape',
    gemstone_size: 'Gemstone Size',
    gemstone_setting: 'Gemstone Setting Style',
    sleeve: 'Inside Sleeve',
    engraving: 'Engraving',
};
