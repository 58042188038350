/* eslint-disable react/jsx-indent */
import React from 'react';
import { connect } from 'react-redux';


const Data = ({ data }: any) => {
    const renderPriceData = () => (
        <>
            <p className="tw-text-base tw-text-darkBlack tw-font-semibold primary-font-family tw-pb-4">Price Details:</p>
            <ul className="list-unstyled tw-mb-5 tw-ml-5">
                {data?.data?.price_data?.map((field: any) => (
                    <li key={field.label} className="tw-pb-2">
                        <span className="tw-capitalize tw-text-sm tw-font-bold tw-text-darkBlack">{field.label}: </span>
                        <span>${field.value}</span>
                    </li>
                ))}
            </ul>
        </>
    );
    const renderNetsuiteData = () => (
        <>
            <p className="tw-text-base tw-text-darkBlack tw-font-semibold primary-font-family tw-pb-4">Netsuite Order Data:</p>
            <ul className="list-unstyled tw-mb-5 tw-ml-5">
                {data?.data?.netsuite_order_data?.map((field: any) => (
                    <li key={field.label} className="tw-pb-2">
                        <span className="tw-capitalize tw-text-sm tw-font-bold tw-text-darkBlack" >{field.label}: </span>
                        {Array.isArray(field.value) ?
                            <ul className="list-unstyled tw-mt-3 tw-ml-3">{renderNestedValues(field.value)}</ul> :
                            <span className="text-break">{field.value}</span>}
                    </li>
                ))}
            </ul>
        </>
    );
    const renderWebOrderData = () => (
        <>
            <p className="tw-text-base tw-text-darkBlack tw-font-semibold primary-font-family tw-pb-4">Web Order Data:</p>
            <ul className="list-unstyled tw-mb-5 tw-ml-5">
                {data?.data?.web_order_data?.map((field: any) => (
                    <li key={field.label} className="tw-pb-2">
                        <span className="tw-capitalize tw-text-sm tw-font-bold tw-text-darkBlack">{field.label}: </span>
                        {Array.isArray(field.value) ?
                            <ul className="list-unstyled tw-mt-3 tw-ml-3">{renderNestedValues(field.value)} </ul> :
                            <span className="text-break">{field.value}</span>}
                    </li>
                ))}
            </ul>
        </>
    );
    const renderWeightData = () => data?.data?.weight_data?.length ? (
        <>
            <p className="tw-text-base tw-text-darkBlack tw-font-semibold primary-font-family tw-pb-4">Weight Data:</p>
            <ul className="list-unstyled tw-mb-5 tw-ml-5">
                {data?.data?.weight_data?.map((field: any) => (
                    <li key={field.label} className="tw-pb-2">
                        <span className="tw-capitalize tw-text-sm tw-font-bold tw-text-darkBlack">{field.label}: </span>
                        {Array.isArray(field.value) ?
                            <ul className="list-unstyled tw-mt-3 tw-ml-3">{renderNestedValues(field.value)} </ul> :
                            <span>{field.value}</span>}
                    </li>
                ))}
            </ul>
        </>
    ) : null;
    const renderNestedValues = (nestedData: any) => nestedData?.map((field :any) => (
        <li key={field.label} className="tw-pb-2">
            <span className="tw-capitalize tw-text-sm tw-font-bold tw-text-darkBlack">{field.label}: </span>
            <span className="text-break">{field.value}</span>
        </li>
    ));

    return (
        <div className="tw-p-8 tw-h-full tw-overflow-y-auto swatch-filter-border">
            {renderPriceData()}
            {renderWeightData()}
            {renderNetsuiteData()}
            {renderWebOrderData()}
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    data: state.customize.product,
});

export default connect(mapStateToProps, null)(Data);
