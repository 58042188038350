import { apiActionTypes } from '../../middleware';
const base = '@@Containers/StripeContainer/';

export const GET_CART_DETAIL_ACTION_TYPES = apiActionTypes(`${base}GET_CART_DETAIL`);
export const GET_ORDER_DETAIL_ACTION_TYPES = apiActionTypes(`${base}GET_ORDER_DETAIL_ACTION_TYPES`);
export const CREATE_ORDER_ACTION_TYPES = apiActionTypes(`${base}CREATE_ORDER`);
export const GET_SHIPPING_DETAILS_ACTION_TYPES = apiActionTypes(`${base}GET_SHIPPING_DETAILS`);
export const GET_COUNTRIES = apiActionTypes(`${base}GET_COUNTRIES`);
export const GET_STATES = apiActionTypes(`${base}GET_STATES`);
export const CREATE_CUSTOMER_ACTION_TYPES = apiActionTypes(`${base}CREATE_CUSTOMER`);
export const GET_ALL_ADDRESSES = apiActionTypes(`${base}GET_ALL_ADDRESSES`);
export const GET_SUBSCRIPTION_SUMMARY = apiActionTypes(`${base}GET_SUBSCRIPTION_SUMMARY`);
export const GET_ORDER_SUMMARY = apiActionTypes(`${base}GET_ORDER_SUMMARY`);
export const GET_PAYMENT_INTENT = apiActionTypes(`${base}GET_PAYMENT_INTENT`);
export const CREATE_SUBSCRIPTION = apiActionTypes(`${base}CREATE_SUBSCRIPTION`);
export const CREATE_DIRECT_ORDER = apiActionTypes(`${base}CREATE_DIRECT_ORDER`);
export const GET_PAYMENT_METHODS = apiActionTypes(`${base}GET_PAYMENT_METHODS`);
export const SAVE_PAYMENT_METHOD = apiActionTypes(`${base}SAVE_PAYMENT_METHOD`);
export const REMOVE_PAYMENT_METHOD = apiActionTypes(`${base}REMOVE_PAYMENT_METHOD`);
export const GET_ACCOUNT_INFO_ACTION_TYPES = apiActionTypes(`${base}GET_ACCOUNT_INFO_ACTION_TYPES`);
export const SET_SHIPPING_METHOD = `${base}SET_SHIPPING_METHOD`;
export const RESET_CHECKOUT_DATA = `${base}RESET_CHECKOUT_DATA`;

export const financingSteps = [
    'Terms and Conditions',
    'Customer Details',
    'Shipping & Payment Details',
];

export const directSteps = [
    'Shipping',
    'Delivery',
    'Payment',
];

export const DIRECT_ORDER = 'direct';
export const FINANCING_ORDER = 'financing';
