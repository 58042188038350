import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button, ListGroup, ListGroupItem } from 'reactstrap';
import { createSelector } from 'reselect';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import queryString from 'qs';
import { getAccountInfo, logout, resetCustomerData } from '../../containers/CustomerContainer/actions';
import If from '../../components/Shared/If';
import { menuLinksChild, menuLinksParent, SALES_DASHBOARD, STORE_SUCCESS } from '../../containers/CustomerContainer/constants';
import AuthManager from '../../utils/AuthManager';
import {
    makeSelectAccountInfo,
} from '../../containers/CustomerContainer/selectors';

const SidePanel = props => {
    const [isMobile, setWidth] = useState(false);
    const [isOpen, toggle] = useState(false);
    const { pathname, search } = useLocation();
    const query = queryString.parse(search.slice(1));
    const { getAccountInfo: getAccountInformation, accountInfo } = props;
    useEffect(() => {
        setWidth(window.innerWidth < 1024);
        window.addEventListener('resize', () => setWidth(window.innerWidth < 1024));

        return () => {
            window.removeEventListener('resize', () => setWidth(window.innerWidth < 1024));
        };
    }, []);

    useEffect(
        () => {
            toggle(false);
        },
        [window.location.href]
    );

    useEffect(() => {
        getAccountInformation();
    }, []);
    
    const parentsLinks = useMemo(() => {
        let links = menuLinksParent;
        if (!accountInfo?.show_store_success_page) {
            links = links.filter(link => link.name !== STORE_SUCCESS);
        }
        if (!accountInfo?.show_sales_dashboard) {
            links = links.filter(link => link.name !== SALES_DASHBOARD);
        }
        return links;
    }, [accountInfo?.show_store_success_page, accountInfo?.show_sales_dashboard]);

    const handleLogout = async () => {
        const { logout, resetCustomerData } = props;
        try {
            await logout();
            resetCustomerData();
            toast.success('Logged Out', { className: 'toast-success' });
        } catch (e) {
            toast.error('Please log in again', { className: 'toast-error' });
        }
    };

    return (
        <aside className="side-panel-container">
            <If condition={isMobile}>
                <i
                    onClick={() => toggle(!isOpen)}
                    className={`${isOpen ? 'fa fa-times' : 'fa fa-ellipsis-v'} light fa-2x side-panel-mobile-button`}
                />
            </If>
            {(!isMobile || (isMobile && isOpen)) && (
                <ListGroup className={`font-universe ${isMobile ? 'side-panel-mobile' : ''}`}>
                    {AuthManager.hasPortalAccess()
                        ? parentsLinks.map(link => {
                            const { recordType } = queryString.parse(link.params?.slice(1));
                            return (
                                <ListGroupItem
                                    className={`p-0 ${
                                        (!link.params && link.link === pathname) ||
                                          (link.params && recordType === query.recordType)
                                            ? 'selected'
                                            : ''
                                    }`}
                                    key={link.name}
                                >
                                    <Link to={`/shop${link.link}${link.params || ''}`}>{link.name}</Link>
                                </ListGroupItem>
                            );
                        })
                        : menuLinksChild.map(link => (
                            <ListGroupItem
                                className={`p-0 ${link.link === pathname ? 'selected' : ''}`}
                                key={link.name}
                            >
                                <Link to={link.link}>{link.name}</Link>
                            </ListGroupItem>
                        ))}
                    {AuthManager.hasPortalAccess() && (
                        <ListGroupItem
                            className={`p-0 ${pathname === '/customer/account/me/order/history' ? 'selected' : ''}`}
                        >
                            <Link to="/shop/customer/account/me/order/history">
                                {AuthManager.isParent() ? 'Review Orders' : 'Order History'}
                            </Link>
                        </ListGroupItem>
                    )}
                    <ListGroupItem className={`p-0 ${pathname === '/customer/billing-agreement' ? 'selected' : ''}`}>
                        <Link to="/shop/customer/billing-agreement">Billing Agreements</Link>
                    </ListGroupItem>
                    <Button className="logout" onClick={handleLogout}>
                        Logout
                    </Button>
                    <ListGroupItem className="p-0 tw-mt-5 tw-rounded tw-bg-yellow-300">
                        <Link to="/shop/customer/request-marketing-materials">
                            Request Marketing Materials
                        </Link>
                    </ListGroupItem>
                </ListGroup>
            )}
        </aside>
    );
};


const mapStateToProps = createSelector(
    [
        makeSelectAccountInfo(),
    ],
    (
        accountInfo,
    ) => ({
        accountInfo,
    })
);

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            logout,
            resetCustomerData,
            getAccountInfo,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(SidePanel);
