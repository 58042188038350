import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import AuthManager from './utils/AuthManager';
import Wrapper from './containers/CustomerContainer/WrapperContainer';

const ProtectedRoute = ({ component: Component, displaySidePanel, ...rest }) => (
    <Route
        render={props =>
            AuthManager.get() ? (
                <Wrapper displaySidePanel={displaySidePanel}>
                    <Component {...props} {...rest} />
                </Wrapper>
            ) : (
                <Redirect to={{ pathname: '/shop/customer/login', state: { from: props.location } }} />
            )
        }
        {...rest}
    />
);

ProtectedRoute.propTypes = {
    component: PropTypes.func.isRequired,
    rest: PropTypes.object,
    location: PropTypes.object,
    displaySidePanel: PropTypes.bool,
};

export default ProtectedRoute;
