import { CALL_API, METHODS } from '../../middleware';
import URLs from '../../utils/url';
import {
    GET_PRODUCT_DETAIL_ACTION_TYPES,
    GET_PRODUCT_SUMMARY_ACTION_TYPES,
    ADD_PRODUCT_TO_CART_ACTION_TYPES,
    RESET_CHANGE_LOG,
    ADD_TO_FAVORITE_ACTION_TYPES,
    REMOVE_FROM_FAVORITE_ACTION_TYPES,
    UPDATE_FAVORITES,
    RESET_ADD_TO_CART,
    RESET_PRODUCT_DATA,
} from './constants';
import AuthManager from '../../utils/AuthManager';
import PricingTypeManager from '../../utils/PricingTypeManager';

const getProductDetail = (url) => {
    const priceType = PricingTypeManager.get();
    const userId = AuthManager.getAnonymousUserId();
    const resultantUrl = `${url}${(userId ? `&user_id=${userId}` : '')}`;
    return {
        [CALL_API]: {
            requestConfig: {
                useAuth: true,
                baseUrl: `${URLs.apiBaseUrl}${URLs.apisV2}`,
                url: resultantUrl,
                method: METHODS.GET,
                headers: { 'Lashbrook-PTPE': priceType },
            },
            types: GET_PRODUCT_DETAIL_ACTION_TYPES,
        },
    };
};

const getProductSummary = (url) => {
    const priceType = PricingTypeManager.get();
    return {
        [CALL_API]: {
            requestConfig: {
                useAuth: true,
                baseUrl: `${URLs.apiBaseUrl}${URLs.apisV2}`,
                url,
                method: METHODS.GET,
                headers: { 'Lashbrook-PTPE': priceType },
            },
            types: GET_PRODUCT_SUMMARY_ACTION_TYPES,
        },
    };
};

const addProductToCart = (url, cartId) => ({
    // specify the cart id to add the item to. If this is blank, the auth token will be used to look up the cart id.
    [CALL_API]: {
        requestConfig: {
            useAuth: true,
            baseUrl: `${URLs.apiBaseUrl}${URLs.apisV2}`,
            url,
            method: METHODS.POST,
            headers: { 'Lashbrook-Cart': cartId },
        },
        types: ADD_PRODUCT_TO_CART_ACTION_TYPES,
    },
});

/**
 * The below action is added to handle add ring to favorite.
 *
 * @param favorite
 *
 * favorite object structure { "product_url": "XYZ", "ring_image_url": "XYZ"}
 *
 *
 * */

const addToFavorite = favorite => {
    const userId = AuthManager.getAnonymousUserId();
    return {
        [CALL_API]: {
            requestConfig: {
                useAuth: true,
                baseUrl: URLs.apiBaseUrl,
                url: URLs.favorite,
                method: METHODS.POST,
                data: { user_id: userId, ...favorite },
            },
            types: ADD_TO_FAVORITE_ACTION_TYPES,
        },
    };
};

const removeFromFavorite = favorite => {
    const userId = AuthManager.getAnonymousUserId();
    const url = `${URLs.favorite}${(userId ? `?user_id=${userId}` : '')}`;
    return {
        [CALL_API]: {
            requestConfig: {
                useAuth: true,
                baseUrl: URLs.apiBaseUrl,
                url,
                method: METHODS.DELETE,
                data: favorite,
            },
            types: REMOVE_FROM_FAVORITE_ACTION_TYPES,
        },
    };
};

const resetChangeLog = () => ({
    type: RESET_CHANGE_LOG,
});

const resetAddToCart = () => ({
    type: RESET_ADD_TO_CART,
});

const resetProductData = () => ({
    type: RESET_PRODUCT_DATA,
});

/**
 * updateFavorites will update the is_favorite flag under product in redux store.
 *
 * @param payload
 *
 * payload is a boolean flag containing updated values of is_favorite flag.
 *
 * */

const updateFavorites = payload => ({
    type: UPDATE_FAVORITES,
    payload,
});


export {
    getProductDetail,
    getProductSummary,
    addProductToCart,
    resetChangeLog,
    addToFavorite,
    removeFromFavorite,
    updateFavorites,
    resetAddToCart,
    resetProductData,
};
