export default class LocalStorage {
    static get(name) {
        return localStorage.getItem(name);
    }

    static add(name, data) {
        localStorage.setItem(name, data);
    }

    static remove(name) {
        localStorage.removeItem(name);
    }
}
