/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable camelcase */
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Tooltip } from 'reactstrap';
import Slider from 'react-rangeslider';
import queryString from 'qs';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import currency from 'currency.js';
import { SYMBOLS } from '../../utils/Constants';
import { addProductToCart, addToFavorite, getProductDetail, getStaticProductDetail, getProductSummary,
    getSimilarRings, resetAddToCart, removeFromFavorite, updateFavorites, resetProductData } from '../RingCustomizerContainer/action';
import Accordion from '../../components/Shared/Accordion';
import GuaranteedIcon from '../../assets/images/Guaranteed.svg';
import MadeInIcon from '../../assets/images/made.svg';
import SizingIcon from '../../assets/images/sizing.svg';
import GenuineMaterialIcon from '../../assets/images/GenuineMaterials.svg';
import './index.scss';
import { faqList, loveStories1, storiesList, swiperList } from './details';
import Map from '../../components/Shared/Map';
import MyGallery from '../RingCustomizerContainer/components/MyGallery';
import AuthManager from '../../utils/AuthManager';
import CustomizeIcon from '../../assets/images/Lashbrook-Customize-Gif.gif';
import Loading from '../../components/Shared/Loader';
import CartManager from '../../utils/CartManager';
import config from '../../config';
import ProtectionPlanModal from '../../components/Product/ProtectionPlanModal';
import { FAVORITE_MESSAGES } from '../CategoryContainer/constants';
import Favorite from '../../components/Shared/Favorite';
import If from '../../components/Shared/If';
import Summary from '../RingCustomizerContainer/components/Summary';
import { getPageData } from '../RingCustomizerContainer/utils/Pages';
import { ITab } from '../RingCustomizerContainer/types/customizer';
import { getSelectedOption } from '../RingCustomizerContainer/utils/commonRingUtils';

interface IProps {
  match: any;
  location: any;
  history: any;
  type: number;
  ringProduct: any;
  staticProduct: any;
  isFetching: boolean;
  productSummary: any;
  addProductResult: any;
  similarRings: Array<any>;
  currentCurrencyCode: 'USD' | 'CAD' | 'EUR',
  currencyConversion: {
    USD: number,
    CAD: number,
    EUR: number
  },
  getProductDetail: (URL: string, type?: number) => any;
  getStaticProductDetail: (URL: string) => any;
  addProductToCart: (URL: string, type?: number) => any;
  resetAddToCart: (URL: string, type?: number) => any;
  getProductSummary: (URL: string, type?: number) => any;
  getSimilarRings: (material: string) => any;
  addToFavorite: (payload: any) => void;
  removeFromFavorite: (payload: any) => void;
  updateFavorites: (payload: any) => void;
  resetProductData: () => void;
}

const ProductDetail = ({ match, location, history, type, ringProduct, staticProduct, isFetching, productSummary, addProductResult,
    similarRings, currentCurrencyCode, currencyConversion, getProductDetail, getStaticProductDetail, addProductToCart, resetAddToCart,
    getProductSummary, getSimilarRings, addToFavorite, removeFromFavorite, updateFavorites, resetProductData }: IProps) => {
    const [product, setProduct] = useState(null);
    const [selectedSize, setSelectedSize] = useState('');
    const [rangeValue, setRangeValue] = useState('');
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [showProtectionModal, setShowProtectionModal] = useState(false);
    const [isSizeUpdating, setIsSizeUpdating] = useState(false);
    const [swiper, setSwiper] = useState<Swiper>(null);
    const [pages, setPages] = useState<ITab[]>([]);
    const [url, setUrl] = useState('');

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0 });
        return () => {
            /**
             * On unmount reset product data
             */
            resetProductData();
        };
    }, []);

    useEffect(() => {
        const { params } = match;
        if (type === 1) {
            const { ring_material } = params;
            getSimilarRings(ring_material);
        } else if (type === 2) {
            const { ring_materials } = params;
            getSimilarRings(ring_materials);
        } else if (type === 3) {
            const { ring_materials } = params;
            getSimilarRings(ring_materials);
        } else if (type === 4) {
            const { ring_material } = params;
            getSimilarRings(ring_material);
        }
    }, [match.params, location.search]);

    useEffect(() => {
        const { params } = match;
        const { search: searchStr } = location;
        if (type === 1) {
            const { profile, width, ring_material } = params;
            const path = `/rings/profiles/${profile}/widths/${width}/ring_materials/${ring_material}${searchStr}`;
            setUrl(path);
            getProductDetail(path, type);
        } else if (type === 2) {
            const { styles, ring_materials, color_arrangements, stone_quantities, variants } = params;
            const path = `/women/rings/styles/${styles}/ring_materials/${ring_materials}/color_arrangements/${color_arrangements}/stone_quantities/${stone_quantities}/variants/${variants}${searchStr}`;
            setUrl(path);
            getProductDetail(path, type);
        } else if (type === 3) {
            const { ring_type, ring_materials } = params;
            const path = `/rings/${ring_type}/ring_materials/${ring_materials}${searchStr}`;
            setUrl(path);
            getProductDetail(path, type);
        } else if (type === 4) {
            const { profile, width, ring_material } = params;
            const path = `/static-product/profiles/${profile}/widths/${width}/ring_materials/${ring_material}${searchStr}`;
            setUrl(path);
            getStaticProductDetail(path);
        }
    }, [match.params, location.search]);

    useEffect(() => {
        if (addProductResult?.message) {
            if (!addProductResult.result) {
                toast.error(addProductResult.message, { className: 'toast-error' });
            } else {
                toast.success(addProductResult.message, { className: 'toast-success' });
            }
        }
    }, [addProductResult?.message]);

    useEffect(() => {
        if (!isFetching && isFetching !== isSizeUpdating) {
            setIsSizeUpdating(false);
        }
    }, [isFetching]);

    useEffect(() => {
        const product = (type === 1 || type === 2 || type === 3) ? ringProduct : staticProduct;
        setProduct(product);
        if (!product || !url) {
            return;
        }
        if (url && product?.ring_options_url !== url) {
            history.push(`/shop/product${product.ring_options_url}`);
        }
    }, [ringProduct, staticProduct]);

    useEffect(() => {
        if (!product || !url) {
            return;
        }
        if (type === 1 || type === 2 || type === 3) {
            const isDataTabAccess = AuthManager.hasDataTabAccess();
            const tabData = getPageData(product, isDataTabAccess, type, match.params.ring_type) as ITab[];
            setPages(tabData);

            const selectedOption = type === 3 ? getSelectedOption(product?.finger_size) : getSelectedOption(product?.size);
            if (selectedOption) {
                const size = parseFloat(selectedOption.sku);
                setRangeValue(size);
                setSelectedSize(size);
            }
        } else if (type === 4) {
            const size = parseFloat(product['Ring Size']);
            setRangeValue(size);
            setSelectedSize(size);
        }
    }, [product]);


    const getMinRange = (list) => list && list.length > 0 ? parseFloat(list[0].sku) : 3;

    const getMaxRange = (list) =>
        list && list.length > 0 ? parseFloat(list[list.length - 1].sku) : 24;

    const getStep = (list) =>
        list && list.length > 0 ? parseFloat(list[0].sku) - parseFloat(list[1].sku) : 0.25;

    const sizePlus = () => {
        let newValue;
        if (rangeValue >= 3 && rangeValue < 24) {
            newValue = rangeValue + 0.25;
            handleSizeChange(newValue);
            setRangeValue(newValue);
        } else {
            return null;
        }
    };

    const sizeMinus = () => {
        let newValue;
        if (rangeValue > 3 && rangeValue <= 24) {
            newValue = rangeValue - 0.25;
            handleSizeChange(newValue);
            setRangeValue(newValue);
        } else {
            return null;
        }
    };


    const handleSizeChange = (newSize) => {
        setIsSizeUpdating(true);
        const { params } = match;
        let { search: searchStr } = location;
        const searchParams = new URLSearchParams(searchStr || '');
        const searchParamsObject = Object.fromEntries(searchParams.entries());
        if(type !== 3) {
            searchParamsObject.size = newSize || rangeValue;
        } else {
            searchParamsObject.finger_size = newSize || rangeValue;
        }
        searchStr = queryString.stringify(searchParamsObject);

        let path = '';
        if (type === 1) {
            const { profile, width, ring_material } = params;
            path = `/rings/profiles/${profile}/widths/${width}/ring_materials/${ring_material}?${searchStr}`;
        } else if (type === 2) {
            const { styles, ring_materials, color_arrangements, stone_quantities, variants } = params;
            path = `/women/rings/styles/${styles}/ring_materials/${ring_materials}/color_arrangements/${color_arrangements}/stone_quantities/${stone_quantities}/variants/${variants}?${searchStr}`;
        } else if (type === 3) {
            const { ring_type, ring_materials } = params;
            path = `/rings/${ring_type}/ring_materials/${ring_materials}?${searchStr}`;
        } else if (type === 4) {
            const { profile, width, ring_material } = params;
            path = `/static-product/profiles/${profile}/widths/${width}/ring_materials/${ring_material}?${searchStr}`;
        }
        history.replace(`/shop/product${path}`);
    };


    const toggleProtectionModal = () => setShowProtectionModal(prev => !prev);

    const addToCartWithProtectionPlan = () => addToCart(true);

    const addToCartWithoutProtectionPlan = () => addToCart(false);

    const handleAddToCart = () => {
        const auth = AuthManager.get();
        if (!config.promptForProtectionPlan || auth) {
            addToCartWithoutProtectionPlan();
        } else {
            setShowProtectionModal(true);
        }
    };


    const addToCart = (withProtectionPlan) => {
        const { params } = match;
        let { search } = location;
        const auth = AuthManager.get();

        if (withProtectionPlan && !auth) {
            search += '&protection_plan=1';
        }
        let url;
        let data = {};
        if (type === 1) {
            url = `/rings/profiles/${params.profile}/widths/${params.width}/ring_materials/${params.ring_material}/cart${search}`;
        } else if (type === 2) {
            url = `/women/rings/styles/${params.styles}/ring_materials/${params.ring_materials}/color_arrangements/${params.color_arrangements}/stone_quantities/${params.stone_quantities}/variants/${params.variants}/cart${search}`;
        } else if (type === 3) {
            url = `/rings/${params.ring_type}/ring_materials/${params.ring_materials}/cart${search}`;
        } else if (type === 4) {
            url = `/static-product/profiles/${params.profile}/widths/${params.width}/ring_materials/${params.ring_material}/cart${search}`;
            data = {
                hashed_url: product.hashed_url,
            };
        }
        data.magic_number = product?.magic_number;

        const cartId = CartManager.get();
        if (config.allowAddToCart) {
            addProductToCart(url, cartId, type, data).then(() => setTimeout(() => resetAddToCart(), 5000));
        } else if (config.id === 'RINGDESIGNER') {
            if (window.parent && window.parent.postMessage) {
                addProductToCart(url, cartId).then(({ payload }) => {
                    const { productId } = payload;
                    if (typeof productId === 'undefined') return;
                    const items = [
                        {
                            id: productId,
                            quantity: 1,
                        },
                    ];
                    const message = { action: 'addToCart', arguments: { items } };
                    window.parent.postMessage(message, '*');
                });
            }
        } else {
            // if add to cart isn't supported, get the product summary and post a message to the parent frame.
            // this is used to support integrations where the checkout is handled by a different backend.
            getProductSummary(url).then(() => {
                if (window.parent && window.parent.postMessage) {
                    const message = { action: 'addToCart', arguments: productSummary };
                    window.parent.postMessage(message, '*');
                }
            });
        }
    };

    const toggleFavorite = () => {
        const favorite = {
            product_url: product.ring_options_url,
        };
        if (product.is_favorite) {
            removeFromFavorite(favorite).then(response => {
                if (response && response.payload && response.payload.result) {
                    updateFavorites(false);
                    toast.success(FAVORITE_MESSAGES.remove, { className: 'toast-success' });
                }
            });
        } else if (!product.is_favorite) {
            favorite.ring_image_url = product.images && product.images.length > 0 ? product.images[0] : '';
            addToFavorite(favorite).then(response => {
                if (response && response.payload && response.payload.result) {
                    updateFavorites(true);
                    toast.success(FAVORITE_MESSAGES.add, { className: 'toast-success' });
                }
            });
        }
    };


    const handleRedirect = () => {
        const customizerUrl = `/shop${product.ring_options_url}`;
        window.open(customizerUrl, '_blank');
    };

    const moveSlideTo = (index) => () => {
        swiper.slideToLoop(index, 2000);
    };

    const getPrice = () => {
        const conversionRate = currencyConversion ? currencyConversion[currentCurrencyCode] : 1;
        const symbol = SYMBOLS[currentCurrencyCode];
        const convertedCurrency = currency(product?.price || 0).multiply(conversionRate);
        return currency(convertedCurrency).format({ symbol });
    };

    const getSummaryContent = () => {
        if (type === 4) {
            return (
                <div className="tw-bg-blue-50 tw-text-xs tw-w-full">
                    <p className="tw-flex tw-justify-between tw-mb-1">
                        <span>Magic Number</span>
                        <span>{product?.magic_number}</span>
                    </p>
                    {Object.keys(product || {})?.filter(key => !['Ring Size', 'data', 'properties', 'sku', 'magic_number'].includes(key) && product[key] != null)?.map((key, i) => (
                        <p key={i} content={key} className="tw-flex tw-justify-between tw-mb-1">
                            <span>{key}</span>
                            <span>{product[key]}</span>
                        </p>
                    ))}
                </div>
            );
        }
        return <Summary product={product} tabs={pages} isPDP />;
    };

    return (
        <>
            <Loading timedOut={isFetching} />
            <div id="product_details_container" className="product_details_container secondary-font-family tw-text-blue-900 sm:tw-pt-3">
                <div id="product-container" className="tw-py-9 lg:tw-py-10 xl:tw-w-4/5 lg:tw-w-5/6 sm:tw-w-11/12 tw-mx-auto">
                    <div className="tw-flex tw-flex-col lg:tw-flex-row lg:tw-items-start tw-gap-8 lg:tw-gap-0 xl:tw-gap-24 lg:tw-mx-9">
                        {/* Gallery */}
                        <div className="tw-flex-1 tw-flex tw-flex-col tw-items-center tw-relative tw-px-7 lg:px-0">
                            <If condition={config.allowFavorites}>
                                <div className="tw-flex tw-absolute tw-left-9 tw-cursor-pointer tw-z-101">
                                    <Favorite
                                        isFavorite={!!product?.is_favorite}
                                        toggleFavorite={toggleFavorite}
                                        id="product-fav-btn"
                                    />
                                </div>
                            </If>
                            <div className="tw-flex-1 tw-w-full tw-mb-8 tw-min-h-[240px] md:tw-min-h-[300px] tw-relative">
                                <MyGallery images={product?.images || (product?.image_url ? [product?.image_url] : [])} showNav goFullscreen={false} />
                            </div>
                            <If condition={type !== 4}>
                                <button className="customizer-btn tw-font-[inherit]" onClick={handleRedirect}>
                                    <img src={CustomizeIcon} alt="" width={47} height={40} className="mr-2" />
                                    <span>Customize with Lashbrook Builder</span>
                                </button>
                            </If>
                        </div>
                        {/* Details */}
                        <div className="tw-flex-1">
                            <div className="tw-bg-blue-50 tw-text-blue-900 tw-px-8 xl:tw-px-13 tw-py-9 lg:tw-rounded-[10px]">
                                <h1 className="tw-text-2xl tw-mb-8 primary-font-family tw-hidden lg:tw-block">{product?.title} - {getPrice()}</h1>
                                <h1 className="tw-text-2xl tw-mb-3 primary-font-family lg:tw-hidden tw-pb-3 tw-border-transparent tw-border-b-gold tw-border-solid">{product?.title}</h1>

                                <div className="description tw-text-blue-900">
                                    <h2 className="tw-font-bold tw-text-base tw-mb-3 lg:tw-hidden">Details</h2>
                                    {/* <p className="tw-text-dark-grey tw-underline tw-hidden lg:tw-block">As low as $134.24 per month for 12 months*</p> */}
                                    <p className="tw-mb-0">{product?.description}</p>
                                    <p className="tw-text-dark-grey tw-text-xs tw-mb-3">{product?.sku}</p>
                                </div>

                                <Accordion
                                    title="SUMMARY"
                                    content={getSummaryContent()}
                                    IsFontInherit
                                    isJXSContent
                                    containerClass="pdp-summary-accordion"
                                    iconPosition="right"
                                />

                                <div className="ring-size">
                                    <p className="tw-text-blue-900 tw-font-bold tw-uppercase">
                                        {'Estimate your Size :'}
                                        <strong className="title-text-color tw-pl-2">
                                            {selectedSize}
                                        </strong>
                                        {!AuthManager.get() &&
                                            <>
                                                <i className="fa fa-info-circle ml-2" aria-hidden="true" id="size_info" />
                                                <Tooltip
                                                    className="size-info-tooltip"
                                                    placement="bottom"
                                                    isOpen={tooltipOpen}
                                                    target="size_info"
                                                    toggle={() => setTooltipOpen(!tooltipOpen)}
                                                >
                                                    Don't worry, we'll ship you a sizing kit before we begin making your ring.
                                                </Tooltip>
                                            </>
                                        }
                                    </p>
                                    <div className={clsx('slider__container', { loading: isFetching && isSizeUpdating })}>
                                        <span className="decrement">
                                            <i
                                                className={`fa fa-minus ${rangeValue <= 3 ? 'disabled' : ''}`}
                                                aria-hidden="true"
                                                onClick={sizeMinus}
                                            />
                                        </span>
                                        <Slider
                                            min={getMinRange(product?.finger_size || product?.size)}
                                            max={getMaxRange(product?.finger_size || product?.size)}
                                            step={getStep(product?.finger_size || product?.size)}
                                            value={rangeValue}
                                            onChange={setRangeValue}
                                            onChangeComplete={() => handleSizeChange()}
                                            className="slider__range"
                                        />
                                        <span className="increment">
                                            <i
                                                className={`fa fa-plus ${rangeValue >= 24 ? 'disabled' : ''}`}
                                                aria-hidden="true"
                                                onClick={sizePlus}
                                            />
                                        </span>
                                    </div>
                                </div>
                                <div className="cta-container">
                                    <button onClick={handleAddToCart} className="tw-bg-blue-500 hover:tw-bg-blue-500 tw-text-white hover:tw-text-white tw-w-full tw-px-9 tw-py-2 tw-rounded-[10px] tw-font-bold tw-normal-case lg:tw-mb-4 focus:tw-border-none  focus:tw-shadow-none focus:tw-outline-none tw-h-14 tw-hidden lg:tw-inline-block tw-font-[inherit]" >Add to Cart</button>
                                    <button onClick={handleAddToCart} className="tw-bg-blue-500 hover:tw-bg-blue-500 tw-text-white hover:tw-text-white tw-w-full tw-px-9 tw-py-2 tw-rounded-[10px] tw-font-bold tw-normal-case lg:tw-mb-4 focus:tw-border-none  focus:tw-shadow-none focus:tw-outline-none tw-h-14 lg:tw-hidden tw-mb-4 tw-font-[inherit]" >Add to Cart - {getPrice()}</button>
                                    {/* <p className="tw-text-dark-grey tw-underline lg:tw-hidden tw-my-4 tw-text-center">As low as $134.24 per month for 12 months*</p> */}
                                    <If condition={!(AuthManager.get() && AuthManager.getUsername())}>
                                        <AnchorLink href="#stores-near-container">
                                            <button className="tw-bg-blue-900 hover:tw-bg-blue-900 tw-text-white hover:tw-text-white tw-w-full tw-px-9 tw-py-2 tw-rounded-[10px] tw-font-bold tw-normal-case focus:tw-border-none  focus:tw-shadow-none focus:tw-outline-none tw-h-14 tw-font-[inherit]" >Find in Store</button>
                                        </AnchorLink>
                                    </If>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Info Icons */}
                <div id="info-icons-container" className="xs:tw-pt-0 tw-py-9 lg:tw-py-10 xl:tw-w-4/5 lg:tw-w-5/6 sm:tw-w-11/12 tw-mx-auto" >
                    <div className="tw-flex tw-flex-wrap tw-justify-between tw-mx-9">
                        <AnchorLink
                            href="#info-container"
                            className="tw-text-center tw-my-9 tw-w-2/4 md:tw-w-auto"
                            onClick={moveSlideTo(0)}
                        >
                            <img src={GuaranteedIcon} alt="guarantee_for_life" className="tw-h-14" />
                            <div className="tw-text-base lg:tw-text-lg tw-text-blue-900">Lifetime Warranty</div>
                        </AnchorLink>
                        <AnchorLink
                            href="#info-container"
                            className="tw-text-center tw-my-9 tw-w-2/4 md:tw-w-auto"
                            onClick={moveSlideTo(1)}
                        >
                            <img src={MadeInIcon} alt="handcraft_here" className="tw-h-14" />
                            <div className="tw-text-base lg:tw-text-lg tw-text-blue-900">Handcrafted Here</div>
                        </AnchorLink>
                        <AnchorLink
                            href="#info-container"
                            className="tw-text-center tw-my-9 tw-w-2/4 md:tw-w-auto"
                            onClick={moveSlideTo(2)}
                        >
                            <img src={SizingIcon} alt="sizing_life" className="tw-h-14" />
                            <div className="tw-text-base lg:tw-text-lg tw-text-blue-900">Sizing for Life</div>
                        </AnchorLink>
                        <AnchorLink
                            href="#info-container"
                            className="tw-text-center tw-my-9 tw-w-2/4 md:tw-w-auto"
                            onClick={moveSlideTo(3)}
                        >
                            <img src={GenuineMaterialIcon} alt="genuine material" className="tw-h-14" />
                            <div className="tw-text-base lg:tw-text-lg tw-text-blue-900">Genuine Materials</div>
                        </AnchorLink>
                    </div>
                </div>
                {/* FAQS */}
                <div id="faq-container" className="tw-py-9 lg:tw-py-10 xl:tw-w-4/5 lg:tw-w-5/6 sm:tw-w-11/12 tw-mx-auto">
                    <h2 className="tw-text-center tw-mb-7 primary-font-family tw-font-normal tw-text-2xl lg:tw-text-5xl">FAQs</h2>
                    <div className="tw-mx-auto xl:tw-w-3/5 lg:tw-w-3/4 sm:tw-w-5/6 tw-w-11/12 tw-text-blue-900">
                        {faqList.map((item: any) => (
                            <Accordion
                                key={item.label}
                                title={item.label}
                                content={item.subText}
                                href={item.href}
                                contact={item.contact}
                                asset={item.asset}
                                IsFontInherit
                                contentClass="faq-content"
                            />
                        ))}
                    </div>
                </div>
                {/* Similar Rings */}
                <div id="similar-ring-container" className="tw-py-9 lg:tw-py-10 xl:tw-w-4/5 lg:tw-w-5/6 sm:tw-w-11/12 tw-mx-auto">
                    <h2 className="tw-text-center tw-mb-7 primary-font-family tw-font-normal tw-text-2xl lg:tw-text-5xl">Customers Also Browsed</h2>
                    <div className="tw-overflow-x-auto tw-flex tw-pb-6 tw-text-base tw-gap-7">
                        {similarRings.map(item => (
                            <AnchorLink
                                href="#product_details_container"
                                className="tw-flex-shrink-0 tw-cursor-pointer tw-text-blue-900 hover:tw-text-blue-900"
                                role="presentation"
                                onClick={() => history.push(`/shop/product${item.ring_options_url}`)}
                            >
                                <div className="tw-h-56 tw-w-56 tw-p-4 tw-bg-blue-50">
                                    <img src={`${config.assetsBaseUrl}${item.ring_image_url}`} alt="" className="tw-h-full" />
                                </div>
                                <div className="tw-pt-5 tw-text-center">{item.price}</div>
                            </AnchorLink>))}
                    </div>
                </div>
                {/* Love Stories */}
                <div id="love-stories-container" className="tw-hidden sm:tw-block tw-py-9 lg:tw-py-10 xl:tw-w-4/5 lg:tw-w-5/6 sm:tw-w-11/12 tw-mx-auto">
                    <h2 className="tw-text-center tw-mb-7 primary-font-family tw-font-normal tw-text-2xl lg:tw-text-5xl">Lashbrook Love Stories</h2>
                    <div className="tw-flex">
                        <div className="tw-w-1/3 xl:tw-p-3 lg:tw-p-2 sm:tw-p-1">
                            <img src={loveStories1} alt="love_stories_1" className="tw-w-full tw-h-full" />
                        </div>
                        <div className="tw-flex tw-flex-wrap tw-w-2/3">
                            {storiesList.map((url, index) => (
                                <div className="tw-w-1/4 xl:tw-p-3 lg:tw-p-2 sm:tw-p-1">
                                    <img src={url} alt={`love_stories_${index}`} className="tw-w-full tw-h-full" />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <If condition={!(AuthManager.get() && AuthManager.getUsername())}>
                    {/* Stores Near Me */}
                    <div id="stores-near-container" className="tw-py-9 lg:tw-py-10 xl:tw-w-4/5 lg:tw-w-5/6 sm:tw-w-11/12 tw-mx-auto">
                        <h2 className="tw-text-center tw-mb-3 primary-font-family tw-font-normal tw-text-2xl lg:tw-text-5xl">Stores Near Me</h2>
                        <Map alwaysShow showNearMeButton={false} showTitle={false} controlsPosition="top" />
                    </div>
                </If>
                {/* Info Anchoring */}
                <div id="info-container" className="tw-pt-9 lg:tw-pt-10 tw-pb-[72px] lg:tw-pb-20">
                    <Swiper
                        loop
                        slidesPerView={1}
                        onSwiper={setSwiper}
                        modules={[Navigation, Pagination]}
                        navigation
                        pagination={{
                            clickable: true,
                        }}
                    >
                        {swiperList.map((item, index: number) => (
                            <SwiperSlide key={index}>
                                <div className="xl:tw-h-[576px] lg:tw-h-[448px] md:tw-h-[332px] tw-h-[544px] tw-flex tw-items-center tw-flex-wrap">
                                    {/* Left content */}
                                    <div className="md:tw-w-1/2 md:tw-h-full tw-w-full tw-h-1/2 md:tw-order-1 tw-order-2 info-slide lg:tw-py-16 tw-py-4">
                                        <div className="xl:tw-w-1/2 md:tw-w-4/5 sm:tw-w-1/2 tw-w-4/5 tw-h-full tw-flex tw-flex-col tw-mx-auto">
                                            <h2 className="sm:tw-text-4xl tw-text-xl tw-text-center m-0 primary-font-family">{item.label}</h2>
                                            <div className="sm:tw-text-base tw-text-sm tw-flex-grow tw-flex tw-items-center tw-text-center">
                                                {item.content}
                                            </div>
                                        </div>
                                    </div>
                                    {/* Right content */}
                                    <img src={item.src} alt="" className="md:tw-w-1/2 md:tw-h-full tw-w-full tw-h-1/2 md:tw-order-2 tw-order-1" />
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
            <ProtectionPlanModal
                showAddToCartModal={showProtectionModal}
                toggleAddToCartModal={toggleProtectionModal}
                addToCartWithProtectionPlan={addToCartWithProtectionPlan}
                addToCartWithoutProtectionPlan={addToCartWithoutProtectionPlan}
            />
        </>
    );
};

const mapStateToProps = (state: any) => ({
    ringProduct: state.customize.product,
    staticProduct: state.customize.staticProduct,
    isFetching: state.customize.isFetching,
    productSummary: state.customize.productSummary,
    addProductResult: state.customize.addProductResult,
    similarRings: state.customize.similarRings,
    currentCurrencyCode: state.global.currentCurrencyCode,
    currencyConversion: state.global.currencyConversion,
});

const mapDispatchToProps = {
    getProductDetail,
    getStaticProductDetail,
    addProductToCart,
    resetAddToCart,
    getProductSummary,
    getSimilarRings,
    addToFavorite,
    removeFromFavorite,
    updateFavorites,
    resetProductData,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail);
