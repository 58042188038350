import React from 'react';

const BillingAgreement = () => (
    <div className="font-universe">
        <div className="font-weight-bold fs-20">Terms and Conditions</div>
        1. APPLICABLE CONTRACT PROVISIONS.<br />
        <br />
            a. “Buyer” means the entity issuing the Order, and any affiliates, subsidiaries, successors or assigns
            thereof. “Seller” refers to Diamonds Direct, LC doing business as Lashbrook. “Materials” means all the
            products and/or services to be supplied by Seller under the Order. “Order” this document which is signed by
            the Buyer for the supply of Materials. “Custom” means Material created by the Seller that is not considered
            a standard product or has been modified under the direction of the Buyer.<br />
        <br />
                b. These terms and conditions, together with the Order, constitute an offer by Buyer to purchase the
                Materials from Seller pursuant to the terms and conditions described herein. This offer is not an
                acceptance or a confirmation of any previous offer or proposal from Seller, and this offer shall be
                deemed to be a rejection and counteroffer with respect to any previous offer or proposal from Seller.
                Acceptance this agreement shall not be construed as an acceptance of any such previous offer or proposal
                or an acceptance of any different or additional terms proposed by Seller.<br />
        <br />
                    c. This offer shall become an “Agreement” upon acceptance by Seller. Seller shall be deemed to have
                    accepted this offer by commencement of performance called for in the Order, by delivery of the
                    Materials to Buyer or by any other act or communication constituting legal acceptance, whether or
                    not any such acceptance or confirmation purports to state terms additional to or different from
                    those stated herein. Buyer hereby expressly objects to and rejects any such additional or different
                    provisions, and none of such provisions shall be deemed to be a part of this Agreement unless
                    specifically agreed to in writing by Buyer and Seller.<br />
        <br />
                        2. SALE OF MATERIALS, PURCHASE PRICE AND TERMS OF PAYMENT.<br />
        <br />
                            a. Seller agrees to sell, transfer and deliver the Materials to Buyer for the purchase price
                            set forth in the Order, subject to all of the covenants, terms and conditions hereof.<br />
        <br />
                                b. Buyer agrees to purchase the Materials, subject to all of the covenants, terms and
                                conditions hereof, and to pay Seller for Materials. <br />
        <br />
                                    c. Material shipped against this Agreement will be invoiced at shipment of the
                                    Order. Seller reserves the right to correct or modify the Material price on the
                                    invoice. Buyer has the right to reject the changes between the Order and Invoiced
                                    price by removing the corrected or modified item from the order. Unless otherwise
                                    specified on the Order, payment of the purchase price shall be due thirty-days after
                                    shipment of materials.<br />
        <br />
                                        d. Seller shall be responsible for arranging all shipping and insurance
                                        coverage. Buyer shall be responsible for all shipping and insurance costs as
                                        outlined on the order.<br />
        <br />
                                            e. A service charge of 1.5% per month will be assessed on all past due balances
                                             remaining at the end of each month.  Customer agrees to a 30% restocking fee in
                                             the event this order is returned.  Customer also agrees to assume all collection
                                             fees including attorney and court fees if necessary.  Customer further agrees to
                                             pay 30% collection charge, in the event of default, if the account is placed with
                                             JBT or an attorney or bonded collection agency. <br />
        <br />
                                                i. If prepayment is required, Buyer agrees to make payment within 30
                                                days of signed agreement. The Materials from this order will not start
                                                in production until prepayment is made. <br />
                                                ii. If prepayment is not made in 30 days, the payment will then become
                                                100% prepayment with a 1.5% per month service charge added to the past
                                                due. <br />
                                                iii. If prepayment is not made within 90 days of signed agreement, the
                                                Seller retains the right to start the collections process for the
                                                balance of the payment due, plus the service charges and any charges
                                                related to the collections of debt. <br />
        <br />
                                                    f. Seller will charge a fee for rush delivery and reserves the right
                                                    to decline request due to availability and schedule. <br />
        <br />
                                                        3. EXCHANGES<br />
                                                        a. Buyer is required to obtain a Return Authorization Number (RA
                                                        number), from Seller’s Customer Service Department, for any
                                                        return or exchange. <br />
        <br />
                                                            b. Excluding custom, as long as our sizing set is used,
                                                            Seller offers free size exchange for life at their
                                                            discretion. Laser engraving will be charged on each
                                                            exchange. <br />
        <br />
                                                                c. Custom rings are non-returnable and non-exchangeable.
                                                                Product may not be returned for credit. <br />
        <br />
                                                                    4. WARRANTY PROVISIONS.<br />
        <br />
                                                                        a. All Seller Materials come with
                                                                        a&nbsp;lifetime
                                                                        warranty&nbsp;covering&nbsp;manufacturing
                                                                        defects&nbsp;in materials and workmanship,
                                                                        regardless of the age of the product, but it
                                                                        does not cover normal wear and tear. Wear and
                                                                        tear is damage that naturally and inevitably
                                                                        occurs as a result of normal use.<br />
        <br />
                                                                            5. INDEMNIFICATION.<br />
        <br />
                                                                                a. Seller agrees to indemnify and hold
                                                                                Buyer harmless from and in respect of
                                                                                any damages, losses or expenses which
                                                                                Buyer may suffer or incur (including
                                                                                reasonable attorneys’ fees) arising out
                                                                                of, relating to or concerning any claim,
                                                                                action or allegation that any of the
                                                                                Materials (or the use of same in an
                                                                                intended manner) infringes any patent or
                                                                                intellectual property rights claimed by
                                                                                any third party; provided that Buyer
                                                                                shall notify Seller in writing of any
                                                                                such claim, act or allegation promptly
                                                                                after learning of the same and shall
                                                                                assist and cooperate in the defense or
                                                                                settlement thereof. Such defense or
                                                                                settlement shall be at Seller’s sole
                                                                                expense, and Seller shall pay all
                                                                                damages and costs finally awarded
                                                                                against Buyer as a result of any such
                                                                                suit or proceeding.<br />
        <br />
                                                                                    6. GENERAL PROVISIONS.<br />
        <br />
                                                                                        a. The title to any tangible
                                                                                        property, including but not
                                                                                        limited to material, goods,
                                                                                        equipment, apparatus, documents,
                                                                                        and literary property (e.g.,
                                                                                        drawings, manuscripts, artwork,
                                                                                        motion pictures, video programs,
                                                                                        and computer software), provided
                                                                                        to Buyer by Seller shall be
                                                                                        vested in Seller, and Buyer
                                                                                        agrees to return or deliver such
                                                                                        tangible property to Seller
                                                                                        within 30 days of request. <br />
        <br />
                                                                                            b. Buyer will not advertise
                                                                                            the Material or any of the
                                                                                            Sellers products below the
                                                                                            agreed minimum advertising
                                                                                            price. Seller has the right
                                                                                            to refuse any future order
                                                                                            if Buyer does not abide by
                                                                                            the minimum advertised
                                                                                            pricing policy. <br />
        <br />
                                                                                                c. Buyer and Seller
                                                                                                acknowledge that this
                                                                                                agreement does not
                                                                                                constitute an exclusive
                                                                                                selling territory for
                                                                                                the Buyer. <br />
        <br />
                                                                                                    d. Buyer and Seller
                                                                                                    acknowledge that
                                                                                                    they are each
                                                                                                    independent parties
                                                                                                    and neither shall be
                                                                                                    deemed an agent or
                                                                                                    representative of
                                                                                                    the other or have
                                                                                                    authority to bind
                                                                                                    the other in any
                                                                                                    manner whatsoever.
                                                                                                    Buyer acknowledges
                                                                                                    that they are not an
                                                                                                    exclusive agent or
                                                                                                    representative of
                                                                                                    Sellers
                                                                                                    Materials. <br />
        <br />
                                                                                                        e. This
                                                                                                        Agreement shall
                                                                                                        be governed by
                                                                                                        and interpreted
                                                                                                        in accordance
                                                                                                        with the laws of
                                                                                                        the State of
                                                                                                        Utah, without
                                                                                                        reference to any
                                                                                                        conflicts of law
                                                                                                        principles. <br />
        <br />
                                                                                                            f. This
                                                                                                            Agreement
                                                                                                            shall
                                                                                                            constitute
                                                                                                            the complete
                                                                                                            understanding
                                                                                                            and contract
                                                                                                            between
                                                                                                            Seller and
                                                                                                            Buyer with
                                                                                                            respect to
                                                                                                            the subject
                                                                                                            matter
                                                                                                            hereof and
                                                                                                            supersedes
                                                                                                            any prior
                                                                                                            written or
                                                                                                            oral
                                                                                                            understandings
                                                                                                            with regard
                                                                                                            thereto. No
                                                                                                            purported
                                                                                                            amendment,
                                                                                                            modification
                                                                                                            or waiver of
                                                                                                            any
                                                                                                            provision of
                                                                                                            the
                                                                                                            Agreement
                                                                                                            shall be
                                                                                                            binding on
                                                                                                            Buyer unless
                                                                                                            set forth in
                                                                                                            a written
                                                                                                            document
                                                                                                            signed by an
                                                                                                            authorized
                                                                                                            representative
                                                                                                            of Buyer.
                                                                                                            Any waiver
                                                                                                            shall be
                                                                                                            limited to
                                                                                                            the
                                                                                                            circumstance
                                                                                                            or event
                                                                                                            specifically
                                                                                                            referenced
                                                                                                            in the
                                                                                                            written
                                                                                                            waiver
                                                                                                            document and
                                                                                                            shall not be
                                                                                                            deemed a
                                                                                                            waiver of
                                                                                                            any other
                                                                                                            term of the
                                                                                                            Agreement
                                                                                                            between
                                                                                                            Seller and
                                                                                                            Buyer or of
                                                                                                            the same
                                                                                                            circumstance
                                                                                                            or event
                                                                                                            upon any
                                                                                                            recurrence
                                                                                                            thereof. <br />
        <br />
    </div>
);

export default BillingAgreement;
