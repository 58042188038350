import { apiActionTypes } from '../../middleware';
const base = '@@Containers/WomensProductContainer/';

export const GET_PRODUCT_DETAIL_ACTION_TYPES = apiActionTypes(`${base}GET_PRODUCT_DETAIL`);
export const GET_PRODUCT_SUMMARY_ACTION_TYPES = apiActionTypes(`${base}GET_PRODUCT_SUMMARY`);
export const ADD_PRODUCT_TO_CART_ACTION_TYPES = apiActionTypes(`${base}ADD_PRODUCT_TO_CART`);
export const ADD_TO_FAVORITE_ACTION_TYPES = apiActionTypes(`${base}ADD_TO_FAVORITE`);
export const REMOVE_FROM_FAVORITE_ACTION_TYPES = apiActionTypes(`${base}REMOVE_FROM_FAVORITE`);
export const RESET_CHANGE_LOG = `${base}RESET_CHANGE_LOG`;
export const UPDATE_FAVORITES = `${base}UPDATE_FAVORITES`;
export const RESET_ADD_TO_CART = `${base}RESET_ADD_TO_CART`;
export const RESET_PRODUCT_DATA = `${base}RESET_PRODUCT_DATA`;
