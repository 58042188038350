import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const LazyLoadedDiv = ({ children, style = {}, className = '', ...props }) => {
    const divRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.unobserve(entry.target);
                }
            });
        });

        if (divRef.current) {
            observer.observe(divRef.current);
        }

        return () => {
            if (divRef.current) {
                observer.unobserve(divRef.current);
            }
        };
    }, []);

    const defaultStyle = isVisible
        ? {
            opacity: 1,
            transition: 'opacity 0.5s ease-in-out',
        }
        : {
            opacity: 0,
            transition: 'opacity 0.5s ease-in-out',
        };

    return (
        <div
            {...props}
            ref={divRef}
            className={`lazy-loaded-div ${className}`}
            style={{ ...defaultStyle, ...style }}
        >
            {children}
        </div>
    );
};

LazyLoadedDiv.defaultProps = {
    style: {},
    className: '',
};
LazyLoadedDiv.propTypes = {
    children: PropTypes.node.isRequired,
    style: PropTypes.object,
    className: PropTypes.string,
};
export default LazyLoadedDiv;
