import React from 'react';

const Progress = ({ steps, currentStep, previous }) => {
    const clickHandler = step => {
        if (step < currentStep) {
            previous();
        }
    };
    const renderSteps = () => steps.map((step, i) => (
        <li
            key={step}
            className={`checkout-step ${currentStep === (i + 1) && 'active'} ${currentStep < (i + 1) && 'disabled-step'}`}
            onClick={() => clickHandler((i + 1))}
        >
            {step}
        </li>));
    return (
        <ul className="font-universe checkout-progress mx-auto">
            {renderSteps()}
        </ul>
    );
};

export default Progress;
