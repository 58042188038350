import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import AppContainer from './containers/AppContainer';

const GuestRoute = ({ component: Component, displayHeader = true, displayFooter = true, type, ...rest }) => (
    <Route
        render={props => (
            <div>
                <AppContainer displayHeader={displayHeader} displayFooter={displayFooter}>
                    <div>
                        <Component {...props} type={type} />
                    </div>
                </AppContainer>
            </div>
        )
        }
        {...rest}
    />
);

GuestRoute.propTypes = {
    component: PropTypes.func.isRequired,
    rest: PropTypes.object,
    location: PropTypes.object,
    displayHeader: PropTypes.bool,
    displayFooter: PropTypes.bool,
    type: PropTypes.number,
};

export default GuestRoute;
